import { XMarkIcon } from '@heroicons/react/24/outline';
import { useQueryClient } from '@tanstack/react-query';
import { useCreateLabelMutation } from '@youshift/shared/hooks/mutations';
import { SlotLabel } from '@youshift/shared/types';
import { LabelIcon, colorIndices } from '@youshift/shared/utils';
import { Dispatch, SetStateAction, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { YSButton } from '../Buttons';
import IconPicker from '../IconPicker';

type ShiftLabelSelectProps = {
  shiftLabels: SlotLabel[];
  selectedLabelId: number | null;
  setSelectedLabelId: (id: number | null) => void;
  isCustomLabel: boolean;
  setIsCustomLabel: Dispatch<SetStateAction<boolean>>;
  newLabelName: string;
  setNewLabelName: Dispatch<SetStateAction<string>>;
  labelIcon: LabelIcon | null;
  setLabelIcon: Dispatch<SetStateAction<LabelIcon | null>>;
};

export function ShiftLabelSelect({
  shiftLabels,
  selectedLabelId,
  setSelectedLabelId,
  isCustomLabel,
  setIsCustomLabel,
  newLabelName,
  setNewLabelName,
  labelIcon,
  setLabelIcon,
}: ShiftLabelSelectProps) {
  const queryClient = useQueryClient();
  const { t } = useTranslation();
  const { idItr: id } = useParams();
  const [saved, setSaved] = useState(false);

  const createLabelMutation = useCreateLabelMutation(queryClient, {
    onSuccess: (data, variables) => {
      setSelectedLabelId(data.id_slot_label);
      setSaved(true);
      queryClient.setQueryData(
        ['slotLabels', id],
        (oldData: SlotLabel[] | undefined) => {
          const newLabel = {
            ...data,
            name: variables.name,
            shade: variables.shade,
            icon: variables.icon,
            id_itr: variables.id_itr,
          };
          if (oldData) {
            return [...oldData, newLabel];
          }
          return [newLabel];
        },
      );
    },
  });

  const handleSelectChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedValue = e.target.value;
    if (selectedValue === 'custom') {
      setIsCustomLabel(true);
      setSelectedLabelId(null);
    } else {
      setIsCustomLabel(false);
      setSelectedLabelId(Number(selectedValue));
    }
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setNewLabelName(e.target.value);
  };

  const handleSaveNewLabel = () => {
    if (newLabelName.trim()) {
      createLabelMutation.mutate({
        id_itr: id,
        name: newLabelName,
        shade: colorIndices[Math.floor(Math.random() * colorIndices.length)],
        icon: labelIcon,
      });
    }
  };

  const handleCancelNewLabel = () => {
    setIsCustomLabel(false);
    setNewLabelName('');
    setLabelIcon(null);
  };

  return (
    <div>
      {!isCustomLabel ? (
        <select
          value={selectedLabelId || 'default'}
          onChange={handleSelectChange}
          className="mt-1 p-2 border rounded w-full"
        >
          <option value="default" disabled>
            {t('manager.sectionsConfig.selectLabel')}
          </option>
          {shiftLabels.map(label => (
            <option key={label.id_slot_label} value={label.id_slot_label}>
              {label.name}
            </option>
          ))}
          <option value="custom">{t('manager.sectionsConfig.newLabel')}</option>
        </select>
      ) : (
        <div className="flex space-x-2 items-center">
          <input
            type="text"
            value={newLabelName}
            onChange={handleInputChange}
            disabled={saved}
            className="mt-1 p-2 border rounded flex-grow"
            placeholder={t('manager.sectionsConfig.newLabel')}
          />
          <IconPicker
            disabled={saved}
            value={labelIcon}
            onChange={setLabelIcon}
          />
          {!saved ? (
            <>
              <YSButton
                variant="primary"
                onClick={handleSaveNewLabel}
                disabled={!newLabelName.trim() || !labelIcon}
              >
                {t('generic.save')}
              </YSButton>
              <YSButton variant="secondary" onClick={handleCancelNewLabel}>
                {t('generic.cancel')}
              </YSButton>
            </>
          ) : (
            <button
              onClick={() => {
                setIsCustomLabel(false);
                setSaved(false);
                setNewLabelName('');
                setLabelIcon(null);
                setSelectedLabelId(null);
              }}
              aria-label="Cancel custom label"
            >
              <XMarkIcon className="w-6 h-6 text-gray-400" />
            </button>
          )}
        </div>
      )}
    </div>
  );
}
