// reducers.ts
import { SectionSlot, VirtualSlot } from '@youshift/shared/types';
import { Dispatch, SetStateAction } from 'react';

export type SectionSlotsReducerActionType =
  | { type: 'UPDATE_REST'; value: number; idSectionSlots: number[] }
  | { type: 'UPDATE_LABEL'; newLabelId: number; idSectionSlots: number[] }
  | { type: 'DELETE_SLOTS'; idSectionSlots: number[] }
  | { type: 'RESET_STATE'; payload: Record<number, SectionSlot> };

export function sectionSlotsReducer(
  state: Record<number, SectionSlot>,
  action: SectionSlotsReducerActionType,
): Record<number, SectionSlot> {
  switch (action.type) {
    case 'UPDATE_REST': {
      const { value, idSectionSlots } = action;
      return Object.fromEntries(
        Object.entries(state).map(([id, slot]) => {
          const updatedSlot = { ...slot };
          if (idSectionSlots.includes(Number(id))) {
            updatedSlot.rest_period = Math.max(
              updatedSlot.rest_period + value,
              0,
            );
          }
          return [id, updatedSlot];
        }),
      );
    }
    case 'DELETE_SLOTS': {
      const { idSectionSlots } = action;
      return Object.fromEntries(
        Object.entries(state).filter(
          ([id]) => !idSectionSlots.includes(Number(id)),
        ),
      );
    }
    case 'UPDATE_LABEL': {
      const { newLabelId, idSectionSlots } = action;
      return Object.fromEntries(
        Object.entries(state).map(([id, slot]) => {
          const updatedSlot = { ...slot };
          if (idSectionSlots.includes(Number(id))) {
            updatedSlot.id_slot_label = newLabelId;
          }
          return [id, updatedSlot];
        }),
      );
    }
    case 'RESET_STATE':
      return action.payload;
    default:
      return state;
  }
}

export type VirtualSlotsReducerActionType =
  | {
      type: 'UPDATE_NEEDS';
      field: 'min_need' | 'max_need'; // Restricts to valid fields in SectionSlot
      value: number; // Increment or decrement value
      idVirtualSlots: number[];
    }
  | {
      type: 'RESET_STATE';
      payload: Record<number, VirtualSlot>; // The new state to replace the current state
    };

function adjustNeeds(
  slot: VirtualSlot,
  field: 'min_need' | 'max_need',
  value: number,
): VirtualSlot {
  const updatedSlot = { ...slot };
  if (field === 'min_need') {
    const newMinNeed = Math.max(updatedSlot.min_need + value, 0);
    updatedSlot.min_need = newMinNeed;
    if (newMinNeed > updatedSlot.max_need) updatedSlot.max_need = newMinNeed;
  } else if (field === 'max_need') {
    // handle case where min_need = max_need = 1
    if (updatedSlot.max_need === 1 && updatedSlot.min_need === 1 && value < 0) {
      updatedSlot.min_need = 0;
    }
    const newMaxNeed = Math.max(updatedSlot.max_need + value, 1);
    updatedSlot.max_need = newMaxNeed;
    if (newMaxNeed < updatedSlot.min_need) updatedSlot.min_need = newMaxNeed;
  }
  return updatedSlot;
}

export function virtualSlotsReducer(
  state: Record<number, VirtualSlot>,
  action: VirtualSlotsReducerActionType,
): Record<number, VirtualSlot> {
  switch (action.type) {
    case 'UPDATE_NEEDS': {
      const { field, value, idVirtualSlots } = action;

      return Object.fromEntries(
        Object.entries(state).map(([idVirtualSlot, virtualSlot]) => {
          if (idVirtualSlots.includes(Number(idVirtualSlot))) {
            const updatedSlot = adjustNeeds({ ...virtualSlot }, field, value);
            return [idVirtualSlot, updatedSlot];
          }
          return [idVirtualSlot, virtualSlot]; // If not, return unchanged slot
        }),
      );
    }
    case 'RESET_STATE':
      return action.payload;
    default:
      return state;
  }
}
