import React from 'react';
import { ArrowRightIcon } from '@heroicons/react/24/solid';

import TimeSelect from './CustomTimePicker';

interface DateTimePickerProps {
  value: string;
  onChange: (datetime: string) => void;
  min?: string;
  max?: string;
  use12HourFormat?: boolean;
}

function CustomDateTimePicker({
  value,
  onChange,
  min,
  max,
  use12HourFormat = false,
}: DateTimePickerProps) {
  // Split into date and time components
  const date = value.split('T')[0];
  const time = value.split('T')[1];

  const handleDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newDate = e.target.value;
    onChange(`${newDate}T${time}`);
  };

  const handleTimeChange = (newTime: string) => {
    onChange(`${date}T${newTime}`);
  };

  return (
    <div className="flex items-center gap-2">
      <input
        type="date"
        value={date}
        min={min?.split('T')[0]}
        max={max?.split('T')[0]}
        onChange={handleDateChange}
        className="p-2 border rounded outline-none"
      />
      <TimeSelect
        value={time}
        onChange={handleTimeChange}
        use12HourFormat={use12HourFormat}
      />
    </div>
  );
}

export default CustomDateTimePicker;
