import {
  ArrowPathRoundedSquareIcon,
  BackwardIcon,
  CalculatorIcon,
  HandRaisedIcon,
} from '@heroicons/react/24/outline';
import { ShiftAssignmentType } from '@youshift/shared/types';

export const iconTypes: Record<
  ShiftAssignmentType,
  { bgColor: string; Icon: React.FC<React.SVGProps<SVGSVGElement>> }
> = {
  ALGORITHM: {
    bgColor: 'bg-blue-600',
    Icon: CalculatorIcon,
  },
  // PREASSIGNED: {
  //   bgColor: 'bg-green-600',
  //   Icon: BackwardIcon,
  // },
  MANAGER: {
    bgColor: 'bg-purple-600',
    Icon: HandRaisedIcon,
  },
  EXCHANGED: {
    bgColor: 'bg-yellow-600',
    Icon: ArrowPathRoundedSquareIcon,
  },
};
