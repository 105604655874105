"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.useReplaceSlotMutation = exports.useUnassignSlotMutation = exports.useAssignSlotMutation = exports.useCreateSlotsFromTemplateMutation = exports.useDeleteSlotsMutation = exports.useDeleteSlotMutation = exports.useEditSlotMutation = exports.useCreateSlotMutation = void 0;
var react_query_1 = require("@tanstack/react-query");
var api_1 = require("../../api");
var useCreateSlotMutation = function (queryClient, options) {
    return (0, react_query_1.useMutation)(__assign({ mutationFn: function (params) {
            var id_itr = params.id_itr, rest = __rest(params, ["id_itr"]);
            return (0, api_1.request)({
                url: "manager/itrs/".concat(id_itr, "/slots/create_slot"),
                method: "post",
                data: rest,
            });
        } }, options), queryClient);
};
exports.useCreateSlotMutation = useCreateSlotMutation;
var useEditSlotMutation = function (queryClient, options) {
    return (0, react_query_1.useMutation)(__assign({ mutationFn: function (params) {
            var id_itr = params.id_itr, id_section_slot = params.id_section_slot, rest = __rest(params, ["id_itr", "id_section_slot"]);
            return (0, api_1.request)({
                url: "manager/itrs/".concat(id_itr, "/slots/").concat(id_section_slot, "/edit"),
                method: "patch",
                data: rest,
            });
        } }, options), queryClient);
};
exports.useEditSlotMutation = useEditSlotMutation;
var useDeleteSlotMutation = function (queryClient, options) {
    return (0, react_query_1.useMutation)(__assign({ mutationFn: function (params) {
            var id_itr = params.id_itr, id_section_slot = params.id_section_slot;
            return (0, api_1.request)({
                url: "manager/itrs/".concat(id_itr, "/slots/delete"),
                method: "delete",
                data: { id_section_slots: [id_section_slot] },
            });
        } }, options), queryClient);
};
exports.useDeleteSlotMutation = useDeleteSlotMutation;
var useDeleteSlotsMutation = function (queryClient, options) {
    return (0, react_query_1.useMutation)(__assign({ mutationFn: function (params) {
            var id_itr = params.id_itr, id_section_slots = params.id_section_slots;
            return (0, api_1.request)({
                url: "manager/itrs/".concat(id_itr, "/slots/delete"),
                method: "delete",
                data: { id_section_slots: id_section_slots },
            });
        } }, options), queryClient);
};
exports.useDeleteSlotsMutation = useDeleteSlotsMutation;
// manager/itrs/<id_itr>/sections/<int:id_section>/create_slots_from_template
var useCreateSlotsFromTemplateMutation = function (queryClient, options) {
    return (0, react_query_1.useMutation)(__assign({ mutationFn: function (params) {
            var id_itr = params.id_itr, id_section = params.id_section, section_slots_template = params.section_slots_template;
            return (0, api_1.request)({
                url: "manager/itrs/".concat(id_itr, "/sections/").concat(id_section, "/create_slots_from_template"),
                method: "post",
                data: { section_slots_template: section_slots_template },
            });
        } }, options), queryClient);
};
exports.useCreateSlotsFromTemplateMutation = useCreateSlotsFromTemplateMutation;
var useAssignSlotMutation = function (queryClient, options) {
    return (0, react_query_1.useMutation)(__assign({ mutationFn: function (params) {
            var id_itr = params.id_itr, id_section_slot = params.id_section_slot, id_user = params.id_user, point_award = params.point_award;
            return (0, api_1.request)({
                url: "manager/itrs/".concat(id_itr, "/slots/").concat(id_section_slot, "/assign"),
                method: "post",
                data: { id_user: id_user, point_award: point_award },
            });
        } }, options), queryClient);
};
exports.useAssignSlotMutation = useAssignSlotMutation;
var useUnassignSlotMutation = function (queryClient, options) {
    return (0, react_query_1.useMutation)(__assign({ mutationFn: function (params) {
            var id_itr = params.id_itr, id_section_slot = params.id_section_slot, id_user = params.id_user;
            return (0, api_1.request)({
                url: "manager/itrs/".concat(id_itr, "/slots/").concat(id_section_slot, "/unassign"),
                method: "delete",
                data: { id_user: id_user },
            });
        } }, options), queryClient);
};
exports.useUnassignSlotMutation = useUnassignSlotMutation;
var useReplaceSlotMutation = function (queryClient, options) {
    return (0, react_query_1.useMutation)(__assign({ mutationFn: function (params) {
            var id_itr = params.id_itr, id_section_slot = params.id_section_slot, current_user = params.current_user, new_user = params.new_user;
            return (0, api_1.request)({
                url: "manager/itrs/".concat(id_itr, "/slots/").concat(id_section_slot, "/replace"),
                method: "post",
                data: {
                    currently_assigned_id_user: current_user,
                    id_user_to_assign: new_user,
                },
            });
        } }, options), queryClient);
};
exports.useReplaceSlotMutation = useReplaceSlotMutation;
