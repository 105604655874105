import {
  CrossGroupIncompatibilityRule,
  IncompatibilityRules,
  SectionSlot,
  ShiftAssignment,
  SpecialEvent,
  User,
  UserPreference,
  UserPreferenceType,
  UserReqRule,
  VirtualSlot,
  UserRequirementType,
} from '@youshift/shared/types';

import { AssignmentsMap } from '../../../layouts/IterationRootLayout/types';
import { datetimeRangeOverlap } from '../utils';

/**
 * Computes all preassigned operating shifts that overlap with a given virtual slot
 * by checking if any of the section slots in the virtual slot have assignments.
 *
 * @param virtualSlot - The virtual slot to check assignments against
 * @param assignmentsByUser - Map of user IDs to their shift assignments
 * @returns Array of shift assignments that overlap with the virtual slot
 */
export const computePreassignedShiftsOnVirtualSlot = (
  virtualSlot: VirtualSlot,
  assignmentsByUser: Record<number, ShiftAssignment[]>,
): ShiftAssignment[] => {
  const preassignedShiftOnVirtualSlot: ShiftAssignment[] = [];

  Object.entries(assignmentsByUser).forEach(([idUser, userAssignments]) => {
    Object.entries(userAssignments).forEach(([idSectionSlot, assignment]) => {
      if (virtualSlot.section_slots.includes(Number(idSectionSlot))) {
        preassignedShiftOnVirtualSlot.push(assignment);
      }
    });
  });

  return preassignedShiftOnVirtualSlot;
};

export const checkUserIsBlockedOnVirtualSlot = (
  virtualSlot: VirtualSlot,
  userShiftAssignments: ShiftAssignment[],
  userPreferences: UserPreference[],
  userEvents: SpecialEvent[],
  sectionSlotsDict: Record<number, SectionSlot>,
): boolean => {
  // Check if user has special events overlapping with virtual slot
  const hasOverlappingEvents = userEvents.some(event =>
    datetimeRangeOverlap(
      new Date(virtualSlot.start),
      new Date(virtualSlot.end),
      new Date(event.start),
      new Date(event.end),
    ),
  );
  if (hasOverlappingEvents) return true;

  // Check if user has preassigned shifts on any section slots in virtual slot
  const hasPreassignedShifts = userShiftAssignments.some(assignment =>
    virtualSlot.section_slots.includes(assignment.id_section_slot),
  );
  if (hasPreassignedShifts) return true;

  // Check if user has rest periods overlapping with virtual slot
  const hasOverlappingRestPeriods = userShiftAssignments.some(assignment => {
    const sectionSlot = sectionSlotsDict[assignment.id_section_slot];
    const restPeriodEnd =
      new Date(sectionSlot.end).getTime() + sectionSlot.rest_period * 60 * 1000;

    return datetimeRangeOverlap(
      new Date(virtualSlot.start),
      new Date(virtualSlot.end),
      new Date(sectionSlot.end),
      new Date(restPeriodEnd),
    );
  });
  if (hasOverlappingRestPeriods) return true;

  const hasBlockingPreferences = virtualSlot.section_slots.every(
    id_section_slot => {
      const sectionSlot = sectionSlotsDict[id_section_slot];
      return userPreferences.some(
        pref =>
          pref.id_pref_slot === sectionSlot.id_pref_slot &&
          (pref.preference === UserPreferenceType.JUSTIFIED_BLOCKING ||
            pref.preference === UserPreferenceType.PERSONAL_BLOCKING),
      );
    },
  );
  if (hasBlockingPreferences) return true;

  return false;
};

export enum CrossGroupIncompPartakingGroup {
  GROUP_1 = 'group1',
  GROUP_2 = 'group2',
}

/**
 * Return a map of user groups that are blocked on the virtual slot due to preassigned shifts on a cross group incomp rule.
 *
 * A user from a GROUP is assigned on the virtual slot if:
 * - The user is assigned on a section slot in the virtual slot that is part of the cross group incomp rule.
 * - The user is assigned on a section slot that is part of the cross group incomp rule that overlaps in time
 *   with the virtual slot (Note that this can be across sections).
 */
export const getCrossGroupIncompRuleAssignedUserGroupsToVirtualSlot = (
  crossGroupIncompRule: CrossGroupIncompatibilityRule,
  virtualSlot: VirtualSlot,
  overlappingSlots: Record<number, number[]>,
  assignmentsMap: AssignmentsMap,
): Record<CrossGroupIncompPartakingGroup, Set<number>> => {
  // Map of user groups that are assigned to the conflicting rules.
  const assignedGroups: Record<CrossGroupIncompPartakingGroup, Set<number>> = {
    [CrossGroupIncompPartakingGroup.GROUP_1]: new Set<number>(),
    [CrossGroupIncompPartakingGroup.GROUP_2]: new Set<number>(),
  };

  // Get section slots from virtual slot that are part of the cross group incomp rule
  const inRuleSectionSlots = new Set(
    virtualSlot.section_slots.filter(idSectionSlot =>
      crossGroupIncompRule.section_slots.includes(idSectionSlot),
    ),
  );

  // Get overlapping section slots that are part of the rule
  const overlappingInRuleSectionSlots = new Set(
    Array.from(inRuleSectionSlots).flatMap(
      idSectionSlot =>
        overlappingSlots[idSectionSlot]?.filter(overlappingSlotId =>
          crossGroupIncompRule.section_slots.includes(overlappingSlotId),
        ) || [],
    ),
  );

  // All section slots that could cause conflicts
  const allConflictingSlots = new Set([
    ...inRuleSectionSlots,
    ...overlappingInRuleSectionSlots,
  ]);

  // Check if any group 1 users are assigned to the conflicting rules.
  crossGroupIncompRule.user_group.forEach(idUser => {
    const userAssignments = assignmentsMap.byUser[idUser] || [];
    const isAssignedOnConflictingSlot = userAssignments.some(assignment =>
      allConflictingSlots.has(assignment.id_section_slot),
    );
    if (isAssignedOnConflictingSlot) {
      assignedGroups[CrossGroupIncompPartakingGroup.GROUP_1].add(idUser);
    }
  });

  // Check if any group 2 users are assigned to the conflicting rules.
  crossGroupIncompRule.user_group_secondary.forEach(idUser => {
    const userAssignments = assignmentsMap.byUser[idUser] || [];
    const isAssignedOnConflictingSlot = userAssignments.some(assignment =>
      allConflictingSlots.has(assignment.id_section_slot),
    );
    if (isAssignedOnConflictingSlot) {
      assignedGroups[CrossGroupIncompPartakingGroup.GROUP_2].add(idUser);
    }
  });

  return assignedGroups;
};

/**
 *
 * A user from a GROUP is assigned on the section slot if:
 * - The user is assigned on the section slot that is part of the cross group incomp rule.
 * - The user is assigned on a section slot that 1) is part of the cross group incomp rule
 * and 2) overlaps in time with this section slot (Note that this can be across sections).
 */
export const getCrossGroupIncompRuleAssignedUserGroupsToSectionSlot = (
  user: User,
  crossGroupIncompRule: CrossGroupIncompatibilityRule,
  sectionSlot: SectionSlot,
  overlappingSlots: Record<number, number[]>,
  assignmentsMap: AssignmentsMap,
): Record<CrossGroupIncompPartakingGroup, Set<number>> => {
  // Map of user groups that are assigned to the conflicting rules.
  const assignedGroups: Record<CrossGroupIncompPartakingGroup, Set<number>> = {
    [CrossGroupIncompPartakingGroup.GROUP_1]: new Set<number>(),
    [CrossGroupIncompPartakingGroup.GROUP_2]: new Set<number>(),
  };

  // Check if section slot is part of the cross group incomp rule
  if (
    !crossGroupIncompRule.section_slots.includes(sectionSlot.id_section_slot)
  ) {
    return assignedGroups;
  }

  // Get overlapping section slots that are part of the rule
  const overlappingInRuleSectionSlots = new Set(
    overlappingSlots[sectionSlot.id_section_slot]?.filter(overlappingSlotId =>
      crossGroupIncompRule.section_slots.includes(overlappingSlotId),
    ) || [],
  );

  // All section slots that could cause conflicts
  const allConflictingSlots = new Set([
    sectionSlot.id_section_slot,
    ...overlappingInRuleSectionSlots,
  ]);

  // Check if any group 1 users are assigned to the conflicting section slots.
  crossGroupIncompRule.user_group.forEach(idUser => {
    const userAssignments = assignmentsMap.byUser[idUser] || [];
    const isAssignedOnConflictingSlot = userAssignments.some(assignment =>
      allConflictingSlots.has(assignment.id_section_slot),
    );
    if (isAssignedOnConflictingSlot) {
      assignedGroups[CrossGroupIncompPartakingGroup.GROUP_1].add(idUser);
    }
  });

  // Check if any group 2 users are assigned to the conflicting rules.
  crossGroupIncompRule.user_group_secondary.forEach(idUser => {
    const userAssignments = assignmentsMap.byUser[idUser] || [];
    const isAssignedOnConflictingSlot = userAssignments.some(assignment =>
      allConflictingSlots.has(assignment.id_section_slot),
    );
    if (isAssignedOnConflictingSlot) {
      assignedGroups[CrossGroupIncompPartakingGroup.GROUP_2].add(idUser);
    }
  });

  return assignedGroups;
};

/**
 * Checks if a user is blocked from being assigned to a section slot based on various constraints:
 * - Special events overlapping with the slot
 * - Preassigned operating shifts on the slot
 * - Rest periods from other preassigned shifts overlapping with the slot
 * - User preferences blocking the slot (personal and justified)
 * - Single group incompatibility rules with other preassigned users
 *
 * @param sectionSlot - The section slot to check blocks against
 * @param userEvents - Array of special events for the user
 * @param userShiftAssignments - Array of shift assignments for the user
 * @param userPreferences - Array of user preferences
 * @param incompatibilities - Map of incompatibility rules
 * @param assignmentsByUser - Map of user IDs to their shift assignments
 * @returns True if user is blocked, false otherwise
 */
export const checkUserIsBlockedOnSectionSlot = (
  user: User,
  sectionSlot: SectionSlot,
  userEvents: SpecialEvent[],
  userShiftAssignments: ShiftAssignment[],
  userPreferences: UserPreference[],
  incompatibilities: IncompatibilityRules,
  sectionSlotsDict: Record<number, SectionSlot>,
  overlappingSlots: Record<number, number[]>,
  assignmentsMap: AssignmentsMap,
): boolean => {
  // Check special events overlap
  if (userEvents) {
    const hasOverlappingEvents = userEvents.some(event =>
      datetimeRangeOverlap(
        new Date(sectionSlot.start),
        new Date(sectionSlot.end),
        new Date(event.start),
        new Date(event.end),
      ),
    );
    if (hasOverlappingEvents) return true;
  }

  if (userShiftAssignments) {
    // Check preassigned shifts on this slot
    const hasPreassignedShift = userShiftAssignments.some(
      assignment => assignment.id_section_slot === sectionSlot.id_section_slot,
    );
    if (hasPreassignedShift) return true;
    // Check rest period overlaps from other assignments
    const hasOverlappingRestPeriods = userShiftAssignments.some(assignment => {
      const assignmentSectionSlot =
        sectionSlotsDict[assignment.id_section_slot];
      const assignmentEnd = new Date(assignmentSectionSlot.end).getTime();
      const restPeriodEnd =
        assignmentEnd + assignmentSectionSlot.rest_period * 60 * 1000;

      return datetimeRangeOverlap(
        new Date(sectionSlot.start),
        new Date(sectionSlot.end),
        new Date(assignmentEnd),
        new Date(restPeriodEnd),
      );
    });
    if (hasOverlappingRestPeriods) return true;
  }

  if (userPreferences) {
    // Check blocking preferences
    const hasBlockingPreference = userPreferences.some(
      pref =>
        pref.id_pref_slot === sectionSlot.id_pref_slot &&
        (pref.preference === UserPreferenceType.JUSTIFIED_BLOCKING ||
          pref.preference === UserPreferenceType.PERSONAL_BLOCKING),
    );
    if (hasBlockingPreference) return true;
  }
  // Check if there are remaining users to be assigned to the section slot
  // for the single group incomp rules.
  const saturatedSingleGroupIncompRules = Object.values(
    incompatibilities.single_group_incomp,
  ).filter(rule => {
    // Only check if the section slot is part of the rule
    if (!rule.section_slots.includes(sectionSlot.id_section_slot)) {
      return false;
    }

    // Get users assigned to this slot
    const assignedUsers = new Set<number>();
    Object.entries(assignmentsMap.byUser).forEach(([userId, assignments]) => {
      if (
        assignments.some(a => a.id_section_slot === sectionSlot.id_section_slot)
      ) {
        assignedUsers.add(Number(userId));
      }
    });

    // Check if any assigned user is in same incompatibility group
    return assignedUsers.size >= rule.max_simult;
  });
  if (saturatedSingleGroupIncompRules.length > 0) return true;

  // Check if any cross group incompatibility rules are violated
  const blockingCrossGroupIncompRules = Object.values(
    incompatibilities.cross_group_incomp,
  ).filter(rule => {
    // Only check if the section slot is part of the rule
    if (!rule.section_slots.includes(sectionSlot.id_section_slot)) {
      return false;
    }

    // Get the user's group in the rule
    let user_group: CrossGroupIncompPartakingGroup | null = null;
    if (rule.user_group.includes(user.id)) {
      user_group = CrossGroupIncompPartakingGroup.GROUP_1;
    } else if (rule.user_group_secondary.includes(user.id)) {
      user_group = CrossGroupIncompPartakingGroup.GROUP_2;
    } else {
      return false;
    }

    // Get users assigned to this slot
    const assignedUsers =
      getCrossGroupIncompRuleAssignedUserGroupsToSectionSlot(
        user,
        rule,
        sectionSlot,
        overlappingSlots,
        assignmentsMap,
      );
    if (user_group === CrossGroupIncompPartakingGroup.GROUP_1) {
      return assignedUsers[CrossGroupIncompPartakingGroup.GROUP_2].size > 0;
    }
    if (user_group === CrossGroupIncompPartakingGroup.GROUP_2) {
      return assignedUsers[CrossGroupIncompPartakingGroup.GROUP_1].size > 0;
    }
    return false;
  });
  if (blockingCrossGroupIncompRules.length > 0) return true;
  return false;
};

/**
 * Finds the maximum number of SectionSlots that can be selected
 * from a list of SectionSlots without any of them overlapping.
 * Takes an extra-parameter for including rest_period or not.
 *
 * The Greedy algorithm is provably optimal (is NOT an approximation) for this problem
 * and is known as the "Activity Selection Problem".
 */
export const getMaxAssignableNonOverlappingSlots = (
  sectionSlots: SectionSlot[],
  accountForRestPeriod: boolean = false,
): SectionSlot[] => {
  // Step 1: Sort the time slots by their end times
  const sortedSectionSlots = [...sectionSlots].sort((a, b) => {
    const aEndTime = accountForRestPeriod
      ? new Date(a.end).getTime() + a.rest_period * 60 * 1000
      : new Date(a.end).getTime();
    const bEndTime = accountForRestPeriod
      ? new Date(b.end).getTime() + b.rest_period * 60 * 1000
      : new Date(b.end).getTime();
    return aEndTime - bEndTime;
  });

  // Step 2: Select the maximum number of non-overlapping slots
  const selectedSlots: SectionSlot[] = [];
  let lastEndTime = new Date(0).getTime(); // Minimum date

  sortedSectionSlots.forEach(sectionSlot => {
    const slotStartTime = new Date(sectionSlot.start).getTime();
    if (slotStartTime >= lastEndTime) {
      selectedSlots.push(sectionSlot);
      lastEndTime = accountForRestPeriod
        ? new Date(sectionSlot.end).getTime() +
          sectionSlot.rest_period * 60 * 1000
        : new Date(sectionSlot.end).getTime();
    }
  });

  return selectedSlots;
};
