import { CheckIcon } from '@heroicons/react/20/solid';
import { useQueryClient } from '@tanstack/react-query';
import { useInitToConfigMutation } from '@youshift/shared/hooks/mutations';
import { ItrStatuses } from '@youshift/shared/types';
import { classNames } from '@youshift/shared/utils';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Navigate,
  Outlet,
  useLocation,
  useNavigate,
  useParams,
} from 'react-router-dom';

import { YSButton } from '../components/Buttons';
import { useItrContext } from './IterationRootLayout/IterationRootLayout';

function IterationInitializationLayout() {
  const { t } = useTranslation();
  const steps = useMemo(
    () => [
      { name: t('generic.roles'), href: 'roles', key: 'roles' },
      {
        name: t('generic.sections'),
        href: 'sections',
        key: 'sections',
      },
      {
        name: t('generic.rules'),
        href: 'rules',
        key: 'rules',
      },
      {
        name: t('generic.incompatibilities'),
        href: 'incompatibilities',
        key: 'incompatibilities',
      },
      {
        name: t('generic.preferences'),
        href: 'preferences',
        key: 'preferences',
      },
    ],
    [t],
  );
  const location = useLocation();
  const currentStepIndex = steps.findIndex(step =>
    location.pathname.includes(step.key),
  );
  const inChildren = location.pathname.includes('new');

  const nextStepLocation = useMemo(() => {
    // last step. unreachable bc we don't show next button there
    if (currentStepIndex === steps.length - 1) {
      return '#';
    }
    return steps[currentStepIndex + 1].href;
  }, [currentStepIndex, steps]);

  const previousStepLocation = useMemo(() => {
    // last step. unreachable bc we don't show next button there
    if (currentStepIndex === 0 || currentStepIndex === -1) {
      return '#';
    }
    return steps[currentStepIndex - 1].href;
  }, [currentStepIndex, steps]);

  const queryClient = useQueryClient();
  const { idItr: id } = useParams();
  const navigate = useNavigate();
  const initToConfig = useInitToConfigMutation(queryClient, {
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['iteration', id] });
      queryClient.invalidateQueries({ queryKey: ['status', id] });
      navigate(`/manager/iteration/${id}/configuration`);
    },
  });

  const { status } = useItrContext();

  return (
    <>
      {status === ItrStatuses.CONFIGURATION ? (
        <Navigate to="../configuration/roles" replace />
      ) : null}
      <div className="h-screen flex flex-col py-6">
        <nav aria-label="Progress" className="flex-shrink-0">
          <ol
            role="list"
            className="flex items-center justify-between mb-8 mx-12"
          >
            {steps.map((step, stepIdx) => {
              const isComplete = stepIdx < currentStepIndex;
              const isCurrent = stepIdx === currentStepIndex;
              return (
                <li
                  key={step.name}
                  className={classNames(
                    stepIdx !== steps.length - 1 ? 'w-full' : '',
                    'relative',
                  )}
                >
                  {isComplete ? (
                    <>
                      <div
                        aria-hidden="true"
                        className="absolute inset-0 flex items-center"
                      >
                        <div className="h-0.5 w-full bg-blue-600" />
                      </div>
                      <a
                        href={step.href}
                        className="relative flex h-8 w-8 items-center justify-center rounded-full bg-blue-600 hover:bg-blue-900"
                      >
                        <CheckIcon
                          aria-hidden="true"
                          className="h-5 w-5 text-white"
                        />
                        <span className="sr-only">{step.name}</span>
                      </a>
                    </>
                  ) : isCurrent ? (
                    <>
                      <div
                        aria-hidden="true"
                        className="absolute inset-0 flex items-center"
                      >
                        <div className="h-0.5 w-full bg-gray-200" />
                      </div>
                      <a
                        href={step.href}
                        aria-current="step"
                        className="relative flex h-8 w-8 items-center justify-center rounded-full border-2 border-blue-600 bg-white"
                      >
                        <span
                          aria-hidden="true"
                          className="h-2.5 w-2.5 rounded-full bg-blue-600"
                        />
                        <span className="sr-only">{step.name}</span>
                      </a>
                    </>
                  ) : (
                    <>
                      <div
                        aria-hidden="true"
                        className="absolute inset-0 flex items-center"
                      >
                        <div className="h-0.5 w-full bg-gray-200" />
                      </div>
                      <a
                        href={step.href}
                        className="group relative flex h-8 w-8 items-center justify-center rounded-full border-2 border-gray-300 bg-white hover:border-gray-400"
                      >
                        <span
                          aria-hidden="true"
                          className="h-2.5 w-2.5 rounded-full bg-transparent group-hover:bg-gray-300"
                        />
                        <span className="sr-only">{step.name}</span>
                      </a>
                    </>
                  )}
                  {/* Add the step title below the dot */}
                  <div className="absolute md:text-sm sm:text-xs text-xxxs text-start mt-2">
                    {step.name}
                  </div>
                </li>
              );
            })}
          </ol>
        </nav>
        <div className="flex-grow overflow-y-auto">
          <Outlet />
        </div>
        {inChildren ? null : (
          <div className="flex-shrink-0 mt-6">
            <div className="flex flex-row gap-2 justify-end">
              {
                // if first step, don't show previous button
                currentStepIndex === 0 ? null : (
                  <YSButton to={previousStepLocation} variant="secondary">
                    {t('generic.previous')}
                  </YSButton>
                )
              }
              {currentStepIndex === steps.length - 1 ? (
                <YSButton
                  onClick={() => initToConfig.mutate({ id_itr: id })}
                  variant="primary"
                >
                  {t('generic.complete')}
                </YSButton>
              ) : (
                <YSButton to={nextStepLocation} variant="primary">
                  {t('generic.next')}
                </YSButton>
              )}
            </div>
          </div>
        )}
      </div>
    </>
  );
}

export default IterationInitializationLayout;
