import { RadioGroup } from '@headlessui/react';
import { CheckIcon, PencilSquareIcon } from '@heroicons/react/24/outline';
import { useQueryClient } from '@tanstack/react-query';
import {
  useDeleteLabelMutation,
  useEditLabelMutation,
} from '@youshift/shared/hooks/mutations';
import { SlotLabel } from '@youshift/shared/types';
import { ColorName, returnColor } from '@youshift/shared/utils';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import toast from 'react-hot-toast';

import IconPicker from '../IconPicker';
import LabelIconComponent from '../LabelIconComponent';
import Modal from '../Modal';
import ShadePicker from '../ShadePicker';
import ShiftLabelLegend from '../Calendars/ShiftLabelLegend';

type ShiftLabelsProps = {
  filteredShiftLabels?: SlotLabel[];
  shiftLabels: SlotLabel[];
  sectionColor: ColorName;
};

function EditShiftLabels({
  filteredShiftLabels,
  shiftLabels,
  sectionColor,
}: ShiftLabelsProps) {
  const [editOpen, setEditOpen] = useState(false);
  const { t } = useTranslation();
  const { idItr } = useParams();
  const [selectedLabel, setSelectedLabel] = useState(shiftLabels[0]);
  const [newName, setNewName] = useState(selectedLabel.name);
  const [newShade, setNewShade] = useState(selectedLabel.shade);
  const [newIcon, setNewIcon] = useState(selectedLabel.icon);
  const queryClient = useQueryClient();

  const editLabelMutation = useEditLabelMutation(queryClient, {
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['slotLabels', idItr] });
      toast.success(t('manager.sectionsConfig.editLabelSuccess'));
    },
  });

  const deleteLabelMutation = useDeleteLabelMutation(queryClient, {
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['slotLabels', idItr] });
      toast.success(t('manager.sectionsConfig.eliminateLabelSuccess'));
    },
  });

  const handleSave = () => {
    editLabelMutation.mutate({
      id_itr: idItr,
      id_slot_label: selectedLabel.id_slot_label,
      name: newName,
      icon: newIcon,
      shade: newShade,
    });
  };

  const handleDelete = () => {
    deleteLabelMutation.mutate({
      id_itr: idItr,
      id_slot_label: selectedLabel.id_slot_label,
    });
  };

  const handleLabelChange = (label: SlotLabel) => {
    setSelectedLabel(label);
    setNewName(label.name);
    setNewShade(label.shade); // Use the correct color from the section
    setNewIcon(label.icon);
  };

  return (
    <div className="flex flex-row gap-2 items-center">
      <ShiftLabelLegend
        labels={filteredShiftLabels || shiftLabels}
        sectionColor={sectionColor}
      />
      <button onClick={() => setEditOpen(true)} aria-label={t('generic.edit')}>
        <PencilSquareIcon
          className=" text-gray-900 rounded-md h-7 w-7 p-1"
          style={{ color: returnColor(sectionColor, 500) }}
        />
      </button>
      <Modal
        isOpen={editOpen}
        onClose={() => setEditOpen(false)}
        size="lg"
        editButtons
        handleSave={handleSave}
        handleDelete={handleDelete}
      >
        <div>
          <h2 className="text-xl mb-4">
            {t('manager.sectionsConfig.editLabel')}
          </h2>

          <RadioGroup value={selectedLabel} onChange={handleLabelChange}>
            <RadioGroup.Label className="sr-only">
              {t('manager.sectionsConfig.editLabel')}
            </RadioGroup.Label>
            <div className="space-y-2">
              {shiftLabels
                .sort((a, b) => a.name.localeCompare(b.name))
                .map(label => {
                  const selected =
                    selectedLabel.id_slot_label === label.id_slot_label;
                  return (
                    <RadioGroup.Option
                      key={label.id_slot_label}
                      value={label}
                      className={`${selected ? 'bg-gray-200' : 'bg-white'}
                    relative flex cursor-pointer rounded-lg px-5 py-4 shadow-md focus:outline-none`}
                    >
                      <div className="flex w-full items-center justify-between">
                        <div className="flex items-center">
                          <div className="text-sm">
                            <RadioGroup.Label
                              as="p"
                              className="font-medium flex flex-row gap-1 text-gray-900 items-center"
                            >
                              <div
                                className="w-5 h-5 rounded-full mr-2 flex justify-center items-center"
                                style={{
                                  backgroundColor: returnColor(
                                    sectionColor,
                                    label.shade,
                                  ),
                                }}
                              >
                                <LabelIconComponent
                                  icon={label.icon}
                                  className="w-5 h-5"
                                />
                              </div>
                              {label.name}
                            </RadioGroup.Label>
                          </div>
                        </div>
                        {selected && (
                          <div className="shrink-0 text-white">
                            <CheckIcon
                              className="h-6 w-6"
                              style={{
                                color: returnColor(sectionColor, 900),
                              }}
                            />
                          </div>
                        )}
                      </div>
                    </RadioGroup.Option>
                  );
                })}
            </div>
          </RadioGroup>
          <div className="mt-4 flex flex-row gap-5">
            <div>
              <label
                htmlFor="labelShade"
                className="block text-sm font-medium text-gray-700 mb-1"
              >
                {t('manager.sectionsConfig.shade')}
              </label>
              <div className="mb-4">
                <ShadePicker
                  color={sectionColor}
                  shade={newShade}
                  onChange={setNewShade}
                />
              </div>
            </div>
            <div>
              <label
                htmlFor="labelShade"
                className="block text-sm font-medium text-gray-700 mb-1"
              >
                {t('generic.icon')}
              </label>
              <div className="mb-4">
                <IconPicker value={newIcon} onChange={setNewIcon} />
              </div>
            </div>
          </div>
          <div className="mt-4">
            <label
              htmlFor="labelName"
              className="block text-sm font-medium text-gray-700"
            >
              {t('manager.sectionsConfig.labelName')}
            </label>
            <input
              id="labelName"
              type="text"
              value={newName}
              onChange={e => setNewName(e.target.value)}
              className="mt-1 block w-full p-2 border border-gray-300 rounded-md"
            />
          </div>
        </div>
      </Modal>
    </div>
  );
}

export default EditShiftLabels;
