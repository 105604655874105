import { RuleTypes } from '@youshift/shared/types';

interface ChildStep {
  name: string;
  key: string;
}

interface VerticalProgressBarProps {
  steps: ChildStep[] | undefined;
  childIndex: number | undefined;
}

export default function VerticalProgressBar({
  steps,
  childIndex,
}: VerticalProgressBarProps) {
  if (steps === undefined || childIndex === undefined) return null;
  return (
    <nav aria-label="Progress">
      <ol className="flex flex-col items-stretch">
        {steps.map(({ name, key }, idx) => {
          // eslint-disable-next-line operator-linebreak
          const status =
            idx < childIndex
              ? 'complete'
              : idx === childIndex
                ? 'current'
                : 'upcoming';
          return (
            <li key={name} className="md:flex-1">
              {status === 'complete' ? (
                <div className="group flex flex-col border-l-4 border-teal-600 py-2 0 hover:border-teal-800 md:border-l-0 border-t-4 w-full">
                  <span className="text-sm font-medium text-teal-600 group-hover:text-teal-800">
                    {`Step ${idx + 1}`}
                  </span>
                  <span className="text-sm font-medium">{name}</span>
                </div>
              ) : status === 'current' ? (
                <div
                  aria-current="step"
                  className="flex flex-col border-l-4 border-teal-600 py-2 0 md:border-l-0 border-t-4  w-full"
                >
                  <span className="text-sm font-medium text-teal-600">
                    {`Step ${idx + 1}`}
                  </span>
                  <span className="text-sm font-medium">{name}</span>
                </div>
              ) : (
                <div className="group flex flex-col border-l-4 border-gray-200 py-2 0 hover:border-gray-300 md:border-l-0 border-t-4 w-full">
                  <span className="text-sm font-medium text-gray-500 group-hover:text-gray-700">
                    {`Step ${idx + 1}`}
                  </span>
                  <span className="text-sm font-medium">{name}</span>
                </div>
              )}
            </li>
          );
        })}
      </ol>
    </nav>
  );
}
