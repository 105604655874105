/* eslint-disable react/require-default-props */
import { ArrowRightIcon } from '@heroicons/react/24/outline';
import { SlotLabel } from '@youshift/shared/types';
import {
  LabelIcon,
  formatSlotDuration,
  getWeekdayInitials,
  localeNormalizer,
} from '@youshift/shared/utils';
import { Dispatch, SetStateAction, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { NumericFormat } from 'react-number-format';
import { CustomCounterIncrementSchema } from '@youshift/shared/hooks/mutations';

import { backendToLocalDate } from '../../utils/helpers';
import i18n from '../../utils/i18n';
import { YSButton } from '../Buttons';
import CustomDateTimePicker from '../Calendars/CustomDateTimePicker';
import TimeSelect from '../Calendars/CustomTimePicker';
import { ShiftLabelSelect } from './ShiftLabelSelect';
import { useItrContext } from '../../layouts/IterationRootLayout/IterationRootLayout';
import { CounterIncrements } from './CounterIncrements';

// When daysOfWeek/setDaysOfWeek/days are present we are creating a slot in a template
export type SlotDetailsBaseProps = {
  selectedLabelId: number | null;
  setSelectedLabelId: (id: number | null) => void;
  daysOfWeek?: boolean[] | undefined;
  setDaysOfWeek?: ((days: boolean[]) => void) | undefined;
  startTime: string;
  setStartTime: (value: string) => void;
  endTime: string;
  setEndTime: (value: string) => void;
  restHours: number;
  setRestHours: (value: number) => void;
  shiftLabels: SlotLabel[];
  buttonDisabled: boolean;
  duration: number;
  setExtra24h?: Dispatch<SetStateAction<number>>;
  onDelete?: () => void;
  onSubmit: () => void;
  submitButtonLabel: string;
  itrStart?: string;
  itrEnd?: string;
  counterIncrementSchemas: CustomCounterIncrementSchema[];
  setCounterIncrementSchemas: (value: CustomCounterIncrementSchema[]) => void;
};

export default function SlotDetailsBase({
  selectedLabelId,
  setSelectedLabelId,
  daysOfWeek = undefined,
  setDaysOfWeek = undefined,
  startTime,
  setStartTime,
  endTime,
  setEndTime,
  restHours,
  setRestHours,
  shiftLabels,
  buttonDisabled,
  duration,
  onSubmit,
  onDelete,
  setExtra24h = () => {},
  submitButtonLabel,
  itrStart,
  itrEnd,
  counterIncrementSchemas,
  setCounterIncrementSchemas,
}: SlotDetailsBaseProps) {
  const { t } = useTranslation();

  const [isCustomLabel, setIsCustomLabel] = useState(false);
  const [newLabelName, setNewLabelName] = useState('');
  const [labelIcon, setLabelIcon] = useState<LabelIcon | null>(null);

  const locale = localeNormalizer(i18n.language);
  const days = getWeekdayInitials(t, locale);

  const creatingTemplate = days && daysOfWeek && setDaysOfWeek;

  const resetState = () => {
    setSelectedLabelId(null);
    setStartTime('');
    setEndTime('');
    setRestHours(0);
    setIsCustomLabel(false);
    setExtra24h(0);
    setNewLabelName('');
    if (daysOfWeek && setDaysOfWeek) {
      setDaysOfWeek([false, false, false, false, false, false, false]);
    }
  };

  const toggleDay = (index: number) => {
    if (daysOfWeek && setDaysOfWeek) {
      const updatedDays = [...daysOfWeek];
      updatedDays[index] = !updatedDays[index];
      setDaysOfWeek(updatedDays);
    }
  };

  const use12HourFormat = locale === 'en';

  return (
    <>
      <div className="grid grid-cols-2 px-4 py-1 mx-auto bg-white shadow-md rounded-lg gap-x-4 gap-y-2">
        {/* Row 1, Column 1 */}
        <div className="my-2">
          <label className="block text-gray-700 font-semibold">
            {t('manager.sectionsConfig.startAndEndTime')}
          </label>
          <div className="flex space-x-2 items-center">
            {creatingTemplate ? (
              <>
                <div className="flex items-center gap-4">
                  <TimeSelect
                    value={startTime}
                    onChange={setStartTime}
                    use12HourFormat={use12HourFormat}
                  />
                  <ArrowRightIcon className="w-5 h-5 text-gray-400" />
                  <TimeSelect
                    value={endTime}
                    onChange={setEndTime}
                    use12HourFormat={use12HourFormat}
                  />
                </div>
                <button
                  className="rounded-full border border-blue-600 text-xs font-semibold text-blue-600 py-2 px-1"
                  onClick={() => setExtra24h(prevState => prevState + 1)}
                >
                  +24h
                </button>
                <button
                  className="rounded-full border border-blue-600 text-xs font-semibold text-blue-600 py-2 px-1.5"
                  onClick={() => setExtra24h(prevState => prevState - 1)}
                  disabled={duration < 1440}
                >
                  -24h
                </button>
              </>
            ) : (
              startTime &&
              endTime && (
                <>
                  <CustomDateTimePicker
                    value={startTime}
                    onChange={setStartTime}
                    min={
                      itrStart ? new Date(itrStart).toISOString() : undefined
                    }
                    max={itrEnd ? new Date(itrEnd).toISOString() : undefined}
                    use12HourFormat={use12HourFormat}
                  />
                  <ArrowRightIcon className="w-5 h-5 text-gray-400" />
                  <CustomDateTimePicker
                    value={endTime}
                    onChange={setEndTime}
                    min={startTime}
                    max={
                      itrEnd
                        ? new Date(...backendToLocalDate(itrEnd)).toISOString()
                        : undefined
                    }
                    use12HourFormat={use12HourFormat}
                  />
                </>
              )
            )}
            <p
              className={`text-xs font-semibold ${duration === 0 ? 'bg-red-400' : ''}`}
            >
              {formatSlotDuration(duration)}
            </p>
          </div>
          {new Date(startTime) > new Date(endTime) && (
            <p className="text-red-600">
              {t('manager.sectionsConfig.startAndEndTimeError')}
            </p>
          )}
        </div>

        {/* Row 1, Column 2 */}
        {creatingTemplate ? (
          <div>
            <label className="block text-gray-700 mb-1">
              {t('manager.sectionsConfig.selectDays')}
            </label>
            <div className="flex space-x-2">
              {days.map((day, index) => (
                <button
                  key={index}
                  onClick={() => toggleDay(index)}
                  className={`w-10 h-10 flex items-center justify-center border rounded ${
                    daysOfWeek[index]
                      ? 'bg-blue-500 text-white'
                      : 'bg-white text-blue-500'
                  }`}
                >
                  {day}
                </button>
              ))}
            </div>
          </div>
        ) : (
          <div />
        )}

        {/* Row 2, Column 1 */}
        <div className="grid grid-rows-2">
          <div>
            <label className="block text-gray-700 font-semibold">
              {t('manager.sectionsConfig.shiftSlotName')}
            </label>
            <p className="text-xs text-gray-500">
              {t('manager.sectionsConfig.shiftLabelDescription')}
            </p>
          </div>
          <ShiftLabelSelect
            shiftLabels={shiftLabels}
            selectedLabelId={selectedLabelId}
            setSelectedLabelId={setSelectedLabelId}
            isCustomLabel={isCustomLabel}
            setIsCustomLabel={setIsCustomLabel}
            newLabelName={newLabelName}
            setNewLabelName={setNewLabelName}
            labelIcon={labelIcon}
            setLabelIcon={setLabelIcon}
          />
        </div>

        {/* Row 2, Column 2 */}
        <div className="grid grid-rows-2">
          <div>
            <label className="block text-gray-700 font-semibold">
              {t('manager.sectionsConfig.rest')}
            </label>
            <p className="text-xs text-gray-500 mb-4">
              {t('manager.sectionsConfig.restDescription')}
            </p>
          </div>
          <div className="flex flex-row items-start mt-1">
            <NumericFormat
              value={restHours}
              onValueChange={values => setRestHours(values.floatValue || 0)}
              min={0}
              allowNegative={false}
              decimalScale={0}
              className="p-2 border rounded w-16"
            />
            <span className="ml-2">h</span>
          </div>
        </div>

        <CounterIncrements
          counterIncrementSchemas={counterIncrementSchemas}
          setCounterIncrementSchemas={setCounterIncrementSchemas}
        />
      </div>
      <div className="flex flex-row gap-1">
        {onDelete ? (
          <YSButton
            variant="delete"
            onClick={() => {
              onDelete();
              resetState();
            }}
            classNames="w-1/6 my-2"
          >
            {t('generic.delete')}
          </YSButton>
        ) : null}
        <YSButton
          variant="primary"
          onClick={() => {
            onSubmit();
            resetState();
          }}
          disabled={buttonDisabled || duration <= 0}
          classNames="w-full my-2"
        >
          {submitButtonLabel}
        </YSButton>
      </div>
    </>
  );
}
