import {
  ChartBarIcon,
  InboxArrowDownIcon,
  LockClosedIcon,
} from '@heroicons/react/20/solid';
import {
  ArchiveBoxIcon,
  ArrowPathIcon,
  CalendarDateRangeIcon,
  CheckIcon,
  Cog8ToothIcon,
  QueueListIcon,
} from '@heroicons/react/24/outline';
import { QueryClient } from '@tanstack/react-query';
import { preLoadQuery } from '@youshift/shared/hooks';
import {
  ChainsResponse,
  userChainsQuery,
} from '@youshift/shared/hooks/queries';
import { ItrStatuses } from '@youshift/shared/types';
import { classNames, parseIterationDates } from '@youshift/shared/utils';
import { useTranslation } from 'react-i18next';
import { Link, useLoaderData } from 'react-router-dom';

import { requireApproved, requireLoggedIn } from '../../utils/checks';
import { EmptyState } from '../../components/EmptyState';
import Wrapper from '../../components/Wrapper';
import NoData from '../Manager/NoData';

export const userIterationsLoader =
  (queryClient: QueryClient) => async (): Promise<ChainsResponse> => {
    const user = await requireLoggedIn(queryClient);
    await requireApproved(user);
    const chainsAndItrs = await preLoadQuery(queryClient, userChainsQuery());
    return chainsAndItrs;
  };

export default function Iterations() {
  const { chains, itrs } = useLoaderData() as ChainsResponse;

  const { t } = useTranslation();

  const status_classes = {
    INITIALIZATION: [
      'text-blue-800 bg-blue-50 ring-blue-600/20',
      t('generic.itrStatuses.configuration'),
      Cog8ToothIcon,
      'bg-blue-600',
    ],
    CONFIGURATION: [
      'text-blue-800 bg-blue-50 ring-blue-600/20',
      t('generic.itrStatuses.configuration'),
      Cog8ToothIcon,
      'bg-blue-600',
    ],
    RUNNING_SMARTASS: [
      'text-yellow-800 bg-yellow-50 ring-yellow-600/20',
      t('generic.itrStatuses.running'),
      ArrowPathIcon,
      'bg-yellow-500',
    ],
    ASSIGNMENT: [
      'text-violet-800 bg-violet-50 ring-violet-600/20',
      t('generic.itrStatuses.assignment'),
      CalendarDateRangeIcon,
      'bg-violet-600',
    ],
    PUBLISHED: [
      'text-teal-800 bg-teal-50 ring-teal-600/20',
      t('generic.itrStatuses.published'),
      CheckIcon,
      'bg-teal-600',
    ],
    CLOSED: [
      'text-stone-800 bg-stone-50 ring-stone-600/20',
      t('generic.itrStatuses.closed'),
      ArchiveBoxIcon,
      'bg-gray-600',
    ],
    ARCHIVED: [
      'text-stone-800 bg-stone-50 ring-stone-600/20',
      t('generic.itrStatuses.archived'),
      ArchiveBoxIcon,
      'bg-gray-600',
    ],
  };

  return (
    <Wrapper>
      <div className="flex flex-row justify-between">
        <h3 className="text-2xl font-semibold leading-6 text-gray-900 my-4">
          {t('user.iterations.myItrs')}
        </h3>
        <div className="flex items-center">
          <Link
            to="history"
            className="ml-6 rounded-md  bg-blue-600 py-2 px-3 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
          >
            {t('generic.seeHistory')}
          </Link>
        </div>
      </div>

      {Object.values(itrs).length > 0 ? (
        <ul className="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3">
          {Object.values(chains).map(chain => (
            <li
              key={chain.id_chain}
              className="col-span-1 rounded-lg bg-white shadow"
            >
              <div className="flex w-full items-center justify-between space-x-6 p-6 border-b border-gray-200">
                <div className="flex-1 truncate">
                  <div className="flex items-center space-x-3">
                    <h3 className="truncate text-md font-medium text-gray-900">
                      {chain.chain_name}
                    </h3>
                  </div>
                </div>
              </div>
              {chain.itrs.map((itrId, itrIdx) => {
                const itr = itrs[itrId];
                const StatusIcon = status_classes[itr.status][2];
                return (
                  <div className="relative">
                    {itrIdx !== chain.itrs.length - 1 ? (
                      <span
                        className="absolute left-10 top-4 -ml-px h-full w-0.5 bg-gray-200"
                        aria-hidden="true"
                      />
                    ) : null}
                    <div className="relative flex w-full items-center justify-between space-x-6 px-6 py-3">
                      <div className="flex-1 truncate">
                        <div className="flex items-center space-x-3">
                          <div>
                            <span
                              className={classNames(
                                status_classes[itr.status][3],
                                'h-8 w-8 rounded-full flex items-center justify-center ring-white',
                              )}
                            >
                              <StatusIcon
                                className="h-5 w-5 text-white"
                                aria-hidden="true"
                              />
                            </span>
                          </div>
                          <p className="font-medium text-gray-700">
                            {parseIterationDates(
                              itr.start_day,
                              itr.end_day,
                              itr.itr_type,
                            )}
                          </p>
                          <span
                            className={`inline-flex flex-shrink-0 items-center rounded-full px-1.5 py-0.5 text-xs font-medium ring-1 ring-inset ${status_classes[itr.status][0]}`}
                          >
                            {`${status_classes[itr.status][1]}`}
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="-mt-px flex divide-x divide-gray-200">
                      <div className="flex w-0 flex-1">
                        {itr.locked && itr.status !== ItrStatuses.PUBLISHED ? (
                          <button
                            disabled
                            className="relative -mr-px inline-flex w-0 flex-1 items-center justify-center gap-x-3 rounded-bl-lg border border-transparent py-4 text-sm font-semibold text-gray-900"
                          >
                            <LockClosedIcon
                              className="h-5 w-5 text-red-600"
                              aria-hidden="true"
                            />
                            {t('user.iterations.locked')}
                          </button>
                        ) : (
                          // eslint-disable-next-line jsx-a11y/anchor-is-valid
                          <Link
                            to={
                              itr.status === ItrStatuses.PUBLISHED
                                ? `/user/${itr.id_itr}/results`
                                : `/user/${itr.id_itr}/preferences`
                            }
                            className="relative -mr-px inline-flex w-0 flex-1 items-center justify-center gap-x-3 rounded-bl-lg border border-transparent py-4 text-sm font-semibold hover:bg-gray-50 text-gray-900"
                            // state={{
                            //   itr_type: itr.itr_type,
                            //   start_day: itr.start_day,
                            //   end_day: itr.end_day,
                            // }}
                          >
                            {itr.status === ItrStatuses.CONFIGURATION ||
                            itr.status === ItrStatuses.ASSIGNMENT ? (
                              <>
                                <InboxArrowDownIcon
                                  className="h-5 w-5 text-blue-600 "
                                  aria-hidden="true"
                                />
                                {t('user.iterations.recordPreferences')}
                              </>
                            ) : (
                              <>
                                <ChartBarIcon
                                  className="h-5 w-5 text-green-400"
                                  aria-hidden="true"
                                />
                                {t('user.iterations.checkResults')}
                              </>
                            )}
                          </Link>
                        )}
                      </div>
                    </div>
                  </div>
                );
              })}
            </li>
          ))}
        </ul>
      ) : (
        <NoData
          text={t('user.iterations.noItrs')}
          description={t('user.iterations.noItrsSubtitle')}
        />
      )}
    </Wrapper>
  );
}
