import { Menu, Popover } from '@headlessui/react';
import { ChartPieIcon, ChevronDownIcon } from '@heroicons/react/24/outline';
import { QueryClient, useQuery } from '@tanstack/react-query';
import { preLoadQuery } from '@youshift/shared/hooks';
import {
  groupStatsQuery,
  GroupStatsReturn,
} from '@youshift/shared/hooks/queries';
import { Rule } from '@youshift/shared/types';
import {
  formatUserName,
  parseIterationDates,
  returnColor,
} from '@youshift/shared/utils';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLoaderData } from 'react-router-dom';

import { EmptyState } from '../../../components/EmptyState';
import LabelIconComponent from '../../../components/LabelIconComponent';
import { CounterManager } from '../../../components/Stats/CounterManager';
import Wrapper from '../../../components/Wrapper';
import { useManagerContext } from '../../../layouts/ManagerLayout';
import {
  buildGroupStats,
  calculateGroupPercentages,
  createBaseIdMapping,
  createLatestBaseObjectMapping,
  createSectionSlotToRulesMapping,
  createShiftAssignmentsMapping,
  loadFromStorage,
  mergeIterationsData,
  participatesInWhichBaseRules,
  saveToStorage,
  toggleItrSelection,
  UserGroupStats,
} from '../utils';
import UsersFilterDropdown from '../../../FilterDropdownWithSubgroups';

export const groupStatsLoader =
  (queryClient: QueryClient) => async (): Promise<GroupStatsReturn> => {
    const groupStats = await preLoadQuery(queryClient, groupStatsQuery());
    return groupStats;
  };

export const useGroupStatsLoader = (): GroupStatsReturn => {
  const initialData = useLoaderData() as GroupStatsReturn;
  const { data: groupStats } = useQuery({
    ...groupStatsQuery(),
    initialData,
    staleTime: 1000 * 60 * 5,
  });

  return groupStats;
};

export default function GroupStats() {
  const { t } = useTranslation();
  const groupStatsData = useGroupStatsLoader();
  const { counters, users, roles } = useManagerContext();

  const [selectedChain, setSelectedChain] = useState(
    Number(Object.keys(groupStatsData)[0]),
  );
  const { chain_name, itrs } = groupStatsData[selectedChain];
  const [selectedItrs, setSelectedItrs] = useState(
    new Set(Object.keys(itrs).map(Number)),
  );

  const {
    section_slots: allSectionSlots,
    user_req_rules: allUserReqRules,
    sections: allSections,
    slot_labels: allSlotLabels,
  } = useMemo(
    () =>
      mergeIterationsData(
        itrs,
        ['section_slots', 'user_req_rules', 'sections', 'slot_labels'],
        selectedItrs,
      ),
    [itrs, selectedItrs],
  );

  const allShiftAssignments = useMemo(
    () => createShiftAssignmentsMapping(itrs, selectedItrs),
    [itrs, selectedItrs],
  );

  const allRules = Object.values(allUserReqRules).reduce<Record<number, Rule>>(
    (acc, { rule }) => {
      acc[rule.id_rule] = rule;
      return acc;
    },
    {},
  );

  const sectionSlotToRules = useMemo(
    () => createSectionSlotToRulesMapping(allUserReqRules),
    [allUserReqRules],
  );

  // Create mappings id_object: base_id_object for sections, rules, and labels
  const sectionsBaseIds = createBaseIdMapping(
    allSections,
    'id_section',
    'base_id_section',
  );
  const rulesBaseIds = createBaseIdMapping(allRules, 'id_rule', 'base_id_rule');
  const labelsBaseIds = createBaseIdMapping(
    allSlotLabels,
    'id_slot_label',
    'base_id_slot_label',
  );

  // Groups all rules that share the same base rule ID
  // e.g., base rule 100 -> [rule1, rule2, rule3]
  const rulesWithSameBase = Object.values(allRules).reduce<
    Record<number, number[]>
  >((acc, rule) => {
    const baseId = rule.base_id_rule || rule.id_rule;
    if (!acc[baseId]) {
      acc[baseId] = [];
    }
    acc[baseId].push(rule.id_rule);
    return acc;
  }, {});

  const userBaseRuleParticipation = useMemo(() => {
    const participation: Record<number, Record<number, boolean>> = {};

    // Initialize participation map for all users
    Object.keys(users).forEach(userId => {
      const numUserId = Number(userId);
      participation[numUserId] = participatesInWhichBaseRules(
        rulesBaseIds,
        rulesWithSameBase,
        allUserReqRules,
        numUserId,
      );
    });

    return participation;
  }, [users, rulesBaseIds, rulesWithSameBase, allUserReqRules]);

  // Create mappings of base object ids (rules, sections, labels) to their most recent version
  const latestRules = createLatestBaseObjectMapping(
    allRules,
    'id_rule',
    'base_id_rule',
  );
  const latestSections = createLatestBaseObjectMapping(
    allSections,
    'id_section',
    'base_id_section',
  );
  const latestLabels = createLatestBaseObjectMapping(
    allSlotLabels,
    'id_slot_label',
    'base_id_slot_label',
  );

  const groupStats: UserGroupStats[] = useMemo(
    () =>
      buildGroupStats(
        allSectionSlots,
        allShiftAssignments,
        userBaseRuleParticipation,
        labelsBaseIds,
        latestLabels,
        latestRules,
        latestSections,
        roles,
        rulesBaseIds,
        sectionsBaseIds,
        sectionSlotToRules,
        users,
      ),
    [
      allSectionSlots,
      allShiftAssignments,
      userBaseRuleParticipation,
      labelsBaseIds,
      latestLabels,
      latestRules,
      latestSections,
      roles,
      rulesBaseIds,
      sectionsBaseIds,
      sectionSlotToRules,
      users,
    ],
  );

  const userPercentages = calculateGroupPercentages(selectedItrs, itrs);

  // Prepare lists for headers
  const sectionsList = useMemo(
    () => Object.values(latestSections),
    [latestSections],
  );
  const rulesList = useMemo(() => Object.values(latestRules), [latestRules]);
  const labelsList = useMemo(() => Object.values(latestLabels), [latestLabels]);

  // Replace the filter state declarations with these versions
  const [selectedCounters, setSelectedCounters] = useState(() =>
    loadFromStorage(
      'groupStats.selectedCounters',
      new Set(Object.keys(counters).map(Number)),
    ),
  );

  const [selectedRules, setSelectedRules] = useState(() =>
    loadFromStorage(
      'groupStats.selectedRules',
      new Set(Object.values(allRules).map(r => r.id_rule)),
    ),
  );

  const [selectedSections, setSelectedSections] = useState(() =>
    loadFromStorage(
      'groupStats.selectedSections',
      new Set(Object.values(latestSections).map(s => s.id_section)),
    ),
  );

  const [selectedLabels, setSelectedLabels] = useState(() =>
    loadFromStorage(
      'groupStats.selectedLabels',
      new Set(Object.values(latestLabels).map(l => l.id_slot_label)),
    ),
  );

  const [selectedUsers, setSelectedUsers] = useState(() =>
    loadFromStorage(
      'groupStats.selectedUsers',
      new Set(Object.keys(users).map(Number)),
    ),
  );

  // Add useEffect hooks to save changes
  useEffect(() => {
    saveToStorage('groupStats.selectedCounters', selectedCounters);
  }, [selectedCounters]);

  useEffect(() => {
    saveToStorage('groupStats.selectedRules', selectedRules);
  }, [selectedRules]);

  useEffect(() => {
    saveToStorage('groupStats.selectedSections', selectedSections);
  }, [selectedSections]);

  useEffect(() => {
    saveToStorage('groupStats.selectedLabels', selectedLabels);
  }, [selectedLabels]);

  useEffect(() => {
    saveToStorage('groupStats.selectedUsers', selectedUsers);
  }, [selectedUsers]);

  return (
    <Wrapper mt="mt-16">
      {/* <h1 className="text-2xl font-semibold leading-6 text-gray-900 my-4">
        {t('manager.stats.title')}
      </h1> */}

      <CounterManager counters={counters} />

      {Object.keys(itrs).length > 0 ? (
        <div className="mt-4">
          <div className="flex flex-row justify-between items-center">
            <h3 className="text-2xl font-semibold leading-6 text-gray-900 my-4">
              {t('manager.stats.title')}
            </h3>
            <div className="flex flex-row lg:gap-4 gap-2">
              {Object.keys(groupStats).length > 1 && (
                <Menu as="div" className="relative inline-block text-left">
                  <div>
                    <Menu.Button className="group inline-flex justify-center text-sm font-medium text-gray-700 hover:text-gray-900">
                      {chain_name}
                      <ChevronDownIcon
                        className="-mr-1 ml-1 h-5 w-5 flex-shrink-0 text-gray-400 group-hover:text-gray-500"
                        aria-hidden="true"
                      />
                    </Menu.Button>
                  </div>

                  <Menu.Items className="absolute left-0 z-30 mt-2 w-40 origin-top-left rounded-md bg-white shadow-2xl ring-1 ring-black ring-opacity-5 transition focus:outline-none data-[closed]:scale-95 data-[closed]:transform data-[closed]:opacity-0 data-[enter]:duration-100 data-[leave]:duration-75 data-[enter]:ease-out data-[leave]:ease-in">
                    <div className="py-1">
                      {Object.entries(groupStatsData).map(
                        ([idChain, chainInfo]) => (
                          <Menu.Item key={idChain}>
                            {() => (
                              <button
                                onClick={() => {
                                  setSelectedItrs(
                                    new Set(
                                      Object.keys(chainInfo.itrs).map(Number),
                                    ),
                                  );
                                  setSelectedChain(Number(idChain));
                                }}
                                className="hover:bg-gray-100 block px-4 py-2 text-sm font-medium text-gray-900 text-start"
                              >
                                {chainInfo.chain_name}
                              </button>
                            )}
                          </Menu.Item>
                        ),
                      )}
                    </div>
                  </Menu.Items>
                </Menu>
              )}
              <Popover as="div" className="relative inline-block text-left">
                <div>
                  <Popover.Button className="group inline-flex items-center justify-center text-sm font-medium text-gray-700 hover:text-gray-900">
                    <span>{t('generic.iterations')}</span>
                    {Object.keys(itrs).length === selectedItrs.size ? (
                      <span className="ml-1.5 rounded bg-gray-200 px-1.5 py-0.5 text-xs font-semibold tabular-nums text-gray-700">
                        {t('generic.all')}
                      </span>
                    ) : null}
                    <ChevronDownIcon
                      className="-mr-1 ml-1 h-5 w-5 flex-shrink-0 text-gray-400 group-hover:text-gray-500"
                      aria-hidden="true"
                    />
                  </Popover.Button>
                </div>

                <Popover.Panel className="absolute right-0 z-30 mt-2 origin-top-right rounded-md bg-white p-4 shadow-2xl ring-1 ring-black ring-opacity-5 transition focus:outline-none data-[closed]:scale-95 data-[closed]:transform data-[closed]:opacity-0 data-[enter]:duration-100 data-[leave]:duration-75 data-[enter]:ease-out data-[leave]:ease-in">
                  <form className="space-y-4">
                    {Object.entries(itrs).map(([idItr, itrInfo]) => (
                      <div key={idItr} className="flex items-center">
                        <input
                          id={`filter-${idItr}`}
                          name={`${idItr}[]`}
                          defaultValue={idItr}
                          type="checkbox"
                          className="h-4 w-4 rounded border-gray-300 text-blue-600 focus:ring-blue-500"
                          checked={selectedItrs.has(Number(idItr))}
                          onChange={() =>
                            toggleItrSelection(
                              Number(idItr),
                              selectedItrs,
                              setSelectedItrs,
                            )
                          }
                        />
                        <label
                          htmlFor={`filter-${idItr}`}
                          className="ml-3 whitespace-nowrap pr-6 text-sm font-medium text-gray-900"
                        >
                          {parseIterationDates(
                            itrInfo.itr.start_day,
                            itrInfo.itr.end_day,
                            itrInfo.itr.itr_type,
                          )}
                        </label>
                      </div>
                    ))}
                  </form>
                </Popover.Panel>
              </Popover>

              <Popover as="div" className="relative inline-block text-left">
                <Popover.Button className="group inline-flex items-center justify-center text-sm font-medium text-gray-700 hover:text-gray-900">
                  <span>{t('generic.sections')}</span>
                  {sectionsList.length === selectedSections.size && (
                    <span className="ml-1.5 rounded bg-gray-200 px-1.5 py-0.5 text-xs font-semibold tabular-nums text-gray-700">
                      {t('generic.all')}
                    </span>
                  )}
                  <ChevronDownIcon className="-mr-1 ml-1 h-5 w-5 flex-shrink-0 text-gray-400 group-hover:text-gray-500" />
                </Popover.Button>

                <Popover.Panel className="absolute right-0 z-30 mt-2 origin-top-right rounded-md bg-white p-4 shadow-2xl ring-1 ring-black ring-opacity-5 focus:outline-none">
                  <form className="space-y-4">
                    {sectionsList.map(section => (
                      <div
                        key={section.id_section}
                        className="flex items-center"
                      >
                        <input
                          type="checkbox"
                          checked={selectedSections.has(section.id_section)}
                          onChange={() => {
                            const newSelection = new Set(selectedSections);
                            if (newSelection.has(section.id_section)) {
                              newSelection.delete(section.id_section);
                            } else {
                              newSelection.add(section.id_section);
                            }
                            setSelectedSections(newSelection);
                          }}
                          className="h-4 w-4 rounded border-gray-300 text-blue-600"
                        />
                        <label className="ml-3 text-sm font-medium text-gray-900">
                          {section.acronym}
                        </label>
                      </div>
                    ))}
                  </form>
                </Popover.Panel>
              </Popover>

              <Popover as="div" className="relative inline-block text-left">
                <Popover.Button className="group inline-flex items-center justify-center text-sm font-medium text-gray-700 hover:text-gray-900">
                  <span>{t('generic.rules')}</span>
                  {rulesList.length === selectedRules.size && (
                    <span className="ml-1.5 rounded bg-gray-200 px-1.5 py-0.5 text-xs font-semibold tabular-nums text-gray-700">
                      {t('generic.all')}
                    </span>
                  )}
                  <ChevronDownIcon className="-mr-1 ml-1 h-5 w-5 flex-shrink-0 text-gray-400 group-hover:text-gray-500" />
                </Popover.Button>

                <Popover.Panel className="absolute right-0 z-30 mt-2 origin-top-right rounded-md bg-white p-4 shadow-2xl ring-1 ring-black ring-opacity-5 focus:outline-none">
                  <form className="space-y-4">
                    {rulesList.map(rule => (
                      <div key={rule.id_rule} className="flex items-center">
                        <input
                          type="checkbox"
                          checked={selectedRules.has(rule.id_rule)}
                          onChange={() => {
                            const newSelection = new Set(selectedRules);
                            if (newSelection.has(rule.id_rule)) {
                              newSelection.delete(rule.id_rule);
                            } else {
                              newSelection.add(rule.id_rule);
                            }
                            setSelectedRules(newSelection);
                          }}
                          className="h-4 w-4 rounded border-gray-300 text-blue-600"
                        />
                        <label className="ml-3 text-sm font-medium text-gray-900">
                          {rule.name}
                        </label>
                      </div>
                    ))}
                  </form>
                </Popover.Panel>
              </Popover>

              <Popover as="div" className="relative inline-block text-left">
                <Popover.Button className="group inline-flex items-center justify-center text-sm font-medium text-gray-700 hover:text-gray-900">
                  <span>{t('generic.labels')}</span>
                  {labelsList.length === selectedLabels.size && (
                    <span className="ml-1.5 rounded bg-gray-200 px-1.5 py-0.5 text-xs font-semibold tabular-nums text-gray-700">
                      {t('generic.all')}
                    </span>
                  )}
                  <ChevronDownIcon className="-mr-1 ml-1 h-5 w-5 flex-shrink-0 text-gray-400 group-hover:text-gray-500" />
                </Popover.Button>

                <Popover.Panel className="absolute right-0 z-30 mt-2 origin-top-right rounded-md bg-white p-4 shadow-2xl ring-1 ring-black ring-opacity-5 focus:outline-none">
                  <form className="space-y-4">
                    {labelsList.map(label => (
                      <div
                        key={label.id_slot_label}
                        className="flex items-center"
                      >
                        <input
                          type="checkbox"
                          checked={selectedLabels.has(label.id_slot_label)}
                          onChange={() => {
                            const newSelection = new Set(selectedLabels);
                            if (newSelection.has(label.id_slot_label)) {
                              newSelection.delete(label.id_slot_label);
                            } else {
                              newSelection.add(label.id_slot_label);
                            }
                            setSelectedLabels(newSelection);
                          }}
                          className="h-4 w-4 rounded border-gray-300 text-blue-600"
                        />
                        <label className="ml-3 text-sm font-medium text-gray-900">
                          {label.name}
                        </label>
                      </div>
                    ))}
                  </form>
                </Popover.Panel>
              </Popover>

              <Popover as="div" className="relative inline-block text-left">
                <Popover.Button className="group inline-flex items-center justify-center text-sm font-medium text-gray-700 hover:text-gray-900">
                  <span>{t('generic.counters')}</span>
                  {Object.keys(counters).length === selectedCounters.size && (
                    <span className="ml-1.5 rounded bg-gray-200 px-1.5 py-0.5 text-xs font-semibold tabular-nums text-gray-700">
                      {t('generic.all')}
                    </span>
                  )}
                  <ChevronDownIcon className="-mr-1 ml-1 h-5 w-5 flex-shrink-0 text-gray-400 group-hover:text-gray-500" />
                </Popover.Button>

                <Popover.Panel className="absolute right-0 z-30 mt-2 origin-top-right rounded-md bg-white p-4 shadow-2xl ring-1 ring-black ring-opacity-5 focus:outline-none">
                  <form className="space-y-4">
                    {Object.values(counters).map(counter => (
                      <div
                        key={counter.id_custom_counter}
                        className="flex items-center"
                      >
                        <input
                          type="checkbox"
                          checked={selectedCounters.has(
                            counter.id_custom_counter,
                          )}
                          onChange={() => {
                            const newSelection = new Set(selectedCounters);
                            if (newSelection.has(counter.id_custom_counter)) {
                              newSelection.delete(counter.id_custom_counter);
                            } else {
                              newSelection.add(counter.id_custom_counter);
                            }
                            setSelectedCounters(newSelection);
                          }}
                          className="h-4 w-4 rounded border-gray-300 text-blue-600"
                        />
                        <label className="ml-3 text-sm font-medium text-gray-900">
                          {counter.name}
                        </label>
                      </div>
                    ))}
                  </form>
                </Popover.Panel>
              </Popover>

              <UsersFilterDropdown
                users={users}
                roles={roles}
                selectedUsers={selectedUsers}
                onSelectionChange={setSelectedUsers}
              />
            </div>
          </div>
          <div className="relative">
            <div className="overflow-x-auto overflow-y-auto max-h-[75vh] border rounded-lg">
              <table className="w-full table-auto bg-white">
                <thead className="sticky top-0 z-20 bg-white">
                  <tr className="bg-gray-100">
                    <th
                      rowSpan={2}
                      className="sticky left-0 top-0 z-20 px-2 py-1 border bg-gray-100 min-w-[120px] whitespace-normal"
                    >
                      {t('generic.name')}
                    </th>
                    <th
                      rowSpan={2}
                      className="sticky left-[120px] top-0 z-20 px-2 py-1 border bg-gray-100 min-w-[100px] whitespace-normal"
                    >
                      {t('generic.role')}
                    </th>
                    <th
                      rowSpan={2}
                      className="sticky left-[220px] top-0 z-20 px-2 py-1 border bg-gray-100 min-w-[80px] whitespace-normal"
                    >
                      {t('generic.total')}
                    </th>
                    <th
                      colSpan={2}
                      className="px-2 py-1 border whitespace-normal"
                    >
                      {t('user.stats.pointsRespected')}
                    </th>
                    {selectedSections.size > 0 && (
                      <th
                        colSpan={selectedSections.size}
                        className="px-2 py-1 border whitespace-normal"
                      >
                        {t('generic.sections')}
                      </th>
                    )}
                    {selectedRules.size > 0 && (
                      <th
                        colSpan={selectedRules.size}
                        className="px-2 py-1 border whitespace-normal"
                      >
                        {t('generic.rules')}
                      </th>
                    )}
                    {selectedLabels.size > 0 && (
                      <th
                        colSpan={selectedLabels.size}
                        className="px-2 py-1 border whitespace-normal"
                      >
                        {t('generic.labels')}
                      </th>
                    )}
                    {selectedCounters.size > 0 && (
                      <th
                        colSpan={selectedCounters.size}
                        className="px-2 py-1 border whitespace-normal"
                      >
                        {t('generic.counters')}
                      </th>
                    )}
                  </tr>
                  <tr>
                    <th className="px-2 py-1 border text-center font-medium text-green-600">
                      +
                    </th>
                    <th className="px-2 py-1 border text-center font-medium text-red-600">
                      -
                    </th>
                    {sectionsList
                      .filter(s => selectedSections.has(s.id_section))
                      .map(section => (
                        <th
                          key={section.id_section}
                          className="px-2 py-1 border relative min-w-[80px] max-w-[120px]"
                        >
                          <div className="flex items-center justify-center gap-2">
                            <div
                              className="w-3 h-3 flex-shrink-0 rounded-full"
                              style={{
                                backgroundColor: returnColor(section.color),
                              }}
                            />
                            <span className="whitespace-normal text-sm break-words">
                              {section.acronym}
                            </span>
                          </div>
                          <div
                            className="absolute bottom-0 left-0 w-full h-1"
                            style={{
                              backgroundColor: returnColor(section.color),
                            }}
                          />
                        </th>
                      ))}
                    {rulesList
                      .filter(r => selectedRules.has(r.id_rule))
                      .map(rule => (
                        <th
                          key={rule.id_rule}
                          className="px-2 py-1 border min-w-[100px] max-w-[120px] whitespace-normal"
                        >
                          {rule.name}
                        </th>
                      ))}
                    {labelsList
                      .filter(l => selectedLabels.has(l.id_slot_label))
                      .map(label => (
                        <th
                          key={label.id_slot_label}
                          className="px-2 py-1 border min-w-[120px] max-w-[160px] whitespace-normal"
                        >
                          <div className="flex items-center justify-center gap-2">
                            {label.icon && (
                              <LabelIconComponent
                                icon={label.icon}
                                className="w-5 h-5 p-0.5"
                              />
                            )}
                            <span className="whitespace-normal text-sm break-words">
                              {label.name}
                            </span>
                          </div>
                        </th>
                      ))}
                    {Object.values(counters)
                      .filter(c => selectedCounters.has(c.id_custom_counter))
                      .map(counter => (
                        <th
                          key={counter.id_custom_counter}
                          className="px-2 py-1 border font-medium text-gray-900 min-w-[80px] max-w-[100px] whitespace-normal"
                          title={counter.name || undefined}
                        >
                          {counter.name}
                        </th>
                      ))}
                  </tr>
                </thead>
                <tbody>
                  {groupStats
                    .filter(stat => selectedUsers.has(stat.userId))
                    .sort(
                      (userStatA, userStatB) =>
                        userStatA.roleName.localeCompare(userStatB.roleName) ||
                        userStatA.userName.localeCompare(userStatB.userName),
                    )
                    .map(userStat => (
                      <tr key={userStat.userId}>
                        <td className="sticky left-0 z-10 px-2 py-1 border bg-white">
                          <Link
                            to={`../team/personnel/${userStat.userId}`}
                            state={{
                              name: userStat.userName,
                              role: userStat.roleName,
                              previous: 'stats',
                            }}
                          >
                            {userStat.userName}
                          </Link>
                        </td>
                        <td className="sticky left-[120px] z-10 px-2 py-1 border bg-white">
                          {userStat.roleName}
                        </td>
                        <td className="sticky left-[220px] z-10 px-2 py-1 border bg-white text-center">
                          <div className="flex flex-row gap-1 justify-center text-center items-center">
                            <div>
                              {Object.values(userStat.shiftsPerSection).reduce(
                                (acc, curr) => acc + (curr?.count || 0),
                                0,
                              )}
                            </div>
                            <span>|</span>
                            <div className="text-gray-500">
                              {Object.values(userStat.shiftsPerSection).reduce(
                                (acc, curr) => acc + (curr?.hours || 0),
                                0,
                              )}
                              h
                            </div>
                          </div>
                        </td>
                        <td className="px-2 py-1 border text-center text-green-600">
                          {userPercentages[userStat.userId]
                            ?.positivePercentage !== null
                            ? `${userPercentages[userStat.userId]?.positivePercentage}%`
                            : '-'}
                        </td>
                        <td className="px-2 py-1 border text-center text-red-600">
                          {userPercentages[userStat.userId]
                            ?.negativePercentage !== null
                            ? `${userPercentages[userStat.userId]?.negativePercentage}%`
                            : '-'}
                        </td>
                        {sectionsList
                          .filter(s => selectedSections.has(s.id_section))
                          .map(section => (
                            <td
                              key={section.id_section}
                              className="px-2 py-1 border bg-white text-center"
                            >
                              <div className="flex flex-row gap-1 justify-center text-center items-center">
                                <div>
                                  {userStat.shiftsPerSection[section.id_section]
                                    ?.count || 0}
                                </div>
                                <span>|</span>
                                <div className="text-gray-500">
                                  {userStat.shiftsPerSection[section.id_section]
                                    ?.hours || 0}
                                  h
                                </div>
                              </div>
                            </td>
                          ))}
                        {rulesList
                          .filter(r => selectedRules.has(r.id_rule))
                          .map(rule => (
                            <td
                              key={rule.id_rule}
                              className="px-2 py-1 border text-center min-w-[100px] max-w-[120px]"
                            >
                              {userBaseRuleParticipation[userStat.userId][
                                rule.id_rule
                              ] ? (
                                <div className="relative z-10 flex flex-row gap-1 justify-center text-center items-center">
                                  <div>
                                    {userStat.shiftsPerRule[rule.id_rule]
                                      ?.count || 0}
                                  </div>
                                  <span>|</span>
                                  <div className="text-sm text-gray-500">
                                    {userStat.shiftsPerRule[rule.id_rule]
                                      ?.hours || 0}
                                    h
                                  </div>
                                </div>
                              ) : (
                                '-'
                              )}
                            </td>
                          ))}
                        {labelsList
                          .filter(l => selectedLabels.has(l.id_slot_label))
                          .map(label => (
                            <td
                              key={label.id_slot_label}
                              className="px-2 py-1 border text-center group min-w-[120px] max-w-[160px]"
                            >
                              <div className="relative z-10 flex flex-row gap-1 justify-center text-center items-center">
                                <div>
                                  {userStat.shiftsPerLabel[label.id_slot_label]
                                    ?.count || 0}
                                </div>
                                <span>|</span>
                                <div className="text-sm text-gray-500">
                                  {userStat.shiftsPerLabel[label.id_slot_label]
                                    ?.hours || 0}
                                  h
                                </div>
                              </div>
                            </td>
                          ))}
                        {Object.values(counters)
                          .filter(c =>
                            selectedCounters.has(c.id_custom_counter),
                          )
                          .map(counter => (
                            <td
                              key={counter.id_custom_counter}
                              className="px-2 py-1 border text-center min-w-[80px] max-w-[100px]"
                            >
                              {userStat.customCounters[
                                counter.id_custom_counter
                              ] || '0'}
                            </td>
                          ))}
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      ) : (
        <div>
          <Menu as="div" className="relative text-left mt-12 flex justify-end">
            <div>
              <Menu.Button className="group inline-flex justify-center text-sm font-medium text-gray-700 hover:text-gray-900">
                {chain_name}
                <ChevronDownIcon
                  className="-mr-1 ml-1 h-5 w-5 flex-shrink-0 text-gray-400 group-hover:text-gray-500"
                  aria-hidden="true"
                />
              </Menu.Button>
            </div>

            <Menu.Items className="absolute left-0 z-30 mt-2 w-40 origin-top-left rounded-md bg-white shadow-2xl ring-1 ring-black ring-opacity-5 transition focus:outline-none data-[closed]:scale-95 data-[closed]:transform data-[closed]:opacity-0 data-[enter]:duration-100 data-[leave]:duration-75 data-[enter]:ease-out data-[leave]:ease-in">
              <div className="py-1">
                {Object.entries(groupStatsData).map(([idChain, chainInfo]) => (
                  <Menu.Item key={idChain}>
                    {() => (
                      <button
                        onClick={() => {
                          setSelectedItrs(
                            new Set(Object.keys(chainInfo.itrs).map(Number)),
                          );
                          setSelectedChain(Number(idChain));
                        }}
                        className="hover:bg-gray-100 block px-4 py-2 text-sm font-medium text-gray-900 text-start"
                      >
                        {chainInfo.chain_name}
                      </button>
                    )}
                  </Menu.Item>
                ))}
              </div>
            </Menu.Items>
          </Menu>
          <EmptyState
            title={t('manager.stats.noData')}
            subtitle={t('manager.stats.noDataSubtitle')}
            Icon={ChartPieIcon}
          />
        </div>
      )}
    </Wrapper>
  );
}
