import { Fragment, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { ExclamationTriangleIcon } from '@heroicons/react/24/outline';

import Modal from '../../../components/Modal';
import { YSButton } from '../../../components/Buttons';

interface PublishProps {
  open: boolean;
  setOpen: (open: boolean) => void;
  publishResults: {
    mutate: (params: {
      send_users_notification: boolean;
      id_itr: string;
    }) => void;
  };
}

export default function PublishModal({
  open,
  setOpen,
  publishResults,
}: PublishProps) {
  const { t } = useTranslation();
  const [sendNotificationCheck, setSendNotificationCheck] = useState(false);
  const { idItr } = useParams();

  return (
    <Modal isOpen={open} onClose={() => setOpen(false)} size="lg">
      <div className="sm:flex sm:items-start">
        <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-amber-100 sm:mx-0 sm:h-10 sm:w-10">
          <ExclamationTriangleIcon
            className="h-6 w-6 text-amber-600"
            aria-hidden="true"
          />
        </div>
        <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
          <h3 className="text-base font-semibold leading-6 text-gray-900">
            {t('manager.iterationVerification.publishResults')}
          </h3>
          <div className="mt-2">
            <p className="text-sm text-gray-500">
              {t('manager.iterationVerification.publishConfirm')}
            </p>
          </div>
        </div>
      </div>

      <fieldset>
        <div className="space-y-5 mt-3">
          <div className="relative flex items-start">
            <div className="flex h-6 items-center">
              <input
                id="comments"
                aria-describedby="comments-description"
                name="comments"
                type="checkbox"
                className="h-4 w-4 rounded border-gray-300 text-blue-600 focus:ring-blue-600"
                checked={sendNotificationCheck}
                onChange={() => {
                  setSendNotificationCheck(!sendNotificationCheck);
                }}
              />
            </div>
            <div className="ml-3 text-sm leading-6">
              <label htmlFor="comments" className="font-medium text-gray-900">
                {t('manager.iterationVerification.sendUserPublishNotification')}
              </label>
              <p id="comments-description" className="text-gray-500">
                {t(
                  'manager.iterationVerification.sendUserPublishNotificationDescription',
                )}
              </p>
            </div>
          </div>
        </div>
      </fieldset>

      <div className="mt-4 sm:flex sm:flex-row-reverse">
        <YSButton
          variant="primary"
          onClick={() => {
            publishResults.mutate({
              send_users_notification: sendNotificationCheck,
              id_itr: idItr ?? '',
            });
            setOpen(false);
          }}
        >
          {t('generic.publish')}
        </YSButton>
        <div className="mr-3">
          <YSButton variant="ghost-secondary" onClick={() => setOpen(false)}>
            {t('generic.cancel')}
          </YSButton>
        </div>
      </div>
    </Modal>
  );
}
