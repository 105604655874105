import axios from 'axios';
import { useState, createContext, useContext, useMemo, ReactNode } from 'react';

interface AuthContextType {
  isLoggedIn: boolean;
  setIsLoggedIn: (value: boolean) => void;
  managerLevel: number;
  setManagerLevel: (level: number) => void;
  setToken: (token: string) => void;
  token: string | null;
  removeToken: () => void;
}

export const AuthContext = createContext<AuthContextType | null>(null);

export function useAuth(): AuthContextType {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
}

interface AuthProviderProps {
  children: ReactNode;
}

export function AuthProvider({ children }: AuthProviderProps) {
  const [isLoggedIn, setIsLoggedIn] = useState<boolean>(
    !!localStorage.getItem('token'),
  );
  const [managerLevel, setManagerLevel] = useState<number>(0);

  function getToken(): string | null {
    const userToken = localStorage.getItem('token');
    return userToken || null;
  }

  const [token, setToken] = useState<string | null>(getToken());

  function saveToken(userToken: string): void {
    localStorage.setItem('token', userToken);
    setToken(userToken);
  }

  function removeToken(): void {
    localStorage.removeItem('token');
    setToken(null);
    setIsLoggedIn(false);
  }

  // Add an Axios interceptor to send the token with each request
  axios.interceptors.request.use(
    config => {
      const tokenToSend = getToken() || token;
      if (tokenToSend) {
        // eslint-disable-next-line no-param-reassign
        config.headers.Authorization = `Bearer ${tokenToSend}`;
      }
      return config;
    },
    error => Promise.reject(error),
  );

  const value = useMemo(
    () => ({
      isLoggedIn,
      setIsLoggedIn,
      managerLevel,
      setManagerLevel,
      setToken: saveToken,
      token,
      removeToken,
    }),
    [isLoggedIn, setIsLoggedIn, managerLevel, setManagerLevel, token],
  );

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}
