import {
  VirtualSlotId,
  UserId,
  SectionSlotId,
  RequirementRuleId,
  SectionSlot,
} from '@youshift/shared/types';

// Types of errors that can happen in configuration.
/**
 * Each error type defined in ConfigurationCheckErrorType enum must have a corresponding
 * Error interface that defines the structure of the error context data for that specific type.
 * This ensures consistent error handling and type safety throughout the application.
 */

export enum ConfigurationCheckErrorType {
  // Check that a user has enough free slots to fill all its requirements for a rule.
  SINGLE_USER_REQ_RULE_SLOTS_DEFICIT = 'single_user_req_rule_slots_deficit',
  SINGLE_USER_REQ_RULE_DURATION_DEFICIT = 'single_user_req_rule_duration_deficit',

  // Check that a user has enough free slots to fill all its section requirements for ALL sections.
  USER_SUPPLY_DEFICIT = 'user_supply_deficit',

  // Check that there are enough people to fill the virtual slot
  VIRTUAL_SLOT_SUPPLY_DEMAND_DEFICIT = 'virtual_slot_supply_demand_deficit',

  // Check that a rule has
  SECTION_USER_REQ_RULE_SLOTS_DEFICIT = 'section_user_req_rule_slots_deficit',
}

export interface VirtualSlotSupplyDemandErrorContext {
  min_demand: number;
  available_workers: number[];
  users_preassigned_OS_on_virtual_slot: number[];
}

export interface SingleUserReqRuleSlotsDeficitErrorContext {
  min_slots: number;
  available_slots: number[];
  already_assigned_slots: number[];
}

export interface SingleUserReqRuleDurationDeficitErrorContext {
  min_duration: number;
  available_duration: number;
  already_assigned_duration: number;
}

export interface UserSupplyDeficitErrorContext {
  min_configured_supply: number;
  max_assignable_slots: number;
  assignable_section_slots: number[];
}

export interface SingleUserReqRuleErrors {
  [ConfigurationCheckErrorType.SINGLE_USER_REQ_RULE_SLOTS_DEFICIT]?: SingleUserReqRuleSlotsDeficitErrorContext;
  [ConfigurationCheckErrorType.SINGLE_USER_REQ_RULE_DURATION_DEFICIT]?: SingleUserReqRuleDurationDeficitErrorContext;
}

export type UserErrors = Partial<{
  user_supply_demand: UserSupplyDeficitErrorContext;
  user_req_rules: { [id_req_rule: RequirementRuleId]: SingleUserReqRuleErrors };
}>;

export type VirtualSlotErrors = Partial<{
  [ConfigurationCheckErrorType.VIRTUAL_SLOT_SUPPLY_DEMAND_DEFICIT]: VirtualSlotSupplyDemandErrorContext;
}>;

export interface SectionUserReqRuleSlotsDeficitErrorContext {
  min_needs: number;
  max_supply: number;
  num_users_in_rule: number;
  num_unbounded_users: number;
}
export type UserReqRuleErrors = Partial<{
  [ConfigurationCheckErrorType.SECTION_USER_REQ_RULE_SLOTS_DEFICIT]: SectionUserReqRuleSlotsDeficitErrorContext;
}>;

export type ConfigurationCheckErrors = {
  users: {
    [id_user: UserId]: UserErrors;
  };
  virtual_slots: {
    [id_virtual_slot: VirtualSlotId]: VirtualSlotErrors;
  };
  user_req_rules: {
    [id_req_rule: RequirementRuleId]: UserReqRuleErrors;
  };
};
