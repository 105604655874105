import { Autocomplete, TextField } from '@mui/material';
import { useQueryClient } from '@tanstack/react-query';
import { organizationsQuery } from '@youshift/shared/hooks/queries';
import { Organization } from '@youshift/shared/types';
import { useEffect, useState, useCallback } from 'react';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';

import {
  AvailableOptions,
  FormActions,
  getPlaces,
  LocationData,
  Option,
  SelectedValues,
  VisibleDropdowns,
} from './types';

interface ExistingOrganizationSignUpFormProps {
  dispatch: React.Dispatch<FormActions>;
  setCreatingGroupWithExistingOrg: (value: boolean) => void;
}

export default function ExistingOrganizationSignUpForm({
  dispatch,
  setCreatingGroupWithExistingOrg,
}: ExistingOrganizationSignUpFormProps) {
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  const [organizations, setOrganizations] = useState<Organization[]>([]);
  const [provinces, setProvinces] = useState<LocationData[]>([]);

  const [availableOptions, setAvailableOption] = useState<AvailableOptions>({
    country: [],
    province: [],
    organization: [],
  });

  const [visibleDropdowns, setVisibleDropdowns] = useState<VisibleDropdowns>({
    province: false,
    organization: false,
  });

  const [selectedValues, setSelectedValues] = useState<SelectedValues>({
    province: null,
    organization: null,
  });

  const updateAvailableOptions = useCallback(
    (selectorUpdated: string, filter: string | null) => {
      let provinceOptions: Option[] = [];
      let organizationOptions: Option[] = [];

      if (filter) {
        switch (selectorUpdated) {
          case 'COUNTRY':
            provinceOptions = provinces
              .filter(p => p.country.toLowerCase() === filter)
              .map(p => ({
                label: `${p.state} - ${p.province}`,
                id: `${p.state} - ${p.province}`.toLowerCase(),
              }));
            break;
          case 'PROVINCE':
            organizationOptions = organizations
              .filter(
                o => `${o.state} - ${o.province}`.toLowerCase() === filter,
              )
              .map(org => ({ label: org.name, id: org.id_org.toString() }));
            break;
          default:
            break;
        }
      }

      setAvailableOption(prev => ({
        country: prev.country,
        province:
          selectorUpdated === 'COUNTRY' ? provinceOptions : prev.province,
        organization:
          selectorUpdated === 'PROVINCE'
            ? organizationOptions
            : prev.organization,
      }));
    },
    [provinces, organizations],
  );

  // Memoized autocomplete handler
  const handleAutocomplete = useCallback(
    (value: Option | null, selector: string) => {
      if (value) {
        updateAvailableOptions(selector, value.id);
        switch (selector) {
          case 'ORGANIZATION':
            setSelectedValues(prev => ({
              organization: value,
              province: prev.province,
            }));
            dispatch({
              type: 'UPDATE_ID_ORG',
              value: Number(value.id),
            });
            break;
          case 'PROVINCE':
            setSelectedValues({ organization: null, province: value });
            setVisibleDropdowns(prev => ({
              organization: !!value.id,
              province: prev.province,
            }));
            dispatch({
              type: 'UPDATE_ID_ORG',
              value: undefined,
            });
            break;
          case 'COUNTRY':
            setVisibleDropdowns({ organization: false, province: !!value.id });
            setSelectedValues({ organization: null, province: null });
            break;
          default:
            break;
        }
      } else {
        dispatch({ type: 'UPDATE_ID_ORG', value: undefined });
        updateAvailableOptions(selector, null);

        switch (selector) {
          case 'PROVINCE':
            setVisibleDropdowns(prev => ({
              organization: false,
              province: true,
            }));
            setSelectedValues(prev => ({
              organization: null,
              province: prev.province,
            }));
            break;
          case 'COUNTRY':
            setVisibleDropdowns({ organization: false, province: false });
            setSelectedValues({ organization: null, province: null });
            break;
          default:
            break;
        }
      }
    },
    [dispatch, updateAvailableOptions],
  );

  // Initial data fetch
  useEffect(() => {
    const fetchData = async () => {
      try {
        const organizations =
          await queryClient.fetchQuery(organizationsQuery());
        const countries = [...new Set(organizations.map(d => d.country))];
        const countryOptions = countries.map(c => ({
          label: c,
          id: c.toLowerCase(),
        }));

        setOrganizations(organizations);
        setProvinces(getPlaces(organizations));
        setAvailableOption(prev => ({
          ...prev,
          country: countryOptions,
        }));
      } catch (error) {
        toast.error(t('auth.signUpManager.organizationFetchError'));
      }
    };
    fetchData();
  }, [queryClient, t]);

  return (
    <div className="my-12">
      <div className="sm:col-span-full">
        <p className="block text-sm font-medium leading-6 text-gray-900">
          {t('generic.organization')}
        </p>
        <div className="mt-2">
          <Autocomplete
            disablePortal
            id="country"
            options={availableOptions.country}
            sx={{
              width: '100%',
              display: 'block',
              borderRadius: '0.375rem',
              borderWidth: '0px',
              paddingTop: '0.375rem',
              paddingBottom: '0.375rem',
            }}
            renderInput={params => (
              <TextField {...params} label={t('generic.country')} />
            )}
            onChange={(_, value) => handleAutocomplete(value, 'COUNTRY')}
          />
        </div>
      </div>

      {visibleDropdowns.province && (
        <div className="sm:col-span-full">
          <div className="mt-2">
            <Autocomplete
              disablePortal
              id="provinces"
              value={selectedValues.province}
              options={availableOptions.province}
              sx={{
                width: '100%',
                display: 'block',
                borderRadius: '0.375rem',
                borderWidth: '0px',
                paddingTop: '0.375rem',
                paddingBottom: '0.375rem',
              }}
              renderInput={params => (
                <TextField {...params} label={t('generic.province')} />
              )}
              onChange={(_, value) => handleAutocomplete(value, 'PROVINCE')}
            />
          </div>
        </div>
      )}

      {visibleDropdowns.organization && (
        <div className="sm:col-span-full">
          <div className="mt-2">
            <Autocomplete
              disablePortal
              id="organization"
              value={selectedValues.organization}
              options={availableOptions.organization}
              sx={{
                width: '100%',
                display: 'block',
                borderRadius: '0.375rem',
                borderWidth: '0px',
                paddingTop: '0.375rem',
                paddingBottom: '0.375rem',
              }}
              renderInput={params => (
                <TextField {...params} label={t('generic.organization')} />
              )}
              onChange={(_, value) => handleAutocomplete(value, 'ORGANIZATION')}
            />
          </div>
        </div>
      )}
      <p className="mt-2 text-sm text-gray-600">
        {t('auth.signUpManager.notFindingOrg')}
        <button
          onClick={() => setCreatingGroupWithExistingOrg(false)}
          className="font-medium text-blue-600 hover:text-blue-400 px-1"
        >
          {t('auth.signUpManager.clickHere')}
        </button>
        {t('auth.signUpManager.toAddIt')}
      </p>
    </div>
  );
}
