import { Dialog, Transition } from '@headlessui/react';
import { PlusIcon } from '@heroicons/react/24/outline';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { Fragment, useRef, useState } from 'react';
import toast from 'react-hot-toast';
import { useCreateOrganizationMutation } from '@youshift/shared/hooks/mutations';
import { Industry } from '@youshift/shared/types';

import Alert from '../../components/FormFeedback/Alert';

interface NewOrgProps {
  open: boolean;
  setOpen: (open: boolean) => void;
}

interface OrganizationInput {
  name: string;
  address: string;
  postal_code: string;
  country: string;
  state: string;
  province: string;
  city: string;
  industry: Industry;
}

export default function NewOrg({ open, setOpen }: NewOrgProps): JSX.Element {
  const cancelButtonRef = useRef<HTMLButtonElement>(null);
  const [name, setName] = useState<string>('');
  const [country, setCountry] = useState<string>('');
  const [state, setState] = useState<string>('');
  const [province, setProvince] = useState<string>('');
  const [address, setAddress] = useState<string>('');
  const [postalCode, setPostalCode] = useState<string>('');
  const [city, setCity] = useState<string>('');
  const [error, setError] = useState<string | null>(null);
  const queryClient = useQueryClient();
  const [industry, setIndustry] = useState<Industry>(Industry.HEALTHCARE);

  const createOrg = useCreateOrganizationMutation(queryClient, {
    onSuccess: () => {
      toast.success('Organization created successfully');
      setOpen(false);
      queryClient.invalidateQueries({ queryKey: ['organizations'] });
    },
    onError: (err: Error) => {
      toast.error(`Error creating organization: ${err.message}`);
      setError(err.message);
    },
  });

  const handleSubmit = () => {
    const input: OrganizationInput = {
      name,
      address,
      postal_code: postalCode,
      country,
      state,
      province,
      city,
      industry,
    };
    createOrg.mutate(input);
  };

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-10"
        initialFocus={cancelButtonRef}
        onClose={setOpen}
      >
        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                <div>
                  <div className="mx-auto my-4 flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-teal-100">
                    <PlusIcon
                      className="h-6 w-6 text-teal-600"
                      aria-hidden="true"
                    />
                  </div>
                  <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                    <Dialog.Title
                      as="h3"
                      className="text-lg leading-6 font-medium mb-4 text-gray-900"
                    >
                      Create New Organization
                    </Dialog.Title>
                    <div className="mt-2">
                      <div className="space-y-4">
                        <input
                          type="text"
                          name="name"
                          id="name"
                          className="block w-full shadow-sm sm:text-sm focus:ring-teal-500 focus:border-teal-500 border-gray-300 rounded-md"
                          placeholder="Organization Name"
                          value={name}
                          onChange={e => setName(e.target.value)}
                        />
                        <input
                          type="text"
                          name="address"
                          id="address"
                          className="block w-full shadow-sm sm:text-sm focus:ring-teal-500 focus:border-teal-500 border-gray-300 rounded-md"
                          placeholder="Address"
                          value={address}
                          onChange={e => setAddress(e.target.value)}
                        />
                        <input
                          type="text"
                          name="postal_code"
                          id="postal_code"
                          className="block w-full shadow-sm sm:text-sm focus:ring-teal-500 focus:border-teal-500 border-gray-300 rounded-md"
                          placeholder="Postal Code"
                          value={postalCode}
                          onChange={e => setPostalCode(e.target.value)}
                        />
                        <input
                          type="text"
                          name="country"
                          id="country"
                          className="block w-full shadow-sm sm:text-sm focus:ring-teal-500 focus:border-teal-500 border-gray-300 rounded-md"
                          placeholder="Country"
                          value={country}
                          onChange={e => setCountry(e.target.value)}
                        />
                        <input
                          type="text"
                          name="state"
                          id="state"
                          className="block w-full shadow-sm sm:text-sm focus:ring-teal-500 focus:border-teal-500 border-gray-300 rounded-md"
                          placeholder="State"
                          value={state}
                          onChange={e => setState(e.target.value)}
                        />
                        <input
                          type="text"
                          name="province"
                          id="province"
                          className="block w-full shadow-sm sm:text-sm focus:ring-teal-500 focus:border-teal-500 border-gray-300 rounded-md"
                          placeholder="Province"
                          value={province}
                          onChange={e => setProvince(e.target.value)}
                        />
                        <input
                          type="text"
                          name="city"
                          id="city"
                          className="block w-full shadow-sm sm:text-sm focus:ring-teal-500 focus:border-teal-500 border-gray-300 rounded-md"
                          placeholder="City"
                          value={city}
                          onChange={e => setCity(e.target.value)}
                        />
                        <select
                          name="industry"
                          id="industry"
                          className="block w-full shadow-sm sm:text-sm focus:ring-teal-500 focus:border-teal-500 border-gray-300 rounded-md"
                          value={industry}
                          onChange={e =>
                            setIndustry(e.target.value as Industry)
                          }
                        >
                          {Object.values(Industry).map(ind => (
                            <option key={ind} value={ind}>
                              {ind}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
                {error && <Alert success={false} text={error} />}
                <div className="mt-5 sm:mt-4 sm:flex sm:justify-end">
                  <button
                    type="button"
                    className="inline-flex mt-4 w-full justify-center rounded-md border border-transparent bg-teal-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-teal-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-teal-500 sm:ml-3 sm:w-auto sm:text-sm"
                    onClick={handleSubmit}
                  >
                    Create Organization
                  </button>
                  <button
                    type="button"
                    className="inline-flex mt-4 w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-teal-500 sm:ml-3 sm:w-auto sm:text-sm"
                    onClick={() => setOpen(false)}
                    ref={cancelButtonRef}
                  >
                    Cancel
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
