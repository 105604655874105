import {
  RuleTypes,
  SectionWithSlots,
  Section,
  SlotLabel,
} from '@youshift/shared/types';
import { Dispatch } from 'react';
import { useTranslation } from 'react-i18next';

export type RuleTypeProps = {
  ruleType: RuleTypes | null;
  setRuleType: Dispatch<RuleTypes>;
  ruleName: string;
  setRuleName: (name: string) => void;
  ruleDescription: string;
  setRuleDescription: (description: string) => void;
  sectionsWithSlots: SectionWithSlots[];
  selectedSection: number | null;
  setSelectedSection: (section: number | null) => void;
  shiftLabels: SlotLabel[];
  selectedLabel: number | null;
  setSelectedLabel: (label: number | null) => void;
};

export default function RuleType({
  ruleType,
  setRuleType,
  ruleName,
  setRuleName,
  sectionsWithSlots,
  selectedSection,
  setSelectedSection,
  shiftLabels,
  selectedLabel,
  setSelectedLabel,
  ruleDescription,
  setRuleDescription,
}: RuleTypeProps) {
  const { t } = useTranslation();

  return (
    <div className="space-y-4 px-8">
      <fieldset aria-label="Rule type" className="rounded-md bg-white">
        <div className="space-y-px">
          <label className="group flex cursor-pointer border border-gray-200 p-4 rounded-t-md">
            <input
              value={RuleTypes.SECTION_USER_REQS}
              checked={ruleType === RuleTypes.SECTION_USER_REQS}
              onChange={e => setRuleType(e.target.value as RuleTypes)}
              name="rule-type"
              type="radio"
              className="mt-0.5 size-4 rounded-full border-gray-300"
            />
            <span className="ml-3">
              <span className="block text-sm font-medium text-gray-900">
                {t('manager.rulesConfig.SECTION_USER_REQS')}
              </span>
              <span className="block text-sm text-gray-500">
                {t('manager.rulesConfig.SECTION_USER_REQS_description')}
              </span>
            </span>
          </label>

          <label className="group flex cursor-pointer border border-gray-200 p-4">
            <input
              value={RuleTypes.SLOT_LABEL_USER_REQS}
              checked={ruleType === RuleTypes.SLOT_LABEL_USER_REQS}
              onChange={e => setRuleType(e.target.value as RuleTypes)}
              name="rule-type"
              type="radio"
              className="mt-0.5 size-4 rounded-full border-gray-300"
            />
            <span className="ml-3">
              <span className="block text-sm font-medium text-gray-900">
                {t('manager.rulesConfig.SLOT_LABEL_USER_REQS')}
              </span>
              <span className="block text-sm text-gray-500">
                {t('manager.rulesConfig.SLOT_LABEL_USER_REQS_description')}
              </span>
            </span>
          </label>

          <label className="group flex cursor-pointer border border-gray-200 p-4 rounded-b-md">
            <input
              value={RuleTypes.CUSTOM_USER_REQS}
              checked={ruleType === RuleTypes.CUSTOM_USER_REQS}
              onChange={e => setRuleType(e.target.value as RuleTypes)}
              name="rule-type"
              type="radio"
              className="mt-0.5 size-4 rounded-full border-gray-300"
            />
            <span className="ml-3">
              <span className="block text-sm font-medium text-gray-900">
                {t('manager.rulesConfig.CUSTOM_USER_REQS')}
              </span>
              <span className="block text-sm text-gray-500">
                {t('manager.rulesConfig.CUSTOM_USER_REQS_description')}
              </span>
            </span>
          </label>
        </div>
      </fieldset>

      {/* Additional inputs based on selected type */}
      {ruleType === RuleTypes.SECTION_USER_REQS && (
        <div className="mt-4">
          <label className="block text-sm font-medium text-gray-700">
            {t('manager.rulesConfig.selectSection')}
          </label>
          <select
            value={selectedSection || undefined}
            onChange={e => setSelectedSection(Number(e.target.value) || null)}
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm"
          >
            <option value={undefined}>
              {t('manager.rulesConfig.selectSectionPlaceholder')}
            </option>
            {sectionsWithSlots.map(({ section }) => (
              <option key={section.id_section} value={section.id_section}>
                {section.name}
              </option>
            ))}
          </select>
        </div>
      )}

      {ruleType === RuleTypes.SLOT_LABEL_USER_REQS && (
        <div className="mt-4">
          <label className="block text-sm font-medium text-gray-700">
            {t('manager.rulesConfig.selectLabel')}
          </label>
          <select
            value={selectedLabel || undefined}
            onChange={e => setSelectedLabel(Number(e.target.value) || null)}
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm"
          >
            <option value={undefined}>
              {t('manager.rulesConfig.selectLabelPlaceholder')}
            </option>
            {shiftLabels.map(label => (
              <option key={label.id_slot_label} value={label.id_slot_label}>
                {label.name}
              </option>
            ))}
          </select>
        </div>
      )}

      {ruleType === RuleTypes.CUSTOM_USER_REQS && (
        <>
          <div className="mt-4">
            <label className="block text-sm font-medium text-gray-700">
              {t('manager.rulesConfig.selectRuleName')}
            </label>
            <input
              type="text"
              value={ruleName}
              onChange={e => setRuleName(e.target.value)}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm"
              placeholder={t('manager.rulesConfig.selectRuleNamePlaceholder')}
            />
          </div>
          <div className="mt-4">
            <label className="block text-sm font-medium text-gray-700">
              {t('manager.rulesConfig.selectRuleDescription')}
            </label>
            <input
              type="text"
              value={ruleDescription}
              onChange={e => setRuleDescription(e.target.value)}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm"
              placeholder={t(
                'manager.rulesConfig.selectRuleDescriptionPlaceholder',
              )}
            />
          </div>
        </>
      )}
    </div>
  );
}
