"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.useBulkDeleteEvents = exports.useBulkCreateEvents = exports.useEditEvent = exports.useDeleteEvent = exports.useCreateEvent = exports.useManagerDeleteEvent = exports.useManagerCreateEvent = exports.useBulkRejectEvents = exports.useBulkApproveEvents = exports.useRejectEvent = exports.useApproveEvent = void 0;
var react_query_1 = require("@tanstack/react-query");
var api_1 = require("../../api");
// MANAGER event endpoints
var useApproveEvent = function (queryClient, options) {
    return (0, react_query_1.useMutation)(__assign({ mutationFn: function (params) {
            var id_special_event = params.id_special_event;
            return (0, api_1.request)({
                url: "manager/events/".concat(id_special_event, "/approve"),
                method: "patch",
            });
        } }, options), queryClient);
};
exports.useApproveEvent = useApproveEvent;
var useRejectEvent = function (queryClient, options) {
    return (0, react_query_1.useMutation)(__assign({ mutationFn: function (params) {
            var id_special_event = params.id_special_event;
            return (0, api_1.request)({
                url: "manager/events/".concat(id_special_event, "/reject"),
                method: "patch",
            });
        } }, options), queryClient);
};
exports.useRejectEvent = useRejectEvent;
var useBulkApproveEvents = function (queryClient, options) {
    return (0, react_query_1.useMutation)(__assign({ mutationFn: function (params) {
            return (0, api_1.request)({
                url: "manager/events/approve",
                method: "patch",
                data: params,
            });
        } }, options), queryClient);
};
exports.useBulkApproveEvents = useBulkApproveEvents;
var useBulkRejectEvents = function (queryClient, options) {
    return (0, react_query_1.useMutation)(__assign({ mutationFn: function (params) {
            return (0, api_1.request)({
                url: "manager/events/reject",
                method: "patch",
                data: params,
            });
        } }, options), queryClient);
};
exports.useBulkRejectEvents = useBulkRejectEvents;
var useManagerCreateEvent = function (queryClient, options) {
    return (0, react_query_1.useMutation)(__assign({ mutationFn: function (params) {
            return (0, api_1.request)({
                url: "manager/events/create",
                method: "post",
                data: params,
            });
        } }, options), queryClient);
};
exports.useManagerCreateEvent = useManagerCreateEvent;
var useManagerDeleteEvent = function (queryClient, options) {
    return (0, react_query_1.useMutation)(__assign({ mutationFn: function (params) {
            var id_special_event = params.id_special_event;
            return (0, api_1.request)({
                url: "manager/events/".concat(id_special_event, "/delete"),
                method: "delete",
            });
        } }, options), queryClient);
};
exports.useManagerDeleteEvent = useManagerDeleteEvent;
// USER event endpoints
var useCreateEvent = function (queryClient, options) {
    return (0, react_query_1.useMutation)(__assign({ mutationFn: function (params) {
            return (0, api_1.request)({
                url: "user/events/create",
                method: "post",
                data: params,
            });
        } }, options), queryClient);
};
exports.useCreateEvent = useCreateEvent;
var useDeleteEvent = function (queryClient, options) {
    return (0, react_query_1.useMutation)(__assign({ mutationFn: function (params) {
            var id_special_event = params.id_special_event;
            return (0, api_1.request)({
                url: "user/events/".concat(id_special_event, "/delete"),
                method: "delete",
            });
        } }, options), queryClient);
};
exports.useDeleteEvent = useDeleteEvent;
var useEditEvent = function (queryClient, options) {
    return (0, react_query_1.useMutation)(__assign({ mutationFn: function (params) {
            var id_special_event = params.id_special_event, comment = params.comment;
            return (0, api_1.request)({
                url: "user/events/".concat(id_special_event, "/edit"),
                method: "patch",
                data: { comment: comment },
            });
        } }, options), queryClient);
};
exports.useEditEvent = useEditEvent;
var useBulkCreateEvents = function (queryClient, options) {
    return (0, react_query_1.useMutation)(__assign({ mutationFn: function (params) {
            return (0, api_1.request)({
                url: "user/events/bulk_create",
                method: "post",
                data: params,
            });
        } }, options), queryClient);
};
exports.useBulkCreateEvents = useBulkCreateEvents;
var useBulkDeleteEvents = function (queryClient, options) {
    return (0, react_query_1.useMutation)(__assign({ mutationFn: function (params) {
            return (0, api_1.request)({
                url: "user/events/bulk_delete",
                method: "delete",
                data: params,
            });
        } }, options), queryClient);
};
exports.useBulkDeleteEvents = useBulkDeleteEvents;
