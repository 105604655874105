import {
  AssignmentCheckErrors,
  AssignmentCheckErrorType,
} from './assignment_checks/types';

export enum ErrorsSeverity {
  WARNINGS = 'warnings',
  ERRORS = 'errors',
  NO_ERRORS = 'no_errors',
}

export const checksThatAreErrors = [
  AssignmentCheckErrorType.USER_REQ_SURPLUS,
  AssignmentCheckErrorType.VIRTUAL_SLOT_NEEDS_SURPLUS,
  AssignmentCheckErrorType.SHIFT_ASSIGNMENT_ON_JUSTIFIED_BLOCK,
  AssignmentCheckErrorType.SHIFT_ASSIGNMENT_ON_PERSONAL_BLOCK,
  AssignmentCheckErrorType.SHIFT_ASSIGNMENT_ON_EVENT,
  AssignmentCheckErrorType.SHIFT_ASSIGNMENT_REST_PERIOD_VIOLATION,
  AssignmentCheckErrorType.SHIFT_ASSIGNMENT_RESTS_24_HOURS,
  AssignmentCheckErrorType.SHIFT_ASSIGNMENT_ON_OVERLAPPING_ASSIGNMENT,
  AssignmentCheckErrorType.CROSS_GROUP_INCOMPATIBILITY,
  AssignmentCheckErrorType.SINGLE_GROUP_INCOMPATIBILITY_SURPLUS,
];

export const determineErrorsSeverity = (errors: AssignmentCheckErrors) => {
  // Flatten all error objects into one array of error types
  const allErrors = [
    ...Object.values(errors.user_req_rules).flatMap(rule =>
      Object.values(rule).flatMap(user => Object.keys(user)),
    ),
    ...Object.values(errors.single_group_incompatibilities).flatMap(
      incompatibility =>
        Object.values(incompatibility).flatMap(slot => Object.keys(slot)),
    ),
    ...Object.values(errors.cross_group_incompatibilities).flatMap(
      incompatibility =>
        Object.values(incompatibility).flatMap(slot => Object.keys(slot)),
    ),
    ...Object.values(errors.user_assignments).flatMap(user =>
      Object.values(user).flatMap(slot => Object.keys(slot)),
    ),
    ...Object.values(errors.virtual_slots).flatMap(slot => Object.keys(slot)),
  ];

  // Check if any error in the list matches the checksThatAreErrors array
  const hasErrors = allErrors.some(errorType =>
    checksThatAreErrors.includes(errorType as AssignmentCheckErrorType),
  );

  if (hasErrors) {
    return ErrorsSeverity.ERRORS;
  }

  // If there are warnings but no errors, return WARNINGS
  if (allErrors.length > 0) {
    return ErrorsSeverity.WARNINGS;
  }

  // If no errors or warnings exist, return NO_ERRORS
  return ErrorsSeverity.NO_ERRORS;
};
