import {
  dateToString,
  getFirstDayOfWeek,
  localeNormalizer,
} from '@youshift/shared/utils';
import { motion } from 'framer-motion';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import i18n from '../../utils/i18n';

function HeroSection() {
  const [isHovered, setIsHovered] = useState(false);
  const { t } = useTranslation();
  const locale = localeNormalizer(i18n.language);
  const firstDayOfWeek = getFirstDayOfWeek(locale);
  // Sample shift data
  const shifts: Record<number, { time: string; dept: string; doc: string }> = {
    3: {
      time: t('landing.hero.time1'),
      dept: t('landing.hero.dept1'),
      doc: t('landing.hero.12h'),
    },
    8: {
      time: t('landing.hero.time2'),
      dept: t('landing.hero.dept2'),
      doc: t('landing.hero.day'),
    },
    12: {
      time: t('landing.hero.time3'),
      dept: t('landing.hero.dept3'),
      doc: t('landing.hero.evening'),
    },
    15: {
      time: t('landing.hero.time4'),
      dept: t('landing.hero.dept1'),
      doc: t('landing.hero.night'),
    },
    19: {
      time: t('landing.hero.time5'),
      dept: t('landing.hero.dept4'),
      doc: t('landing.hero.day'),
    },
    24: {
      time: t('landing.hero.time6'),
      dept: t('landing.hero.dept5'),
      doc: t('landing.hero.support'),
    },
    28: {
      time: t('landing.hero.time7'),
      dept: t('landing.hero.dept1'),
      doc: t('landing.hero.12h'),
    },
  };

  const getDeptColor = (dept: string) => {
    const colors = {
      [t('landing.hero.dept1')]: 'bg-blue-600',
      [t('landing.hero.dept2')]: 'bg-indigo-600',
      [t('landing.hero.dept3')]: 'bg-teal-600',
      [t('landing.hero.dept4')]: 'bg-purple-600',
      [t('landing.hero.dept5')]: 'bg-cyan-600',
    };
    return colors[dept as keyof typeof colors] || 'bg-blue-600';
  };

  return (
    <div className="max-w-8xl mx-auto px-4 sm:px-6 py-12 sm:py-24">
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-8 lg:gap-12 items-center">
        {/* Left side - Content */}
        <div className="mx-auto text-center lg:text-left">
          <motion.h1
            className="text-4xl sm:text-5xl md:text-6xl lg:text-7xl font-bold mb-4 sm:mb-6 font-heading lg:mt-2 tracking-tight text-gray-900"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
          >
            {t('landing.hero.title')}{' '}
            <span className="text-teal-600">{t('landing.hero.title2')}</span>.
          </motion.h1>
          <motion.p
            className="text-lg sm:text-xl md:text-2xl mb-6 sm:mb-8"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5, delay: 0.2 }}
          >
            {t('landing.hero.description')}
          </motion.p>
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5, delay: 0.4 }}
          >
            <motion.button
              className="bg-blue-600 text-white px-8 py-4 rounded-full text-lg font-semibold hover:bg-blue-700 transition duration-300"
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
              onHoverStart={() => setIsHovered(true)}
              onHoverEnd={() => setIsHovered(false)}
            >
              <Link to="/signup">{t('landing.hero.startUsingYouShift')}</Link>
            </motion.button>
            <motion.div
              className="my-4 text-sm"
              initial={{ opacity: 0, y: 10 }}
              animate={{ opacity: isHovered ? 1 : 0, y: isHovered ? 0 : 10 }}
              transition={{ duration: 0.2 }}
            >
              {t('landing.hero.signUpForFree')}
            </motion.div>
          </motion.div>
        </div>

        {/* Right side - Enhanced Mockup */}
        <motion.div
          className="flex-1 max-w-full overflow-x-auto"
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5, delay: 0.6 }}
        >
          <motion.div
            className="bg-white rounded-2xl shadow-2xl p-4 sm:p-6"
            whileHover={{ scale: 1.02 }}
            transition={{ duration: 0.2 }}
          >
            {/* Calendar header */}
            <motion.div
              className="flex flex-col sm:flex-row justify-between items-start sm:items-center mb-6 gap-4 sm:gap-0"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 0.3, delay: 0.8 }}
            >
              <div className="flex items-center gap-4">
                <h2 className="font-semibold text-lg">
                  {dateToString(new Date(), 'mmyy', locale)}
                </h2>
                <div className="flex gap-2">
                  <span className="text-sm text-gray-500">
                    {t('landing.hero.weekView')}
                  </span>
                  <span className="text-sm font-medium text-blue-600">
                    {t('landing.hero.monthView')}
                  </span>
                </div>
              </div>
              <div className="flex gap-2">
                <motion.button
                  whileHover={{ scale: 1.1 }}
                  whileTap={{ scale: 0.95 }}
                  className="w-8 h-8 bg-gray-50 rounded-lg flex items-center justify-center hover:bg-gray-100"
                >
                  <span className="text-gray-600">←</span>
                </motion.button>
                <motion.button
                  whileHover={{ scale: 1.1 }}
                  whileTap={{ scale: 0.95 }}
                  className="w-8 h-8 bg-gray-50 rounded-lg flex items-center justify-center hover:bg-gray-100"
                >
                  <span className="text-gray-600">→</span>
                </motion.button>
              </div>
            </motion.div>

            {/* Calendar grid */}
            <div className="grid grid-cols-7 gap-1 sm:gap-2">
              {/* Week days */}
              {[
                ...(firstDayOfWeek === 7 ? [t('calendars.su')] : []),
                t('calendars.mo'),
                t('calendars.tu'),
                t('calendars.we'),
                t('calendars.th'),
                t('calendars.fr'),
                t('calendars.sa'),
                ...(firstDayOfWeek === 7 ? [] : [t('calendars.su')]),
              ].map(day => (
                <div
                  key={day}
                  className="text-center text-gray-500 text-sm py-2"
                >
                  {day}
                </div>
              ))}

              {/* Calendar days */}
              {Array.from({ length: 31 }, (_, i) => {
                const shift = shifts[i + 1];
                return (
                  <motion.div
                    key={i}
                    initial={{ opacity: 0, scale: 0.8 }}
                    animate={{ opacity: 1, scale: 1 }}
                    transition={{
                      duration: 0.2,
                      delay: 0.7 + i * 0.02,
                    }}
                    whileHover={
                      shift
                        ? {
                            scale: 1.1,
                            transition: { duration: 0.2 },
                          }
                        : {}
                    }
                    className={`
                      aspect-square rounded-lg p-2 text-sm
                      ${shift ? getDeptColor(shift.dept) : 'bg-gray-50'}
                      ${shift ? 'text-white' : 'text-gray-700'}
                    `}
                  >
                    <div className="flex justify-between">
                      <span>{i + 1}</span>
                      {shift && (
                        <span className="text-[10px] opacity-75">
                          {shift.dept}
                        </span>
                      )}
                    </div>
                    {shift && (
                      <div className="mt-1">
                        <div className="text-[10px] font-medium">
                          {shift.time}
                        </div>
                        <div className="text-[10px] opacity-75">
                          {shift.doc}
                        </div>
                      </div>
                    )}
                  </motion.div>
                );
              })}
            </div>

            {/* Legend */}
            <motion.div
              className="mt-4 flex flex-wrap gap-2 sm:gap-3 text-[10px] text-gray-500"
              initial={{ opacity: 0, y: 10 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.3, delay: 1 }}
            >
              <span className="flex items-center gap-1">
                <span className="w-2 h-2 rounded-full bg-blue-600" />
                {t('landing.hero.dept1')}
              </span>
              <span className="flex items-center gap-1">
                <span className="w-2 h-2 rounded-full bg-indigo-600" />
                {t('landing.hero.dept2')}
              </span>
              <span className="flex items-center gap-1">
                <span className="w-2 h-2 rounded-full bg-teal-600" />
                {t('landing.hero.dept3')}
              </span>
              <span className="flex items-center gap-1">
                <span className="w-2 h-2 rounded-full bg-purple-600" />
                {t('landing.hero.dept4')}
              </span>
              <span className="flex items-center gap-1">
                <span className="w-2 h-2 rounded-full bg-cyan-600" />
                {t('landing.hero.dept5')}
              </span>
            </motion.div>
          </motion.div>
        </motion.div>
      </div>
    </div>
  );
}

export default HeroSection;
