import { QueryClient, useQuery } from '@tanstack/react-query';
import { preLoadQuery } from '@youshift/shared/hooks';
import {
  UserStatsReturn,
  userStatsUserQuery,
} from '@youshift/shared/hooks/queries';
import { useTranslation } from 'react-i18next';
import { useLoaderData } from 'react-router-dom';

import NoData from '../../Manager/NoData';
import SingleUserStats from './SingleUserStats';

export const userStatsLoader =
  (queryClient: QueryClient) => async (): Promise<UserStatsReturn> => {
    const userStats = await preLoadQuery(queryClient, userStatsUserQuery());
    return userStats;
  };

export const useUserStatsLoader = (): UserStatsReturn => {
  const initialData = useLoaderData() as UserStatsReturn;
  const { data: userStats } = useQuery({
    ...userStatsUserQuery(),
    initialData,
    staleTime: 1000 * 60 * 5,
  });

  return userStats;
};

export default function UserStats() {
  const { chains, counters } = useUserStatsLoader();
  const { t } = useTranslation();

  if (!Object.keys(chains).length) {
    return (
      <NoData
        text={t('user.stats.noDataText')}
        description={t('user.stats.noDataDescription')}
      />
    );
  }

  return <SingleUserStats chainStats={chains} counters={counters} />;
}
