import {
  CursorArrowRippleIcon,
  PencilSquareIcon,
} from '@heroicons/react/24/outline';
import { QueryClient, useQuery, useQueryClient } from '@tanstack/react-query';
import { preLoadQuery } from '@youshift/shared/hooks';
import {
  useEditSlotGroupElts,
  useEditSlotGroupUserReqs,
} from '@youshift/shared/hooks/mutations';
import { userReqRuleQuery } from '@youshift/shared/hooks/queries';
import {
  RuleTypes,
  Section,
  UserReqRule,
  UserRequirementType,
} from '@youshift/shared/types';
import { getShadeMap } from '@youshift/shared/utils';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  LoaderFunctionArgs,
  useLoaderData,
  useNavigate,
  useParams,
} from 'react-router-dom';
import toast from 'react-hot-toast';

import { YSButton } from '../../../../components/Buttons';
import EditRuleModal from '../../../../components/ItrConfig/EditRuleModal';
import NavigateSectionsOrRules from '../../../../components/ItrConfig/NavigateSectionsOrRules';
import RuleTypeBadge from '../../../../components/ItrConfig/RuleTypeBadge';
import { VirgueriaVersion } from '../../../../components/ManualAssignment/types';
import { Virgueria } from '../../../../components/ManualAssignment/Virgueria';
import Wrapper from '../../../../components/Wrapper';
import { useItrContext } from '../../../../layouts/IterationRootLayout/IterationRootLayout';
import { requireApproved, requireManager } from '../../../../utils/checks';
import { ArrangedSectionSlot } from '../Sections/types';
import DefineReqs from './DefineReqs';
import { State } from './types';
import { useUserReqReducer } from './useUserReqReducer';
import { useSlotSelection } from './hooks/useSlotSelection';
import ShiftLabelLegend from '../../../../components/Calendars/ShiftLabelLegend';

export const userReqRuleLoader =
  (queryClient: QueryClient) =>
  async ({ params }: LoaderFunctionArgs): Promise<UserReqRule | null> => {
    const user = await requireManager(queryClient);
    await requireApproved(user);
    if (params.idItr === undefined || params.idRule === undefined) {
      return null;
    }
    const { idItr, idRule } = params;
    const userReqRule = await preLoadQuery(
      queryClient,
      userReqRuleQuery(idItr, idRule),
    );
    return userReqRule;
  };

function computeDifferences(initialState: State, currentState: State) {
  const addedUserRequirements = [];
  const editedUserRequirements = [];
  const deletedUsers = [];

  for (const roleId in currentState.roles) {
    if (Object.prototype.hasOwnProperty.call(currentState.roles, roleId)) {
      const currentRole = currentState.roles[roleId];
      const initialRole = initialState.roles[roleId] || { users: [] };

      // Create a map of initial users for quick lookup
      const initialUsersMap = Object.fromEntries(
        initialRole.users.map(user => [user.id_user, user]),
      );

      // Create a map of current users for reverse lookup
      // const currentUsersMap = Object.fromEntries(
      //   currentRole.users.map(user => [user.id_user, user]),
      // );

      // Iterate over all users in currentRole
      for (const user of currentRole.users) {
        const initialUser = initialUsersMap[user.id_user];

        if (!initialUser.included && user.included) {
          // User was not included before but is now included
          addedUserRequirements.push({
            id_user: user.id_user,
            ...(currentState.req_type === UserRequirementType.SLOTS && {
              min_slots: user.min_slots,
              max_slots: user.max_slots,
            }),
            ...(currentState.req_type === UserRequirementType.DURATION && {
              min_duration: user.min_duration,
              max_duration: user.max_duration,
            }),
          });
        } else if (initialUser.included && !user.included) {
          // User was included before but is now not included
          deletedUsers.push(user.id_user);
        } else if (user.included) {
          // User is included in both states
          // First check if the requirements for the CURRENT req type have changed
          const requirementsChanged =
            currentState.req_type === UserRequirementType.SLOTS
              ? initialUser.min_slots !== user.min_slots ||
                initialUser.max_slots !== user.max_slots
              : initialUser.min_duration !== user.min_duration ||
                initialUser.max_duration !== user.max_duration;

          if (
            requirementsChanged ||
            initialState.req_type !== currentState.req_type
          ) {
            editedUserRequirements.push({
              id_user: user.id_user,
              ...(currentState.req_type === UserRequirementType.SLOTS && {
                min_slots: user.min_slots,
                max_slots: user.max_slots,
              }),
              ...(currentState.req_type === UserRequirementType.DURATION && {
                min_duration: user.min_duration,
                max_duration: user.max_duration,
              }),
            });
          }
          // If both are not included, no action needed
        }
      }
    }
  }

  return {
    addedUserRequirements,
    editedUserRequirements,
    deletedUsers,
  };
}

export default function UserReqRuleConfig() {
  const { idItr, idRule } = useParams();

  const userReqRule = useLoaderData() as UserReqRule;
  const {
    data: { rule, user_reqs: userReqs, section_slots: sectionSlots },
  } = useQuery({
    ...userReqRuleQuery(idItr!, idRule!),
    initialData: userReqRule,
  });
  const {
    shiftLabels,
    iteration,
    sectionsWithSlots,
    allUserReqs,
    users,
    roles,
    itrUsers,
  } = useItrContext();
  const rules = Object.values(allUserReqs).map(userReq => userReq.rule);

  const [activeTab, setActiveTab] = useState<'slots' | 'reqs'>('slots');
  const { t } = useTranslation();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const shadeMap = useMemo(() => getShadeMap(shiftLabels || []), [shiftLabels]);
  const [slotsEdited, setSlotsEdited] = useState(false);
  const [reqsEdited, setReqsEdited] = useState(false);
  const [isEditRuleModalOpen, setIsEditRuleModalOpen] = useState(false);

  const initialReqType =
    Object.values(userReqs || {})[0]?.req_type || UserRequirementType.SLOTS;

  const saveable =
    (slotsEdited && activeTab === 'slots') ||
    (reqsEdited && activeTab === 'reqs');

  const slotsArentEditable =
    rule?.type === RuleTypes.SECTION_USER_REQS ||
    rule?.type === RuleTypes.SLOT_LABEL_USER_REQS;

  const {
    selectedSlots,
    toggleSlot,
    onDayClick,
    onSectionClick,
    filteredShiftLabels,
    toggleLabel,
  } = useSlotSelection({
    initialSlots: sectionSlots ? new Set(sectionSlots) : new Set<number>(),
    sectionsWithSlots,
    onSlotsChange: () => setSlotsEdited(true),
    disabled: slotsArentEditable,
    shiftLabels,
  });

  const { initialState, state, dispatch } = useUserReqReducer({
    itrUsers: Object.keys(itrUsers).map(Number),
    roles,
    users,
    userReqs, // existing user requirements
    initialReqType,
    onAnyEdit: () => setReqsEdited(true), // <--- we want to track edits
  });

  const editSlotGroupEltsMutation = useEditSlotGroupElts(queryClient, {
    onSuccess: () => {
      setSlotsEdited(false);
      toast.success(t('manager.rulesConfig.slotGroupSaved'));
    },
  });
  const editSlotGroupUserReqs = useEditSlotGroupUserReqs(queryClient, {
    onSuccess: () => {
      setReqsEdited(false);
      queryClient.invalidateQueries({
        queryKey: ['userReqRule', idRule],
      });
      queryClient.invalidateQueries({
        queryKey: ['extendedUserReqRules', idItr],
      });
      toast.success(t('manager.rulesConfig.userReqSaved'));
    },
  });

  if (!rule) {
    return null;
  }

  const saveChanges = () => {
    if (activeTab === 'slots') {
      editSlotGroupEltsMutation.mutate({
        id_itr: iteration.id_itr,
        id_rule: rule.id_rule,
        id_section_slots: Array.from(selectedSlots),
      });
    } else if (activeTab === 'reqs') {
      const { addedUserRequirements, editedUserRequirements, deletedUsers } =
        computeDifferences(initialState, state);
      editSlotGroupUserReqs.mutate({
        id_itr: iteration.id_itr,
        id_rule: rule?.id_rule,
        added_user_requirements: addedUserRequirements,
        edited_user_requirements: editedUserRequirements,
        deleted_users: deletedUsers,
        req_type: state.req_type,
      });
    }
  };

  return (
    <Wrapper>
      <EditRuleModal
        isOpen={isEditRuleModalOpen}
        onClose={() => setIsEditRuleModalOpen(false)}
        details={rule}
      />
      <div className="flex flex-row justify-between items-center">
        <div className="flex items-center gap-2">
          {/* <div className="rounded-full w-6 h-6" /> */}
          <RuleTypeBadge
            type={rule.type}
            string={t(`manager.rulesConfig.${rule.type}`)}
          />
          <h1 className="text-2xl font-bold">{rule?.name}</h1>
          <button onClick={() => setIsEditRuleModalOpen(true)}>
            <PencilSquareIcon className="w-6 h-6 text-gray-600" />
          </button>
        </div>
        {rules ? (
          <NavigateSectionsOrRules
            currentId={rule.id_rule}
            items={rules.map(rule => ({ id: rule.id_rule, name: rule.name }))}
          />
        ) : null}
      </div>
      <div className="border-b border-gray-200 mb-3">
        <nav className="-mb-px flex space-x-8">
          <button
            onClick={() => setActiveTab('slots')}
            className={`${
              activeTab === 'slots'
                ? 'border-blue-500 text-blue-600'
                : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300'
            } whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm`}
          >
            {t('manager.sectionsConfig.needs')}
          </button>
          <button
            onClick={() => setActiveTab('reqs')}
            className={`${
              activeTab === 'reqs'
                ? 'border-blue-500 text-blue-600'
                : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300'
            } whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm`}
          >
            {t('manager.sectionsConfig.reqs')}
          </button>
        </nav>
      </div>

      {/* Tab Content */}
      <>
        {activeTab === 'slots' && (
          <>
            <div className="flex flex-row justify-between items-center">
              <ShiftLabelLegend
                labels={filteredShiftLabels}
                onLabelClick={!slotsArentEditable ? toggleLabel : undefined}
              />
              {slotsArentEditable ? null : (
                <YSButton
                  classNames="self-end"
                  onClick={saveChanges}
                  disabled={!saveable}
                  loading={editSlotGroupEltsMutation.isPending}
                >
                  {saveable
                    ? t('generic.saveChanges')
                    : t('generic.savedChanges')}
                </YSButton>
              )}
            </div>
            <div className="flex flex-row gap-1 justify-center items-center">
              {!slotsArentEditable ? (
                <CursorArrowRippleIcon className="h-5 w-5 text-teal-600" />
              ) : null}
              <p className="edit-disabled-text">
                {slotsArentEditable
                  ? `${t('manager.rulesConfig.editDisabled')} ${rule.name}`
                  : t('manager.rulesConfig.editEnabled')}
              </p>
            </div>
            <Virgueria
              start={iteration.start_day}
              end={iteration.end_day}
              sectionsWithSlots={sectionsWithSlots.filter(({ section }) =>
                rule.type === RuleTypes.SECTION_USER_REQS
                  ? section.id_section === rule.id_section
                  : true,
              )}
              selectedSlots={selectedSlots}
              shadeMap={shadeMap}
              onSlotClick={toggleSlot}
              version={VirgueriaVersion.RulesEditing}
              labels={shiftLabels}
              onDayClick={onDayClick}
              onSectionClick={onSectionClick}
            />
          </>
        )}
        {activeTab === 'reqs' && (
          <div className="flex flex-col">
            <YSButton
              classNames="self-end"
              onClick={saveChanges}
              disabled={!saveable}
              loading={editSlotGroupUserReqs.isPending}
            >
              {saveable ? t('generic.saveChanges') : t('generic.savedChanges')}
            </YSButton>
            <DefineReqs state={state} dispatch={dispatch} />
          </div>
        )}
      </>
    </Wrapper>
  );
}
