import React, { useMemo } from 'react';
import { MinusIcon, PlusIcon } from '@heroicons/react/24/outline';
import { useTranslation } from 'react-i18next';
import { SectionSlot, VirtualSlot } from '@youshift/shared/types';
import {
  dateToString,
  localeNormalizer,
  parseSectionSlotDateTime,
} from '@youshift/shared/utils';

import {
  AssignmentCheckErrorType,
  UserRequirementRuleErrors,
  VirtualSlotErrors,
  VirtualSlotNeedsDeficitErrorContext,
  VirtualSlotNeedsSurplusErrorContext,
} from '../../../utils/iteration_checks/assignment_checks/types';
import i18n from '../../../utils/i18n';

type Props = {
  virtualSlotErrors: VirtualSlotErrors;
  virtualSlots: Record<number, VirtualSlot>;
};

function VirtualSlotsTable({ virtualSlotErrors, virtualSlots }: Props) {
  const { deficits, surpluses } = useMemo(() => {
    const deficits: Record<number, VirtualSlotNeedsDeficitErrorContext> = {};
    const surpluses: Record<number, VirtualSlotNeedsSurplusErrorContext> = {};

    for (const [idStr, partialErrors] of Object.entries(virtualSlotErrors)) {
      const id = Number(idStr);

      if (partialErrors[AssignmentCheckErrorType.VIRTUAL_SLOT_NEEDS_DEFICIT]) {
        deficits[id] =
          partialErrors[AssignmentCheckErrorType.VIRTUAL_SLOT_NEEDS_DEFICIT]!;
      }
      if (partialErrors[AssignmentCheckErrorType.VIRTUAL_SLOT_NEEDS_SURPLUS]) {
        surpluses[id] =
          partialErrors[AssignmentCheckErrorType.VIRTUAL_SLOT_NEEDS_SURPLUS]!;
      }
    }

    return { deficits, surpluses };
  }, [virtualSlotErrors]);
  const locale = localeNormalizer(i18n.language);
  const { t } = useTranslation();

  // If no deficits or surpluses, render nothing (or you could return null)
  if (
    Object.keys(deficits).length === 0 &&
    Object.keys(surpluses).length === 0
  ) {
    return null;
  }

  return (
    <div className="">
      {/* Deficit Table */}
      {Object.keys(deficits).length > 0 && (
        <div className="my-2">
          <div className="flex flex-row items-center">
            <MinusIcon className="text-white font-bold w-5 h-5 rounded-full p-0.5 bg-rose-500" />
            <h3 className="text-base font-semibold p-2">
              {t('manager.assignmentErrors.virtual_slot_deficit_title')}
            </h3>
          </div>
          <div className=" bg-white shadow-lg rounded-lg overflow-hidden">
            <div className="overflow-x-auto">
              <table className="w-full">
                <thead>
                  <tr className="bg-gray-50 border-b border-gray-200 text-xs font-medium text-gray-500 uppercase tracking-wider">
                    <th className="px-3 py-1.5 text-left">
                      {t('manager.assignmentErrors.period')}
                    </th>
                    <th className="px-3 py-1.5 text-center">
                      {t('manager.assignmentErrors.has')}
                    </th>
                    <th className="px-3 py-1.5 text-center">
                      {t('generic.min')}
                    </th>
                    <th className="px-3 py-1.5 text-center">
                      {t('manager.assignmentErrors.missing')}
                    </th>
                  </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200">
                  {Object.entries(deficits).map(
                    ([
                      virtualSlotId,
                      { assigned_shift_assignments, min_need },
                    ]) => {
                      const missing =
                        min_need - assigned_shift_assignments.length;
                      const virtualSlot = virtualSlots[Number(virtualSlotId)];
                      return (
                        <tr key={virtualSlotId} className="hover:bg-gray-50">
                          <td className="px-2 py-1 whitespace-nowrap text-sm font-medium text-gray-900">
                            {`${parseSectionSlotDateTime(virtualSlot.start, virtualSlot.end, locale)}`}
                          </td>
                          <td className="px-2 py-1 whitespace-nowrap text-sm text-gray-500 text-center">
                            {assigned_shift_assignments.length}
                          </td>
                          <td className="px-2 py-1 whitespace-nowrap text-sm text-gray-500 text-center">
                            {min_need}
                          </td>
                          <td className="px-2 py-1 whitespace-nowrap text-sm text-red-500 text-center font-semibold">
                            {missing}
                          </td>
                        </tr>
                      );
                    },
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      )}

      {/* Surplus Table */}
      {Object.keys(surpluses).length > 0 && (
        <div className="">
          <div className="flex flex-row items-center">
            <PlusIcon className="text-white font-bold w-5 h-5 rounded-full p-0.5 bg-yellow-500" />
            <h3 className="text-base font-semibold p-2">
              {t('manager.assignmentErrors.virtual_slot_surplus_title')}
            </h3>
          </div>
          <div className="mb-6 bg-white shadow-lg rounded-lg overflow-hidden">
            <div className="overflow-x-auto">
              <table className="w-full">
                <thead>
                  <tr className="bg-gray-50 border-b border-gray-200 text-xs font-medium text-gray-500 uppercase tracking-wider">
                    <th className="px-3 py-1.5 text-left">
                      {t('manager.assignmentErrors.period')}
                    </th>
                    <th className="px-3 py-1.5 text-center">
                      {t('manager.assignmentErrors.has')}
                    </th>
                    <th className="px-3 py-1.5 text-center">
                      {t('generic.max')}
                    </th>
                    <th className="px-3 py-1.5 text-center">
                      {t('manager.assignmentErrors.excess')}
                    </th>
                  </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200">
                  {Object.entries(surpluses).map(
                    ([
                      virtualSlotId,
                      { assigned_shift_assignments, max_need },
                    ]) => {
                      const excess =
                        assigned_shift_assignments.length - max_need;
                      const virtualSlot = virtualSlots[Number(virtualSlotId)];
                      return (
                        <tr key={virtualSlotId} className="hover:bg-gray-50">
                          <td className="px-2 py-1whitespace-nowrap text-sm font-medium text-gray-900">
                            {`${parseSectionSlotDateTime(virtualSlot.start, virtualSlot.end, locale)}`}{' '}
                          </td>
                          <td className="px-2 py-1whitespace-nowrap text-sm text-gray-500 text-center">
                            {assigned_shift_assignments.length}
                          </td>
                          <td className="px-2 py-1whitespace-nowrap text-sm text-gray-500 text-center">
                            {max_need}
                          </td>
                          <td className="px-2 py-1whitespace-nowrap text-sm text-red-500 text-center font-semibold">
                            {excess}
                          </td>
                        </tr>
                      );
                    },
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default VirtualSlotsTable;
