import { UsersIcon } from '@heroicons/react/24/outline';
import type { Section, SectionSlot } from '@youshift/shared/types';
import { useTranslation } from 'react-i18next';

import { CrossGroupIncompatibilityErrors } from '../../../utils/iteration_checks/assignment_checks/types';
import SectionSlotChip from '../../Calendars/SectionSlotChip';

type Props = {
  slots: CrossGroupIncompatibilityErrors[number];
  userFullNameMap: Record<number, string>;
  sections: Record<number, Section>;
  sectionSlots: Record<number, SectionSlot>;
};

function CrossGroupTable({
  slots,
  userFullNameMap,
  sections,
  sectionSlots,
}: Props) {
  const { t } = useTranslation();

  // If no errors
  if (Object.values(slots).length === 0) {
    return null;
  }

  return (
    <div className="">
      <div className="my-2">
        <div className="flex flex-row items-center">
          <UsersIcon className="text-white font-bold w-5 h-5 rounded-full p-0.5 bg-orange-500" />
          <h3 className="text-base font-semibold p-2">
            {t('manager.assignmentErrors.cross_group_incompatibility_title')}
          </h3>
        </div>
        <div className="bg-white shadow-lg rounded-lg overflow-hidden">
          <div className="overflow-x-auto">
            <table className="w-full">
              <thead>
                <tr className="bg-gray-50 border-b border-gray-200 text-xs font-medium text-gray-500 uppercase tracking-wider">
                  <th className="px-1 py-1 text-left">{t('generic.slot')}</th>
                  <th className="px-1 py-1 text-center">
                    {t('manager.incomp.groupA')}
                  </th>
                  <th className="px-1 py-1 text-center">
                    {t('manager.incomp.groupB')}
                  </th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                {Object.entries(slots).map(
                  ([
                    slotId,
                    { assigned_users_group_1, assigned_users_group_2 },
                  ]) => {
                    const sectionSlot = sectionSlots[Number(slotId)];
                    const section = sections[sectionSlot.id_section];
                    return (
                      <tr key={slotId} className="hover:bg-gray-50">
                        <td className="px-1 py-1 text-sm font-medium text-gray-900">
                          <SectionSlotChip
                            small
                            sectionName={section?.acronym}
                            start={sectionSlot?.start}
                            end={sectionSlot?.end}
                          />
                        </td>
                        <td className="px-1 py-1 text-sm text-gray-500 text-center">
                          <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-teal-100 text-teal-800">
                            {assigned_users_group_1
                              .map(userId => userFullNameMap[userId])
                              .join(', ')}
                          </span>
                        </td>
                        <td className="px-1 py-1 text-sm text-red-500 text-center font-semibold">
                          <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-violet-100 text-violet-800">
                            {assigned_users_group_2
                              .map(userId => userFullNameMap[userId])
                              .join(', ')}
                          </span>
                        </td>
                      </tr>
                    );
                  },
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CrossGroupTable;
