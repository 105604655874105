"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.useDeleteItrAlgResultsMutation = exports.useDeleteItrMutation = void 0;
var react_query_1 = require("@tanstack/react-query");
var api_1 = require("../../api");
var useDeleteItrMutation = function (queryClient, options) {
    return (0, react_query_1.useMutation)(__assign({ mutationFn: function (_a) {
            var id_itr = _a.id_itr;
            return (0, api_1.request)({
                url: "/manager/itrs/".concat(id_itr, "/delete"),
                method: "delete",
            });
        } }, options), queryClient);
};
exports.useDeleteItrMutation = useDeleteItrMutation;
var useDeleteItrAlgResultsMutation = function (queryClient, options) {
    return (0, react_query_1.useMutation)(__assign({ mutationFn: function (_a) {
            var id_itr = _a.id_itr;
            return (0, api_1.request)({
                url: "/manager/itrs/".concat(id_itr, "/delete_alg_results"),
                method: "delete",
            });
        } }, options), queryClient);
};
exports.useDeleteItrAlgResultsMutation = useDeleteItrAlgResultsMutation;
