import React, { useState, useCallback, ChangeEvent } from 'react';
import {
  ChevronUpIcon,
  ChevronDownIcon,
  ArrowRightIcon,
} from '@heroicons/react/24/solid';
import { MoonIcon, SunIcon } from '@heroicons/react/24/outline';

interface TimeSelectProps {
  value: string;
  onChange: (time: string) => void;
  use12HourFormat: boolean;
}

type Period = 'AM' | 'PM' | undefined;

/** Generate array of valid hour strings (padded). */
const hours = Array.from({ length: 24 }, (_, i) =>
  i.toString().padStart(2, '0'),
);

/** Generate array of valid minute strings (padded). */
const minutes = Array.from({ length: 12 }, (_, i) =>
  (i * 5).toString().padStart(2, '0'),
);

/** Returns 'AM' if hours are 1, 2, ..., 12. PM otherwise */
const getPeriod = (hoursStr: string): Period => {
  const hours = Number(hoursStr);
  return hours < 12 ? 'AM' : 'PM';
};

/** Break a string like "13:05" or "01:05" into hours, minutes, period. */
const parseTime = (timeString: string, use12HourFormat: boolean) => {
  const [hours = '00', minutes = '00'] = timeString.split(':');
  const period = use12HourFormat ? getPeriod(hours) : undefined;
  return { hours, minutes, period: period as Period };
};

/** Create a time object to display from hours, minutes, and optional AM/PM. */
const displayTime = (
  hours: string,
  minutes: string,
  use12HourFormat: boolean,
) => {
  if (use12HourFormat) {
    const numHours = Number(hours) % 24; // Ensure hours are within 0-23 range
    const formattedHours =
      numHours === 0 || numHours === 12
        ? 12 // Return 12 for 0 (midnight) or 12 (noon)
        : numHours % 12; // Convert to 12-hour format for other times
    return { hours: `${formattedHours}`.padStart(2, '0'), minutes };
  }
  return { hours, minutes };
};

function TimeSelect({
  value,
  onChange,
  use12HourFormat,
}: TimeSelectProps): JSX.Element {
  const {
    hours: selectedHours,
    minutes: selectedMinutes,
    period,
  } = parseTime(value, use12HourFormat);

  // const [currentPeriod, setCurrentPeriod] = useState<Period>(period);

  // ---------------------------------
  // Increment / decrement logic
  // ---------------------------------
  const handleHourChange = useCallback(
    (direction: 'up' | 'down', amount: number = 1) => {
      const currentIndex = hours.indexOf(selectedHours);
      const newIndex =
        direction === 'up'
          ? (currentIndex + amount) % hours.length
          : (currentIndex - amount + hours.length) % hours.length;
      onChange(`${hours[newIndex]}:${selectedMinutes}`);
    },
    [selectedHours, onChange, selectedMinutes],
  );

  const handleMinuteChange = useCallback(
    (direction: 'up' | 'down') => {
      const currentIndex = minutes.indexOf(selectedMinutes);
      const newIndex =
        direction === 'up'
          ? (currentIndex + 1) % minutes.length
          : (currentIndex - 1 + minutes.length) % minutes.length;

      onChange(`${selectedHours}:${minutes[newIndex]}`);
    },
    [selectedHours, selectedMinutes, onChange],
  );

  const handlePeriodToggle = useCallback(() => {
    handleHourChange('up', 12);
  }, [handleHourChange]);

  function stripLeadingZeros(value: string): string {
    // Remove all leading zeros
    const stripped = value.replace(/^0+/, '');
    // If empty after stripping, default to "0"
    return stripped === '' ? '0' : stripped;
  }

  const handleHourTyping = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      // Remove any non-digits
      let inputVal = e.target.value.replace(/\D+/g, '');
      // Remove leading zeros
      inputVal = stripLeadingZeros(inputVal);

      let hourNum = parseInt(inputVal, 10);
      if (Number.isNaN(hourNum)) hourNum = 0;

      // Clamp valid range
      if (use12HourFormat) {
        // 12-hour format => 1..12
        if (hourNum < 1) hourNum = 1;
        if (hourNum > 12) hourNum = 12;
      } else {
        // 24-hour format => 0..23
        if (hourNum < 0) hourNum = 0;
        if (hourNum > 23) hourNum = 23;
      }

      // Reformat final string
      const finalHourStr = hourNum.toString().padStart(2, '0'); // always 2 digits
      onChange(`${finalHourStr}:${selectedMinutes}`);
    },
    [selectedMinutes, onChange, use12HourFormat],
  );

  const handleMinuteTyping = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      let inputVal = e.target.value.replace(/\D+/g, '');
      // Remove leading zeros
      inputVal = stripLeadingZeros(inputVal);

      let minuteNum = parseInt(inputVal, 10);
      if (Number.isNaN(minuteNum)) minuteNum = 0;

      // Clamp valid range => 0..59
      if (minuteNum < 0) minuteNum = 0;
      if (minuteNum > 59) minuteNum = 59;

      const finalMinuteStr = minuteNum.toString().padStart(2, '0');
      onChange(`${selectedHours}:${finalMinuteStr}`);
    },
    [selectedHours, onChange],
  );

  // ---------------------------------
  // Determine what to display
  // ---------------------------------
  // If using 12-hour format, we need to convert the "selectedHours"
  // from 24-hour style to 12-hour style for display.
  const { hours: displayHours, minutes: displayMinutes } = displayTime(
    selectedHours,
    selectedMinutes,
    use12HourFormat,
  );

  return (
    <div className="flex flex-col items-center">
      <div className="flex gap-2">
        {/* Hours */}
        <div className="relative flex flex-row items-center">
          <div className="flex flex-col">
            <button
              className="p-1 hover:bg-gray-100 rounded"
              onClick={() => handleHourChange('up')}
              aria-label="Increment hours"
            >
              <ChevronUpIcon className="w-4 h-4" />
            </button>
            <button
              className="p-1 hover:bg-gray-100 rounded"
              onClick={() => handleHourChange('down')}
              aria-label="Decrement hours"
            >
              <ChevronDownIcon className="w-4 h-4" />
            </button>
          </div>
          <input
            type="text"
            className="w-12 text-center py-1 border rounded outline-none"
            value={displayHours}
            onChange={handleHourTyping}
            disabled={use12HourFormat}
          />
        </div>

        <div className="flex items-center">:</div>

        {/* Minutes */}
        <div className="relative flex flex-row items-center">
          <input
            type="text"
            className="w-12 text-center py-1 border rounded outline-none"
            value={displayMinutes}
            onChange={handleMinuteTyping}
            disabled={use12HourFormat}
          />
          <div className="flex flex-col">
            <button
              className="p-1 hover:bg-gray-100 rounded"
              onClick={() => handleMinuteChange('up')}
              aria-label="Increment minutes"
            >
              <ChevronUpIcon className="w-4 h-4" />
            </button>
            <button
              className="p-1 hover:bg-gray-100 rounded"
              onClick={() => handleMinuteChange('down')}
              aria-label="Decrement minutes"
            >
              <ChevronDownIcon className="w-4 h-4" />
            </button>
          </div>
        </div>

        {/* AM/PM toggle for 12-hour format */}
        {use12HourFormat && (
          <div className="relative flex flex-col items-center">
            <button
              className={`p-1 hover:bg-gray-100 rounded ${period === 'AM' && 'bg-gray-100'}`}
              onClick={() => handlePeriodToggle()}
              aria-label="Toggle AM/PM"
            >
              {/* <SunIcon className="w-4 h-4" /> */}
              AM
            </button>
            <button
              className={`p-1 hover:bg-gray-100 rounded ${period === 'PM' && 'bg-gray-100'}`}
              onClick={() => handlePeriodToggle()}
              aria-label="Toggle AM/PM"
            >
              PM
            </button>
          </div>
        )}
      </div>
    </div>
  );
}

export default TimeSelect;
