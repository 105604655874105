import { Dialog, Transition } from '@headlessui/react';
import { PlusIcon } from '@heroicons/react/24/outline';
import { useMutation, useQueryClient, useQuery } from '@tanstack/react-query';
import { Fragment, useRef, useState } from 'react';
import toast from 'react-hot-toast';
import { useAdminCreateGroupMutation } from '@youshift/shared/hooks/mutations';
import { organizationsQuery } from '@youshift/shared/hooks/queries';
import {
  ClientSourcingOrigin,
  MedicalSpecialty,
  SupportedLocale,
} from '@youshift/shared/types';

import Alert from '../../components/FormFeedback/Alert';

interface NewGroupProps {
  open: boolean;
  setOpen: (open: boolean) => void;
}

export default function NewGroup({
  open,
  setOpen,
}: NewGroupProps): JSX.Element {
  const cancelButtonRef = useRef<HTMLButtonElement>(null);
  const [firstname, setFirstname] = useState<string>('');
  const [lastname, setLastname] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [phone, setPhone] = useState<string>('');
  const [sourcingOrigin, setSourcingOrigin] = useState<string>('');
  const [specialty, setSpecialty] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [organization, setOrganization] = useState<number>(0);
  const [groupName, setGroupName] = useState<string>('');
  const [language, setLanguage] = useState<SupportedLocale>('es');
  const [error, setError] = useState<string | null>(null);

  const queryClient = useQueryClient();

  const { data: organizations } = useQuery(organizationsQuery());

  const createGroup = useAdminCreateGroupMutation(queryClient, {
    onSuccess: () => {
      toast.success('Group created successfully');
      setOpen(false);
      queryClient.invalidateQueries({ queryKey: ['admin'] });
    },
    onError: (err: Error) => {
      toast.error(`Error creating group: ${err.message}`);
      setError(err.message);
    },
  });

  const handleSubmit = () => {
    const input = {
      id_org: organization,
      group: {
        firstname,
        lastname,
        email,
        phone,
        sourcing_origin: sourcingOrigin,
        specialty,
        password,
        group_name: groupName,
        has_agreed_to_privacy_policy: true,
        language,
      },
    };
    createGroup.mutate(input);
  };

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-10"
        initialFocus={cancelButtonRef}
        onClose={setOpen}
      >
        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                <div>
                  <div className="mx-auto my-4 flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-blue-100">
                    <PlusIcon
                      className="h-6 w-6 text-blue-600"
                      aria-hidden="true"
                    />
                  </div>
                  <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                    <Dialog.Title
                      as="h3"
                      className="text-lg leading-6 font-medium mb-4 text-gray-900"
                    >
                      Create New Group
                    </Dialog.Title>
                    <div className="mt-2">
                      <div className="space-y-4">
                        <input
                          type="text"
                          name="firstname"
                          className="block w-full shadow-sm sm:text-sm focus:ring-blue-500 focus:border-blue-500 border-gray-300 rounded-md"
                          placeholder="First Name"
                          value={firstname}
                          onChange={e => setFirstname(e.target.value)}
                        />
                        <input
                          type="text"
                          name="lastname"
                          className="block w-full shadow-sm sm:text-sm focus:ring-blue-500 focus:border-blue-500 border-gray-300 rounded-md"
                          placeholder="Last Name"
                          value={lastname}
                          onChange={e => setLastname(e.target.value)}
                        />
                        <input
                          type="email"
                          name="email"
                          className="block w-full shadow-sm sm:text-sm focus:ring-blue-500 focus:border-blue-500 border-gray-300 rounded-md"
                          placeholder="Email"
                          value={email}
                          onChange={e => setEmail(e.target.value)}
                        />
                        <input
                          type="tel"
                          name="phone"
                          className="block w-full shadow-sm sm:text-sm focus:ring-blue-500 focus:border-blue-500 border-gray-300 rounded-md"
                          placeholder="Phone (optional)"
                          value={phone}
                          onChange={e => setPhone(e.target.value)}
                        />
                        <select
                          name="sourcingOrigin"
                          className="block w-full shadow-sm sm:text-sm focus:ring-blue-500 focus:border-blue-500 border-gray-300 rounded-md"
                          value={sourcingOrigin}
                          onChange={e => setSourcingOrigin(e.target.value)}
                        >
                          <option value="">Select Sourcing Origin</option>
                          {Object.values(ClientSourcingOrigin).map(origin => (
                            <option key={origin} value={origin}>
                              {origin}
                            </option>
                          ))}
                        </select>
                        <select
                          name="specialty"
                          className="block w-full shadow-sm sm:text-sm focus:ring-blue-500 focus:border-blue-500 border-gray-300 rounded-md"
                          value={specialty}
                          onChange={e => setSpecialty(e.target.value)}
                        >
                          <option value="">Select Specialty (Optional)</option>
                          {Object.values(MedicalSpecialty).map(spec => (
                            <option key={spec} value={spec}>
                              {spec.replace(/_/g, ' ')}
                            </option>
                          ))}
                        </select>
                        <input
                          type="password"
                          name="password"
                          className="block w-full shadow-sm sm:text-sm focus:ring-blue-500 focus:border-blue-500 border-gray-300 rounded-md"
                          placeholder="Password"
                          value={password}
                          onChange={e => setPassword(e.target.value)}
                        />
                        <select
                          name="organization"
                          className="block w-full shadow-sm sm:text-sm focus:ring-blue-500 focus:border-blue-500 border-gray-300 rounded-md"
                          value={organization}
                          onChange={e =>
                            setOrganization(Number(e.target.value))
                          }
                        >
                          <option value={0}>Select Organization</option>
                          {organizations?.map(org => (
                            <option key={org.id_org} value={org.id_org}>
                              {org.name}
                            </option>
                          ))}
                        </select>
                        <input
                          type="text"
                          name="groupName"
                          className="block w-full shadow-sm sm:text-sm focus:ring-blue-500 focus:border-blue-500 border-gray-300 rounded-md"
                          placeholder="Group Name"
                          value={groupName}
                          onChange={e => setGroupName(e.target.value)}
                        />
                        <select
                          name="language"
                          className="block w-full shadow-sm sm:text-sm focus:ring-blue-500 focus:border-blue-500 border-gray-300 rounded-md"
                          value={language}
                          onChange={e =>
                            setLanguage(e.target.value as SupportedLocale)
                          }
                        >
                          <option value="es">Spanish</option>
                          <option value="en">English</option>
                          <option value="ca">Catalan</option>
                          <option value="pt">Portuguese</option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
                {error && <Alert success={false} text={error} />}
                <div className="mt-5 sm:mt-4 sm:flex sm:justify-end">
                  <button
                    type="button"
                    className="inline-flex mt-4 w-full justify-center rounded-md border border-transparent bg-blue-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:ml-3 sm:w-auto sm:text-sm"
                    onClick={handleSubmit}
                  >
                    Create Group
                  </button>
                  <button
                    type="button"
                    className="inline-flex mt-4 w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:ml-3 sm:w-auto sm:text-sm"
                    onClick={() => setOpen(false)}
                    ref={cancelButtonRef}
                  >
                    Cancel
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
