import { useTranslation } from 'react-i18next';

/* eslint-disable max-len */
const testimonials = [0, 1, 2, 3, 4, 5];

export default function Testimonials() {
  const { t } = useTranslation();

  return (
    <div className="bg-white py-8 sm:py-8">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto max-w-4xl lg:text-center">
          <p className="mt-8 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
            {t('landing.testimonials.title')}
            {/* {' '}
            <span className="text-teal-600">{t('landing.stats.title2')}</span> */}
          </p>
          <p className="mt-6 text-xl leading-8 text-gray-600">
            {t('landing.testimonials.subtitle')}
          </p>
        </div>
        <div className="mx-auto my-12 flow-root max-w-2xl sm:mt-12 lg:mx-0 lg:max-w-none">
          <div className="-mt-8 sm:-mx-4 sm:columns-2 sm:text-[0] lg:columns-3">
            {testimonials.map(id => (
              <div key={id} className="pt-8 sm:inline-block sm:w-full sm:px-4">
                <figure className="rounded-2xl bg-gray-50 p-8 text-sm leading-6">
                  <blockquote className="text-gray-900">
                    <p>{`“${t(`landing.testimonials.items.${id}.body`)}”`}</p>
                  </blockquote>
                  <figcaption className="mt-6 flex items-center gap-x-4">
                    <div>
                      <div className="font-semibold text-gray-900">
                        {t(`landing.testimonials.items.${id}.author.name`)}
                      </div>
                      <div className="text-gray-600">
                        {t(`landing.testimonials.items.${id}.author.handle`)}
                      </div>
                    </div>
                  </figcaption>
                </figure>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}
