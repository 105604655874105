import { PlusIcon, TrashIcon } from '@heroicons/react/24/outline';
import { useQueryClient } from '@tanstack/react-query';
import {
  CustomCounterIncrementSchema,
  useCreateCounterMutation,
} from '@youshift/shared/hooks/mutations';
import { CounterUnit } from '@youshift/shared/types';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { NumericFormat } from 'react-number-format';

import { useManagerContext } from '../../layouts/ManagerLayout';

interface CounterIncrementProps {
  counterIncrementSchemas: CustomCounterIncrementSchema[];
  setCounterIncrementSchemas: (schemas: CustomCounterIncrementSchema[]) => void;
}

export function CounterIncrements({
  counterIncrementSchemas,
  setCounterIncrementSchemas,
}: CounterIncrementProps) {
  const { t } = useTranslation();
  const { counters } = useManagerContext();
  const queryClient = useQueryClient();
  const [counterForm, setCounterForm] = useState({
    name: '',
    description: '',
    unit: CounterUnit.NO_UNIT,
  });
  const [editingIndex, setEditingIndex] = useState<number | null>(null);

  const createCounter = useCreateCounterMutation(queryClient, {
    onSuccess: data => {
      queryClient.invalidateQueries({ queryKey: ['counters'] });
      // Update the schema at the editing index with the new counter
      const newSchemas = [...counterIncrementSchemas];
      newSchemas[editingIndex!] = {
        id_custom_counter: data.id_custom_counter,
        value: 0,
      };
      setCounterIncrementSchemas(newSchemas);
      setEditingIndex(null);
      setCounterForm({ name: '', description: '', unit: CounterUnit.NO_UNIT });
    },
  });

  // Filter out counters that are already in use
  const availableCounters = Object.values(counters).filter(
    counter =>
      !counterIncrementSchemas.some(
        schema => schema.id_custom_counter === counter.id_custom_counter,
      ),
  );

  const handleAddCounter = () => {
    setCounterIncrementSchemas([
      ...counterIncrementSchemas,
      {
        id_custom_counter:
          Object.values(availableCounters)[0]?.id_custom_counter || 0,
        value: 0,
      },
    ]);
  };

  const handleDeleteCounter = (index: number) => {
    setCounterIncrementSchemas(
      counterIncrementSchemas.filter((_, idx) => idx !== index),
    );
  };

  const updateCounterValue = (index: number, value: number) => {
    const newSchemas = [...counterIncrementSchemas];
    newSchemas[index] = { ...newSchemas[index], value };
    setCounterIncrementSchemas(newSchemas);
  };

  const updateCounterId = (index: number, id_custom_counter: number) => {
    const newSchemas = [...counterIncrementSchemas];
    newSchemas[index] = { ...newSchemas[index], id_custom_counter };
    setCounterIncrementSchemas(newSchemas);
  };

  const handleSelectChange = (index: number, value: string) => {
    if (value === 'new') {
      setEditingIndex(index);
    } else {
      updateCounterId(index, Number(value));
    }
  };

  const handleCancelCreate = () => {
    setEditingIndex(null);
    setCounterForm({ name: '', description: '', unit: CounterUnit.NO_UNIT });
  };

  const handleCreateCounter = () => {
    if (counterForm.name.trim()) {
      createCounter.mutate(counterForm);
    }
  };

  return (
    <div className="space-y-2 mt-2 mb-4">
      <div className="flex justify-between items-center mb-4">
        <div>
          <p className="block font-semibold text-gray-700">
            {t('manager.stats.counters')}
          </p>
          <p className="text-xs text-gray-500">
            {t('manager.stats.counterIncrementsDescription')}
          </p>
        </div>
        <button
          type="button"
          onClick={handleAddCounter}
          className="inline-flex items-center p-1 border border-transparent rounded-full shadow-sm text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
        >
          <PlusIcon className="h-4 w-4" aria-hidden="true" />
        </button>
      </div>

      {counterIncrementSchemas.map((schema, index) => (
        <div key={index} className="flex items-center space-x-2">
          {editingIndex === index ? (
            <div className="flex-1 space-y-2 bg-gray-100 shadow-sm p-2 rounded-md">
              <p className="text-sm">
                <span className="font-semibold block">
                  {t('manager.stats.createCounterTitle')}
                </span>
                <span> {t('manager.stats.createCounterDescription')}</span>
              </p>
              <input
                type="text"
                value={counterForm.name}
                onChange={e =>
                  setCounterForm(prev => ({ ...prev, name: e.target.value }))
                }
                placeholder={t('generic.name')}
                className="block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
              />
              <textarea
                value={counterForm.description}
                onChange={e =>
                  setCounterForm(prev => ({
                    ...prev,
                    description: e.target.value,
                  }))
                }
                placeholder={t('generic.description')}
                className="block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
              />
              <select
                value={counterForm.unit}
                onChange={e =>
                  setCounterForm(prev => ({
                    ...prev,
                    unit: e.target.value as CounterUnit,
                  }))
                }
                className="block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
              >
                {Object.values(CounterUnit).map(unit => (
                  <option key={unit} value={unit}>
                    {t(`manager.stats.${unit.toLowerCase()}`)}
                  </option>
                ))}
              </select>
              <div className="flex space-x-2">
                <button
                  type="button"
                  onClick={handleCreateCounter}
                  disabled={!counterForm.name.trim()}
                  className="inline-flex items-center px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md shadow-sm text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 disabled:opacity-50"
                >
                  {t('generic.save')}
                </button>
                <button
                  type="button"
                  onClick={handleCancelCreate}
                  className="inline-flex items-center px-3 py-2 border border-gray-300 text-sm leading-4 font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                >
                  {t('generic.cancel')}
                </button>
              </div>
            </div>
          ) : (
            <>
              <select
                value={schema.id_custom_counter}
                onChange={e => handleSelectChange(index, e.target.value)}
                className="block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
              >
                <option value={schema.id_custom_counter}>
                  {counters[schema.id_custom_counter]?.name}
                </option>
                {availableCounters.map(counter => (
                  <option
                    key={counter.id_custom_counter}
                    value={counter.id_custom_counter}
                  >
                    {counter.name}
                  </option>
                ))}
                <option value="new">{t('manager.stats.createCounter')}</option>
              </select>

              <NumericFormat
                value={schema.value}
                onValueChange={values =>
                  updateCounterValue(index, values.floatValue || 0)
                }
                className="block w-20 rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                allowNegative={false}
              />

              <button
                type="button"
                onClick={() => handleDeleteCounter(index)}
                className="p-1 text-red-600 hover:text-red-800"
              >
                <TrashIcon className="h-4 w-4" />
              </button>
            </>
          )}
        </div>
      ))}
    </div>
  );
}
