import { ColorName } from '@youshift/shared/utils';
import { useTranslation } from 'react-i18next';

import ColorPicker from '../../../../../components/ColorPicker';

type SectionDetailsProps = {
  name: string;
  setName: (name: string) => void;
  acronym: string;
  setAcronym: (acronym: string) => void;
  setColor: (color: ColorName) => void;
  color: ColorName;
};

function SectionDetails({
  name,
  setName,
  acronym,
  setAcronym,
  setColor,
  color,
}: SectionDetailsProps) {
  const { t } = useTranslation();
  return (
    <div className="bg-white px-8 rounded-lg w-full mx-auto">
      <div className="mb-4">
        <label
          className="block text-gray-700 font-medium mb-1"
          htmlFor="section-name"
        >
          {t('manager.sectionsConfig.name')}
        </label>
        <input
          className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
          type="text"
          id="section-name"
          placeholder={t('manager.sectionsConfig.namePlaceholder')}
          value={name}
          onChange={e => setName(e.target.value)}
        />
      </div>

      <div className="mb-4">
        <label
          className="block text-gray-700 font-medium mb-1"
          htmlFor="acronym"
        >
          {t('manager.sectionsConfig.acronym')}
        </label>
        <input
          className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
          type="text"
          id="acronym"
          maxLength={5}
          placeholder={t('manager.sectionsConfig.acronymPlaceholder')}
          value={acronym}
          onChange={e => setAcronym(e.target.value)}
        />
      </div>

      <div className="mb-6">
        <label className="block text-gray-700 font-medium mb-1" htmlFor="color">
          {t('generic.color')}
        </label>
        <ColorPicker value={color} onChange={setColor} />
      </div>
    </div>
  );
}

export default SectionDetails;
