import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

export default function CTA() {
  const { t } = useTranslation();

  return (
    <div className="bg-white">
      <div className="mx-auto sm:px-6 lg:px-8">
        <div className="relative isolate overflow-hidden bg-blue-600 px-6 py-20 text-center shadow-2xl sm:rounded-3xl sm:px-16">
          <h2 className="text-balance text-4xl font-semibold tracking-tight text-white sm:text-5xl">
            {t('landing.cta.title')}
          </h2>
          <p className="mx-auto mt-6 mb-8 max-w-xl text-3xl text-white">
            {t('landing.cta.subtitle1')}{' '}
            <span className="relative">
              <span className="relative z-10">{t('generic.forFree')}</span>
              <span className="absolute bottom-0 left-0 right-0 h-3 -skew-x-12 bg-teal-500" aria-hidden="true" />
            </span>
            {' '}
            {t('landing.cta.subtitle2')}
          </p>
          <Link
            to="signup"
            className="rounded-md bg-white mt-3 px-3.5 py-2.5 text-xl font-semibold text-blue-600 shadow-sm hover:bg-blue-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
          >
            {t('landing.cta.action')}
          </Link>
          <svg
            viewBox="0 0 1024 1024"
            aria-hidden="true"
            className="absolute left-1/2 top-1/2 -z-10 size-[64rem] -translate-x-1/2 [mask-image:radial-gradient(closest-side,white,transparent)]"
          >
            <circle r={512} cx={512} cy={512} fill="url(#827591b1-ce8c-4110-b064-7cb85a0b1217)" fillOpacity="0.9" />
            <defs>
              <radialGradient id="827591b1-ce8c-4110-b064-7cb85a0b1217">
                <stop stopColor="rgba(20, 184, 166, 0.9)" />
                <stop offset={1} stopColor="rgba(16, 185, 129, 0.9)" />
              </radialGradient>
            </defs>
          </svg>
        </div>
      </div>
    </div>
  );
}

