import { Listbox } from '@headlessui/react';
import { LabelIcon, LabelIcons } from '@youshift/shared/utils';
import { useTranslation } from 'react-i18next';

import LabelIconComponent from './LabelIconComponent';

type IconPickerProps = {
  value: LabelIcon | null; // Current selected icon
  onChange: (icon: LabelIcon) => void; // Change handler
  disabled?: boolean;
};

function IconPicker({ value, onChange, disabled }: IconPickerProps) {
  const { t } = useTranslation();

  return (
    <Listbox value={value} onChange={onChange} disabled={disabled}>
      <div className="relative mt-1 z-10">
        {/* Button to show the selected icon */}
        <Listbox.Button
          className={`relative cursor-pointer rounded-md p-2 px-6 border border-gray-600 shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 flex items-center ${value ? '' : 'text-gray-600'}`}
        >
          {value ? <LabelIconComponent icon={value} /> : t('generic.icon')}
        </Listbox.Button>

        {/* Dropdown list of icons */}
        <Listbox.Options className="absolute mt-1 bg-white shadow-lg max-h-32 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm">
          {LabelIcons.map(icon => (
            <Listbox.Option
              key={icon}
              className={({ active }) =>
                `${
                  active ? 'text-white bg-blue-600' : 'text-gray-900'
                } cursor-pointer select-none relative py-2 pl-3 pr-9 flex items-center`
              }
              value={icon}
            >
              {({ selected, active }) => (
                <>
                  <LabelIconComponent icon={icon} />
                  {selected ? (
                    <span
                      className={`${
                        active ? 'text-white' : 'text-blue-600'
                      } absolute inset-y-0 right-0 flex items-center pr-4`}
                    >
                      ✓
                    </span>
                  ) : null}
                </>
              )}
            </Listbox.Option>
          ))}
        </Listbox.Options>
      </div>
    </Listbox>
  );
}

export default IconPicker;
