import {
  Bars4Icon,
  PlusIcon,
  RectangleGroupIcon,
  UsersIcon,
} from '@heroicons/react/24/outline';
import { QueryClient, useQuery } from '@tanstack/react-query';
import { preLoadQuery } from '@youshift/shared/hooks';
import { userReqRulesQuery } from '@youshift/shared/hooks/queries';
import { Rule } from '@youshift/shared/types';
import { useTranslation } from 'react-i18next';
import {
  Link,
  LoaderFunctionArgs,
  useNavigate,
  useParams,
} from 'react-router-dom';

import { EmptyState } from '../../../../components/EmptyState';
import RuleTypeBadge from '../../../../components/ItrConfig/RuleTypeBadge';
import Wrapper from '../../../../components/Wrapper';
import { requireApproved, requireManager } from '../../../../utils/checks';

type UserReqRulesLoader = { rules: Rule[] };
export const userReqRulesLoader =
  (queryClient: QueryClient) =>
  async ({
    params,
  }: LoaderFunctionArgs): Promise<UserReqRulesLoader | null> => {
    const user = await requireManager(queryClient);
    await requireApproved(user);
    if (params.idItr === undefined) {
      return null;
    }
    const rules = await preLoadQuery(
      queryClient,
      userReqRulesQuery(params.idItr),
    );
    return { rules };
  };

export default function UserReqRulesConfig() {
  const { idItr: id } = useParams();

  const { data: rules } = useQuery(userReqRulesQuery(id!));
  const { t } = useTranslation();
  const navigate = useNavigate();

  if (!rules?.length) {
    return (
      <Wrapper>
        <EmptyState
          title={t('manager.rulesConfig.createPrompt')}
          subtitle={t('manager.rulesConfig.definition')}
          buttonText={t('manager.rulesConfig.createRule')}
          Icon={RectangleGroupIcon}
          onClick={() => navigate('./new')}
        />
      </Wrapper>
    );
  }

  return (
    <Wrapper>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 w-full mx-auto">
        <Link
          to="./users"
          className="col-span-1 md:col-span-2 lg:col-span-3 flex items-center justify-between bg-blue-50 hover:bg-blue-100 rounded-lg p-4 text-blue-700 transition-colors duration-200"
        >
          <div className="flex items-center gap-3">
            <UsersIcon className="w-6 h-6" />
            <span className="text-lg font-medium">
              {t('manager.rulesConfig.viewPerUser')}
            </span>
          </div>
        </Link>

        {rules
          .sort(
            (a, b) =>
              a.type.localeCompare(b.type) || a.name.localeCompare(b.name),
          )
          .map(rule => (
            <div
              key={rule.id_rule}
              className="bg-white rounded-lg border border-gray-200 p-3"
            >
              <Link
                to={`${rule.id_rule}`}
                className="flex items-center justify-between space-x-3"
              >
                <div className="flex flex-row gap-2">
                  <RuleTypeBadge
                    type={rule.type}
                    string={t(`manager.rulesConfig.${rule.type}`)}
                  />
                  <span className="text-lg font-medium">{rule.name}</span>
                </div>
                <button className="p-1 rounded-full border border-gray-300">
                  <Bars4Icon className="w-5 h-5 text-gray-400" />
                </button>
              </Link>
            </div>
          ))}
        <button
          onClick={() => navigate('./new')}
          className="flex w-full items-center justify-between bg-gray-100 rounded-lg p-3 text-gray-400"
        >
          <span>{t('manager.rulesConfig.createRule')}</span>
          <div
            className="p-1 rounded-full border border-gray-300"
            aria-label={t('manager.rulesConfig.createRule')}
          >
            <PlusIcon className="w-5 h-5" />
          </div>
        </button>
      </div>
    </Wrapper>
  );
}
