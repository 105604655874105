import { Dialog, Listbox, Transition } from '@headlessui/react';
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/20/solid';
import { PlusCircleIcon, XMarkIcon } from '@heroicons/react/24/outline';
import { useQueryClient } from '@tanstack/react-query';
import { usePostResponseMutation } from '@youshift/shared/hooks/mutations';
import {
  UserLayoutResponse,
  UserShiftExchangeResponse,
} from '@youshift/shared/hooks/queries';
import {
  ExchangeRequest,
  ExchangeResponseType,
  Section,
  SectionSlot,
  ShiftAssignment,
} from '@youshift/shared/types';
import {
  dateToString,
  generateErrorStringFromError,
  localeNormalizer,
  mergeIterationData,
  mergeRecords,
  parseSectionSlotDateTime,
} from '@youshift/shared/utils';
import { Fragment, useMemo, useState } from 'react';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';

import {
  useUserContext,
  useUserLayoutLoader,
} from '../../../layouts/UserLayout';
import {
  UserShiftExchangeContext,
  userShiftExchangeLayoutLoader,
  useUserShiftExchangeContext,
} from './ShiftExchangeLayout';
import Modal from '../../../components/Modal';
import i18n from '../../../utils/i18n';
import SectionSlotChip from '../../../components/Calendars/SectionSlotChip';

export default function PostResponse({
  open,
  setOpen,
  selectedRequest,
  exchangeType,
}: {
  open: boolean;
  setOpen: (open: boolean) => void;
  selectedRequest: ExchangeRequest;
  exchangeType: ExchangeResponseType;
}) {
  const queryClient = useQueryClient();
  const { userLayout } = useUserContext();
  const exchangeData = useUserShiftExchangeContext();

  const { t } = useTranslation();

  const locale = localeNormalizer(i18n.language);

  const futureActiveAssignments: Record<number, ShiftAssignment> =
    Object.entries(userLayout.itrs).reduce((acc, [_, itr]) => {
      const activeShiftAssignments = Object.fromEntries(
        Object.entries(itr.shift_assignments).filter(
          ([_, shift_assignment]) => {
            const sectionSlot =
              itr.section_slots[shift_assignment.id_section_slot];
            return new Date(sectionSlot.start) > new Date();
          },
        ),
      );
      return { ...acc, ...activeShiftAssignments };
    }, {});

  const [selectedShiftAssignment, setSelectedShiftAssignment] =
    useState<ShiftAssignment | null>(null);

  const postResponseMutation = usePostResponseMutation(queryClient, {
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['userExchangeDashboard'],
      });
      toast.success(t('user.shiftExchange.successNewResponse'));
      setOpen(false);
    },
  });

  const { sections: allSections, section_slots: allSectionSlots } = useMemo(
    () =>
      mergeIterationData(userLayout.itrs, [
        'sections',
        'section_slots',
        'shift_assignments',
        'slot_labels',
      ]),
    [userLayout],
  );

  const other_user_request_shift_assignment = Object.values(exchangeData).find(
    itr =>
      selectedRequest.id_shift_assignment in
      itr.other_users_requestable_shift_assignments,
  )?.other_users_requestable_shift_assignments[
    selectedRequest.id_shift_assignment
  ];
  if (!other_user_request_shift_assignment) {
    return null;
  }
  const request_section_slot =
    allSectionSlots[other_user_request_shift_assignment.id_section_slot];
  if (!request_section_slot) {
    return null;
  }
  const request_section = allSections[request_section_slot.id_section];
  if (!request_section) {
    return null;
  }

  const handleSave = () => {
    postResponseMutation.mutate({
      id_request: selectedRequest.id_request,
      id_shift_assignment: selectedShiftAssignment?.id_shift_assignment,
      type: exchangeType,
    });
  };

  return (
    <Modal
      isOpen={open}
      onClose={() => setOpen(false)}
      size="lg"
      editButtons
      handleSave={handleSave}
    >
      <div className="flex items-center">
        <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-blue-100 sm:mx-0 sm:h-10 sm:w-10">
          <PlusCircleIcon
            className="h-6 w-6 text-blue-600"
            aria-hidden="true"
          />
        </div>
        <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
          <Dialog.Title
            as="h3"
            className="text-base font-semibold leading-6 text-gray-900"
          >
            {t('user.shiftExchange.postResponse')}
          </Dialog.Title>
        </div>
      </div>
      <div className="flex flex-col items-start gap-2 my-2">
        <div className="font-semibold">
          {t('user.shiftExchange.shiftRequested')}
        </div>
        <div>
          <SectionSlotChip
            sectionName={request_section.name}
            start={request_section_slot.start}
            end={request_section_slot.end}
          />
        </div>
      </div>
      {exchangeType == ExchangeResponseType.ONE_FOR_ONE ? (
        <div className="flex flex-col items-start gap-2 my-3">
          <h2 className="font-semibold">
            {t('user.shiftExchange.selectedShiftTrade')}
          </h2>
          <div className="items-center">
            <Listbox
              value={selectedShiftAssignment?.id_shift_assignment}
              onChange={id_shift_assignment =>
                setSelectedShiftAssignment(
                  id_shift_assignment
                    ? futureActiveAssignments[id_shift_assignment]
                    : null,
                )
              }
            >
              <div className="relative mt-1">
                <Listbox.Button className="relative cursor-default rounded-lg bg-white py-2 pl-3 pr-10 text-left shadow-md focus:outline-none focus-visible:border-blue-500 focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-blue-300 sm:text-sm">
                  <span className="block truncate">
                    {selectedShiftAssignment
                      ? `${dateToString(
                          allSectionSlots[
                            selectedShiftAssignment.id_section_slot
                          ].start,
                          'weekday-hour',
                        )} - ${allSections[selectedShiftAssignment.id_section]?.acronym}`
                      : t('user.shiftExchange.selectShift')}
                  </span>
                  <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                    <ChevronUpDownIcon
                      className="h-5 w-5 text-gray-400"
                      aria-hidden="true"
                    />
                  </span>
                </Listbox.Button>
                <Transition
                  as={Fragment}
                  leave="transition ease-in duration-100"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <Listbox.Options className="absolute mt-1 max-h-24 overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                    {Object.values(futureActiveAssignments)
                      .sort((a, b) => {
                        const slotA = allSectionSlots[a.id_section_slot];
                        const slotB = allSectionSlots[b.id_section_slot];
                        return (
                          new Date(slotA.start).getTime() -
                          new Date(slotB.start).getTime()
                        );
                      })
                      .map(shiftAssignment => {
                        const section_slot =
                          allSectionSlots[shiftAssignment.id_section_slot];
                        return (
                          <Listbox.Option
                            key={shiftAssignment.id_shift_assignment}
                            className={({ active }) =>
                              `relative cursor-default select-none py-2 pl-10 pr-4 ${active ? 'bg-blue-100 text-blue-900' : 'text-gray-900'}`
                            }
                            value={shiftAssignment.id_shift_assignment}
                          >
                            {({ selected }) => (
                              <>
                                <span
                                  className={`block truncate ${selected ? 'font-medium' : 'font-normal'}`}
                                >
                                  {`${allSections[shiftAssignment.id_section]?.acronym} - ${parseSectionSlotDateTime(
                                    section_slot.start,
                                    section_slot.end,
                                    locale,
                                    false,
                                  )}`}
                                </span>
                                {selected ? (
                                  <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-blue-600">
                                    <CheckIcon
                                      className="h-5 w-5"
                                      aria-hidden="true"
                                    />
                                  </span>
                                ) : null}
                              </>
                            )}
                          </Listbox.Option>
                        );
                      })}
                  </Listbox.Options>
                </Transition>
              </div>
            </Listbox>
          </div>
        </div>
      ) : null}
      <p className="mt-2">{t('user.shiftExchange.approvalWarning')}</p>
    </Modal>
  );
}
