/* eslint-disable max-len */
/* eslint-disable no-unused-expressions */
import {
  CheckIcon,
  LockClosedIcon,
  LockOpenIcon,
} from '@heroicons/react/20/solid';
import {
  ArrowPathRoundedSquareIcon,
  CalendarDaysIcon,
  CalendarIcon,
  ChartBarIcon,
  Cog8ToothIcon,
  GlobeEuropeAfricaIcon,
  QueueListIcon,
  UserIcon,
  UsersIcon,
} from '@heroicons/react/24/outline';
import { QueryClient, useQuery } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import { parseIterationDates, classNames } from '@youshift/shared/utils';
import { profileQuery } from '@youshift/shared/hooks/jsQueries';
import { preLoadQuery } from '@youshift/shared/hooks';
import { allChainsQuery, personnelQuery } from '@youshift/shared/hooks/queries';
import { ItrStatuses, User } from '@youshift/shared/types';

// import logo from '../../assets/Logo/youshift_logotype.svg';
import ManagerDashboardChecklist from '../../components/ManagerDashboardChecklist';
import { requireApproved, requireManager } from '../../utils/checks';
import Wrapper from '../../components/Wrapper';

export const managerDashboardLoader =
  (queryClient: QueryClient) => async () => {
    const user = await requireManager(queryClient);
    await requireApproved(user);
    queryClient.getQueryData(profileQuery().queryKey) ??
      (await queryClient.fetchQuery(profileQuery()));

    await preLoadQuery(queryClient, personnelQuery());
    await preLoadQuery(queryClient, allChainsQuery());
    return null;
  };

// Add this before the ManagerDashboard component
const getNavItems = (t: (key: string) => string) => [
  {
    to: '../chains',
    icon: <CalendarIcon className="w-full h-full" />,
    title: t('manager.chains.activeItrs'),
    subtitle: t('manager.dashboard.manageCalendars'),
  },
  {
    to: '../personnel',
    icon: <UsersIcon className="w-full h-full" />,
    title: t('manager.navigation.team'),
    subtitle: t('manager.dashboard.managePersonnel'),
  },
  {
    to: '../profile',
    icon: <Cog8ToothIcon className="w-full h-full" />,
    title: t('manager.dashboard.settings'),
    subtitle: t('manager.dashboard.manageSettings'),
  },
  {
    to: '../exchange',
    icon: <ArrowPathRoundedSquareIcon className="w-full h-full" />,
    title: t('generic.shiftExchange.title'),
    subtitle: t('manager.dashboard.manageExchanges'),
  },
  {
    to: '../event',
    icon: <GlobeEuropeAfricaIcon className="w-full h-full" />,
    title: t('eventCenter.eventCenter'),
    subtitle: t('manager.dashboard.manageEvents'),
  },
  // {
  //   to: '../stats',
  //   icon: <ChartBarIcon className="w-full h-full" />,
  //   title: t('generic.stats'),
  //   subtitle: t('manager.dashboard.manageStats'),
  // },
];

interface NavCardProps {
  to: string;
  icon: React.ReactNode;
  title: string;
  subtitle: string;
}

export function NavCard({ to, icon, title, subtitle }: NavCardProps) {
  return (
    <NavLink
      to={to}
      className="flex flex-col items-center p-6 bg-white rounded-xl shadow-lg hover:shadow-xl transition-shadow"
    >
      <div className="w-12 h-12 text-teal-500 mb-4">{icon}</div>
      <h3 className="text-lg font-semibold text-gray-900">{title}</h3>
      <p className="text-sm text-gray-600 text-center mt-2">{subtitle}</p>
    </NavLink>
  );
}

export default function ManagerDashboard() {
  const { data } = useQuery(allChainsQuery());
  const itrs = data?.itrs ?? {};
  const chains = data?.chains ?? {};
  const { data: personnel } = useQuery(personnelQuery());

  const shouldShowCheckList =
    Object.keys(personnel?.roles ?? {}).length === 0 ||
    Object.keys(chains).length === 0;

  const { t } = useTranslation();

  if (!personnel || !chains) {
    return null;
  }

  const steps = [
    { id: '01', name: t('generic.config') },
    { id: '02', name: t('generic.assignment') },
    { id: '03', name: t('generic.publ') },
  ];

  // TODO: abstract and use enums
  const stepIdMapping = {
    [ItrStatuses.CONFIGURATION]: '01',
    [ItrStatuses.INITIALIZATION]: '01',
    [ItrStatuses.ASSIGNMENT]: '02',
    [ItrStatuses.PUBLISHED]: '03',
    [ItrStatuses.RUNNING_SMARTASS]: '02', // TODO handle status
    [ItrStatuses.CLOSED]: '-01', // Add missing status
    [ItrStatuses.ARCHIVED]: '-02', // Add missing ARCHIVED status
  };

  const countUnverifiedUsers = (users: Record<number, User>) => {
    let unverifiedUsers = 0;
    let unverifiedMailUsers = 0;

    for (const user of Object.values(users)) {
      if (!user.user_is_verified) {
        unverifiedUsers++;
      }
      if (!user.mail_is_verified) {
        unverifiedMailUsers++;
      }
    }

    return [unverifiedUsers, unverifiedMailUsers];
  };

  const [unverifiedUsersCount, unverifiedMailCount] = countUnverifiedUsers(
    personnel?.users,
  );

  function getCurrentTime() {
    const now = new Date();
    const time = now.toLocaleTimeString([], {
      hour: '2-digit',
      minute: '2-digit',
    });
    const fullDate = now.toLocaleDateString(undefined, {
      weekday: 'long',
      day: 'numeric',
      month: 'long',
      year: 'numeric',
    });
    return { time, fullDate };
  }

  const { time, fullDate } = getCurrentTime();

  const buttonDisplay = (status: ItrStatuses) => {
    if (
      status === ItrStatuses.INITIALIZATION ||
      status === ItrStatuses.CONFIGURATION
    ) {
      return t('manager.dashboard.continueConfig');
    }
    if (status === ItrStatuses.RUNNING_SMARTASS) {
      return t('generic.itrStatuses.running');
    }
    if (status === ItrStatuses.PUBLISHED || status === ItrStatuses.ASSIGNMENT) {
      return t('manager.dashboard.checkResults');
    }
    // mas estados?
    return '';
  };

  const chain = Object.values(chains)[0];
  const activeItr =
    chain && chain.itrs && chain.itrs.length > 0
      ? itrs[chain.itrs[chain.itrs.length - 1]]
      : null;
  const currentStepId = activeItr ? stepIdMapping[activeItr.status] : null;

  return (
    <Wrapper mt="mt-16">
      <div className="container mx-auto px-4 py-8">
        {/* Main Status Card */}
        <div className="bg-white rounded-xl shadow-lg p-6 mb-8">
          {activeItr ? (
            <div className="flex flex-col md:flex-row justify-between items-center gap-8">
              <div className="flex-1">
                <div className="flex items-center gap-2 mb-2">
                  <h2 className="text-2xl font-bold text-gray-900">
                    {chain.chain_name}
                  </h2>
                  {activeItr.locked ? (
                    <LockClosedIcon className="h-5 w-5 text-teal-500" />
                  ) : (
                    <LockOpenIcon className="h-5 w-5 text-teal-500" />
                  )}
                </div>
                <p className="text-gray-600 mb-4">
                  {parseIterationDates(
                    activeItr.start_day,
                    activeItr.end_day,
                    activeItr.itr_type,
                  )}
                </p>
                <div className="flex items-center space-x-4 mb-2">
                  {steps.map((step, index) => (
                    <div key={step.id} className="flex items-center">
                      <div
                        className={classNames(
                          'flex flex-col items-center',
                          'relative',
                        )}
                      >
                        <div
                          className={classNames(
                            'w-8 h-8 rounded-full flex items-center justify-center',
                            step.id === currentStepId
                              ? 'bg-teal-500 text-white'
                              : currentStepId && step.id < currentStepId
                                ? 'bg-teal-100 text-teal-700'
                                : 'bg-gray-100 text-gray-400',
                          )}
                        >
                          {currentStepId && step.id < currentStepId ? (
                            <CheckIcon className="w-5 h-5" />
                          ) : (
                            <span className="text-sm font-medium">
                              {index + 1}
                            </span>
                          )}
                        </div>
                        <div className="mt-2 text-sm font-medium text-gray-600">
                          {step.name}
                        </div>
                      </div>
                      {index < steps.length - 1 && (
                        <div
                          className={classNames(
                            'h-0.5 w-12',
                            currentStepId && step.id < currentStepId
                              ? 'bg-teal-500'
                              : 'bg-gray-200',
                          )}
                        />
                      )}
                    </div>
                  ))}
                </div>
              </div>
              <div className="flex gap-4">
                <NavLink
                  to={`../iteration/${activeItr.id_itr}`}
                  className="inline-flex items-center px-4 py-2 rounded-lg bg-teal-500 text-white hover:bg-teal-600 transition-colors"
                >
                  {buttonDisplay(activeItr.status)}
                </NavLink>
              </div>
            </div>
          ) : (
            <ManagerDashboardChecklist
              rolesAreCreated={Object.keys(personnel?.roles ?? {}).length > 0}
              itrsAreCreated={Object.keys(chains).length > 0}
            />
          )}
        </div>

        {/* Quick Actions Grid */}
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
          {getNavItems(t).map(item => (
            <NavCard key={item.to} {...item} />
          ))}
        </div>
      </div>
    </Wrapper>
  );
}
