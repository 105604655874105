import { Dialog, Transition } from '@headlessui/react';
import { LockClosedIcon } from '@heroicons/react/24/outline';
import { request } from '@youshift/shared/api';
import {
  generateErrorStringFromError,
  passwordRegex,
} from '@youshift/shared/utils';
import { Fragment, useRef, useState, ChangeEvent } from 'react';
import toast from 'react-hot-toast';
import { useChangePasswordMutation } from '@youshift/shared/hooks/auth';
import { useQueryClient } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';

import { YSButton } from '../../components/Buttons';

interface ChangePasswordPopupProps {
  open: boolean;
  setOpen: (open: boolean) => void;
}

export default function ChangePasswordPopup({
  open,
  setOpen,
}: ChangePasswordPopupProps) {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const changePasswordMutation = useChangePasswordMutation(queryClient, {
    onSuccess: () => {
      setOpen(false);
      toast.success(t('auth.changePassword.successMessage'));
    },
  });

  const [oldPassword, setOldPassword] = useState<string>('');
  const [newPassword, setNewPassword] = useState<string>('');
  const [confirmPassword, setConfirmPassword] = useState<string>('');

  const handleInputChange = (
    e: ChangeEvent<HTMLInputElement>,
    setter: (value: string) => void,
  ) => {
    setter(e.target.value);
  };

  const sendPasswordChangeRequest = () => {
    if (newPassword !== confirmPassword) {
      toast.error(t('auth.passwordNoMatch'));
      return;
    }
    if (!passwordRegex.test(newPassword)) {
      toast.error(t('auth.passwordRequirements'));
      return;
    }

    changePasswordMutation.mutate({
      old_password: oldPassword,
      new_password: newPassword,
    });
  };

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={setOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                  <div className="flex flex-row gap-4 items-center">
                    <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-teal-100 sm:mx-0 sm:h-10 sm:w-10">
                      <LockClosedIcon
                        className="h-6 w-6 text-teal-600"
                        aria-hidden="true"
                      />
                    </div>
                    <Dialog.Title
                      as="h3"
                      className="text-base font-semibold leading-6 text-gray-900"
                    >
                      {t('auth.changePassword.changePassword')}
                    </Dialog.Title>
                  </div>
                  <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                    <div className="mt-2">
                      <div className="grid grid-cols-1 gap-x-8 gap-y-6">
                        <div>
                          <label
                            htmlFor="old-password"
                            className="block text-sm font-semibold leading-6 text-gray-900"
                          >
                            {t('auth.changePassword.oldPassword')}
                          </label>
                          <div className="mt-2.5">
                            <input
                              type="password"
                              name="old-password"
                              id="old-password"
                              value={oldPassword}
                              onChange={e =>
                                handleInputChange(e, setOldPassword)
                              }
                              className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                            />
                          </div>
                        </div>
                        <div>
                          <label
                            htmlFor="new-password"
                            className="block text-sm font-semibold leading-6 text-gray-900"
                          >
                            {t('auth.newPassword')}
                          </label>
                          <div className="mt-2.5">
                            <input
                              type="password"
                              name="new-password"
                              id="new-password"
                              value={newPassword}
                              onChange={e =>
                                handleInputChange(e, setNewPassword)
                              }
                              className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                            />
                          </div>
                        </div>
                        <div>
                          <label
                            htmlFor="confirm-password"
                            className="block text-sm font-semibold leading-6 text-gray-900"
                          >
                            {t('auth.confirmNewPassword')}
                          </label>
                          <div className="mt-2.5">
                            <input
                              type="password"
                              name="confirm-password"
                              id="confirm-password"
                              value={confirmPassword}
                              onChange={e =>
                                handleInputChange(e, setConfirmPassword)
                              }
                              className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                  <YSButton
                    onClick={sendPasswordChangeRequest}
                    variant="primary"
                    classNames="sm:ml-3"
                    loading={changePasswordMutation.isPending}
                  >
                    {t('generic.save')}
                  </YSButton>
                  <YSButton onClick={() => setOpen(false)} variant="secondary">
                    {t('generic.cancel')}
                  </YSButton>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
