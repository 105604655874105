import React from 'react';

type SlotCapacityProps = {
  minNeed: number;
  maxNeed: number;
  current: number;
};

const SlotCapacity: React.FC<SlotCapacityProps> = function SlotCapacity({
  minNeed,
  maxNeed,
  current,
}) {
  const circles = [];

  for (let i = 1; i <= Math.max(maxNeed, current); i++) {
    const isFilled = i <= maxNeed && i <= current;
    const isOverMax = i > maxNeed;

    circles.push(
      <div
        key={i}
        className={`
          w-2 h-2 rounded-full border border-gray-600 
          ${i > minNeed ? 'border-dashed' : ''}
          ${isFilled ? 'bg-green-500' : isOverMax ? 'bg-red-500' : 'bg-white'}
        `}
      />,
    );
  }

  return (
    <div className="flex flex-wrap mt-0.5 items-center justify-center gap-0.5 relative">
      {circles.map(circle => (
        <div>{circle}</div>
      ))}
    </div>
  );
};

export default SlotCapacity;
