// useUserReqReducer.ts

import { useCallback, useMemo, useReducer } from 'react';
import {
  UserRequirementType,
  User,
  UserRequirement,
} from '@youshift/shared/types';

import { Action, State, RoleConfig } from './types';

type UseUserReqReducerProps = {
  itrUsers: number[];
  roles: Record<number, { name: string }>;
  users: Record<number, User>;
  userReqs?: Record<number, UserRequirement>;
  initialReqType?: UserRequirementType;
  onAnyEdit?: () => void; // for example setReqsEdited(true)
};

export function useUserReqReducer(props: UseUserReqReducerProps) {
  const {
    itrUsers,
    roles,
    users,
    userReqs = {},
    initialReqType = UserRequirementType.SLOTS,
    onAnyEdit,
  } = props;

  const initialState: State = useMemo(() => {
    // Build out the shape { roles: { roleId: { role_name, users: [...] } } }
    const newState: State = {
      req_type: initialReqType,
      roles: {},
    };

    // Initialize role => { role_name, users: [] }
    for (const roleId in roles) {
      if (Object.prototype.hasOwnProperty.call(roles, roleId)) {
        newState.roles[roleId] = {
          role_name: roles[roleId].name,
          users: [],
        };
      }
    }

    // Populate each role with the iteration users who belong to that role
    Object.values(users).forEach(user => {
      if (!itrUsers.includes(user.id)) return;
      const roleId = user.id_user_role;
      if (!roleId) return;

      // Check if there's existing requirement data
      const existingReq = userReqs[user.id];

      // if existingReq is defined => we are in "edit mode"
      const included = !!existingReq;
      const isSlots = initialReqType === UserRequirementType.SLOTS;
      const isDuration = initialReqType === UserRequirementType.DURATION;

      const userConfig = {
        id_user: user.id,
        name: `${user.firstname} ${user.lastname}`,
        included,
        min_slots:
          isSlots && existingReq?.min_slots ? existingReq.min_slots : 0,
        max_slots:
          isSlots && existingReq?.max_slots ? existingReq.max_slots : 0,
        min_duration:
          isDuration && existingReq?.min_duration
            ? existingReq.min_duration
            : 0,
        max_duration:
          isDuration && existingReq?.max_duration
            ? existingReq.max_duration
            : 0,
      };

      if (!newState.roles[roleId]) {
        // If there's a role missing for some reason, create it
        newState.roles[roleId] = {
          role_name: roles[roleId]?.name ?? 'Unknown Role',
          users: [userConfig],
        };
      } else {
        newState.roles[roleId].users.push(userConfig);
      }
    });

    // Clean up roles that have no users
    for (const roleId in newState.roles) {
      if (newState.roles[roleId].users.length === 0) {
        delete newState.roles[roleId];
      }
    }

    return newState;
  }, [itrUsers, roles, users, userReqs, initialReqType]);

  // The shared reducer
  const reducer = useCallback(
    (state: State, action: Action): State => {
      if (onAnyEdit) onAnyEdit();
      switch (action.type) {
        // Toggles the `isHoursConfig` boolean flag
        case 'TOGGLE_REQ_TYPE': {
          const resetRoles = Object.fromEntries(
            Object.entries(state.roles).map(([key, roleConfig]) => [
              key, // The key remains the same
              {
                ...roleConfig,
                users: roleConfig.users.map(user => ({
                  ...user,
                  min_slots: 0,
                  max_slots: 0,
                  min_duration: 0,
                  max_duration: 0,
                })),
              },
            ]),
          );

          return {
            ...state,
            req_type: action.value,
            roles: resetRoles,
          };
        }

        // Toggles the inclusion of all users within a specified role
        case 'TOGGLE_ROLE': {
          return {
            ...state,
            roles: {
              ...state.roles,
              [action.id_role]: {
                ...state.roles[action.id_role],
                users: state.roles[action.id_role].users.map(user => ({
                  ...user,
                  included: action.payload.type === 'on', // Toggle the included flag for each user
                  min_slots: 0,
                  max_slots: 0,
                  min_duration: 0,
                  max_duration: 0,
                })),
              },
            },
          };
        }

        // Toggles the inclusion of a specific user based on their `id_user`
        case 'TOGGLE_USER': {
          // Find the role that contains the user we want to toggle
          const roleId = Object.keys(state.roles).find(roleId =>
            state.roles[Number(roleId)].users.some(
              user => user.id_user === action.id_user,
            ),
          )!;
          return {
            ...state,
            roles: {
              ...state.roles,
              [roleId]: {
                ...state.roles[Number(roleId)],
                users: state.roles[Number(roleId)].users.map(user =>
                  user.id_user === action.id_user
                    ? {
                        ...user,
                        included: !user.included, // Toggle the included flag for the user
                        min_slots: 0,
                        max_slots: 0,
                        min_duration: 0,
                        max_duration: 0,
                      }
                    : user,
                ),
              },
            },
          };
        }

        // Updates the requirements (slots or duration) for all users within a specified role
        case 'UPDATE_ROLE_REQS': {
          return {
            ...state,
            roles: {
              ...state.roles,
              [action.id_role]: {
                ...state.roles[action.id_role],
                users: state.roles[action.id_role].users.map(user => ({
                  ...user,
                  ...action.payload, // Update the relevant fields (min/max slots, min/max duration) for each user
                })),
              },
            },
          };
        }

        // Updates the requirements (slots or duration) for a specific user based on their `id_user`
        case 'UPDATE_USER_REQS': {
          // Find the role that contains the user whose requirements we want to update
          const roleId = Object.keys(state.roles).find(roleId =>
            state.roles[Number(roleId)].users.some(
              user => user.id_user === action.id_user,
            ),
          )!;
          return {
            ...state,
            roles: {
              ...state.roles,
              [roleId]: {
                ...state.roles[Number(roleId)],
                users: state.roles[Number(roleId)].users.map(user =>
                  user.id_user === action.id_user
                    ? {
                        ...user,
                        ...action.payload, // Update the relevant fields (min/max slots, min/max duration) for the user
                      }
                    : user,
                ),
              },
            },
          };
        }

        // Includes all users across all roles
        case 'INCLUDE_ALL': {
          return {
            ...state,
            roles: Object.fromEntries(
              Object.entries(state.roles).map(([roleId, roleConfig]) => [
                roleId,
                {
                  ...roleConfig,
                  users: roleConfig.users.map(user => ({
                    ...user,
                    included: true,
                  })),
                },
              ]),
            ),
          };
        }

        // Excludes all users across all roles
        case 'UNINCLUDE_ALL': {
          return {
            ...state,
            roles: Object.fromEntries(
              Object.entries(state.roles).map(([roleId, roleConfig]) => [
                roleId,
                {
                  ...roleConfig,
                  users: roleConfig.users.map(user => ({
                    ...user,
                    included: false,
                    min_slots: 0,
                    max_slots: 0,
                    min_duration: 0,
                    max_duration: 0,
                  })),
                },
              ]),
            ),
          };
        }

        // Default case returns the current state if no action matches
        default:
          return state;
      }
    },
    [onAnyEdit],
  );

  const [state, dispatch] = useReducer(reducer, initialState);

  // If you need a way to reset to initial, here's how you might do it:
  // const resetState = useCallback(() => {
  //   dispatch({ type: 'RESET', payload: initialState });
  // }, [initialState]);

  return { initialState, state, dispatch };
}
