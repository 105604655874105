import { Section, UserPreferenceType } from '@youshift/shared/types';
import { eventBgColor, returnColor } from '@youshift/shared/utils';
import React from 'react';
import { useTranslation } from 'react-i18next';

export default function PreferencesLegend(): JSX.Element {
  const { t } = useTranslation();
  return (
    <div className="mt-4 flex flex-wrap gap-2">
      <div className="inline-flex items-center gap-x-2 rounded-full px-2 py-1 text-xs font-medium text-gray-900 ring-1 ring-inset ring-gray-200">
        {/* Circle for the section color */}
        <span
          className="w-5 h-5 rounded-full"
          style={{
            backgroundColor: eventBgColor(
              UserPreferenceType.POINTS,
              false,
              50,
              50,
            ),
          }}
        />
        {/* Section name */}
        {t('user.preferences.positivePoints')}
      </div>
      <div className="inline-flex items-center gap-x-2 rounded-full px-2 py-1 text-xs font-medium text-gray-900 ring-1 ring-inset ring-gray-200">
        {/* Circle for the section color */}
        <span
          className="w-5 h-5 rounded-full"
          style={{
            backgroundColor: eventBgColor(
              UserPreferenceType.POINTS,
              false,
              -50,
              50,
            ),
          }}
        />
        {/* Section name */}
        {t('user.preferences.negativePoints')}
      </div>
      <div className="inline-flex items-center gap-x-2 rounded-full px-2 py-1 text-xs font-medium text-gray-900 ring-1 ring-inset ring-gray-200">
        {/* Circle for the section color */}
        <span
          className="w-5 h-5 rounded-full"
          style={{
            backgroundColor: eventBgColor(
              UserPreferenceType.PERSONAL_BLOCKING,
              false,
            ),
          }}
        />
        {/* Section name */}
        {t('user.preferences.personalBlockLegend')}
      </div>
      <div className="inline-flex items-center gap-x-2 rounded-full px-2 py-1 text-xs font-medium text-gray-900 ring-1 ring-inset ring-gray-200">
        {/* Circle for the section color */}
        <span
          className="w-5 h-5 rounded-full"
          style={{
            backgroundColor: eventBgColor(
              UserPreferenceType.JUSTIFIED_BLOCKING,
              false,
            ),
          }}
        />
        {/* Section name */}
        {t('user.preferences.justifiedBlockLegend')}
      </div>
    </div>
  );
}
