import { Spanning } from '@youshift/shared/utils';

export enum VirgueriaVersion {
  RulesCreating = 'rules_creating',
  RulesEditing = 'rules_editing',
  Section = 'section',
  Virgueria = 'virgueria',
}

export type ArrangedVirtualSlot = {
  duration_minutes: number;
  end_position: number;
  end_time: string;
  id_section_slots: number[];
  id_virtual_slot: number;
  max_need: number;
  min_need: number;
  start_position: number;
  start_time: string;
  spanning: Spanning;
};
