import React, { ReactNode, useState } from 'react';
import { motion } from 'framer-motion';
import { XMarkIcon } from '@heroicons/react/24/outline';

interface DraggableOverlayProps {
  open: boolean;
  setOpen: (open: boolean) => void;
  children: ReactNode;
  title: string;
  w?: string;
}

function DraggableOverlay({
  open,
  setOpen,
  children,
  title,
  w,
}: DraggableOverlayProps) {
  const [position, setPosition] = useState({
    x: window.innerWidth - 384,
    y: 0,
  });

  if (!open) return null;

  return (
    <motion.div
      drag
      dragMomentum={false}
      onDragEnd={(_, info) => {
        setPosition({
          x: position.x + info.offset.x,
          y: position.y + info.offset.y,
        });
      }}
      className={`fixed right-0 top-0 z-50 ${w || 'w-96'} h-screen bg-white shadow-xl border-l border-gray-200 cursor-move`}
      style={{ pointerEvents: 'auto' }}
    >
      <div className="flex justify-between items-center p-4 border-b border-gray-200 cursor-move bg-gray-50">
        <div className="font-medium">{title}</div>
        <button
          onClick={() => setOpen(false)}
          className="p-1 hover:bg-gray-200 rounded-full"
        >
          <XMarkIcon className="h-4 w-4" />
        </button>
      </div>
      <div className="p-4 h-[calc(100vh-64px)] overflow-y-auto">{children}</div>
    </motion.div>
  );
}

export default DraggableOverlay;
