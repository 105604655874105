import { ItrStatuses } from '@youshift/shared/types';
import { Navigate } from 'react-router-dom';

import { useItrContext } from './IterationRootLayout/IterationRootLayout';

// ** HANDLES ROUTING WHEN CLICKING ON AN ITERATION ** //

function IterationStatus() {
  const { status } = useItrContext();

  // prevent people from going to any route
  if (status === ItrStatuses.CONFIGURATION) {
    return <Navigate to="configuration" />;
  }
  if (status === ItrStatuses.INITIALIZATION) {
    return <Navigate to="initialization" />;
  }
  if (
    status === ItrStatuses.ASSIGNMENT ||
    status === ItrStatuses.PUBLISHED ||
    status === ItrStatuses.RUNNING_SMARTASS ||
    status === ItrStatuses.ARCHIVED
  ) {
    return <Navigate to="assignment" />;
  }
}

export default IterationStatus;
