import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { request } from '@youshift/shared/api';
import toast from 'react-hot-toast';
import { passwordRegex } from '@youshift/shared/utils';

import SignUpSuccessful from './SignUpSuccessful';

interface ResetPasswordParams {
  token: string;
}

interface ErrorResponse {
  response: {
    data: {
      type: 'EXPIRED_TOKEN' | 'INVALID_TOKEN' | string;
    };
  };
}

export default function ResetPassword(): JSX.Element {
  const [newPassword, setNewPassword] = useState<string>('');
  const [confirmPassword, setConfirmPassword] = useState<string>('');
  const [success, setSuccess] = useState<boolean>(false);

  const { token } = useParams();

  const { t } = useTranslation();

  const sendPasswordReset = async (): Promise<void> => {
    try {
      if (newPassword !== confirmPassword) {
        setSuccess(false);
        toast.error(t('auth.passwordNoMatch'));
      } else if (!passwordRegex.test(newPassword)) {
        toast.error(t('auth.passwordRequirements'));
      } else {
        await request({
          url: `/reset_password/${token}`,
          method: 'post',
          data: { password: newPassword },
        });
        setSuccess(true);
      }
    } catch (error) {
      const err = error as ErrorResponse;
      if (err.response.data.type === 'EXPIRED_TOKEN') {
        toast.error(t('auth.resetPassword.expiredToken'));
      } else if (err.response.data.type === 'INVALID_TOKEN') {
        toast.error(t('auth.resetPassword.invalidToken'));
      } else {
        toast.error(t('generic.error'));
      }
    }
  };

  return success ? (
    <SignUpSuccessful
      h1={t('auth.resetPassword.success')}
      h2={t('auth.resetPassword.successSubtitle')}
    />
  ) : (
    <main className="grid min-h-full place-items-center bg-white px-6 py-24 sm:py-32 lg:px-8 lg:w-1/2">
      <div className="text-center m-24">
        <p className="text-base font-semibold text-blue-600">
          {t('auth.resetPassword.resetPassword')}
        </p>
        <h1 className="mt-4 text-xl font-bold tracking-tight text-gray-900 sm:text-4xl my-4">
          {t('auth.resetPassword.instructions')}
        </h1>
        <div className="mt-6">
          <label
            htmlFor="new-password"
            className="block text-sm font-semibold leading-6 text-gray-900"
          >
            {t('auth.newPassword')}
          </label>
          <div className="mt-2.5">
            <input
              type="password"
              name="new-password"
              id="new-password"
              value={newPassword}
              onChange={e => setNewPassword(e.target.value)}
              className="block w-2/3 mx-auto rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
            />
          </div>
        </div>
        <div className="mt-6">
          <label
            htmlFor="confirm-password"
            className="block text-sm font-semibold leading-6 text-gray-900"
          >
            {t('auth.confirmNewPassword')}
          </label>
          <div className="mt-2.5">
            <input
              type="password"
              name="confirm-password"
              id="confirm-password"
              value={confirmPassword}
              onChange={e => setConfirmPassword(e.target.value)}
              className="block w-2/3 mx-auto rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
            />
          </div>
        </div>
        <div className="mt-10 flex items-center justify-center gap-x-6">
          <button
            type="button"
            className="rounded-md bg-blue-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
            onClick={sendPasswordReset}
          >
            {t('auth.resetPassword.action')}
          </button>
        </div>
      </div>
    </main>
  );
}
