import React from 'react';
import { CalendarIcon, ClockIcon } from '@heroicons/react/24/solid';
import {
  localeNormalizer,
  parseSectionSlotDateTime,
} from '@youshift/shared/utils';

import i18n from '../../utils/i18n';

interface SectionSlotChipProps {
  sectionName: string;
  start: string;
  end: string;
  small?: boolean;
}

function SectionSlotChip({
  sectionName,
  start,
  end,
  small,
}: SectionSlotChipProps) {
  const locale = localeNormalizer(i18n.language);
  return (
    <div
      className={`flex bg-blue-100 rounded-full ${small ? 'flex-col px-2 py-1 text-xs' : 'items-center text-sm justify-between gap-2 p-2 mt-2 ml-5 shadow-md'}`}
    >
      {/* Section Name */}
      <div className="flex items-center gap-1">
        <CalendarIcon
          className={`${small ? 'h-3 w-3' : 'h-5 w-5'} text-blue-500`}
        />
        <p className="text-blue-700">{sectionName}</p>
      </div>

      {/* Time */}
      <div className="flex items-center gap-1">
        <ClockIcon
          className={`${small ? 'h-3 w-3' : 'h-5 w-5'} text-blue-500`}
        />
        <p className="text-blue-600">
          {parseSectionSlotDateTime(start, end, locale, false)}
        </p>
      </div>
    </div>
  );
}

export default SectionSlotChip;
