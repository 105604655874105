/* eslint-disable react/require-default-props */
import { CheckCircleIcon } from '@heroicons/react/24/solid';
import { ColorName, returnColor } from '@youshift/shared/utils';
import { useState } from 'react';

import SlotCapacity from './ManualAssignment/SlotCapacity';
import { ArrangedVirtualSlot } from './ManualAssignment/types';
import { borderOfSpanningSlot } from '../layouts/IterationRootLayout/utils';

interface VirtualSlotProps {
  slot: ArrangedVirtualSlot;
  sectionColor: ColorName;
  onVirtualSlotClick?: (virtualSlotId: number) => void;
  nextIsContiguous: boolean;
  selected: boolean;
}

interface VirtualSlotVirgueriaProps {
  slot: ArrangedVirtualSlot;
  sectionColor: ColorName;
  currentlyAssigned?: number;
  nextIsContiguous: boolean;
}

export function VirtualSlot({
  slot,
  sectionColor,
  onVirtualSlotClick,
  nextIsContiguous,
  selected,
}: VirtualSlotProps) {
  const borderColor = returnColor(sectionColor, 500);
  return (
    <button
      key={`${slot.start_time}-${slot.id_virtual_slot}`}
      className="bg-white justify-between relative gap-0.5 mt-3 mb-1.5"
      style={{
        borderTop: `1px solid ${borderColor}`,
        borderBottom: `1px solid ${borderColor}`,
        gridColumnStart: slot.start_position,
        gridColumnEnd: slot.end_position,
        borderRight: nextIsContiguous ? `1px solid ${borderColor}` : 'none',
      }}
      onClick={() => onVirtualSlotClick?.(slot.id_virtual_slot)}
    >
      <CheckCircleIcon
        className={`absolute -top-3 -right-1 w-4 h-4 text-orange-500 bg-orange-100 rounded-full ${selected ? '' : 'hidden'}`}
      />
      {/* Main content container to avoid overlap */}
      <div className="flex flex-col items-center">
        <div className="flex flex-col justify-center items-center gap-0.5 mx-1">
          {/* Display Current Needs */}
          <p className="text-gray-800 md:text-xs text-xxxs">{`${slot.max_need}`}</p>
          <p className="text-gray-800 md:text-xs text-xxxs">{`${slot.min_need}`}</p>
        </div>
      </div>
    </button>
  );
}

export function VirtualSlotVirgueria({
  slot,
  sectionColor,
  currentlyAssigned,
  nextIsContiguous,
}: VirtualSlotVirgueriaProps) {
  if (slot.id_virtual_slot === undefined) {
    return null;
  }

  const color = returnColor(sectionColor, 500);
  const borderColor = returnColor(sectionColor, 500);
  return (
    <div
      key={`${slot.start_time}-${slot.id_virtual_slot}`}
      className="bg-white flex flex-col items-center justify-around relative gap-0.5 mt-3"
      style={{
        borderTop: `1px solid ${color}`,
        borderBottom: `1px solid ${color}`,
        gridColumnStart: slot.start_position,
        gridColumnEnd: slot.end_position,
        borderRight: nextIsContiguous ? `1px solid ${borderColor}` : 'none',
        ...borderOfSpanningSlot(slot.spanning),
      }}
    >
      {/* Main content container to avoid overlap */}
      <div className="flex flex-col items-center pb-0.5 pt-0.5">
        {currentlyAssigned !== undefined && (
          <SlotCapacity
            minNeed={slot.min_need}
            maxNeed={slot.max_need}
            current={currentlyAssigned}
          />
        )}
      </div>
    </div>
  );
}
