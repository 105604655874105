import { PencilSquareIcon, PlusIcon } from '@heroicons/react/24/outline';
import { QueryClient, useQueryClient } from '@tanstack/react-query';
import {
  useCreateCounterMutation,
  useDeleteCounterMutation,
  useEditCounterMutation,
} from '@youshift/shared/hooks/mutations';
import { CounterUnit, CustomCounter } from '@youshift/shared/types';
import { useState } from 'react';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';

import Modal from '../Modal';

interface CounterManagerProps {
  counters: Record<string, CustomCounter>;
}

export function CounterManager({ counters }: CounterManagerProps) {
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  const [isNewCounterModalOpen, setIsNewCounterModalOpen] = useState(false);
  const [isEditCounterModalOpen, setIsEditCounterModalOpen] = useState(false);
  const [selectedCounter, setSelectedCounter] = useState<CustomCounter | null>(
    null,
  );
  const [counterForm, setCounterForm] = useState({
    name: '',
    description: '',
    unit: CounterUnit.NO_UNIT,
  });

  const createCounter = useCreateCounterMutation(queryClient, {
    onSuccess: () => {
      setIsNewCounterModalOpen(false);
      toast.success(t('manager.stats.createCounterSuccess'));
      queryClient.invalidateQueries({ queryKey: ['counters'] });
      setCounterForm({ name: '', description: '', unit: CounterUnit.NO_UNIT });
    },
  });

  const editCounter = useEditCounterMutation(queryClient, {
    onSuccess: () => {
      setIsEditCounterModalOpen(false);
      toast.success(t('manager.stats.editCounterSuccess'));
      queryClient.invalidateQueries({ queryKey: ['counters'] });
      setSelectedCounter(null);
    },
  });

  const deleteCounter = useDeleteCounterMutation(queryClient, {
    onSuccess: () => {
      setIsEditCounterModalOpen(false);
      toast.success(t('manager.stats.deleteCounterSuccess'));
      queryClient.invalidateQueries({ queryKey: ['counters'] });
      setSelectedCounter(null);
    },
  });

  const handleCreateCounter = () => {
    createCounter.mutate(counterForm);
  };

  const handleEditCounter = () => {
    if (!selectedCounter) return;
    editCounter.mutate({
      id_custom_counter: selectedCounter.id_custom_counter,
      ...counterForm,
    });
  };

  const handleDeleteCounter = () => {
    if (!selectedCounter) return;
    deleteCounter.mutate({
      id_custom_counter: selectedCounter.id_custom_counter,
    });
  };

  return (
    <div className="bg-gray-100 rounded-md shadow-md p-4">
      <h3 className="text-xl font-semibold leading-6 text-gray-900">
        {t('manager.stats.counters')}
      </h3>
      <div className="flex flex-row flex-wrap gap-2 mt-4">
        {Object.values(counters).length > 0 ? (
          Object.values(counters).map(counter => (
            <button
              key={counter.id_custom_counter}
              className="inline-flex items-center bg-white gap-x-1.5 rounded-full px-2 py-1 text-xs font-medium text-gray-900 ring-1 ring-inset ring-gray-200 hover:bg-gray-100"
              onClick={() => {
                setSelectedCounter(counter);
                setCounterForm({
                  name: counter.name,
                  description: counter.description,
                  unit: CounterUnit.NO_UNIT,
                });
                setIsEditCounterModalOpen(true);
              }}
              type="button"
            >
              <span>{counter.name}</span>
              <PencilSquareIcon className="h-4 w-4 text-gray-500" />
            </button>
          ))
        ) : (
          <p className="text-gray-500">
            {t('manager.stats.createCounterDescription')}
          </p>
        )}
        <button
          className="inline-flex items-center gap-x-1.5 rounded-full px-2 py-1 text-xs font-medium text-gray-900 ring-1 ring-inset ring-gray-200 hover:bg-gray-100 bg-white"
          onClick={() => setIsNewCounterModalOpen(true)}
          type="button"
        >
          <PlusIcon className="h-4 w-4 text-gray-500" />
        </button>
      </div>

      {/* Create/Edit Counter Modal */}
      <Modal
        isOpen={isNewCounterModalOpen || isEditCounterModalOpen}
        onClose={() => {
          setIsNewCounterModalOpen(false);
          setIsEditCounterModalOpen(false);
          setSelectedCounter(null);
          setCounterForm({
            name: '',
            description: '',
            unit: CounterUnit.NO_UNIT,
          });
        }}
        size="md"
        handleSave={
          isNewCounterModalOpen ? handleCreateCounter : handleEditCounter
        }
        editButtons
        handleDelete={isEditCounterModalOpen ? handleDeleteCounter : undefined}
      >
        <div className="space-y-4">
          <h2 className="text-xl font-semibold">
            {isNewCounterModalOpen
              ? t('manager.stats.createCounterTitle')
              : t('manager.stats.editCounterTitle')}
          </h2>
          <div>
            <label htmlFor="name" className="block text-sm text-gray-700">
              {t('generic.name')}
            </label>
            <input
              type="text"
              id="name"
              value={counterForm.name}
              onChange={e =>
                setCounterForm(prev => ({ ...prev, name: e.target.value }))
              }
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
            />
          </div>
          <div>
            <label
              htmlFor="description"
              className="block text-sm text-gray-700"
            >
              {t('generic.description')}
            </label>
            <textarea
              id="description"
              value={counterForm.description}
              onChange={e =>
                setCounterForm(prev => ({
                  ...prev,
                  description: e.target.value,
                }))
              }
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
            />
          </div>
          <div>
            <label htmlFor="unit" className="block text-sm text-gray-700">
              {t('manager.stats.unit')}
            </label>
            <select
              id="unit"
              value={counterForm.unit}
              onChange={e =>
                setCounterForm(prev => ({
                  ...prev,
                  unit: e.target.value as CounterUnit,
                }))
              }
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
            >
              {Object.values(CounterUnit).map(unit => (
                <option key={unit} value={unit}>
                  {t(`manager.stats.${unit.toLowerCase()}`)}
                </option>
              ))}
            </select>
          </div>
        </div>
      </Modal>
    </div>
  );
}
