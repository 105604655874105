/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable react/require-default-props */
import {
  CheckCircleIcon,
  ClockIcon,
  PlusCircleIcon,
} from '@heroicons/react/24/outline';
import { CheckCircleIcon as CheckCircleIconSolid } from '@heroicons/react/24/solid';
import {
  SpecialEventWithoutDates,
  User,
  UserPreferenceType,
} from '@youshift/shared/types';
import {
  AMPMFormat,
  ColorIndex,
  ColorName,
  LabelIcon,
  convert24To12,
  dateToString,
  localeNormalizer,
  minutesToHours,
  returnColor,
} from '@youshift/shared/utils';
import React, { ReactNode, useState } from 'react';
import { useTranslation } from 'react-i18next';

import type { ArrangedSectionSlot } from '../pages/Manager/IterationConfig/Sections/types';
import LabelIconComponent from './LabelIconComponent';
import CustomCombobox from './ManualAssignment/Combobox';
import { Epa } from '../layouts/IterationRootLayout/types';
import { borderOfSpanningSlot } from '../layouts/IterationRootLayout/utils';
import i18n from '../utils/i18n';

interface SectionSlotProps {
  slot: ArrangedSectionSlot;
  sectionColor: ColorName;
  shade: ColorIndex;
  onIconClick?: (slot: ArrangedSectionSlot) => void;
  onSlotClick?: (slotId: number) => void;
  Icon: React.FC<React.SVGProps<SVGSVGElement>>;
  children?: ReactNode;
  labelIcon: LabelIcon | undefined;
  isSelected?: boolean;
}

interface SectionSlotSelectProps {
  slot: ArrangedSectionSlot;
  sectionColor: ColorName;
  shade: ColorIndex;
  onClick?: (slotId: number) => void;
  children?: ReactNode;
  selectedSlots: Set<number>;
  labelIcon: LabelIcon | undefined;
}

interface SectionSlotVirgueriaProps {
  slot: ArrangedSectionSlot;
  sectionColor: ColorName;
  shade: ColorIndex;
  isSlotFull: boolean;
  epa: Epa | undefined;
  users: Record<number, User> | undefined;
  start: Date;
  end: Date;
  labelIcon: LabelIcon | undefined;
  showRestPeriod: boolean;
  selectedPeople: Set<number>;
}

export function ShowRestPeriod({ restPeriod }: { restPeriod: number }) {
  return (
    <div className="flex flex-row justify-center items-center gap-1 mx-1 pb-1">
      <ClockIcon className="h-3 w-3 text-gray-500" />
      <p className="text-gray-800 md:text-xs text-xxxs whitespace-nowrap">{`${minutesToHours(restPeriod)} h`}</p>
    </div>
  );
}

export function SectionSlot({
  slot,
  sectionColor,
  shade,
  onIconClick,
  onSlotClick,
  Icon,
  labelIcon,
  children,
  isSelected,
}: SectionSlotProps) {
  const { t } = useTranslation();
  const locale = localeNormalizer(i18n.language);
  const ampm = AMPMFormat(locale);
  return (
    <div
      key={`${slot.start_time}-${slot.day_idx}-${slot.id_slot_label}`}
      className="rounded-md flex flex-col justify-between relative gap-0.5"
      style={{
        backgroundColor: returnColor(sectionColor, shade),
        border: '1px solid gray',
        gridColumnStart: slot.start_position,
        gridColumnEnd: slot.end_position,
      }}
    >
      {/* Main content container to avoid overlap */}
      <div className="flex flex-row justify-end">
        <button
          className="ml-0.5 text-gray-500"
          onClick={() => onIconClick && onIconClick(slot)}
          aria-label={t('generic.delete')}
        >
          <Icon className="md:h-4 md:w-4 w-2 h-2" />
        </button>
        <button
          className="flex flex-grow justify-end"
          onClick={() => {
            if (onSlotClick) {
              onSlotClick(slot.id_section_slot!);
            }
          }}
        >
          <LabelIconComponent
            className="md:h-4 md:w-4 w-2 h-2 text-gray-500"
            icon={labelIcon}
          />
        </button>
      </div>
      <button
        onClick={() => {
          if (onSlotClick) {
            onSlotClick(slot.id_section_slot!);
          }
        }}
      >
        <CheckCircleIconSolid
          className={`absolute -top-3 right-1 w-5 h-5 text-purple-600 bg-purple-100 rounded-full ${isSelected ? '' : 'hidden'}`}
        />
        <div className="flex flex-col items-center">
          <p
            className={`text-center md:text-xs text-xxxs ${
              slot.duration_minutes < 480 ? 'px-0.5 pb-1' : 'px-2 pb-1'
            }`}
          >
            {`${slot.spanning === 'end' ? '' : ampm ? convert24To12(slot.start_time) : slot.start_time} - ${ampm ? convert24To12(slot.end_time) : slot.end_time}`}
          </p>
          {/* {children} */}
        </div>
        <ShowRestPeriod restPeriod={slot.rest_period} />
      </button>
    </div>
  );
}

export function SectionSlotSelect({
  slot,
  sectionColor,
  shade,
  onClick,
  children,
  selectedSlots,
  labelIcon,
}: SectionSlotSelectProps) {
  const { t } = useTranslation();
  if (slot.id_section_slot === undefined) {
    return null;
  }
  const selected = selectedSlots.has(slot.id_section_slot);
  const color = returnColor(sectionColor, shade);
  const darkestColor = returnColor(sectionColor, 900);
  const locale = localeNormalizer(i18n.language);
  const ampm = AMPMFormat(locale);
  return (
    <button
      key={`${slot.start_time}-${slot.day_idx}-${slot.id_slot_label}`}
      className="rounded-md flex flex-col items-center justify-around relative gap-0.5 mt-1"
      style={{
        backgroundColor: selected ? color : 'white',
        border: '1px solid',
        borderColor: darkestColor,
        gridColumnStart: slot.start_position,
        gridColumnEnd: slot.end_position,
        ...borderOfSpanningSlot(slot.spanning),
      }}
      onClick={() => onClick && onClick(slot.id_section_slot!)}
    >
      {/* Main content container to avoid overlap */}
      <div className="flex flex-col items-center">
        <LabelIconComponent
          className="md:h-4 md:w-4 w-2 h-2"
          icon={labelIcon}
        />
        <p
          className={`text-center md:text-xs text-xxxs ${
            slot.duration_minutes < 480 ? 'px-0.5 pt-2 pb-1' : 'px-2 pt-2 pb-1'
          }`}
        >
          {`${slot.spanning === 'end' ? '' : ampm ? convert24To12(slot.start_time) : slot.start_time} - ${ampm ? convert24To12(slot.end_time) : slot.end_time}`}
        </p>
      </div>
      <CheckCircleIcon
        className={`${selected ? 'block' : 'invisible'} text-gray-700 w-8 h-8 mx-auto`}
      />
    </button>
  );
}

export function SectionSlotVirgueria({
  slot,
  sectionColor,
  shade,
  isSlotFull,
  epa,
  users,
  start,
  end,
  labelIcon,
  showRestPeriod,
  selectedPeople,
}: SectionSlotVirgueriaProps) {
  const [additionalComboboxes, setAdditionalComboboxes] = useState(0);

  if (
    slot.id_section_slot === undefined ||
    epa === undefined ||
    users === undefined
  ) {
    return null;
  }

  const { eventsMap, preferencesMap, assignmentsMap } = epa;
  const { bySectionSlot: assignmentsBySectionSlot } = assignmentsMap;
  const { byPreferenceSlot: preferenceByPrefSlot } = preferencesMap;
  const { byDate: eventsByDate } = eventsMap;

  const slotStart = dateToString(start, 'dd/mm/yyyy');
  const slotEnd = dateToString(end, 'dd/mm/yyyy');

  let eventsOfTheDay: SpecialEventWithoutDates[] = [];
  if (slotStart === slotEnd) {
    eventsOfTheDay = eventsByDate[slotStart] ?? [];
  } else {
    eventsOfTheDay = [
      ...(eventsByDate[slotStart] ?? []),
      ...(eventsByDate[slotEnd] ?? []),
    ];
  }
  const preferencesOfSlot = preferenceByPrefSlot[slot.id_pref_slot!] || [];
  const assignmentsOfSlot =
    assignmentsBySectionSlot[slot.id_section_slot!] || [];

  // a slot will be entirely faded out if it's full and only has non-selected people
  // otherwise, individual comboboxes will be fadedOut
  const fadedOut =
    assignmentsOfSlot.length &&
    isSlotFull &&
    assignmentsOfSlot.every(
      assignment => !selectedPeople.has(assignment.id_user),
    );

  // Function to get user preference based on events or slot preferences
  // returns: number of points, Blocked, or Event Type
  const getUserPref = (id: number) => {
    // Check if the user has an event
    const event = eventsOfTheDay?.find(event => event.id_user === id);
    if (event) {
      return event.type; // Return event type if found (e.g., 'V', 'E')
    }

    // Check for user preferences in the slot
    const preference = preferencesOfSlot?.find(
      preference => preference.id_user === id,
    );
    if (preference) {
      // Return 'B' if preference is 'B', otherwise return the points value
      return preference.preference === UserPreferenceType.POINTS
        ? preference.points
        : preference.preference;
    }

    // Return 0 if no event or preference is found
    return 0;
  };

  // Map through users to construct the people array
  const people = Object.values(users)
    .map(({ id, firstname, lastname }) => {
      const userPref = getUserPref(id);
      // Determine preference and points based on the type of userPref
      const isString = typeof userPref === 'string';
      const preference = isString ? userPref : UserPreferenceType.POINTS;
      const points = isString ? 0 : userPref;

      // Check if the user is assigned and active in any slots
      const assigned = assignmentsOfSlot.some(
        assignment => assignment.id_user == id && assignment.active,
      );

      // Return the user object with calculated properties
      return {
        id,
        name: `${firstname} ${lastname}`,
        preference,
        points,
        assigned,
      };
    })
    .sort((a, b) => {
      // Prioritize those with `assigned` set to `true`
      if (a.assigned !== b.assigned) {
        return a.assigned ? -1 : 1;
      }

      // Check if either has non-point preferences (B, V, E)
      const aHasEvent = a.preference !== UserPreferenceType.POINTS;
      const bHasEvent = b.preference !== UserPreferenceType.POINTS;

      // If one has an event and the other doesn't, event goes last
      if (aHasEvent !== bHasEvent) {
        return aHasEvent ? 1 : -1;
      }

      // If both are point-based preferences, sort by points
      if (!aHasEvent && !bHasEvent) {
        // Handle positive, zero, and negative points
        if (a.points > 0 !== b.points > 0) {
          return b.points - a.points; // Higher points first
        }
        if ((a.points === 0) !== (b.points === 0)) {
          return a.points === 0 ? -1 : 1; // Zero points before negative
        }
        return b.points - a.points; // Sort within same category
      }

      // If both have events, sort alphabetically
      return a.name.localeCompare(b.name);
    });

  const color = returnColor(sectionColor, shade);
  const darkestColor = returnColor(sectionColor, 900);
  const locale = localeNormalizer(i18n.language);
  const ampm = AMPMFormat(locale);

  return (
    <div
      key={`${slot.start_time}-${slot.day_idx}-${slot.id_slot_label}`}
      className={`rounded-md flex flex-col items-center justify-around relative gap-0.5 mt-1 ${fadedOut ? 'opacity-20' : ''}`}
      style={{
        backgroundColor: color,
        border: `1px solid ${darkestColor}`,
        gridColumnStart: slot.start_position,
        gridColumnEnd: slot.end_position,
        ...borderOfSpanningSlot(slot.spanning),
      }}
    >
      {/* Main content container to avoid overlap */}
      <div className="flex flex-col items-center">
        <LabelIconComponent
          className="w-4 h-4 text-gray-500"
          icon={labelIcon}
        />
        <p
          className={`text-center md:text-xs text-xxxs ${
            slot.duration_minutes < 480
              ? 'px-0.5 pt-1 pb-0.5'
              : 'px-1 pt-1 pb-0.5'
          }`}
        >
          {`${slot.spanning === 'end' ? '' : ampm ? convert24To12(slot.start_time) : slot.start_time} - ${ampm ? convert24To12(slot.end_time) : slot.end_time}`}
        </p>
      </div>
      {showRestPeriod && <ShowRestPeriod restPeriod={slot.rest_period} />}
      <div className="text-start w-full px-0.5 text-xs flex flex-col gap-1 my-1">
        {people.map(
          (person, needIdx) =>
            person.assigned && (
              <CustomCombobox
                people={people}
                key={needIdx}
                needIdx={needIdx}
                slot={slot}
                fadedOut={person.assigned && !selectedPeople.has(person.id)}
              />
            ),
        )}
        {Array.from({ length: additionalComboboxes }).map((_, idx) => (
          <CustomCombobox
            key={`extra-${idx}`}
            people={people}
            slot={slot}
            fadedOut={false}
            setAdditionalComboboxes={setAdditionalComboboxes}
            lastCombobox={idx === additionalComboboxes - 1}
          />
        ))}
        {!additionalComboboxes && (
          <button
            onClick={() => setAdditionalComboboxes(prev => prev + 1)}
            className="mx-auto"
          >
            <PlusCircleIcon className="h-4 w-4 text-gray-500" />
          </button>
        )}
      </div>
    </div>
  );
}
