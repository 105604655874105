/* eslint-disable max-len */
import { QueryClient, useQuery } from '@tanstack/react-query';
import { preLoadQuery } from '@youshift/shared/hooks';
import {
  AssignmentsResponse,
  assignmentToolQuery,
  countersQuery,
  extendedUserReqRulesQuery,
  incompatibilitiesQuery,
  IncompatibilitiesResponse,
  iterationQuery,
  itrUsersQuery,
  personnelQuery,
  PersonnelReturn,
  sectionSlotsQuery,
  shiftAssignmentsQuery,
  slotLabelsQuery,
  statusQuery,
} from '@youshift/shared/hooks/queries';
import {
  CustomCounter,
  Iteration,
  ItrStatuses,
  ItrStatusType,
  ItrUser,
  PreferenceSlot,
  SectionSlot,
  SectionWithSlots,
  ShiftAssignment,
  SlotLabel,
  SpecialEvent,
  User,
  UserPreference,
  UserReqRule,
  UserRole,
  VirtualSlot,
} from '@youshift/shared/types';
import { createContext, useContext, useMemo } from 'react';
import {
  LoaderFunctionArgs,
  Outlet,
  useLoaderData,
  useParams,
} from 'react-router-dom';

// import ConfigurationPreview from '../../components/Iteration/ConfigurationPreview';
import { requireManager } from '../../utils/checks';
import { postExecChecks } from '../../utils/iteration_checks/assignment_checks/assignment_checks';
import { AssignmentCheckErrors } from '../../utils/iteration_checks/assignment_checks/types';
import { configurationChecks } from '../../utils/iteration_checks/configuration_checks/configuration_checks';
import { ConfigurationCheckErrors } from '../../utils/iteration_checks/configuration_checks/types';
import { findOverlappingSectionSlots } from '../../utils/iteration_checks/utils';
import { Epa } from './types';
import { useEpaData } from './utils';

// Move these types from IterationLayout
type IterationLoader = {
  assignmentTool: AssignmentsResponse;
  allUserReqs: Record<number, UserReqRule>;
  incompatibilities: IncompatibilitiesResponse;
  iteration: Iteration;
  personnel: PersonnelReturn;
  itrUsers: Record<number, ItrUser>;
  sectionsWithSlots: SectionWithSlots[];
  shiftAssignments: ShiftAssignment[];
  shiftLabels: SlotLabel[];
};

export type ItrContextType = {
  userPreferences: Record<number, UserPreference[]>;
  preferenceSlots: Record<number, PreferenceSlot>;
  specialEvents: SpecialEvent[];
  status: ItrStatusType;
  users: Record<number, User>;
  roles: Record<number, UserRole>;
  epa: Epa;
  configErrors: ConfigurationCheckErrors;
  assignmentErrors: AssignmentCheckErrors | undefined;
} & Omit<IterationLoader, 'assignmentTool' | 'personnel'>;

// Move the context definition
export const IterationContext = createContext<ItrContextType | undefined>(
  undefined,
);

// Move the context hook
export function useItrContext() {
  const context = useContext(IterationContext);
  if (!context) {
    throw new Error('useItrContext must be used within an IterationProvider');
  }
  return context;
}

// Move the loader function
export const iterationRootLoader =
  (queryClient: QueryClient) =>
  async ({ params }: LoaderFunctionArgs): Promise<IterationLoader | null> => {
    await requireManager(queryClient);
    if (params.idItr === undefined) {
      return null;
    }
    const { idItr } = params;
    const assignmentTool = await preLoadQuery(
      queryClient,
      assignmentToolQuery(idItr),
    );
    const shiftAssignments = await preLoadQuery(
      queryClient,
      shiftAssignmentsQuery(idItr),
    );
    const sectionsWithSlots = await preLoadQuery(
      queryClient,
      sectionSlotsQuery(idItr),
    );
    const itrUsers = await preLoadQuery(queryClient, itrUsersQuery(idItr));
    const personnel = await preLoadQuery(queryClient, personnelQuery());
    const shiftLabels = await preLoadQuery(queryClient, slotLabelsQuery(idItr));
    const iteration = await preLoadQuery(queryClient, iterationQuery(idItr));
    const incompatibilities = await preLoadQuery(
      queryClient,
      incompatibilitiesQuery(idItr),
    );
    const allUserReqs = await preLoadQuery(
      queryClient,
      extendedUserReqRulesQuery(idItr),
    );
    return {
      incompatibilities,
      allUserReqs,
      sectionsWithSlots,
      shiftAssignments,
      assignmentTool,
      itrUsers,
      personnel,
      shiftLabels,
      iteration,
    };
  };

// Move the useItrLoader hook
export function useItrLoader(
  id: string,
): IterationLoader & { status: ItrStatusType } {
  const initialData = useLoaderData() as IterationLoader;

  const { data: assignmentTool } = useQuery({
    ...assignmentToolQuery(id),
    initialData: initialData.assignmentTool,
    staleTime: 1000 * 60 * 5, // 5 minutes
  });

  const { data: shiftAssignments } = useQuery({
    ...shiftAssignmentsQuery(id),
    initialData: initialData.shiftAssignments,
    staleTime: 1000 * 60 * 2, // 2 minutes
  });

  const { data: sectionsWithSlots } = useQuery({
    ...sectionSlotsQuery(id),
    initialData: initialData.sectionsWithSlots,
    staleTime: 1000 * 60 * 5, // 5 minutes
  });

  const { data: itrUsers } = useQuery({
    ...itrUsersQuery(id),
    initialData: initialData.itrUsers,
    staleTime: 1000 * 60 * 5, // 5 minutes
  });

  // we filter non itr users here
  const { data: personnel } = useQuery({
    ...personnelQuery(),
    initialData: initialData.personnel,
    staleTime: 1000 * 60 * 5, // 5 minutes
    select: ({ users, roles }) =>
      ({
        roles,
        users: Object.fromEntries(
          Object.entries(users).filter(([userId]) =>
            Object.keys(initialData.itrUsers).includes(userId),
          ),
        ),
      }) as PersonnelReturn,
  });

  const { data: shiftLabels } = useQuery({
    ...slotLabelsQuery(id),
    initialData: initialData.shiftLabels,
    staleTime: 1000 * 60 * 5, // 5 minutes
  });

  const { data: iteration } = useQuery({
    ...iterationQuery(id),
    initialData: initialData.iteration,
    staleTime: 1000 * 60 * 5, // 5 minutes
  });

  const { data: incompatibilities } = useQuery({
    ...incompatibilitiesQuery(id),
    initialData: initialData.incompatibilities,
    staleTime: 1000 * 60 * 5, // 5 minutes
  });

  const { data: allUserReqs } = useQuery({
    ...extendedUserReqRulesQuery(id),
    initialData: initialData.allUserReqs,
    staleTime: 1000 * 60 * 5, // 5 minutes
  });

  const { data: status } = useQuery({
    ...statusQuery(id),
    refetchInterval: data =>
      data.state.data === ItrStatuses.RUNNING_SMARTASS ? 1000 : false,
    initialData: initialData.iteration.status,
  });

  return {
    assignmentTool,
    shiftAssignments,
    sectionsWithSlots,
    itrUsers,
    shiftLabels,
    personnel,
    status,
    iteration,
    incompatibilities,
    allUserReqs,
  };
}

export default function IterationRootLayout() {
  const { idItr } = useParams();
  const {
    assignmentTool: {
      user_preferences: userPreferences,
      pref_slots: preferenceSlots,
      special_events: specialEvents,
    },
    shiftAssignments,
    sectionsWithSlots,
    itrUsers,
    shiftLabels,
    personnel: { users, roles },
    iteration,
    incompatibilities,
    allUserReqs,
    status,
  } = useItrLoader(idItr || '');

  const epa = useEpaData({
    shiftAssignments,
    userPreferences,
    preferenceSlots,
    events: specialEvents,
  });

  const allSectionSlots: SectionSlot[] = sectionsWithSlots.flatMap(
    ({ section_slots }) => Object.values(section_slots),
  );

  const overlappingSlots: Record<number, number[]> = useMemo(() => {
    const overlaps: Record<number, number[]> = {};
    allSectionSlots.forEach(slot => {
      overlaps[slot.id_section_slot] = findOverlappingSectionSlots(
        slot,
        allSectionSlots.filter(s => s.id_section_slot !== slot.id_section_slot),
      );
    });
    return overlaps;
  }, [allSectionSlots]);

  const sectionSlotsDict: Record<number, SectionSlot> = useMemo(
    () =>
      sectionsWithSlots.reduce<Record<number, SectionSlot>>(
        (acc, { section_slots }) => ({
          ...acc,
          ...section_slots,
        }),
        {},
      ),
    [sectionsWithSlots],
  );

  const allVirtualSlots: VirtualSlot[] = sectionsWithSlots.flatMap(
    ({ virtual_slots }) => Object.values(virtual_slots),
  );

  const configErrors: ConfigurationCheckErrors = configurationChecks(
    users,
    allUserReqs,
    epa,
    incompatibilities,
    sectionSlotsDict,
    overlappingSlots,
    allVirtualSlots,
  );
  const assignment = status === ItrStatuses.ASSIGNMENT;
  const running = status === ItrStatuses.RUNNING_SMARTASS;

  const assignmentErrors: AssignmentCheckErrors | undefined =
    assignment || running
      ? postExecChecks(
          allUserReqs,
          epa,
          incompatibilities,
          sectionSlotsDict,
          overlappingSlots,
          allVirtualSlots,
        )
      : undefined;

  const contextValue: ItrContextType = useMemo(
    () => ({
      userPreferences,
      preferenceSlots,
      specialEvents,
      shiftAssignments,
      sectionsWithSlots,
      itrUsers,
      shiftLabels,
      users,
      roles,
      iteration,
      incompatibilities,
      allUserReqs,
      status,
      epa,
      configErrors,
      assignmentErrors,
    }),
    [
      userPreferences,
      preferenceSlots,
      specialEvents,
      shiftAssignments,
      sectionsWithSlots,
      itrUsers,
      shiftLabels,
      users,
      roles,
      iteration,
      incompatibilities,
      allUserReqs,
      status,
      epa,
      configErrors,
      assignmentErrors,
    ],
  );

  return (
    <IterationContext.Provider value={contextValue}>
      <Outlet />
    </IterationContext.Provider>
  );
}
