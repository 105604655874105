import { useTranslation } from 'react-i18next';
import { ChangeEvent } from 'react';
import { ClientSourcingOrigin, Industry } from '@youshift/shared/types';
import { Link, useParams } from 'react-router-dom';

import { FormActions, SignUpState, UserInfo } from './types';

interface UserSignUpFormProps {
  formState: SignUpState;
  dispatch: React.Dispatch<FormActions>;
}

export default function UserSignUpForm({
  formState,
  dispatch,
}: UserSignUpFormProps) {
  const { t } = useTranslation();
  const handleUserChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    dispatch({
      type: 'UPDATE_USER_FIELD',
      field: name as keyof UserInfo,
      value,
    });
  };

  const handleTogglePrivacyPolicy = () => {
    dispatch({ type: 'TOGGLE_PRIVACY_POLICY' });
  };

  const specialties = t('specialties', { returnObjects: true });

  const { industry: industryStr } = useParams();
  const industry = (industryStr?.toUpperCase() as Industry) || Industry.OTHER;

  return (
    <div className="space-y-6 my-6">
      <div className="grid grid-cols-1 gap-x-6 gap-y-5 sm:grid-cols-6">
        <div className="sm:col-span-3">
          <label
            htmlFor="first-name"
            className="block text-sm font-medium leading-6 text-gray-900"
          >
            {t('generic.firstName')}
          </label>
          <div className="mt-2">
            <input
              type="text"
              name="firstname"
              id="first-name"
              autoComplete="given-name"
              value={formState.user.firstname}
              onChange={handleUserChange}
              className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
            />
          </div>
        </div>

        <div className="sm:col-span-3">
          <label
            htmlFor="last-name"
            className="block text-sm font-medium leading-6 text-gray-900"
          >
            {t('generic.lastName')}
          </label>
          <div className="mt-2">
            <input
              type="text"
              name="lastname"
              id="last-name"
              autoComplete="family-name"
              value={formState.user.lastname}
              onChange={handleUserChange}
              className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
            />
          </div>
        </div>

        <div className="col-span-full">
          <label
            htmlFor="team"
            className="block text-sm font-medium leading-6 text-gray-900"
          >
            {t('generic.teamName')}
          </label>
          <div className="mt-2">
            <input
              type="text"
              name="group_name"
              id="team"
              value={formState.user.group_name}
              onChange={handleUserChange}
              className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
            />
          </div>
        </div>

        {industry === Industry.HEALTHCARE && (
          <div className="mt-2 col-span-full">
            <label
              htmlFor="specialty"
              className="block text-sm font-medium leading-6 text-gray-900"
            >
              {t('generic.specialty')}
            </label>
            <select
              id="specialty"
              name="specialty"
              className="block w-full mt-2 rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-blue-600 sm:text-sm sm:leading-6"
              defaultValue={formState.user.specialty} // Set default value
              onChange={e =>
                dispatch({
                  type: 'UPDATE_USER_FIELD',
                  field: 'specialty',
                  value: e.target.value,
                })
              }
            >
              <option value={undefined} aria-label="No specialty" />
              {Object.entries(specialties).map(([key, value]) => (
                <option key={key} value={key}>
                  {value}
                </option>
              ))}
            </select>
          </div>
        )}
      </div>
      {/* Email and phone number */}
      <div>
        <label
          htmlFor="email"
          className="block text-sm font-medium leading-6 text-gray-900"
        >
          {t('generic.email')}
        </label>
        <div className="mt-2">
          <input
            id="email"
            name="email"
            type="email"
            autoComplete="email"
            required
            value={formState.user.email}
            onChange={handleUserChange}
            className="block w-full rounded-md border-0 py-1.5 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
          />
        </div>
      </div>
      <div className="mt-2">
        <label
          htmlFor="phone-number"
          className="block text-sm font-medium leading-6 text-gray-900"
        >
          {t('generic.phoneNumber')}
          <span className="text-xs text-gray-500">
            {' '}
            ({t('generic.optional')})
          </span>
        </label>
        <div className="mt-2">
          <input
            id="phone-number"
            name="phone"
            type="tel"
            value={formState.user.phone}
            onChange={handleUserChange}
            className="block w-full rounded-md border-0 py-1.5 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
          />
        </div>
      </div>
      <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-5 sm:grid-cols-6">
        <div className="sm:col-span-3">
          <label
            htmlFor="password"
            className="block text-sm font-medium leading-6 text-gray-900"
          >
            {t('generic.password')}
          </label>
          <div className="mt-2">
            <input
              id="password"
              name="password"
              type="password"
              required
              value={formState.user.password}
              onChange={handleUserChange}
              className="block w-full rounded-md border-0 py-1.5 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
            />
          </div>
        </div>

        <div className="sm:col-span-3">
          <label
            htmlFor="confirm-password"
            className="block text-sm font-medium leading-6 text-gray-900"
          >
            {t('auth.signUpManager.confirmPassword')}
          </label>
          <div className="mt-2">
            <input
              id="confirm-password"
              name="confirmPassword"
              type="password"
              required
              value={formState.user.confirmPassword}
              onChange={handleUserChange}
              className="block w-full rounded-md border-0 py-1.5 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
            />
          </div>
        </div>
      </div>
      <div className="mt-2">
        <label
          htmlFor="source-origin"
          className="block text-sm font-medium leading-6 text-gray-900"
        >
          {t('auth.signUpManager.sourceOrigin')}
        </label>
        <select
          id="sourceOrigin"
          name="sourceOrigin"
          className="block w-full mt-2 rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-blue-600 sm:text-sm sm:leading-6"
          defaultValue={formState.user.sourceOrigin} // Set default value
          onChange={e =>
            dispatch({
              type: 'UPDATE_USER_FIELD',
              field: 'sourceOrigin',
              value: e.target.value,
            })
          }
        >
          <option value={undefined} aria-label="No source origin" />
          <option value={ClientSourcingOrigin.REFERRAL}>
            {t('auth.signUpManager.referral')}
          </option>
          <option value={ClientSourcingOrigin.BROWSER}>
            {t('auth.signUpManager.browser')}
          </option>
          <option value={ClientSourcingOrigin.OUTREACH}>
            {t('auth.signUpManager.outreach')}
          </option>
          <option value={ClientSourcingOrigin.HOSPITAL_MANAGEMENT}>
            {t('auth.signUpManager.management')}
          </option>
          <option value={ClientSourcingOrigin.SOCIAL_MEDIA}>
            {t('auth.signUpManager.social_media')}
          </option>
          <option value={ClientSourcingOrigin.OTHER}>
            {t('generic.other')}
          </option>
        </select>
      </div>

      <div className="flex items-center justify-between">
        <div className="flex items-center">
          <input
            id="privacy-policy"
            name="privacyPolicy"
            type="checkbox"
            className="h-4 w-4 rounded border-gray-300 text-blue-600 focus:ring-blue-600"
            checked={formState.user.has_agreed_to_privacy_policy}
            onChange={handleTogglePrivacyPolicy}
          />
          <label
            htmlFor="privacy-policy"
            className="ml-2 block text-sm text-gray-900"
          >
            {t('auth.signUpManager.privacyPolicyLabel')}{' '}
            <Link to="/privacy" className="text-blue-600">
              {t('auth.signUpManager.privacyPolicyLink')}
            </Link>
          </label>
        </div>
      </div>
    </div>
  );
}
