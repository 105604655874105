/* eslint-disable react/require-default-props */
import React from 'react';
import { Dialog } from '@headlessui/react';
import { XMarkIcon } from '@heroicons/react/24/outline';
import { useTranslation } from 'react-i18next';

import { YSButton } from './Buttons';

interface ModalProps {
  isOpen: boolean;
  onClose: () => void;
  children: React.ReactNode;
  size?: 'sm' | 'md' | 'lg' | 'xl';
  editButtons?: boolean;
  handleDelete?: () => void;
  handleSave?: () => void;
}

export default function Modal({
  isOpen,
  onClose,
  children,
  size = 'md',
  editButtons = false,
  handleDelete,
  handleSave,
}: ModalProps) {
  const sizeClasses = {
    sm: 'sm:max-w-sm',
    md: 'sm:max-w-md',
    lg: 'sm:max-w-lg',
    xl: 'sm:max-w-xl',
  };

  const { t } = useTranslation();

  return (
    <Dialog open={isOpen} onClose={onClose} className="relative z-50">
      <Dialog.Backdrop className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />

      <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
        <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
          <Dialog.Panel
            className={`relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full ${sizeClasses[size]} sm:p-6`}
          >
            <div className="absolute right-0 top-0 hidden pr-4 pt-4 sm:block">
              <button
                type="button"
                onClick={onClose}
                className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
              >
                <span className="sr-only">{t('generic.close')}</span>
                <XMarkIcon className="h-6 w-6" aria-hidden="true" />
              </button>
            </div>
            {children}
            {editButtons ? (
              <div className="flex justify-end space-x-2 mt-2">
                {handleDelete && (
                  <YSButton variant="delete" onClick={handleDelete}>
                    {t('generic.eliminate')}
                  </YSButton>
                )}
                <YSButton variant="ghost-secondary" onClick={onClose}>
                  {t('generic.cancel')}
                </YSButton>
                {handleSave && (
                  <YSButton variant="primary" onClick={handleSave}>
                    {t('generic.save')}
                  </YSButton>
                )}
              </div>
            ) : null}
          </Dialog.Panel>
        </div>
      </div>
    </Dialog>
  );
}
