/* eslint-disable react/require-default-props */
import React, { ReactNode } from 'react';
import { Link, LinkProps, RelativeRoutingType, To } from 'react-router-dom';

interface ButtonProps<T = undefined> {
  children: ReactNode;
  type?: 'button' | 'submit' | 'reset';
  onClick?: () => void | undefined;
  to?: To | undefined;
  variant?:
    | 'primary'
    | 'secondary'
    | 'outline-primary'
    | 'outline-secondary'
    | 'ghost-primary'
    | 'ghost-secondary'
    | 'delete';
  classNames?: string;
  disabled?: boolean;
  relative?: RelativeRoutingType | undefined;
  state?: T;
  loading?: boolean;
}

const getClassNames = (variant: string) => {
  switch (variant) {
    case 'primary':
      return 'flex items-center bg-blue-600 hover:bg-blue-700 text-white font-semibold py-2 px-4 rounded-md disabled:opacity-50 disabled:cursor-not-allowed';
    case 'secondary':
      return 'flex items-center bg-teal-600 hover:bg-teal-700 text-white font-semibold py-2 px-4 rounded-md disabled:opacity-50 disabled:cursor-not-allowed';
    case 'outline-primary':
      return 'flex items-center bg-transparent hover:bg-blue-600 text-blue-600 font-semibold hover:text-white py-2 px-4 border border-blue-600 hover:border-transparent rounded-md';
    case 'outline-secondary':
      return 'flex items-center bg-transparent hover:bg-teal-600 text-teal-600 font-semibold hover:text-white py-2 px-4 border border-teal-600 hover:border-transparent rounded-md';
    case 'ghost-primary':
      return 'flex items-center bg-transparent text-blue-600 font-semibold py-2 px-4 rounded-md';
    case 'ghost-secondary':
      return 'flex items-center bg-transparent text-teal-600 font-semibold py-2 px-4 rounded-md';
    case 'delete':
      return 'flex items-center bg-red-600 hover:bg-red-700 text-white font-semibold py-2 px-4 rounded-md disabled:opacity-50 disabled:cursor-not-allowed';
    default:
      return '';
  }
};

export function YSButton<T = undefined>({
  type = 'button',
  children,
  to = undefined,
  onClick = undefined,
  variant = 'primary',
  classNames,
  disabled = false,
  relative = 'path',
  state = undefined,
  loading = false,
}: ButtonProps<T>) {
  const className =
    (classNames ? `${classNames} ` : '') + getClassNames(variant);

  return to ? (
    <Link
      to={to}
      onClick={onClick}
      className={className}
      relative={relative}
      state={state}
    >
      {children}
    </Link>
  ) : (
    <button
      type={type}
      onClick={onClick}
      className={className}
      disabled={disabled || loading}
    >
      {loading && (
        <svg
          className="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
        >
          <circle
            className="opacity-25"
            cx="12"
            cy="12"
            r="10"
            stroke="currentColor"
            strokeWidth="4"
          />
          <path
            className="opacity-75"
            fill="currentColor"
            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
          />
        </svg>
      )}
      {children}
    </button>
  );
}
