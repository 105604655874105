import { useQueryClient } from '@tanstack/react-query';
import {
  useDeleteSectionMutation,
  useEditSectionMutation,
} from '@youshift/shared/hooks/mutations';
import { Section } from '@youshift/shared/types';
import { ColorName } from '@youshift/shared/utils';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import ColorPicker from '../ColorPicker';
import DeleteConfirmationModal from '../DeleteConfirmationModal';
import Modal from '../Modal';

interface EditSectionModalProps {
  isOpen: boolean;
  onClose: () => void;
  details: Section;
}

export default function EditSectionModal({
  isOpen,
  onClose,
  details,
}: EditSectionModalProps) {
  const {
    name: sectionName,
    acronym: sectionAcronym,
    color: sectionColor,
    id_itr,
    id_section,
  } = details;
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { t } = useTranslation();
  const [name, setName] = useState(sectionName);
  const [acronym, setAcronym] = useState(sectionAcronym);
  const [color, setColor] = useState<ColorName>(sectionColor);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);

  const editSectionMutation = useEditSectionMutation(queryClient, {
    onSuccess: () => {
      onClose();
      queryClient.invalidateQueries({
        queryKey: ['section', id_section.toString()],
      });
    },
  });

  const deleteSectionMutation = useDeleteSectionMutation(queryClient, {
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['sections', String(id_itr)],
      });
      onClose();
      navigate('../sections');
    },
  });

  const handleSave = () => {
    editSectionMutation.mutate({
      id_itr,
      id_section,
      name,
      acronym,
      color,
    });
  };

  const handleDeleteClick = () => {
    setShowDeleteConfirmation(true);
  };

  const handleConfirmDelete = () => {
    deleteSectionMutation.mutate({
      id_itr,
      id_section,
    });
  };

  return (
    <>
      <Modal
        isOpen={isOpen}
        onClose={onClose}
        size="md"
        editButtons
        handleDelete={handleDeleteClick}
        handleSave={handleSave}
      >
        <div className="space-y-4 mb-20">
          <h2 className="text-xl font-semibold">
            {t('manager.sectionsConfig.editSection')}
          </h2>
          <div>
            <label
              htmlFor="name"
              className="block text-sm font-medium text-gray-700"
            >
              {t('manager.sectionsConfig.name')}
            </label>
            <input
              id="name"
              type="text"
              value={name}
              onChange={e => setName(e.target.value)}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
            />
          </div>
          <div>
            <label
              htmlFor="acronym"
              className="block text-sm font-medium text-gray-700"
            >
              {t('manager.sectionsConfig.acronym')}
            </label>
            <input
              id="acronym"
              type="text"
              value={acronym}
              maxLength={5}
              onChange={e => setAcronym(e.target.value)}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
            />
          </div>
          <div>
            <label
              htmlFor="color"
              className="block text-sm font-medium text-gray-700"
            >
              {t('generic.color')}
            </label>
            <ColorPicker value={color} onChange={setColor} />
          </div>
        </div>
      </Modal>

      <DeleteConfirmationModal
        isOpen={showDeleteConfirmation}
        onClose={() => setShowDeleteConfirmation(false)}
        onConfirm={handleConfirmDelete}
        title={t('manager.sectionsConfig.deleteConfirmation')}
        description={t('manager.sectionsConfig.deleteConfirmationText', {
          name,
        })}
      />
    </>
  );
}
