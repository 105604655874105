"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.useChangePasswordMutation = exports.useCreateGroupWithOrgMutation = exports.useLoginMutation = exports.useCreateGroupMutation = exports.useConvertMockUserMutation = void 0;
var react_query_1 = require("@tanstack/react-query");
var request_1 = require("../../api/request");
var useConvertMockUserMutation = function (queryClient, options) {
    return (0, react_query_1.useMutation)(__assign({ mutationFn: function (params) {
            var token = params.token, data = __rest(params, ["token"]);
            return (0, request_1.request)({
                url: "manager/personnel/user/mock/convert/".concat(token),
                method: "post",
                data: data,
            });
        } }, options), queryClient);
};
exports.useConvertMockUserMutation = useConvertMockUserMutation;
var useCreateGroupMutation = function (queryClient, options) {
    return (0, react_query_1.useMutation)(__assign({ mutationFn: function (params) {
            return (0, request_1.request)({
                url: "manager/group/signup/new",
                method: "post",
                data: params,
            });
        } }, options), queryClient);
};
exports.useCreateGroupMutation = useCreateGroupMutation;
var useLoginMutation = function (queryClient, options) {
    return (0, react_query_1.useMutation)(__assign({ mutationFn: function (params) {
            return (0, request_1.request)({
                url: "/login",
                method: "post",
                data: params,
            });
        } }, options), queryClient);
};
exports.useLoginMutation = useLoginMutation;
var useCreateGroupWithOrgMutation = function (queryClient, options) {
    return (0, react_query_1.useMutation)(__assign({ mutationFn: function (params) {
            return (0, request_1.request)({
                url: "manager/group/signup/new_with_organization",
                method: "post",
                data: params,
            });
        } }, options), queryClient);
};
exports.useCreateGroupWithOrgMutation = useCreateGroupWithOrgMutation;
var useChangePasswordMutation = function (queryClient, options) {
    return (0, react_query_1.useMutation)(__assign({ mutationFn: function (params) {
            return (0, request_1.request)({
                url: "/change_password",
                method: "post",
                data: params,
            });
        } }, options), queryClient);
};
exports.useChangePasswordMutation = useChangePasswordMutation;
