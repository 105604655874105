import React from 'react';

function AcceptIcon({ status }) {
  // Determine the size based on the small prop

  let strokeColor;
  let transform;

  switch (status) {
    case 'pending_manager':
    case 'completed_right':
    case 'pending_right':
      strokeColor = status === 'pending_right' ? 'orange' : 'green';
      transform = 'rotate(180)';
      break;
    case 'completed_left':
    case 'pending_left':
      strokeColor = status === 'pending_left' ? 'orange' : 'green';
      break;
    case 'rejected_left':
    case 'rejected_right':
      strokeColor = 'red';
      transform = status === 'rejected_right' ? 'rotate(180)' : '';
      break;
    default:
      // Default to green right arrow
      strokeColor = 'green';
      transform = 'rotate(180)';
  }

  return (
    <svg
      width={75}
      height={24}
      viewBox="0 0 50 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="mx-auto"
      transform={transform}
    >
      <path
        d="M50 12.2444L38.8525 12.2444L0.999999 12.2444M0.999999 12.2444L16.19 23M0.999999 12.2444L16.19 1"
        stroke={strokeColor}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default AcceptIcon;
