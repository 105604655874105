import { VirtualSlot as VirtualSlotType } from '@youshift/shared/types';
import {
  ColorName,
  GRID_GRANULARITY,
  Spanning,
  dateToString,
  getDifferenceInColumns,
  getDifferenceInMinutes,
} from '@youshift/shared/utils';

import { ArrangedVirtualSlot } from './ManualAssignment/types';
import { VirtualSlot, VirtualSlotVirgueria } from './VirtualSlot';

interface VirtualSlotWeekProps {
  rowIndex: number;
  row: (VirtualSlotType & {
    spanning: Spanning;
  } & {
    start: Date;
    end: Date;
  })[];
  week: Date[];
  assignmentsByVirtualSlot?: {
    [virtualSlotId: string]: number;
  };
  isVirgueria: boolean;
  sectionColor: ColorName;
  onVirtualSlotClick?: (slotId: number) => void;
  selectedVirtualSlots?: Set<number>;
}

export function VirtualSlotWeek({
  rowIndex,
  row,
  week,
  assignmentsByVirtualSlot,
  isVirgueria,
  sectionColor,
  onVirtualSlotClick,
  selectedVirtualSlots,
}: VirtualSlotWeekProps) {
  const arrangedVirtualSlots: ArrangedVirtualSlot[] = row.map(slot => {
    const diffFromStart = getDifferenceInColumns(week[0], slot.start);
    const duration = getDifferenceInColumns(slot.start, slot.end);
    const { id_virtual_slot, min_need, max_need, spanning } = slot;
    return {
      start_position: diffFromStart === 0 ? diffFromStart + 1 : diffFromStart,
      end_position: diffFromStart + duration,
      start_time: dateToString(slot.start, 'hh:mm'),
      end_time: dateToString(slot.end, 'hh:mm'),
      duration_minutes: getDifferenceInMinutes(slot.start, slot.end),
      id_section_slots: slot.section_slots,
      id_virtual_slot,
      min_need,
      max_need,
      spanning,
    };
  });
  return (
    <div
      key={`${rowIndex}-slots`}
      className="grid relative"
      style={{
        gridTemplateColumns: `repeat(${GRID_GRANULARITY * 168}, 1fr)`,
      }}
    >
      {!isVirgueria && (
        <div className="flex flex-col text-xxxs absolute h-full items-center justify-center gap-0.5 z-10">
          <p>max</p>
          <p>min</p>
        </div>
      )}
      {arrangedVirtualSlots.map((arrangedVirtualSlot, idx) => {
        const nextIsContiguous =
          arrangedVirtualSlots[idx + 1]?.start_position ===
          arrangedVirtualSlot.end_position;
        const currentlyAssigned =
          assignmentsByVirtualSlot?.[arrangedVirtualSlot.id_virtual_slot] || 0;
        return isVirgueria ? (
          <VirtualSlotVirgueria
            slot={arrangedVirtualSlot}
            sectionColor={sectionColor}
            currentlyAssigned={currentlyAssigned}
            nextIsContiguous={nextIsContiguous}
          />
        ) : (
          <VirtualSlot
            slot={arrangedVirtualSlot}
            sectionColor={sectionColor}
            onVirtualSlotClick={onVirtualSlotClick}
            nextIsContiguous={nextIsContiguous}
            selected={
              selectedVirtualSlots?.has(arrangedVirtualSlot.id_virtual_slot) ||
              false
            }
          />
        );
      })}
    </div>
  );
}
