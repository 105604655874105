import { Disclosure } from '@headlessui/react';
import { ChevronUpIcon } from '@heroicons/react/20/solid';
import { QueryClient, useQueryClient } from '@tanstack/react-query';
import { preLoadQuery } from '@youshift/shared/hooks';
import {
  useExcludeItrUsersMutation,
  useIncludeItrUsersMutation,
} from '@youshift/shared/hooks/mutations';
import { itrUsersQuery, personnelQuery } from '@youshift/shared/hooks/queries';
import { User, UserRole } from '@youshift/shared/types';
import { generateErrorStringFromError } from '@youshift/shared/utils';
import { useEffect, useMemo, useState } from 'react';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { LoaderFunctionArgs, useLoaderData, useParams } from 'react-router-dom';

import { requireApproved, requireManager } from '../../../../utils/checks';
import { useItrContext } from '../../../../layouts/IterationRootLayout/IterationRootLayout';
import Wrapper from '../../../../components/Wrapper';

type RolesLoader = {
  itrUsers: number[];
  users: Record<number, User>;
  roles: Record<number, UserRole>;
};

export const itrUsersLoader =
  (queryClient: QueryClient) =>
  async ({ params }: LoaderFunctionArgs): Promise<RolesLoader | null> => {
    const user = await requireManager(queryClient);
    await requireApproved(user);
    if (params.idItr === undefined) {
      return null;
    }
    const itrUsers = Object.keys(
      await preLoadQuery(queryClient, itrUsersQuery(params.idItr)),
    ).map(Number);
    const personnel = await preLoadQuery(queryClient, personnelQuery());
    const { users, roles } = personnel;
    return { itrUsers, users, roles };
  };

export default function RolesConfig() {
  // TODO: review this
  // const context = useOutletContext();
  // const locked = context ? context[0] : false;
  const locked = true;

  const { roles, users, itrUsers } = useLoaderData() as RolesLoader;
  const { iteration } = useItrContext();
  const [localItrUsers, setLocalItrUsers] = useState<number[]>(itrUsers);
  useEffect(() => {
    setLocalItrUsers(itrUsers);
  }, [itrUsers]);

  const { t } = useTranslation();

  const { idItr } = useParams();

  const userIdsByRole = useMemo(
    () =>
      Object.values(users).reduce<Record<number, number[]>>((acc, user) => {
        if (user.id_user_role) {
          if (acc[user.id_user_role]) {
            acc[user.id_user_role].push(user.id);
          } else {
            acc[user.id_user_role] = [user.id];
          }
        }
        return acc;
      }, {}),
    [users],
  );
  const includedRoles = useMemo(
    () =>
      Object.keys(roles)
        .map(Number)
        .filter(id =>
          userIdsByRole[id]?.some(userId => localItrUsers.includes(userId)),
        ),
    [localItrUsers, roles, userIdsByRole],
  );

  const queryClient = useQueryClient();

  const includeUsersMutation = useIncludeItrUsersMutation(queryClient, {
    onMutate: ({ id_users }) => {
      setLocalItrUsers(prev => [...prev, ...id_users]);
    },
    onSuccess: () => {
      toast.success(t('generic.success'));
      queryClient.invalidateQueries({ queryKey: ['itrUsers', String(idItr)] });
    },
    onError: () => {
      // const { errorKey, context } = generateErrorStringFromError(
      //   error,
      //   t('manager.rolesConfig.errorInclude'),
      // );
      // const errorString = t(errorKey, context);
      // toast.error(errorString);
      queryClient.invalidateQueries({ queryKey: ['itrUsers', String(idItr)] });
    },
  });

  const excludeUsersMutation = useExcludeItrUsersMutation(queryClient, {
    onMutate: ({ id_users }) => {
      setLocalItrUsers(prev =>
        prev.filter(userId => !id_users.includes(userId)),
      );
    },
    onSuccess: () => {
      toast.success(t('generic.success'));
      queryClient.invalidateQueries({ queryKey: ['itrUsers', String(idItr)] });
    },
    onError: error => {
      const { errorKey, context } = generateErrorStringFromError(
        error,
        t('manager.rolesConfig.errorExclude'),
      );
      const errorString = t(errorKey, context);
      toast.error(errorString);
      queryClient.invalidateQueries({ queryKey: ['itrUsers', String(idItr)] });
    },
  });

  return (
    <Wrapper>
      <div className="inline-block min-w-full align-middle">
        <div className="relative">
          {Object.keys(roles).length > 0 ? (
            <>
              <p className="mb-6 text-gray-800 font-medium">
                {t('manager.rolesConfig.participantsAddRemove')}
              </p>
              {Object.values(roles).map(role => (
                <div className="flex flex-row justify-center" id="rolesTable">
                  <div className="relative px-4 sm:px-6">
                    {includedRoles.includes(role.id) && (
                      <div className="absolute inset-y-0 left-0 w-0.5 bg-blue-600" />
                    )}
                    <label className="relative inline-flex items-center cursor-pointer align-middle">
                      <input
                        type="checkbox"
                        className="sr-only peer"
                        checked={includedRoles.includes(role.id)}
                        onChange={e => {
                          if (!userIdsByRole[role.id]) {
                            toast.error(t('manager.rolesConfig.noUsers'));
                            return;
                          }
                          if (!iteration.locked) {
                            toast.error(
                              t('manager.iterationConfig.itrUnlocked'),
                            );
                            return;
                          }
                          if (e.target.checked) {
                            includeUsersMutation.mutate({
                              id_users: userIdsByRole[role.id],
                              id_itr: Number(idItr),
                            });
                          } else {
                            excludeUsersMutation.mutate({
                              id_users: userIdsByRole[role.id].filter(user =>
                                localItrUsers.includes(user),
                              ),
                              id_itr: Number(idItr),
                            });
                          }
                        }}
                        disabled={!locked}
                      />
                      <div className="w-9 h-5 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-4 after:w-4 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600" />
                    </label>
                  </div>
                  <Disclosure
                    as="div"
                    key={role.id}
                    className="mb-2 w-full"
                    defaultOpen={includedRoles.includes(role.id)}
                  >
                    {({ open, close }) => (
                      <>
                        <Disclosure.Button
                          disabled={!includedRoles.includes(role.id)}
                          className="flex w-full z-0 justify-between rounded-lg px-4 bg-blue-100 hover:bg-blue-200 disabled:hover:bg-blue-200 py-2 text-left text-sm font-medium text-blue-900 focus:outline-none focus-visible:ring focus-visible:ring-blue-500/75"
                        >
                          <div className="flex flex-row items-center gap-4">
                            <div>
                              <p className="font-medium">{role.name}</p>
                              {/* <p className="text-xs">
                                    {role.num_users
                                      ? `${role.num_users} usuarios`
                                      : null}
                                  </p> */}
                            </div>
                          </div>
                          <ChevronUpIcon
                            className={`${open ? 'rotate-180 transform' : ''} h-5 w-5 text-blue-500`}
                          />
                        </Disclosure.Button>
                        <Disclosure.Panel className="px-4 pb-2 pt-4 text-sm text-gray-500">
                          {includedRoles.includes(role.id) ? (
                            <div className="grid grid-cols-2">
                              {userIdsByRole[role.id]
                                ?.sort((a, b) => {
                                  const nameA =
                                    `${users[a].firstname} ${users[a].lastname}`.toLowerCase();
                                  const nameB =
                                    `${users[b].firstname} ${users[b].lastname}`.toLowerCase();
                                  return nameA.localeCompare(nameB);
                                })
                                .map(participantId => (
                                  <div className="flex flex-row my-2">
                                    <div className="relative px-4 sm:px-6">
                                      {localItrUsers.includes(
                                        participantId,
                                      ) && (
                                        <div className="absolute inset-y-0 left-0 w-0.5 bg-blue-600" />
                                      )}
                                      <label className="relative inline-flex items-center cursor-pointer align-middle">
                                        <input
                                          type="checkbox"
                                          className="sr-only peer"
                                          checked={localItrUsers.includes(
                                            participantId,
                                          )}
                                          onChange={e => {
                                            if (!iteration.locked) {
                                              toast.error(
                                                t(
                                                  'manager.iterationConfig.itrUnlocked',
                                                ),
                                              );
                                              return;
                                            }
                                            if (e.target.checked) {
                                              includeUsersMutation.mutate({
                                                id_users: [participantId],
                                                id_itr: Number(idItr),
                                              });
                                            } else {
                                              excludeUsersMutation.mutate({
                                                id_users: [participantId],
                                                id_itr: Number(idItr),
                                              });
                                            }
                                          }}
                                          disabled={!locked}
                                        />
                                        <div className="w-9 h-5 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-4 after:w-4 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600" />
                                      </label>
                                    </div>
                                    <p>{`${users[participantId].firstname} ${users[participantId].lastname}`}</p>
                                  </div>
                                ))}
                            </div>
                          ) : null}
                        </Disclosure.Panel>
                      </>
                    )}
                  </Disclosure>
                </div>
              ))}
            </>
          ) : (
            <p>{t('manager.rolesConfig.noRoles')}</p>
          )}
        </div>
      </div>
    </Wrapper>
  );
}
