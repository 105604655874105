import React from 'react';
import { MinusIcon, PlusIcon } from '@heroicons/react/24/outline';
import { useTranslation } from 'react-i18next';
import { UserRequirementType } from '@youshift/shared/types';
import { minutesToHours } from '@youshift/shared/utils';

import { UserRequirementRuleErrors } from '../../../utils/iteration_checks/assignment_checks/types';

type Props = {
  ruleErrors: UserRequirementRuleErrors[number];
  userFullNameMap: Record<number, string>;
  reqType?: UserRequirementType;
};

function UserReqTable({ ruleErrors, userFullNameMap, reqType }: Props) {
  const deficits = ruleErrors.user_req_deficit || {};
  const surpluses = ruleErrors.user_req_surplus || {};

  const deficitUsers = Object.entries(deficits);
  const surplusUsers = Object.entries(surpluses);

  const { t } = useTranslation();

  // If no deficits or surpluses, render nothing (or you could return null)
  if (deficitUsers.length === 0 && surplusUsers.length === 0) {
    return null;
  }

  const isHours = reqType === UserRequirementType.DURATION;

  return (
    <div className="">
      {/* Deficit Table */}
      {deficitUsers.length > 0 && (
        <div className="my-2">
          <div className="flex flex-row items-center">
            <MinusIcon className="text-white font-bold w-5 h-5 rounded-full p-0.5 bg-rose-500" />
            <h3 className="text-base font-semibold p-2">
              {isHours
                ? t('manager.assignmentErrors.user_req_deficit_title_hours')
                : t('manager.assignmentErrors.user_req_deficit_title')}
            </h3>
          </div>
          <div className=" bg-white shadow-lg rounded-lg overflow-hidden">
            <div className="overflow-x-auto">
              <table className="w-full">
                <thead>
                  <tr className="bg-gray-50 border-b border-gray-200 text-xs font-medium text-gray-500 uppercase tracking-wider">
                    <th className="px-3 py-1.5 text-left">
                      {t('generic.name')}
                    </th>
                    <th className="px-3 py-1.5 text-center">
                      {t('manager.assignmentErrors.has')}
                    </th>
                    <th className="px-3 py-1.5 text-center">
                      {t('generic.min')}
                    </th>
                    <th className="px-3 py-1.5 text-center">
                      {t('manager.assignmentErrors.missing')}
                    </th>
                  </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200">
                  {deficitUsers.map(([userId, { assigned_units, min_req }]) => {
                    const minReq = isHours ? minutesToHours(min_req) : min_req;
                    const assignedUnits = isHours
                      ? minutesToHours(assigned_units)
                      : assigned_units;
                    const missing = minReq - assignedUnits;
                    return (
                      <tr key={userId} className="hover:bg-gray-50">
                        <td className="px-2 py-1 whitespace-nowrap text-sm font-medium text-gray-900">
                          {userFullNameMap[Number(userId)]}
                        </td>
                        <td className="px-2 py-1 whitespace-nowrap text-sm text-gray-500 text-center">
                          {assignedUnits}
                        </td>
                        <td className="px-2 py-1 whitespace-nowrap text-sm text-gray-500 text-center">
                          {minReq}
                        </td>
                        <td className="px-2 py-1 whitespace-nowrap text-sm text-red-500 text-center font-semibold">
                          {missing}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      )}

      {/* Surplus Table */}
      {surplusUsers.length > 0 && (
        <div className="">
          <div className="flex flex-row items-center">
            <PlusIcon className="text-white font-bold w-5 h-5 rounded-full p-0.5 bg-yellow-500" />
            <h3 className="text-base font-semibold p-2">
              {isHours
                ? t('manager.assignmentErrors.user_req_surplus_title_hours')
                : t('manager.assignmentErrors.user_req_surplus_title')}
            </h3>
          </div>{' '}
          <div className="mb-6 bg-white shadow-lg rounded-lg overflow-hidden">
            <div className="overflow-x-auto">
              <table className="w-full">
                <thead>
                  <tr className="bg-gray-50 border-b border-gray-200 text-xs font-medium text-gray-500 uppercase tracking-wider">
                    <th className="px-3 py-1.5 text-left">
                      {t('generic.name')}
                    </th>
                    <th className="px-3 py-1.5 text-center">
                      {t('manager.assignmentErrors.has')}
                    </th>
                    <th className="px-3 py-1.5 text-center">
                      {t('generic.max')}
                    </th>
                    <th className="px-3 py-1.5 text-center">
                      {t('manager.assignmentErrors.excess')}
                    </th>
                  </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200">
                  {surplusUsers.map(([userId, { assigned_units, max_req }]) => {
                    const assignedUnits = isHours
                      ? minutesToHours(assigned_units)
                      : assigned_units;
                    const maxReq = isHours ? minutesToHours(max_req) : max_req;
                    const excess = assignedUnits - maxReq;
                    return (
                      <tr key={userId} className="hover:bg-gray-50">
                        <td className="px-2 py-1whitespace-nowrap text-sm font-medium text-gray-900">
                          {userFullNameMap[Number(userId)]}
                        </td>
                        <td className="px-2 py-1whitespace-nowrap text-sm text-gray-500 text-center">
                          {assignedUnits}
                        </td>
                        <td className="px-2 py-1whitespace-nowrap text-sm text-gray-500 text-center">
                          {maxReq}
                        </td>
                        <td className="px-2 py-1whitespace-nowrap text-sm text-red-500 text-center font-semibold">
                          {excess}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default UserReqTable;
