"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.useAssignmentToConfigMutation = exports.useInterruptAlgMutation = exports.useRunAlgMutation = exports.useLockMutation = exports.useUnlockMutation = exports.useConfigToAssignmentMutation = exports.useInitToConfigMutation = exports.useEditMessageMutation = exports.useUnarchiveItrMutation = exports.useArchiveItrMutation = exports.useUnpublishResultsMutation = exports.usePublishResultsMutation = void 0;
var react_query_1 = require("@tanstack/react-query");
var api_1 = require("../../api");
var usePublishResultsMutation = function (queryClient, options) {
    return (0, react_query_1.useMutation)(__assign({ mutationFn: function (_a) {
            var id_itr = _a.id_itr, send_users_notification = _a.send_users_notification;
            return (0, api_1.request)({
                url: "/manager/itrs/".concat(id_itr, "/flow/publish"),
                method: "post",
                data: { send_users_notification: send_users_notification },
            });
        } }, options), queryClient);
};
exports.usePublishResultsMutation = usePublishResultsMutation;
var useUnpublishResultsMutation = function (queryClient, options) {
    return (0, react_query_1.useMutation)(__assign({ mutationFn: function (_a) {
            var id_itr = _a.id_itr;
            return (0, api_1.request)({
                url: "/manager/itrs/".concat(id_itr, "/flow/unpublish"),
                method: "post",
            });
        } }, options), queryClient);
};
exports.useUnpublishResultsMutation = useUnpublishResultsMutation;
var useArchiveItrMutation = function (queryClient, options) {
    return (0, react_query_1.useMutation)(__assign({ mutationFn: function (_a) {
            var id_itr = _a.id_itr;
            return (0, api_1.request)({
                url: "/manager/itrs/".concat(id_itr, "/flow/close"),
                method: "post",
            });
        } }, options), queryClient);
};
exports.useArchiveItrMutation = useArchiveItrMutation;
var useUnarchiveItrMutation = function (queryClient, options) {
    return (0, react_query_1.useMutation)(__assign({ mutationFn: function (_a) {
            var id_itr = _a.id_itr;
            return (0, api_1.request)({
                url: "/manager/itrs/".concat(id_itr, "/flow/unarchive"),
                method: "post",
            });
        } }, options), queryClient);
};
exports.useUnarchiveItrMutation = useUnarchiveItrMutation;
var useEditMessageMutation = function (queryClient, options) {
    return (0, react_query_1.useMutation)(__assign({ mutationFn: function (_a) {
            var id_itr = _a.id_itr, message = _a.message;
            return (0, api_1.request)({
                url: "/manager/itrs/".concat(id_itr, "/edit_message"),
                method: "patch",
                data: { message: message },
            });
        } }, options), queryClient);
};
exports.useEditMessageMutation = useEditMessageMutation;
var useInitToConfigMutation = function (queryClient, options) {
    return (0, react_query_1.useMutation)(__assign({ mutationFn: function (_a) {
            var id_itr = _a.id_itr;
            return (0, api_1.request)({
                url: "/manager/itrs/".concat(id_itr, "/flow/init_to_config"),
                method: "post",
            });
        } }, options), queryClient);
};
exports.useInitToConfigMutation = useInitToConfigMutation;
var useConfigToAssignmentMutation = function (queryClient, options) {
    return (0, react_query_1.useMutation)(__assign({ mutationFn: function (_a) {
            var id_itr = _a.id_itr;
            return (0, api_1.request)({
                url: "/manager/itrs/".concat(id_itr, "/flow/config_to_assignment"),
                method: "post",
            });
        } }, options), queryClient);
};
exports.useConfigToAssignmentMutation = useConfigToAssignmentMutation;
var useUnlockMutation = function (queryClient, options) {
    return (0, react_query_1.useMutation)(__assign({ mutationFn: function (_a) {
            var id_itr = _a.id_itr, _b = _a.send_users_notification, send_users_notification = _b === void 0 ? false : _b;
            return (0, api_1.request)({
                url: "/manager/itrs/".concat(id_itr, "/flow/unlock"),
                method: "post",
                data: { send_users_notification: send_users_notification },
            });
        } }, options), queryClient);
};
exports.useUnlockMutation = useUnlockMutation;
var useLockMutation = function (queryClient, options) {
    return (0, react_query_1.useMutation)(__assign({ mutationFn: function (_a) {
            var id_itr = _a.id_itr;
            return (0, api_1.request)({
                url: "/manager/itrs/".concat(id_itr, "/flow/lock"),
                method: "post",
            });
        } }, options), queryClient);
};
exports.useLockMutation = useLockMutation;
var useRunAlgMutation = function (queryClient, options) {
    return (0, react_query_1.useMutation)(__assign({ mutationFn: function (_a) {
            var id_itr = _a.id_itr;
            return (0, api_1.request)({
                url: "/manager/itrs/".concat(id_itr, "/flow/run_alg"),
                method: "post",
            });
        } }, options), queryClient);
};
exports.useRunAlgMutation = useRunAlgMutation;
var useInterruptAlgMutation = function (queryClient, options) {
    return (0, react_query_1.useMutation)(__assign({ mutationFn: function (_a) {
            var id_itr = _a.id_itr;
            return (0, api_1.request)({
                url: "/manager/itrs/".concat(id_itr, "/flow/interrupt_alg"),
                method: "post",
            });
        } }, options), queryClient);
};
exports.useInterruptAlgMutation = useInterruptAlgMutation;
var useAssignmentToConfigMutation = function (queryClient, options) {
    return (0, react_query_1.useMutation)(__assign({ mutationFn: function (_a) {
            var id_itr = _a.id_itr;
            return (0, api_1.request)({
                url: "/manager/itrs/".concat(id_itr, "/flow/assignment_to_config"),
                method: "post",
            });
        } }, options), queryClient);
};
exports.useAssignmentToConfigMutation = useAssignmentToConfigMutation;
