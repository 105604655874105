import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

interface SignUpSuccessfulProps {
  h1: string;
  h2: string;
  h3?: string;
}

export default function SignUpSuccessful({
  h1,
  h2,
  h3,
}: SignUpSuccessfulProps) {
  const { t } = useTranslation();

  return (
    <main className="grid min-h-full place-items-center bg-white px-6 py-24 sm:py-32 lg:px-8">
      <div className="text-center">
        <img
          className="h-24 w-auto my-0 mx-auto"
          src="https://youshift-docs.s3.eu-west-3.amazonaws.com/youshift_logotype.svg"
        />
        <p className="text-base font-semibold text-green-600">{h1}</p>
        <h1 className="mt-4 text-3xl font-bold tracking-tight text-gray-900 sm:text-5xl">
          {h2}
        </h1>
        {h3 && <p className="mt-6 text-base leading-7 text-gray-600">{h3}</p>}
        <div className="mt-10 flex items-center justify-center gap-x-6">
          <Link
            to="/"
            className="rounded-md bg-blue-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
          >
            {t('generic.goHome')}
          </Link>
          <Link to="/contact" className="text-sm font-semibold text-gray-900">
            {t('generic.contactUs')}
            <span aria-hidden="true">&rarr;</span>
          </Link>
        </div>
      </div>
    </main>
  );
}
