import { SlotLabel } from '@youshift/shared/types';
import { ColorName, returnColor } from '@youshift/shared/utils';

import LabelIconComponent from '../LabelIconComponent';

export default function ShiftLabelLegend({
  labels,
  sectionColor,
  onLabelClick,
}: {
  labels: SlotLabel[];
  sectionColor?: ColorName;
  onLabelClick?: (labelId: number) => void;
}) {
  return (
    <div className="flex flex-row gap-2">
      {labels
        .sort((a, b) => a.name.localeCompare(b.name))
        .map(label => (
          <button
            key={label.id_slot_label}
            className={`inline-flex items-center gap-x-1.5 rounded-full px-2 py-1 text-xs font-medium text-gray-900 ring-1 ring-inset ring-gray-200 ${
              onLabelClick ? 'cursor-pointer hover:bg-gray-100' : ''
            }`}
            onClick={() => onLabelClick?.(label.id_slot_label)}
            style={{
              backgroundColor: sectionColor
                ? returnColor(sectionColor, label.shade)
                : undefined,
            }}
            type="button"
          >
            <LabelIconComponent icon={label.icon} className="w-5 h-5 p-0.5" />
            {label.name}
          </button>
        ))}
    </div>
  );
}
