type TableProps = {
  headers: string[];
  rows: (string | number)[][];
};

function Table({ headers, rows }: TableProps) {
  return (
    <table className="w-full">
      <thead>
        <tr className="bg-gray-50 border-b border-gray-200 text-xs font-medium text-gray-500 uppercase tracking-wider">
          {headers.map(header => (
            <th
              key={header}
              className="px-3 py-1.5 text-center whitespace-normal"
            >
              {header}
            </th>
          ))}
        </tr>
      </thead>
      <tbody className="bg-white divide-y divide-gray-200">
        {rows.map((row, rowIndex) => (
          <tr className="hover:bg-gray-50" key={rowIndex}>
            {row.map((cell, cellIdx) => (
              <td
                key={cellIdx}
                className={`px-2 py-1 whitespace-nowrap text-center text-sm ${
                  cellIdx === 0
                    ? 'font-medium text-gray-900'
                    : 'text-gray-500 text-center'
                }`}
              >
                {cell}
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  );
}

export default Table;
