import { Dialog, Listbox, Switch, Transition } from '@headlessui/react';
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/20/solid';
import { PlusCircleIcon, XMarkIcon } from '@heroicons/react/24/outline';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { request } from '@youshift/shared/api';
import { usePostAnnouncementMutation } from '@youshift/shared/hooks/mutations';
import { SectionSlot } from '@youshift/shared/types';
import { generateErrorStringFromError } from '@youshift/shared/utils';
import { Fragment, useState } from 'react';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';

import SectionSlotChip from '../../../components/Calendars/SectionSlotChip';
import { YSButton } from '../../../components/Buttons';

interface Shift {
  id: number;
  text: string;
}

interface SwitchGroupProps {
  label: string;
  enabled: boolean;
  setEnabled: (value: boolean) => void;
}

function SwitchGroup({ label, enabled, setEnabled }: SwitchGroupProps) {
  return (
    <div className="flex items-center justify-start">
      <p className="text-gray-600 mr-2">{label}</p>
      <Switch
        checked={enabled}
        onChange={setEnabled}
        className={`${enabled ? 'bg-blue-600' : 'bg-gray-200'} relative inline-flex h-6 w-11 items-center rounded-full`}
      >
        <span
          aria-hidden="true"
          className={`${enabled ? 'translate-x-6' : 'translate-x-1'} inline-block h-4 w-4 transform rounded-full bg-white transition`}
        />
      </Switch>
    </div>
  );
}

interface PostAnnouncementProps {
  open: boolean;
  setOpen: (open: boolean) => void;
  selectedAssignment: number;
  sectionSlot: SectionSlot;
  sectionName: string;
}

export default function PostAnnouncement({
  open,
  setOpen,
  selectedAssignment,
  sectionSlot,
  sectionName,
}: PostAnnouncementProps) {
  const [justification, setJustification] = useState<string>('');
  const [acceptTrades, setAcceptTrades] = useState<boolean>(true);
  const [acceptAccepts, setAcceptAccepts] = useState<boolean>(true);

  const handleAcceptTradesChange = (newValue: boolean) => {
    if (!newValue && !acceptAccepts) {
      // If trying to disable acceptTrades while acceptAccepts is already disabled,
      // enable acceptAccepts and disable acceptTrades.
      setAcceptAccepts(true);
      setAcceptTrades(false);
    } else {
      // Otherwise, just set acceptTrades to the new value.
      setAcceptTrades(newValue);
    }
  };

  const handleAcceptAcceptsChange = (newValue: boolean) => {
    if (!newValue && !acceptTrades) {
      // If trying to disable acceptAccepts while acceptTrades is already disabled,
      // enable acceptTrades and disable acceptAccepts.
      setAcceptTrades(true);
      setAcceptAccepts(false);
    } else {
      // Otherwise, just set acceptAccepts to the new value.
      setAcceptAccepts(newValue);
    }
  };

  const queryClient = useQueryClient();

  const { t } = useTranslation();

  const postAnnouncement = usePostAnnouncementMutation(queryClient, {
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['userExchangeDashboard'] });
      toast.success(t('user.shiftExchange.successPostAnnouncement'));
      setOpen(false);
    },
  });

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={setOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                <div className="absolute right-0 top-0 hidden pr-4 pt-4 sm:block">
                  <button
                    type="button"
                    className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                    onClick={() => setOpen(false)}
                  >
                    <span className="sr-only">Close</span>
                    <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>
                <div className="sm:flex sm:items-center">
                  <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-blue-100 sm:mx-0 sm:h-10 sm:w-10">
                    <PlusCircleIcon
                      className="h-6 w-6 text-blue-600"
                      aria-hidden="true"
                    />
                  </div>
                  <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                    <Dialog.Title
                      as="h3"
                      className="text-base font-semibold leading-6 text-gray-900"
                    >
                      {t('user.shiftExchange.postAnnouncement')}
                    </Dialog.Title>
                  </div>
                </div>
                <div className="mt-2 gap-6">
                  <h2 className="font-semibold">
                    {t('user.shiftExchange.selectedShift')}:
                  </h2>
                  <SectionSlotChip
                    sectionName={sectionName}
                    start={sectionSlot.start}
                    end={sectionSlot.end}
                  />
                </div>
                <div className="mt-2">
                  <h2 className="font-semibold">
                    {t('generic.justification')}
                  </h2>
                  <p>
                    {t('user.shiftExchange.onlySeenManager')}
                    {` (${t('generic.optional')})`}
                  </p>
                  <div className="mt-2.5">
                    <textarea
                      name="justification"
                      id="justification"
                      rows={4}
                      value={justification}
                      onChange={e => setJustification(e.target.value)}
                      className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                    />
                  </div>
                </div>
                <div className="flex flex-col gap-2 py-4 justify-end items-end">
                  <SwitchGroup
                    label={t('user.shiftExchange.acceptTrades')}
                    enabled={acceptTrades}
                    setEnabled={handleAcceptTradesChange}
                  />
                  <SwitchGroup
                    label={t('user.shiftExchange.acceptAccepts')}
                    enabled={acceptAccepts}
                    setEnabled={handleAcceptAcceptsChange}
                  />
                </div>
                <div className="mt-5 sm:mt-4 flex gap-4">
                  <YSButton
                    onClick={() =>
                      postAnnouncement.mutate({
                        id_shift_assignment: selectedAssignment,
                        reason: justification,
                        allow_one_for_one: acceptTrades,
                        allow_one_for_zero: acceptAccepts,
                      })
                    }
                  >
                    {t('generic.publish')}
                  </YSButton>
                  <YSButton
                    onClick={() => setOpen(false)}
                    variant="ghost-primary"
                  >
                    {t('generic.cancel')}
                  </YSButton>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
