import {
  SectionSlot,
  ShiftAssignment,
  SpecialEvent,
  UserPreference,
  UserReqRule,
  UserRequirement,
  IncompatibilityRules,
  VirtualSlot,
  UserRequirementType,
  User,
  RuleTypes,
  SectionWithSlots,
} from '@youshift/shared/types';

import {
  Epa,
  AssignmentsMap,
} from '../../../layouts/IterationRootLayout/types';
import {
  ConfigurationCheckErrorType,
  ConfigurationCheckErrors,
  UserErrors,
  SingleUserReqRuleDurationDeficitErrorContext,
  SingleUserReqRuleSlotsDeficitErrorContext,
  UserSupplyDeficitErrorContext,
  VirtualSlotSupplyDemandErrorContext,
  UserReqRuleErrors,
  SectionUserReqRuleSlotsDeficitErrorContext,
} from './types';
import {
  CrossGroupIncompPartakingGroup,
  checkUserIsBlockedOnSectionSlot,
  checkUserIsBlockedOnVirtualSlot,
  computePreassignedShiftsOnVirtualSlot,
  getCrossGroupIncompRuleAssignedUserGroupsToVirtualSlot,
  getMaxAssignableNonOverlappingSlots,
} from './configuration_checks_utils';
import { isFullSectionUserReqRule, isSlotUserReqRule } from '../utils';

export const checkVirtualSlotSupplyDemandDeficit = (
  virtualSlot: VirtualSlot,
  assignmentsMap: AssignmentsMap,
  allUserReqs: Record<number, UserReqRule>,
  incompatibilities: IncompatibilityRules,
  sectionSlotsDict: Record<number, SectionSlot>,
  overlappingSlots: Record<number, number[]>,
  allUsers: Record<number, User>,
  epa: Epa,
):
  | {
      type: ConfigurationCheckErrorType.VIRTUAL_SLOT_SUPPLY_DEMAND_DEFICIT;
      context: VirtualSlotSupplyDemandErrorContext;
    }
  | false => {
  const minDemand = virtualSlot.min_need;

  // Get users preassigned to this virtual slot
  const usersPreassignedOnVirtualSlot = computePreassignedShiftsOnVirtualSlot(
    virtualSlot,
    assignmentsMap.byUser,
  );

  // Find related user requirement rules
  const relatedRules: number[] = [];
  Object.entries(allUserReqs).forEach(([idRule, userReqRule]) => {
    if (
      virtualSlot.section_slots.some(idSlot =>
        userReqRule.section_slots.includes(idSlot),
      )
    ) {
      relatedRules.push(Number(idRule));
    }
  });

  // Find unavailable workers. This means discarding users that have maxed out on assignments outside virtual slot.
  const unavailableWorkers = new Set<number>();
  relatedRules.forEach(idRule => {
    const userReqRule = allUserReqs[idRule];

    Object.entries(userReqRule.user_reqs).forEach(([idUser, userReq]) => {
      const userId = Number(idUser);
      const userAssignments = assignmentsMap.byUser[userId] || [];

      // Check if user is maxed out on assignments outside virtual slot
      const assignmentsOutsideVirtualSlot = userAssignments.filter(
        assignment =>
          userReqRule.section_slots.includes(assignment.id_section_slot) &&
          !virtualSlot.section_slots.includes(assignment.id_section_slot),
      );

      // Check if user is maxed out on assignments outside virtual slot.
      // This is only relevant for SLOT configured rules
      if (userReq.req_type === UserRequirementType.SLOTS && userReq.max_slots) {
        if (assignmentsOutsideVirtualSlot.length >= userReq.max_slots) {
          unavailableWorkers.add(userId);
        }
      }
    });
  });

  // Handle cross group incompatibilities
  Object.values(incompatibilities.cross_group_incomp).forEach(
    crossGroupIncompRule => {
      const assignedGroups =
        getCrossGroupIncompRuleAssignedUserGroupsToVirtualSlot(
          crossGroupIncompRule,
          virtualSlot,
          overlappingSlots,
          assignmentsMap,
        );

      // Remove incompatible users
      if (assignedGroups[CrossGroupIncompPartakingGroup.GROUP_1].size > 0) {
        crossGroupIncompRule.user_group_secondary.forEach(userId =>
          unavailableWorkers.add(userId),
        );
      }
      if (assignedGroups[CrossGroupIncompPartakingGroup.GROUP_2].size > 0) {
        crossGroupIncompRule.user_group.forEach(userId =>
          unavailableWorkers.add(userId),
        );
      }
    },
  );

  // Handle users blocked on virtual slot
  Object.values(allUsers).forEach(user => {
    const isBlocked = checkUserIsBlockedOnVirtualSlot(
      virtualSlot,
      epa.assignmentsMap.byUser[user.id] || [],
      epa.preferencesMap.byUser[user.id] || [],
      epa.eventsMap.byUser[user.id] || [],
      sectionSlotsDict,
    );

    if (isBlocked) {
      unavailableWorkers.add(user.id);
    }
  });
  // TODO: Handle single group incompatibility rules

  const availableWorkers = Object.values(allUsers).filter(
    user => !unavailableWorkers.has(user.id),
  );

  const remainingMinDemand = minDemand - usersPreassignedOnVirtualSlot.length;
  if (availableWorkers.length < remainingMinDemand) {
    return {
      type: ConfigurationCheckErrorType.VIRTUAL_SLOT_SUPPLY_DEMAND_DEFICIT,
      context: {
        min_demand: minDemand,
        available_workers: availableWorkers.map(user => user.id),
        users_preassigned_OS_on_virtual_slot: usersPreassignedOnVirtualSlot.map(
          assignment => assignment.id_user,
        ),
      },
    };
  }

  return false;
};

/**
 * For a given worker, checks if they have enough free slots on their user requirement rules
 * to fulfill all minimum slot requirements.
 *
 * This check is only done for UserRequirementType.SLOTS rules because:
 */
export const checkSingleUserReqRuleSlotsDeficit = (
  user: User,
  userReqRule: UserReqRule,
  userReq: UserRequirement,
  sectionSlotsDict: Record<number, SectionSlot>,
  userEvents: SpecialEvent[],
  userShiftAssignments: ShiftAssignment[],
  userPreferences: UserPreference[],
  incompatibilities: IncompatibilityRules,
  overlappingSlots: Record<number, number[]>,
  assignmentsMap: AssignmentsMap,
):
  | false
  | {
      type: ConfigurationCheckErrorType.SINGLE_USER_REQ_RULE_SLOTS_DEFICIT;
      context: SingleUserReqRuleSlotsDeficitErrorContext;
    } => {
  if (userReq.req_type !== UserRequirementType.SLOTS || !userReq.min_slots) {
    return false;
  }
  // Get slots for this user on this rule that are not blocked
  const availableSlots: number[] = userReqRule.section_slots.filter(slotId => {
    const slot = sectionSlotsDict[slotId];
    const isBlocked = checkUserIsBlockedOnSectionSlot(
      user,
      slot,
      userEvents,
      userShiftAssignments,
      userPreferences,
      incompatibilities,
      sectionSlotsDict,
      overlappingSlots,
      assignmentsMap,
    );
    return !isBlocked;
  });

  // Get already assigned slots for this user on this rule
  const alreadyAssignedSlots = (userShiftAssignments || []).filter(
    assignment =>
      userReqRule.section_slots.includes(assignment.id_section_slot) &&
      user.id === assignment.id_user,
  );

  // Check if there are enough available slots to assign to this user on this rule
  if (availableSlots.length < userReq.min_slots - alreadyAssignedSlots.length) {
    return {
      type: ConfigurationCheckErrorType.SINGLE_USER_REQ_RULE_SLOTS_DEFICIT,
      context: {
        min_slots: userReq.min_slots,
        available_slots: availableSlots,
        already_assigned_slots: alreadyAssignedSlots.map(
          assignment => assignment.id_section_slot,
        ),
      },
    };
  }

  return false;
};

/**
 * For a given worker, checks if they have enough free slots on their user requirement rules
 * to fulfill all minimum slot requirements.
 *
 * This check is only done for UserRequirementType.DURATION rules because:
 */
export const checkSingleUserReqRuleDurationDeficit = (
  user: User,
  userReqRule: UserReqRule,
  userReq: UserRequirement,
  sectionSlotsDict: Record<number, SectionSlot>,
  userEvents: SpecialEvent[],
  userShiftAssignments: ShiftAssignment[],
  userPreferences: UserPreference[],
  incompatibilities: IncompatibilityRules,
  overlappingSlots: Record<number, number[]>,
  assignmentsMap: AssignmentsMap,
):
  | false
  | {
      type: ConfigurationCheckErrorType.SINGLE_USER_REQ_RULE_DURATION_DEFICIT;
      context: SingleUserReqRuleDurationDeficitErrorContext;
    } => {
  if (
    userReq.req_type !== UserRequirementType.DURATION ||
    !userReq.min_duration
  ) {
    return false;
  }
  // Get slots for this user on this rule that are not blocked
  const availableSlots: number[] = userReqRule.section_slots.filter(slotId => {
    const slot = sectionSlotsDict[slotId];
    const isBlocked = checkUserIsBlockedOnSectionSlot(
      user,
      slot,
      userEvents,
      userShiftAssignments,
      userPreferences,
      incompatibilities,
      sectionSlotsDict,
      overlappingSlots,
      assignmentsMap,
    );
    return !isBlocked;
  });

  // In minutes
  const availableDuration = availableSlots.reduce(
    (acc, slotId) =>
      acc +
      (new Date(sectionSlotsDict[slotId].end).getTime() -
        new Date(sectionSlotsDict[slotId].start).getTime()) /
        (1000 * 60),
    0,
  );

  // Get already assigned slots for this user on this rule
  const alreadyAssignedSlots = (userShiftAssignments || []).filter(
    assignment =>
      userReqRule.section_slots.includes(assignment.id_section_slot) &&
      user.id === assignment.id_user,
  );

  // In minutes
  const alreadyAssignedDuration = alreadyAssignedSlots.reduce(
    (acc, assignment) =>
      acc +
      (new Date(sectionSlotsDict[assignment.id_section_slot].end).getTime() -
        new Date(
          sectionSlotsDict[assignment.id_section_slot].start,
        ).getTime()) /
        (1000 * 60),
    0,
  );

  // Check if there are enough available duration to assign to this user on this rule
  if (availableDuration < userReq.min_duration - alreadyAssignedDuration) {
    return {
      type: ConfigurationCheckErrorType.SINGLE_USER_REQ_RULE_DURATION_DEFICIT,
      context: {
        min_duration: userReq.min_duration,
        available_duration: availableDuration,
        already_assigned_duration: alreadyAssignedDuration,
      },
    };
  }

  return false;
};

/**
 * For a given worker, checks if they have enough free slots on their user requirement rules
 * to fulfill all minimum requirements.
 *
 * This check is only done for SECTION_USER_REQS rules because:
 * - Only these rules contain all section slots for all virtual slots
 * - Section slot domains are disjoint for each section rule
 *
 * Checks that the user has enough free slots for all their section requirements.
 * This must take into account the "effective" number of slots that the user can be assigned
 * to rather than the actual min_slots defined in the UserShiftRequirement. This is because
 * SectionSlots overlap across different Sections and within a section which means that they
 * cannot be assigned to at the same time.
 *
 * @param user - The user to check requirements for
 * @param userShiftAssignments - Array of shift assignments for the user
 * @param userPreferences - Array of user preferences
 * @param userEvents - Array of special events for the user
 * @param userReqs - Map of user requirement rules
 * @param sectionSlotsDict - Map of section slots by ID
 * @param incompatibilities - Map of incompatibility rules between users/groups
 * @param overlappingSlots - Map of section slot IDs to arrays of overlapping slot IDs
 * @param assignmentsMap - Map containing shift assignments organized by user and section slot
 * @returns False if check passes, or error object with deficit details if check fails
 */
export const checkUserSupplyDeficit = (
  user: User,
  userShiftAssignments: ShiftAssignment[],
  userPreferences: UserPreference[],
  userEvents: SpecialEvent[],
  userReqs: Record<number, UserReqRule>,
  sectionSlotsDict: Record<number, SectionSlot>,
  incompatibilities: IncompatibilityRules,
  overlappingSlots: Record<number, number[]>,
  assignmentsMap: AssignmentsMap,
):
  | false
  | {
      type: ConfigurationCheckErrorType.USER_SUPPLY_DEFICIT;
      context: UserSupplyDeficitErrorContext;
    } => {
  let minConfiguredSupply = 0;
  const assignableSectionSlots: SectionSlot[] = [];

  // Calculate total minimum slots required across all section user requirements
  Object.entries(userReqs).forEach(([idRule, userReqRule]) => {
    if (!Object.keys(userReqRule.user_reqs).includes(String(user.id))) {
      return;
    }
    const userShiftReq = userReqRule.user_reqs[user.id];

    if (
      userReqRule.rule.type === RuleTypes.SECTION_USER_REQS &&
      userShiftReq.req_type == UserRequirementType.SLOTS &&
      userShiftReq.min_slots
    ) {
      minConfiguredSupply += userShiftReq.min_slots;

      // Check which section slots are assignable
      userReqRule.section_slots.forEach(idSectionSlot => {
        const sectionSlot = sectionSlotsDict[idSectionSlot];
        const isBlocked = checkUserIsBlockedOnSectionSlot(
          user,
          sectionSlot,
          userEvents,
          userShiftAssignments,
          userPreferences,
          incompatibilities,
          sectionSlotsDict,
          overlappingSlots,
          assignmentsMap,
        );

        if (!isBlocked) {
          assignableSectionSlots.push(sectionSlot);
        }
      });
    }
  });

  const maxAssignableSlots = getMaxAssignableNonOverlappingSlots(
    assignableSectionSlots,
    /* accountForRestPeriod= */ true,
  );

  if (maxAssignableSlots.length < minConfiguredSupply) {
    const assignableSectionSlotIds = assignableSectionSlots.map(
      slot => slot.id_section_slot,
    );
    return {
      type: ConfigurationCheckErrorType.USER_SUPPLY_DEFICIT,
      context: {
        min_configured_supply: minConfiguredSupply,
        max_assignable_slots: maxAssignableSlots.length,
        assignable_section_slots: assignableSectionSlotIds,
      },
    };
  }

  return false;
};

/**
 * For a given user requirement rule, checks if the combination of all users have enough free slots
 * to fulfill all minimum requirements.
 *
 * total min needs = sum(min_need for all virtual slots in the section for this rule)
 * total max needs = sum(max_need for all virtual slots in the section for this rule)
 *
 */
export const checkSlotsSectionUserReqRuleSupplyDeficit = (
  userReqRule: UserReqRule,
  allUserReqs: Record<number, UserReqRule>,
  epa: Epa,
  incompatibilities: IncompatibilityRules,
  sectionSlotsDict: Record<number, SectionSlot>,
  overlappingSlots: Record<number, number[]>,
  allVirtualSlots: VirtualSlot[],
  allUsers: Record<number, User>,
):
  | false
  | {
      type: ConfigurationCheckErrorType.SECTION_USER_REQ_RULE_SLOTS_DEFICIT;
      context: SectionUserReqRuleSlotsDeficitErrorContext;
    } => {
  // Check if there are enough free slots for all users on this rule
  // Get all virtual slots for this rule's section
  const sectionVirtualSlots = allVirtualSlots.filter(
    virtualSlot => virtualSlot.id_section === userReqRule.rule.id_section,
  );

  // Sum up min_needs across all virtual slots in the section
  const totalSectionMinNeeds = sectionVirtualSlots.reduce(
    (sum, virtualSlot) => sum + virtualSlot.min_need,
    0,
  );

  let maxInRuleUsersSupply = 0;
  let unboundedUsers = 0;
  Object.entries(allUsers).forEach(([idUser, user]) => {
    const userId = Number(idUser);

    // Get user requirement for this user if they are in the rule
    const userReq = userReqRule.user_reqs[userId];
    // If user is not in the rule, it is unbounded
    if (!userReq) {
      unboundedUsers++;
    }

    // For each user in the rule, calculate their max possible contribution
    const userAssignments = epa.assignmentsMap.byUser[user.id] || [];
    const userPreferences = epa.preferencesMap.byUser[user.id] || [];
    const userEvents = epa.eventsMap.byUser[user.id] || [];

    // Compute max assignable slots for this user
    // Loop through all slots in the rule and check if user is assignable
    const assignableSlots = userReqRule.section_slots.filter(idSlot => {
      const sectionSlot = sectionSlotsDict[idSlot];
      // Check if user is blocked on this slot due to incompatibilities

      const isBlocked = checkUserIsBlockedOnSectionSlot(
        user,
        sectionSlot,
        userEvents,
        userAssignments,
        userPreferences,
        incompatibilities,
        sectionSlotsDict,
        overlappingSlots,
        epa.assignmentsMap,
      );

      return !isBlocked;
    });

    const maxAssignableSlots = getMaxAssignableNonOverlappingSlots(
      assignableSlots.map(id => sectionSlotsDict[id]),
      /* accountForRestPeriod= */ true,
    );

    // TODO(): account for User min requirements in other Full Section User Requirement
    // Rules to reduce the number of slots available to this user.
    // This will be an approximation because we don't know which users will be assigned to which slots.

    // Compute effective max supply
    const effectiveMaxSupply = maxAssignableSlots.length;

    maxInRuleUsersSupply += effectiveMaxSupply;
  });

  if (maxInRuleUsersSupply < totalSectionMinNeeds) {
    return {
      type: ConfigurationCheckErrorType.SECTION_USER_REQ_RULE_SLOTS_DEFICIT,
      context: {
        min_needs: totalSectionMinNeeds,
        max_supply: maxInRuleUsersSupply,
        num_users_in_rule: Object.keys(userReqRule.user_reqs).length,
        num_unbounded_users: unboundedUsers,
      },
    };
  }

  return false;
};

export function configurationChecks(
  allUsers: Record<number, User>,
  allUserReqs: Record<number, UserReqRule>,
  epa: Epa,
  incompatibilities: IncompatibilityRules,
  sectionSlotsDict: Record<number, SectionSlot>,
  overlappingSlots: Record<number, number[]>,
  allVirtualSlots: VirtualSlot[],
): ConfigurationCheckErrors {
  const errors: ConfigurationCheckErrors = {
    users: {},
    virtual_slots: {},
    user_req_rules: {},
  };

  Object.entries(allUsers).forEach(([id_user_str, user]) => {
    const id_user: number = Number(id_user_str);
    const user_supply_demand_error = checkUserSupplyDeficit(
      user,
      epa.assignmentsMap.byUser[id_user],
      epa.preferencesMap.byUser[id_user],
      epa.eventsMap.byUser[id_user],
      allUserReqs,
      sectionSlotsDict,
      incompatibilities,
      overlappingSlots,
      epa.assignmentsMap,
    );

    if (user_supply_demand_error) {
      if (!errors.users[id_user]) {
        errors.users[id_user] = {} as UserErrors;
      }
      errors.users[id_user].user_supply_demand =
        user_supply_demand_error.context;
    }

    // User Requirement Rules checks.
    Object.entries(allUserReqs).forEach(([id_req_rule_str, req_rule]) => {
      const id_req_rule: number = Number(id_req_rule_str);
      const user_requirement = req_rule.user_reqs[id_user];
      // Skip if user is not included in this requirement rule
      if (!user_requirement) {
        return;
      }
      if (user_requirement.req_type === UserRequirementType.SLOTS) {
        const user_req_rule_slots_deficit_error =
          checkSingleUserReqRuleSlotsDeficit(
            user,
            req_rule,
            user_requirement,
            sectionSlotsDict,
            epa.eventsMap.byUser[id_user],
            epa.assignmentsMap.byUser[id_user],
            epa.preferencesMap.byUser[id_user],
            incompatibilities,
            overlappingSlots,
            epa.assignmentsMap,
          );
        if (user_req_rule_slots_deficit_error) {
          if (!errors.users[id_user]) {
            errors.users[id_user] = {} as UserErrors;
          }
          if (!errors.users[id_user].user_req_rules) {
            errors.users[id_user].user_req_rules = {};
          }
          const userReqRules = errors.users[id_user].user_req_rules!;
          if (!userReqRules[id_req_rule]) {
            userReqRules[id_req_rule] = {};
          }
          userReqRules[id_req_rule].single_user_req_rule_slots_deficit =
            user_req_rule_slots_deficit_error.context;
        }
      }

      if (user_requirement.req_type === UserRequirementType.DURATION) {
        const user_req_rule_duration_deficit_error =
          checkSingleUserReqRuleDurationDeficit(
            user,
            req_rule,
            user_requirement,
            sectionSlotsDict,
            epa.eventsMap.byUser[id_user] || [],
            epa.assignmentsMap.byUser[id_user] || [],
            epa.preferencesMap.byUser[id_user] || [],
            incompatibilities,
            overlappingSlots,
            epa.assignmentsMap,
          );

        if (user_req_rule_duration_deficit_error) {
          if (!errors.users[id_user]) {
            errors.users[id_user] = {} as UserErrors;
          }
          if (!errors.users[id_user].user_req_rules) {
            errors.users[id_user].user_req_rules = {};
          }
          const userReqRules = errors.users[id_user].user_req_rules!;
          if (!userReqRules[id_req_rule]) {
            userReqRules[id_req_rule] = {};
          }
          userReqRules[id_req_rule].single_user_req_rule_duration_deficit =
            user_req_rule_duration_deficit_error.context;
        }
      }
    });
  });

  // User Requirement Rules checks.
  Object.entries(allUserReqs)
    // Only check SLOT user requirement rules
    .filter(([id_req_rule_str, req_rule]) => isSlotUserReqRule(req_rule))
    // Only check user requirement rules that are SECTION_USER_REQS. (other wise VirtualSlot demand cannot be checked)
    .filter(([id_req_rule_str, req_rule]) =>
      isFullSectionUserReqRule(req_rule, sectionSlotsDict),
    )
    .forEach(([id_req_rule_str, req_rule]) => {
      const id_req_rule: number = Number(id_req_rule_str);
      const user_req_rule_supply_deficit_error =
        checkSlotsSectionUserReqRuleSupplyDeficit(
          req_rule,
          allUserReqs,
          epa,
          incompatibilities,
          sectionSlotsDict,
          overlappingSlots,
          allVirtualSlots,
          allUsers,
        );
      if (user_req_rule_supply_deficit_error) {
        if (!errors.user_req_rules[id_req_rule]) {
          errors.user_req_rules[id_req_rule] = {} as UserReqRuleErrors;
        }
        errors.user_req_rules[id_req_rule][
          user_req_rule_supply_deficit_error.type
        ] = user_req_rule_supply_deficit_error.context;
      }
    });

  // VirtualSlots.
  allVirtualSlots.forEach(virtualSlot => {
    const virtual_slot_error = checkVirtualSlotSupplyDemandDeficit(
      virtualSlot,
      epa.assignmentsMap,
      allUserReqs,
      incompatibilities,
      sectionSlotsDict,
      overlappingSlots,
      allUsers,
      epa,
    );

    if (virtual_slot_error) {
      if (!errors.virtual_slots[virtualSlot.id_virtual_slot]) {
        errors.virtual_slots[virtualSlot.id_virtual_slot] = {};
      }
      errors.virtual_slots[virtualSlot.id_virtual_slot][
        virtual_slot_error.type
      ] = virtual_slot_error.context;
    }
  });

  return errors;
}
