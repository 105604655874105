import { Disclosure } from '@headlessui/react';
import {
  CalendarIcon,
  ClockIcon,
  FunnelIcon,
} from '@heroicons/react/24/outline';
import { useTranslation } from 'react-i18next';
import {
  getFirstDayOfWeek,
  getWeekdayInitials,
  localeNormalizer,
  returnColor,
  dateToString,
  ColorName,
} from '@youshift/shared/utils';
import { SlotLabel, VirtualSlot, SectionSlot } from '@youshift/shared/types';
import { useMemo, useState } from 'react';

import { YSButton } from '../Buttons';
import LabelIconComponent from '../LabelIconComponent';
import i18n from '../../utils/i18n';

interface SmartSelectorProps {
  virtualSlots: Record<number, VirtualSlot>;
  sectionSlots: Record<number, SectionSlot>;
  filteredShiftLabels: SlotLabel[];
  sectionColor: ColorName;
  setSelectedVirtualSlots: (slots: Set<number>) => void;
  setSelectedSectionSlots: (slots: Set<number>) => void;
  selectedVirtualSlots: Set<number>;
  selectedSectionSlots: Set<number>;
}

export default function SmartSelector({
  virtualSlots,
  sectionSlots,
  filteredShiftLabels,
  sectionColor,
  setSelectedVirtualSlots,
  setSelectedSectionSlots,
  selectedVirtualSlots,
  selectedSectionSlots,
}: SmartSelectorProps) {
  const { t } = useTranslation();
  const [activeTab, setActiveTab] = useState<'sectionSlots' | 'virtualSlots'>(
    'sectionSlots',
  );

  const locale = localeNormalizer(i18n.language);

  const daysOfWeek = getWeekdayInitials(t, locale);

  const [smartSelectorSelectedLabels, setSmartSelectorSelectedLabels] =
    useState<Set<number>>(new Set());
  const [smartSelectorSelectedTimeFrames, setSmartSelectorSelectedTimeFrames] =
    useState<Set<string>>(new Set());
  const [smartSelectorSelectedDaysOfWeek, setSmartSelectorSelectedDaysOfWeek] =
    useState<Set<number>>(new Set(Object.keys(daysOfWeek).map(Number)));

  const virtualSlotsUniqueTimeFrames = useMemo(() => {
    const timeFrames: Set<string> = new Set();
    Object.values(virtualSlots).forEach(virtualSlot =>
      timeFrames.add(
        `${dateToString(virtualSlot.start, 'hh:mm')} - ${dateToString(virtualSlot.end, 'hh:mm')}`,
      ),
    );
    return Array.from(timeFrames);
  }, [virtualSlots]);

  const handleSmartVirtualSlotsSelector = () => {
    setSelectedVirtualSlots(
      new Set(
        Object.keys(virtualSlots)
          .filter(virtualSlotId => {
            const virtualSlot = virtualSlots[Number(virtualSlotId)];
            const virtualSlotStart = new Date(virtualSlot.start);
            const virtualSlotDayOfWeek = virtualSlotStart.getUTCDay();
            const isVirtualSlotInSelectedDaysOfWeek =
              smartSelectorSelectedDaysOfWeek.has(virtualSlotDayOfWeek);
            const isVirtualSlotInSelectedTimeFrames =
              smartSelectorSelectedTimeFrames.has(
                `${dateToString(virtualSlot.start, 'hh:mm')} - ${dateToString(virtualSlot.end, 'hh:mm')}`,
              );
            return (
              isVirtualSlotInSelectedDaysOfWeek &&
              isVirtualSlotInSelectedTimeFrames
            );
          })
          .map(Number),
      ),
    );
    setSmartSelectorSelectedTimeFrames(new Set());
  };

  const handleSmartSectionSlotsSelector = () => {
    setSelectedSectionSlots(
      new Set(
        Object.keys(sectionSlots)
          .filter(sectionSlotId =>
            smartSelectorSelectedLabels.has(
              sectionSlots[Number(sectionSlotId)].id_slot_label,
            ),
          )
          .map(Number),
      ),
    );
    setSmartSelectorSelectedLabels(new Set());
  };

  return (
    <Disclosure
      as="section"
      aria-labelledby="filter-heading"
      className="flex flex-col justify-center border rounded-md p-1 shadow-sm border-gray-200 my-2"
    >
      <div className="relative py-2">
        <div className="mx-auto flex justify-between divide-gray-200 px-4 text-sm">
          <div className="mr-3 pr-3 border-r border-gray-200">
            <Disclosure.Button className="group flex items-center font-medium text-gray-700">
              <FunnelIcon
                aria-hidden="true"
                className="mr-2 h-5 w-5 flex-none text-gray-400 group-hover:text-gray-500"
              />
              {t('manager.sectionsConfig.smartSelector')}
            </Disclosure.Button>
          </div>
          <div className="flex flex-row gap-2">
            {selectedVirtualSlots.size === 0 ? (
              <button
                type="button"
                className="flex flex-row gap-2 text-gray-500"
                onClick={() =>
                  setSelectedVirtualSlots(
                    new Set(Object.keys(virtualSlots).map(Number)),
                  )
                }
              >
                <ClockIcon className="w-5 h-5 text-orange-600" />
                {t('manager.sectionsConfig.selectAllVirtualSlots')}
              </button>
            ) : (
              <button
                type="button"
                className="flex flex-row gap-2 text-gray-500"
                onClick={() => setSelectedVirtualSlots(new Set())}
              >
                <ClockIcon className="w-5 h-5 text-orange-600" />
                {t('manager.sectionsConfig.deselectAllVirtualSlots')}
              </button>
            )}
            {selectedSectionSlots.size === 0 ? (
              <button
                type="button"
                className="flex flex-row gap-2 text-gray-500"
                onClick={() =>
                  setSelectedSectionSlots(
                    new Set(Object.keys(sectionSlots).map(Number)),
                  )
                }
              >
                <CalendarIcon className="w-5 h-5 text-purple-600" />
                {t('manager.sectionsConfig.selectAllSectionSlots')}
              </button>
            ) : (
              <button
                type="button"
                className="flex flex-row gap-2 text-gray-500"
                onClick={() => setSelectedSectionSlots(new Set())}
              >
                <CalendarIcon className="w-5 h-5 text-purple-600" />
                {t('manager.sectionsConfig.deselectAllSectionSlots')}
              </button>
            )}
          </div>
        </div>
      </div>

      <Disclosure.Panel className="border-t border-gray-200 p-2">
        <div className="flex flex-row gap-2">
          <div className="grid grid-cols-2 mb-4 gap-2">
            <button
              onClick={() => setActiveTab('sectionSlots')}
              className={`tab-button ${activeTab === 'sectionSlots' ? 'active' : ''} px-2 py-1 border-b-2 font-semibold text-sm ${
                activeTab === 'sectionSlots'
                  ? 'border-blue-500'
                  : 'border-gray-200'
              }`}
            >
              {t('generic.sectionSlots')}
            </button>
            <button
              onClick={() => setActiveTab('virtualSlots')}
              className={`tab-button ${activeTab === 'virtualSlots' ? 'active' : ''} px-2 py-1 border-b-2 font-semibold text-sm ${
                activeTab === 'virtualSlots'
                  ? 'border-blue-500'
                  : 'border-gray-200'
              }`}
            >
              {t('generic.virtualSlots')}
            </button>
          </div>
        </div>

        <div className="flex flex-row gap-1">
          {activeTab === 'sectionSlots' && (
            <div>
              {filteredShiftLabels
                .sort((a, b) => a.name.localeCompare(b.name))
                .map(label => (
                  <span
                    key={label.id_slot_label}
                    className="inline-flex items-center gap-x-1.5 rounded-full px-2 py-1 text-xs font-medium text-gray-900 ring-1 ring-inset ring-gray-200"
                    style={{
                      backgroundColor: sectionColor
                        ? returnColor(sectionColor, label.shade)
                        : undefined,
                    }}
                  >
                    <input
                      type="checkbox"
                      checked={smartSelectorSelectedLabels.has(
                        label.id_slot_label,
                      )}
                      onChange={() => {
                        setSmartSelectorSelectedLabels(prev => {
                          const newSet = new Set(prev);
                          if (prev.has(label.id_slot_label)) {
                            newSet.delete(label.id_slot_label);
                          } else {
                            newSet.add(label.id_slot_label);
                          }
                          return newSet;
                        });
                      }}
                      className="h-4 w-4 rounded border-gray-300 text-blue-600 focus:ring-blue-500"
                    />
                    <LabelIconComponent
                      icon={label.icon}
                      className="w-5 h-5 p-0.5"
                    />
                    {label.name}
                  </span>
                ))}
              <YSButton
                onClick={handleSmartSectionSlotsSelector}
                classNames="mt-3"
              >
                {t('manager.sectionsConfig.selectSectionSlots')}
              </YSButton>
            </div>
          )}
        </div>

        <div className="flex flex-row gap-1">
          {activeTab === 'virtualSlots' && (
            <div>
              <label className="block text-gray-700 mb-1">
                {t('manager.sectionsConfig.selectDaysOfWeek')}
              </label>
              <div className="flex space-x-2">
                {daysOfWeek.map((day, index) => (
                  <span
                    key={index}
                    className="inline-flex items-center gap-x-1.5 rounded-full px-2 py-1 text-xs font-medium text-gray-900 ring-1 ring-inset ring-gray-200"
                  >
                    <input
                      type="checkbox"
                      checked={smartSelectorSelectedDaysOfWeek.has(
                        getFirstDayOfWeek(locale) === 1
                          ? (index + 1) % 7
                          : index,
                      )}
                      onChange={() =>
                        setSmartSelectorSelectedDaysOfWeek(prev => {
                          const newSet = new Set(prev);
                          const normalizedIndex =
                            getFirstDayOfWeek(locale) === 1
                              ? (index + 1) % 7
                              : index;
                          if (prev.has(normalizedIndex)) {
                            newSet.delete(normalizedIndex);
                          } else {
                            newSet.add(normalizedIndex);
                          }
                          return newSet;
                        })
                      }
                      className="h-4 w-4 rounded border-gray-300 text-blue-600 focus:ring-blue-500"
                    />
                    {day}
                  </span>
                ))}
              </div>
              <label className="block text-gray-700 mb-1 mt-2">
                {t('manager.sectionsConfig.selectTimeFrames')}
              </label>
              <div className="flex space-x-2">
                {virtualSlotsUniqueTimeFrames.map(timeFrame => (
                  <span
                    key={timeFrame}
                    className="inline-flex items-center gap-x-1.5 rounded-full px-2 py-1 text-xs font-medium text-gray-900 ring-1 ring-inset ring-gray-200"
                  >
                    <input
                      type="checkbox"
                      checked={smartSelectorSelectedTimeFrames.has(timeFrame)}
                      onChange={() => {
                        setSmartSelectorSelectedTimeFrames(prev => {
                          const newSet = new Set(prev);
                          if (prev.has(timeFrame)) {
                            newSet.delete(timeFrame);
                          } else {
                            newSet.add(timeFrame);
                          }
                          return newSet;
                        });
                      }}
                      className="h-4 w-4 rounded border-gray-300 text-blue-600 focus:ring-blue-500"
                    />
                    {timeFrame}
                  </span>
                ))}
              </div>
              <YSButton
                classNames="mt-3"
                onClick={handleSmartVirtualSlotsSelector}
              >
                {t('manager.sectionsConfig.selectVirtualSlots')}
              </YSButton>
            </div>
          )}
        </div>
      </Disclosure.Panel>
    </Disclosure>
  );
}
