import { PlusIcon } from '@heroicons/react/24/outline';

type EmptyStateProps = {
  onClick?: () => void;
  title: string;
  subtitle?: string;
  buttonText?: string;
  Icon: React.FC<React.SVGProps<SVGSVGElement>>;
};

export function EmptyState({
  onClick,
  title,
  subtitle,
  buttonText,
  Icon,
}: EmptyStateProps) {
  return (
    <div className="text-center mt-24 mx-auto">
      <Icon className="mx-auto h-12 w-12 text-gray-400" />
      <h3 className="mt-2 text-sm font-semibold text-gray-900">{title}</h3>
      {subtitle && (
        <p className="text-sm text-gray-500 text-whitespace max-w-lg my-4 mx-auto align-center">
          {subtitle}
        </p>
      )}
      {onClick && (
        <div className="mt-6">
          <button
            type="button"
            className="inline-flex items-center rounded-md bg-blue-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
            onClick={onClick}
          >
            <PlusIcon className="-ml-0.5 mr-1.5 h-5 w-5" aria-hidden="true" />
            {buttonText}
          </button>
        </div>
      )}
    </div>
  );
}
