"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.passwordRegex = exports.GRID_GRANULARITY = exports.LabelIcons = exports.returnColor = exports.colorNames = exports.colorIndices = exports.bgColorRGBA = exports.textColorRGBA = exports.colorPalette = void 0;
exports.colorPalette = {
    teal: {
        50: "rgba(240, 253, 250, 0.7)",
        100: "rgba(204, 251, 241, 0.7)",
        200: "rgba(153, 246, 228, 0.7)",
        300: "rgba(94, 234, 212, 0.7)",
        400: "rgba(45, 212, 191, 0.7)",
        500: "rgba(20, 184, 166, 1)",
        600: "rgba(13, 148, 136, 0.7)",
        700: "rgba(15, 118, 110, 0.7)",
        800: "rgba(17, 94, 89, 0.7)",
        900: "rgba(19, 78, 74, 0.7)",
        950: "rgba(4, 47, 46, 0.7)",
    },
    cyan: {
        50: "rgba(236, 254, 255, 0.7)",
        100: "rgba(207, 250, 254, 0.7)",
        200: "rgba(165, 243, 252, 0.7)",
        300: "rgba(103, 232, 249, 0.7)",
        400: "rgba(34, 211, 238, 0.7)",
        500: "rgba(6, 182, 212, 1)",
        600: "rgba(8, 145, 178, 0.7)",
        700: "rgba(14, 116, 144, 0.7)",
        800: "rgba(21, 94, 117, 0.7)",
        900: "rgba(22, 78, 99, 0.7)",
        950: "rgba(8, 51, 68, 0.7)",
    },
    turquoise: {
        50: "rgba(230, 248, 248, 0.7)",
        100: "rgba(199, 238, 238, 0.7)",
        200: "rgba(161, 229, 229, 0.7)",
        300: "rgba(123, 221, 221, 0.7)",
        400: "rgba(85, 213, 213, 0.7)",
        500: "rgba(47, 204, 204, 1)",
        600: "rgba(39, 175, 175, 0.7)",
        700: "rgba(31, 145, 145, 0.7)",
        800: "rgba(25, 116, 116, 0.7)",
        900: "rgba(18, 87, 87, 0.7)",
    },
    sky: {
        50: "rgba(240, 249, 255, 0.7)",
        100: "rgba(224, 242, 254, 0.7)",
        200: "rgba(186, 230, 253, 0.7)",
        300: "rgba(125, 211, 252, 0.7)",
        400: "rgba(56, 189, 248, 0.7)",
        500: "rgba(14, 165, 233, 1)",
        600: "rgba(2, 132, 199, 0.7)",
        700: "rgba(3, 105, 161, 0.7)",
        800: "rgba(7, 89, 133, 0.7)",
        900: "rgba(12, 74, 110, 0.7)",
        950: "rgba(8, 47, 73, 0.7)",
    },
    blue: {
        50: "rgba(239, 246, 255, 0.7)",
        100: "rgba(219, 234, 254, 0.7)",
        200: "rgba(191, 219, 254, 0.7)",
        300: "rgba(147, 197, 253, 0.7)",
        400: "rgba(96, 165, 250, 0.7)",
        500: "rgba(59, 130, 246, 1)",
        600: "rgba(37, 99, 235, 0.7)",
        700: "rgba(29, 78, 216, 0.7)",
        800: "rgba(30, 64, 175, 0.7)",
        900: "rgba(30, 58, 138, 0.7)",
        950: "rgba(23, 37, 84, 0.7)",
    },
    lime: {
        50: "rgba(247, 254, 231, 0.7)",
        100: "rgba(236, 252, 203, 0.7)",
        200: "rgba(217, 249, 157, 0.7)",
        300: "rgba(190, 242, 100, 0.7)",
        400: "rgba(163, 230, 53, 0.7)",
        500: "rgba(132, 204, 22, 1)",
        600: "rgba(101, 163, 13, 0.7)",
        700: "rgba(77, 124, 15, 0.7)",
        800: "rgba(63, 98, 18, 0.7)",
        900: "rgba(54, 83, 20, 0.7)",
    },
    green: {
        50: "rgba(240, 253, 244, 0.7)",
        100: "rgba(220, 252, 231, 0.7)",
        200: "rgba(187, 247, 208, 0.7)",
        300: "rgba(134, 239, 172, 0.7)",
        400: "rgba(74, 222, 128, 0.7)",
        500: "rgba(34, 197, 94, 1)",
        600: "rgba(22, 163, 74, 0.7)",
        700: "rgba(21, 128, 61, 0.7)",
        800: "rgba(22, 101, 52, 0.7)",
        900: "rgba(20, 83, 45, 0.7)",
    },
    emerald: {
        50: "rgba(236, 253, 245, 0.7)",
        100: "rgba(209, 250, 229, 0.7)",
        200: "rgba(167, 243, 208, 0.7)",
        300: "rgba(110, 231, 183, 0.7)",
        400: "rgba(52, 211, 153, 0.7)",
        500: "rgba(16, 185, 129, 1)",
        600: "rgba(5, 150, 105, 0.7)",
        700: "rgba(4, 120, 87, 0.7)",
        800: "rgba(6, 95, 70, 0.7)",
        900: "rgba(6, 78, 59, 0.7)",
    },
    indigo: {
        50: "rgba(238, 242, 255, 0.7)",
        100: "rgba(224, 231, 255, 0.7)",
        200: "rgba(199, 210, 254, 0.7)",
        300: "rgba(165, 180, 252, 0.7)",
        400: "rgba(129, 140, 248, 0.7)",
        500: "rgba(99, 102, 241, 1)",
        600: "rgba(79, 70, 229, 0.7)",
        700: "rgba(67, 56, 202, 0.7)",
        800: "rgba(55, 48, 163, 0.7)",
        900: "rgba(49, 46, 129, 0.7)",
        950: "rgba(30, 27, 75, 0.7)",
    },
    violet: {
        50: "rgba(245, 243, 255, 0.7)",
        100: "rgba(237, 233, 254, 0.7)",
        200: "rgba(221, 214, 254, 0.7)",
        300: "rgba(196, 181, 253, 0.7)",
        400: "rgba(167, 139, 250, 0.7)",
        500: "rgba(139, 92, 246, 1)",
        600: "rgba(124, 58, 237, 0.7)",
        700: "rgba(109, 40, 217, 0.7)",
        800: "rgba(91, 33, 182, 0.7)",
        900: "rgba(76, 29, 149, 0.7)",
        950: "rgba(46, 16, 101, 0.7)",
    },
    purple: {
        50: "rgba(250, 245, 255, 0.7)",
        100: "rgba(243, 232, 255, 0.7)",
        200: "rgba(233, 213, 255, 0.7)",
        300: "rgba(216, 180, 254, 0.7)",
        400: "rgba(192, 132, 252, 0.7)",
        500: "rgba(168, 85, 247, 1)",
        600: "rgba(147, 51, 234, 0.7)",
        700: "rgba(126, 34, 206, 0.7)",
        800: "rgba(107, 33, 168, 0.7)",
        900: "rgba(88, 28, 135, 0.7)",
        950: "rgba(59, 7, 100, 0.7)",
    },
    fuchsia: {
        50: "rgba(253, 244, 255, 0.7)",
        100: "rgba(250, 232, 255, 0.7)",
        200: "rgba(245, 208, 254, 0.7)",
        300: "rgba(240, 171, 252, 0.7)",
        400: "rgba(232, 121, 249, 0.7)",
        500: "rgba(217, 70, 239, 1)",
        600: "rgba(192, 38, 211, 0.7)",
        700: "rgba(162, 28, 175, 0.7)",
        800: "rgba(134, 25, 143, 0.7)",
        900: "rgba(112, 26, 117, 0.7)",
        950: "rgba(74, 4, 78, 0.7)",
    },
    pink: {
        50: "rgba(253, 242, 248, 0.7)",
        100: "rgba(252, 231, 243, 0.7)",
        200: "rgba(251, 207, 232, 0.7)",
        300: "rgba(249, 168, 212, 0.7)",
        400: "rgba(244, 114, 182, 0.7)",
        500: "rgba(236, 72, 153, 1)",
        600: "rgba(219, 39, 119, 0.7)",
        700: "rgba(190, 24, 93, 0.7)",
        800: "rgba(157, 23, 77, 0.7)",
        900: "rgba(131, 24, 67, 0.7)",
        950: "rgba(80, 7, 36, 0.7)",
    },
    rose: {
        50: "rgba(255, 241, 242, 0.7)",
        100: "rgba(255, 228, 230, 0.7)",
        200: "rgba(254, 205, 211, 0.7)",
        300: "rgba(253, 164, 175, 0.7)",
        400: "rgba(251, 113, 133, 0.7)",
        500: "rgba(244, 63, 94, 1)",
        600: "rgba(225, 29, 72, 0.7)",
        700: "rgba(190, 18, 60, 0.7)",
        800: "rgba(159, 18, 57, 0.7)",
        900: "rgba(136, 19, 55, 0.7)",
        950: "rgba(76, 5, 25, 0.7)",
    },
    yellow: {
        50: "rgba(254, 248, 227, 0.7)",
        100: "rgba(253, 239, 181, 0.7)",
        200: "rgba(251, 227, 136, 0.7)",
        300: "rgba(248, 214, 89, 0.7)",
        400: "rgba(245, 200, 43, 0.7)",
        500: "rgba(243, 185, 0, 1)",
        600: "rgba(219, 159, 0, 0.7)",
        700: "rgba(184, 130, 0, 0.7)",
        800: "rgba(148, 102, 0, 0.7)",
        900: "rgba(112, 74, 0, 0.7)",
    },
    orange: {
        50: "rgba(255, 243, 227, 0.7)",
        100: "rgba(255, 226, 181, 0.7)",
        200: "rgba(255, 195, 136, 0.7)",
        300: "rgba(255, 156, 89, 0.7)",
        400: "rgba(255, 117, 26, 0.7)",
        500: "rgba(255, 84, 0, 1)",
        600: "rgba(219, 71, 0, 0.7)",
        700: "rgba(184, 58, 0, 0.7)",
        800: "rgba(148, 45, 0, 0.7)",
        900: "rgba(112, 31, 0, 0.7)",
    },
    red: {
        50: "rgba(255, 227, 227, 0.7)",
        100: "rgba(255, 189, 189, 0.7)",
        200: "rgba(255, 157, 157, 0.7)",
        300: "rgba(255, 112, 112, 0.7)",
        400: "rgba(255, 71, 71, 0.7)",
        500: "rgba(255, 31, 31, 1)",
        600: "rgba(219, 24, 24, 0.7)",
        700: "rgba(184, 19, 19, 0.7)",
        800: "rgba(148, 14, 14, 0.7)",
        900: "rgba(112, 8, 8, 0.7)",
    },
    zinc: {
        50: "rgba(250, 250, 250, 0.7)",
        100: "rgba(244, 244, 245, 0.7)",
        200: "rgba(228, 228, 231, 0.7)",
        300: "rgba(212, 212, 216, 0.7)",
        400: "rgba(161, 161, 170, 0.7)",
        500: "rgba(113, 113, 122, 1)",
        600: "rgba(82, 82, 91, 0.7)",
        700: "rgba(63, 63, 70, 0.7)",
        800: "rgba(39, 39, 42, 0.7)",
        900: "rgba(24, 24, 27, 0.7)",
    },
};
exports.textColorRGBA = [
    "rgba(20, 184, 166, 1)", // teal-600
    "rgba(139, 92, 246, 1)", // violet-600
    "rgba(239, 68, 68, 1)", // red-600
    "rgba(14, 165, 233, 1)", // sky-600
    "rgba(132, 204, 22, 1)", // lime-600
    "rgba(234, 179, 8, 1)", // yellow-600
    "rgba(6, 182, 212, 1)", // cyan-600
    "rgba(249, 115, 22, 1)", // orange-600
    "rgba(217, 70, 239, 1)", // fuchsia-600
    "rgba(163, 154, 145, 1)", // stone-600
    "rgba(5, 150, 105, 1)", // green-600
    "rgba(236, 72, 153, 1)", // pink-600
];
exports.bgColorRGBA = [
    "rgba(240, 253, 250, 1)", // teal-100
    "rgba(245, 243, 255, 1)", // violet-100
    "rgba(254, 242, 242, 1)", // red-100
    "rgba(240, 249, 255, 1)", // sky-100
    "rgba(247, 254, 231, 1)", // lime-100
    "rgba(254, 249, 231, 1)", // yellow-100
    "rgba(236, 252, 253, 1)", // cyan-100
    "rgba(255, 247, 237, 1)", // orange-100
    "rgba(253, 244, 255, 1)", // fuchsia-100
    "rgba(250, 250, 250, 1)", // stone-100
    "rgba(240, 253, 244, 1)", // green-100
    "rgba(253, 242, 248, 1)", // pink-100
];
exports.colorIndices = [
    50, 100, 200, 300, 400, 500, 600, 700, 800, 900,
];
exports.colorNames = Object.keys(exports.colorPalette);
var returnColor = function (color, shade) {
    return exports.colorPalette[color][shade || 500];
};
exports.returnColor = returnColor;
exports.LabelIcons = [
    "sun", // Morning Shift
    "moon", // Night Shift
    "star", // Late Evening or Premium Shift
    "bolt", // Emergency or On-Call Shift
    "bell", // High-Alert or Reminder Shift
    "fire", // High-Intensity or Critical Shift
    "truck", // Logistics or Delivery Shift
    "shield-check", // Security Shift
    "calendar", // Scheduled or Recurring Shift
    "clock", // Anytime or Flexible Timing Shift
    "computer-desktop", // Office or Tech Shift
    "flag", // Supervisory or Leadership Shift
    "trophy", // High-Performance or Key Milestone Shift
    "puzzle-piece", // Collaborative or Team-Oriented Shift
    "wrench-screwdriver", // Maintenance or Repair Shift
    "globe-alt", // Remote or Global Shift
    "eye", // Monitoring or Observation Shift
    "gift", // Holiday or Seasonal Shift
    "megaphone", // Outreach or Public-Facing Shift
    "beaker", // Research or Lab Shift
    "musical-note", // Creative or Event Shift
    "archive-box", // Storage or Inventory Shift
    "arrow-up-tray", // Pickup Shift
    "arrow-down-tray", // Delivery Shift
    "chat-bubble-left", // Customer Support or Communication Shift
    "scale", // Decision-Making or Evaluation Shift
]; // Ensure the array is immutable
// 5 minutes
exports.GRID_GRANULARITY = 60;
exports.passwordRegex = /^(?=.*[A-Z])(?=.*\d)(?!.*\s).{8,}$/;
