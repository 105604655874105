import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  useMemo,
  ReactNode,
} from 'react';
import { request } from '@youshift/shared/api';
import {
  ItrResolution,
  ItrStatuses,
  ItrType,
  WorkerTaskStatus,
} from '@youshift/shared/types';

interface AppConfig {
  max_subset_slots: number;
  allow_alg_executions: boolean;
  allow_post_exec_executions: boolean;
  maintenance_mode: boolean;
}

interface Constants {
  ItrStatus: ItrStatuses;
  ItrType: ItrType[];
  ItrResolution: ItrResolution[];
  WorkerTaskStatus: WorkerTaskStatus[];
}

interface AppOptions {
  app_config: AppConfig;
  constants: Constants;
}

interface AppOptionsContextType {
  appOptions: AppOptions | null;
  loading: boolean;
  error: string | null;
}

interface AppOptionsProviderProps {
  children: ReactNode;
}

const AppOptionsContext = createContext<AppOptionsContextType | undefined>(
  undefined,
);

export const useAppOptions = (): AppOptionsContextType => {
  const context = useContext(AppOptionsContext);
  if (context === undefined) {
    throw new Error('useAppOptions must be used within an AppOptionsProvider');
  }
  return context;
};

export function AppOptionsProvider({ children }: AppOptionsProviderProps) {
  const [appOptions, setAppOptions] = useState<AppOptions | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchAppOptions = async () => {
      try {
        const data = await request<AppOptions>({
          url: '/load_app_options',
          method: 'GET',
        });
        setAppOptions(data);
      } catch (err) {
        setError(err instanceof Error ? err.message : 'An error occurred');
      } finally {
        setLoading(false);
      }
    };
    fetchAppOptions();
  }, []);

  const value = useMemo(
    () => ({
      appOptions,
      loading,
      error,
    }),
    [appOptions, loading, error],
  );

  return (
    <AppOptionsContext.Provider value={value}>
      {children}
    </AppOptionsContext.Provider>
  );
}
