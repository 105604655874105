import {
  Bars4Icon,
  FolderPlusIcon,
  PlusIcon,
} from '@heroicons/react/24/outline';
import { QueryClient, useQuery } from '@tanstack/react-query';
import { preLoadQuery } from '@youshift/shared/hooks';
import { sectionsQuery } from '@youshift/shared/hooks/queries';
import { Section } from '@youshift/shared/types';
import { returnColor } from '@youshift/shared/utils';
import { useTranslation } from 'react-i18next';
import {
  Link,
  LoaderFunctionArgs,
  useLoaderData,
  useNavigate,
  useParams,
} from 'react-router-dom';

import { EmptyState } from '../../../../components/EmptyState';
import Wrapper from '../../../../components/Wrapper';
import { requireApproved, requireManager } from '../../../../utils/checks';

type SectionsLoader = Section[];
export const sectionsLoader =
  (queryClient: QueryClient) =>
  async ({ params }: LoaderFunctionArgs): Promise<SectionsLoader | null> => {
    const user = await requireManager(queryClient);
    await requireApproved(user);
    if (params.idItr === undefined) {
      return null;
    }
    const sections = await preLoadQuery(
      queryClient,
      sectionsQuery(params.idItr),
    );
    return sections;
  };

export default function SectionsConfig() {
  const { idItr } = useParams();
  const initialSections = useLoaderData() as Section[];
  const { data: sections } = useQuery({
    ...sectionsQuery(idItr || ''),
    initialData: initialSections,
  });
  const { t } = useTranslation();
  const navigate = useNavigate();

  if (!sections.length) {
    return (
      <Wrapper>
        <EmptyState
          title={t('manager.sectionsConfig.createPrompt')}
          subtitle={t('manager.sectionsConfig.definition')}
          buttonText={t('manager.sectionsConfig.newShift')}
          Icon={FolderPlusIcon}
          onClick={() => navigate('./new')}
        />
      </Wrapper>
    );
  }

  return (
    <Wrapper>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 w-full mx-auto">
        {sections.map(section => (
          <div
            key={section.id_section}
            className="bg-white rounded-lg border border-gray-200 p-3"
          >
            <Link
              to={`${section.id_section}`}
              className="flex items-center justify-between space-x-3"
            >
              <div className="flex items-center space-x-3 min-w-0">
                <div
                  className="p-1 rounded-full w-5 h-5 flex-shrink-0"
                  style={{ background: returnColor(section.color) }}
                />
                <span className="text-lg font-medium truncate">
                  {section.name}
                </span>
              </div>
              <div className="p-1 rounded-full border border-gray-300 flex-shrink-0">
                <Bars4Icon className="w-5 h-5 text-gray-400" />
              </div>
            </Link>
          </div>
        ))}
        <button
          onClick={() => navigate('./new')}
          className="flex w-full items-center justify-between bg-gray-100 rounded-lg p-3 text-gray-400"
        >
          <span>{t('manager.sectionsConfig.addSection')}</span>
          <div
            className="p-1 rounded-full border border-gray-300"
            aria-label={t('manager.sectionsConfig.addSection')}
          >
            <PlusIcon className="w-5 h-5" />
          </div>
        </button>
      </div>
    </Wrapper>
  );
}
