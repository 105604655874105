import {
  SectionSlot,
  SectionWithSlots,
  UserReqRule,
  UserRequirement,
  UserRequirementType,
  RuleTypes,
} from '@youshift/shared/types';

export const datetimeRangeOverlap = (
  start1: Date,
  end1: Date,
  start2: Date,
  end2: Date,
) => start1 < end2 && end1 > start2;

/**
 * Checks if a user requirement rule is a SLOT user requirement rule.
 *
 * For historic reasons, the req_type (SLOTS or DURATION) is stored in the UserShiftRequirement
 * object instead of the Rule object. However, all user_reqs within a Rule object
 * are of the same type.
 *
 * @param userReq - The user requirement rule to check.
 * @returns True if the user requirement rule is a SLOT user requirement rule, false otherwise.
 */
export const isSlotUserReqRule = (userReqRule: UserReqRule): boolean =>
  Object.values(userReqRule.user_reqs).some(
    rule => rule.req_type === UserRequirementType.SLOTS,
  );

/**
 * Checks if a user requirement rule is a full section user requirement rule.
 *
 * A full section user requirement rule is a rule that covers all slots in a section.
 * SECTION_USER_REQS rules are always full section user requirement rules.
 *
 * @param userReqRule - The user requirement rule to check.
 * @param sectionSlotsDict - The dictionary of section slots.
 * @returns True if the user requirement rule is a full section user requirement rule, false otherwise.
 */
export const isFullSectionUserReqRule = (
  userReqRule: UserReqRule,
  sectionSlotsDict: Record<number, SectionSlot>,
): boolean => {
  if (userReqRule.rule.type === RuleTypes.SECTION_USER_REQS) {
    return true;
  }

  // Get all section slots for this rule
  const ruleSectionSlots = userReqRule.section_slots;

  // Get the section ID of the first slot to compare against
  const firstSlotId = ruleSectionSlots[0];
  const firstSlot = sectionSlotsDict[firstSlotId];
  const sectionId = firstSlot.id_section;

  // Check that all slots are from the same section
  const allSameSection = ruleSectionSlots.every(
    slotId => sectionSlotsDict[slotId].id_section === sectionId,
  );

  if (!allSameSection) {
    return false;
  }

  // Get all section slots for this section
  const sectionSlots = Object.values(sectionSlotsDict).filter(
    slot => slot.id_section === sectionId,
  );

  // Check that rule includes all slots from the section
  return sectionSlots.every(slot =>
    ruleSectionSlots.includes(slot.id_section_slot),
  );
};

export function findOverlappingSectionSlots(
  base_section_slot: SectionSlot,
  section_slots: Record<number, SectionSlot>,
): number[] {
  const overlapping_section_slots = Object.values(section_slots)
    .filter(section_slot =>
      datetimeRangeOverlap(
        new Date(base_section_slot.start),
        new Date(base_section_slot.end),
        new Date(section_slot.start),
        new Date(section_slot.end),
      ),
    )
    .map(section_slot => section_slot.id_section_slot);
  return overlapping_section_slots;
}
