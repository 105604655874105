import { Popover, Menu } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/24/outline';
import React, { useMemo, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from 'recharts';
import { Iteration, Rule, Section, SlotLabel } from '@youshift/shared/types';
import {
  parseIterationDates,
  returnColor,
  classNames,
  textColorRGBA,
} from '@youshift/shared/utils';

interface UserStatsChartProps {
  shiftsPerSection: Record<
    number,
    {
      count: number;
      hours: number;
      perItr: Record<number, { count: number; hours: number }>;
    }
  >;
  shiftsPerRule: Record<
    number,
    {
      count: number;
      hours: number;
      perItr: Record<number, { count: number; hours: number }>;
    }
  >;
  shiftsPerLabel: Record<
    number,
    {
      count: number;
      hours: number;
      perItr: Record<number, { count: number; hours: number }>;
    }
  >;
  filteredItrs: Record<
    number,
    { start_day: string; end_day: string; itr_type: string }
  >;
  allSections: Record<number, Section>;
  allRules: Record<number, Rule>;
  allLabels: Record<number, SlotLabel>;
}

export default function UserStatsChart({
  shiftsPerSection,
  shiftsPerRule,
  shiftsPerLabel,
  filteredItrs,
  allSections,
  allRules,
  allLabels,
}: UserStatsChartProps) {
  const { t } = useTranslation();
  const [viewType, setViewType] = useState<'sections' | 'rules' | 'labels'>(
    'sections',
  );
  const [selectedItems, setSelectedItems] = useState<Set<number>>(new Set());
  const [metric, setMetric] = useState<'hours' | 'count'>('hours');

  // Initialize selected items when view type changes
  useEffect(() => {
    const items =
      viewType === 'sections'
        ? shiftsPerSection
        : viewType === 'rules'
          ? shiftsPerRule
          : shiftsPerLabel;
    setSelectedItems(new Set(Object.keys(items).map(Number)));
  }, [viewType, shiftsPerSection, shiftsPerRule, shiftsPerLabel]);

  const getData = () => {
    const items =
      viewType === 'sections'
        ? shiftsPerSection
        : viewType === 'rules'
          ? shiftsPerRule
          : shiftsPerLabel;
    const names =
      viewType === 'sections'
        ? allSections
        : viewType === 'rules'
          ? allRules
          : allLabels;

    return Object.entries(items)
      .filter(([id]) => selectedItems.has(Number(id)))
      .map(([id, data]) => ({
        id: Number(id),
        name: names[Number(id)].name,
        color:
          viewType === 'sections'
            ? returnColor(allSections[Number(id)].color, 400)
            : textColorRGBA[Number(id) % textColorRGBA.length],
        ...data,
      }));
  };

  return (
    <div className="space-y-4">
      <div className="flex justify-between items-center">
        <div className="flex items-center gap-4">
          <Menu as="div" className="relative">
            <Menu.Button className="group inline-flex justify-center text-sm font-medium text-gray-700 hover:text-gray-900">
              {t(`generic.${viewType}`)}
              <ChevronDownIcon className="-mr-1 ml-1 h-5 w-5 flex-shrink-0 text-gray-400 group-hover:text-gray-500" />
            </Menu.Button>

            <Menu.Items className="absolute left-0 z-10 mt-2 w-40 origin-top-right rounded-md bg-white shadow-2xl ring-1 ring-black ring-opacity-5 focus:outline-none">
              <div className="py-1">
                {(['sections', 'rules', 'labels'] as const).map(type => (
                  <Menu.Item key={type}>
                    {({ active }) => (
                      <button
                        onClick={() => setViewType(type)}
                        className={classNames(
                          active ? 'bg-gray-100' : '',
                          'block px-4 py-2 text-sm text-gray-900 w-full text-left',
                        )}
                      >
                        {t(`generic.${type}`)}
                      </button>
                    )}
                  </Menu.Item>
                ))}
              </div>
            </Menu.Items>
          </Menu>

          <Menu as="div" className="relative">
            <Menu.Button className="group inline-flex justify-center text-sm font-medium text-gray-700 hover:text-gray-900">
              {t(
                metric === 'hours'
                  ? 'user.stats.hoursView'
                  : 'user.stats.shiftsView',
              )}
              <ChevronDownIcon className="-mr-1 ml-1 h-5 w-5 flex-shrink-0 text-gray-400 group-hover:text-gray-500" />
            </Menu.Button>

            <Menu.Items className="absolute left-0 z-10 mt-2 w-40 origin-top-right rounded-md bg-white shadow-2xl ring-1 ring-black ring-opacity-5 focus:outline-none">
              <div className="py-1">
                {(['hours', 'count'] as const).map(type => (
                  <Menu.Item key={type}>
                    {({ active }) => (
                      <button
                        onClick={() => setMetric(type)}
                        className={classNames(
                          active ? 'bg-gray-100' : '',
                          'block px-4 py-2 text-sm text-gray-900 w-full text-left',
                        )}
                      >
                        {t(
                          type === 'hours'
                            ? 'user.stats.hoursView'
                            : 'user.stats.shiftsView',
                        )}
                      </button>
                    )}
                  </Menu.Item>
                ))}
              </div>
            </Menu.Items>
          </Menu>
        </div>

        <Popover className="relative">
          <Popover.Button className="group inline-flex items-center justify-center text-sm font-medium text-gray-700 hover:text-gray-900">
            <span>{t('generic.filter')}</span>
            <ChevronDownIcon className="-mr-1 ml-1 h-5 w-5 flex-shrink-0 text-gray-400 group-hover:text-gray-500" />
          </Popover.Button>

          <Popover.Panel className="absolute right-0 z-10 mt-2 origin-top-right rounded-md bg-white p-4 shadow-2xl ring-1 ring-black ring-opacity-5">
            <form className="space-y-4">
              {Object.entries(
                viewType === 'sections'
                  ? allSections
                  : viewType === 'rules'
                    ? allRules
                    : allLabels,
              ).map(([id, item]) => (
                <div key={id} className="flex items-center">
                  <input
                    type="checkbox"
                    checked={selectedItems.has(Number(id))}
                    onChange={e => {
                      const newSelected = new Set(selectedItems);
                      if (e.target.checked) {
                        newSelected.add(Number(id));
                      } else {
                        newSelected.delete(Number(id));
                      }
                      setSelectedItems(newSelected);
                    }}
                    className="h-4 w-4 rounded border-gray-300 text-blue-600"
                  />
                  <label className="ml-3 text-sm text-gray-600">
                    {item.name}
                  </label>
                </div>
              ))}
            </form>
          </Popover.Panel>
        </Popover>
      </div>

      <div className="h-96">
        <ResponsiveContainer width="100%" height="100%">
          <LineChart
            data={Object.entries(filteredItrs).map(([itrId, itr]) => {
              const { start_day, end_day, itr_type } = itr;
              const date = parseIterationDates(start_day, end_day, itr_type);
              const dataPoints = getData();

              return {
                name: date,
                ...dataPoints.reduce(
                  (acc, item) => ({
                    ...acc,
                    [item.name]: item.perItr[Number(itrId)]?.[metric] || 0,
                  }),
                  {},
                ),
              };
            })}
            margin={{
              top: 5,
              right: 30,
              left: 20,
              bottom: 5,
            }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="name" />
            <YAxis />
            <Tooltip />
            <Legend />
            {getData().map(item => (
              <Line
                key={item.id}
                type="monotone"
                dataKey={item.name}
                stroke={item.color}
                activeDot={{ r: 8 }}
              />
            ))}
          </LineChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
}
