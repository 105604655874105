/* eslint-disable max-len */
import { useState } from 'react';
import { localeNormalizer } from '@youshift/shared/utils';

import CookiesBanner from '../../components/Banners/CookiesBanner';
import {
  CTA,
  FAQ,
  // Hero,
  InterestPopup,
  Press,
  PrimaryFeatures,
  Stats,
  Testimonials,
  Features,
} from '../../components/Landing/index';
import HeroSection from '../../components/Landing/Hero';
import i18n from '../../utils/i18n';

export default function Landing() {
  const [open, setOpen] = useState(false);
  const [cookiesAccepted, setCookiesAccepted] = useState(
    localStorage.getItem('cookiesAccepted') === 'true',
  );

  const handleAcceptCookies = () => {
    localStorage.setItem('cookiesAccepted', 'true');
    setCookiesAccepted(true);
  };

  const handleRejectCookies = () => {
    localStorage.setItem('cookiesAccepted', 'false');
    setCookiesAccepted(false);
  };

  const locale = localeNormalizer(i18n.language);

  return (
    <div style={{ scrollBehavior: 'smooth' }}>
      {!cookiesAccepted && (
        <CookiesBanner
          onAccept={handleAcceptCookies}
          onReject={handleRejectCookies}
        />
      )}
      <InterestPopup open={open} setOpen={setOpen} />
      <HeroSection />
      <div id="caracteristicas">
        {/* <PrimaryFeatures open={open} setOpen={setOpen} />
         */}
        <Features />
      </div>
      <div id="stats">
        <Stats />
      </div>
      <div id="testimonials">
        <Testimonials />
      </div>
      <CTA />
      <div id="equipo">{/* <Team /> */}</div>
      {locale === 'es' && (
        <div id="press">
          <Press />
        </div>
      )}
      <div id="faq">
        <FAQ />
      </div>
    </div>
  );
}
