import { Menu, Transition } from '@headlessui/react';
import { ChevronDownIcon, PlusCircleIcon } from '@heroicons/react/20/solid';
import { CalendarDaysIcon, TrashIcon } from '@heroicons/react/24/outline';
import { QueryClient, useQueryClient } from '@tanstack/react-query';
import { preLoadQuery } from '@youshift/shared/hooks';
import { useDeleteEvent } from '@youshift/shared/hooks/mutations';
import { userEventsQuery } from '@youshift/shared/hooks/queries';
import {
  EventStatus,
  EventStatusFilter,
  EventType,
  EventTypeFilter,
  SpecialEvent,
} from '@youshift/shared/types';
import {
  classNames,
  dateToString,
  localeNormalizer,
} from '@youshift/shared/utils';
import { Fragment, useState } from 'react';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { useLoaderData } from 'react-router-dom';

import { YSButton } from '../../../components/Buttons';
import { EmptyState } from '../../../components/EmptyState';
import Wrapper from '../../../components/Wrapper';
import { requireApproved, requireLoggedIn } from '../../../utils/checks';
import i18n from '../../../utils/i18n';
import NewRequestModal from './NewRequestModal';
import { useUserContext } from '../../../layouts/UserLayout';

export default function UserEventCenter() {
  const [statusFilter, setStatusFilter] =
    useState<EventStatusFilter>('allStatuses');
  const [typeFilter, setTypeFilter] = useState<EventTypeFilter>('allTypes');

  const queryClient = useQueryClient();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const { t } = useTranslation();
  const locale = localeNormalizer(i18n.language);

  const { events } = useUserContext();

  const [requests, setRequests] = useState(events);

  const deleteEvent = useDeleteEvent(queryClient, {
    onSuccess: (data, variables, context) => {
      toast.success(t('generic.success'));
      setRequests(prevRequests =>
        prevRequests.filter(
          request => request.id_special_event !== variables.id_special_event,
        ),
      );
    },
  });

  const historic = Object.values(EventType).map(type => ({
    type,
    used: requests
      .filter(event => event.type === type)
      .filter(event => event.status === EventStatus.APPROVED).length,
  }));

  return (
    <Wrapper>
      <NewRequestModal
        open={isModalOpen}
        setOpen={setIsModalOpen}
        setRequests={setRequests}
      />
      <h1 className="text-2xl font-bold text-start py-4">
        {t('eventCenter.eventCenter')}
      </h1>
      <main className="container mx-auto py-8">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
          <section>
            <h2 className="text-xl font-semibold mb-4">
              {' '}
              {t('eventCenter.myRequests')}
            </h2>
            {requests.length ? (
              <>
                <div className="flex flex-wrap gap-4 mb-4 items-center">
                  <Menu as="div" className="relative inline-block text-left">
                    <div>
                      <Menu.Button className="inline-flex w-full justify-center gap-x-1.5 rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50">
                        {t(`eventCenter.${statusFilter}`)}
                        <ChevronDownIcon
                          className="-mr-1 h-5 w-5 text-gray-400"
                          aria-hidden="true"
                        />
                      </Menu.Button>
                    </div>

                    <Transition
                      as={Fragment}
                      enter="transition ease-out duration-100"
                      enterFrom="transform opacity-0 scale-95"
                      enterTo="transform opacity-100 scale-100"
                      leave="transition ease-in duration-75"
                      leaveFrom="transform opacity-100 scale-100"
                      leaveTo="transform opacity-0 scale-95"
                    >
                      <Menu.Items className="absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                        <div className="py-1">
                          {Object.values(EventStatus).map(status => (
                            <Menu.Item key={status}>
                              {({ active }) => (
                                <button
                                  className={classNames(
                                    active
                                      ? 'bg-gray-100 text-gray-900'
                                      : 'text-gray-700',
                                    'block px-4 py-2 text-sm  w-full text-start',
                                  )}
                                  onClick={() => setStatusFilter(status)}
                                >
                                  {t(`eventCenter.${status}`)}
                                </button>
                              )}
                            </Menu.Item>
                          ))}
                          <Menu.Item key="allStatuses">
                            {({ active }) => (
                              <button
                                className={classNames(
                                  active
                                    ? 'bg-gray-100 text-gray-900'
                                    : 'text-gray-700',
                                  'block px-4 py-2 text-sm',
                                )}
                                onClick={() => setStatusFilter('allStatuses')}
                              >
                                {t(`eventCenter.allStatuses`)}
                              </button>
                            )}
                          </Menu.Item>
                        </div>
                      </Menu.Items>
                    </Transition>
                  </Menu>

                  <Menu as="div" className="relative inline-block text-left">
                    <div>
                      <Menu.Button className="inline-flex w-full justify-center gap-x-1.5 rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50">
                        {t(`eventCenter.${typeFilter}`)}
                        <ChevronDownIcon
                          className="-mr-1 h-5 w-5 text-gray-400"
                          aria-hidden="true"
                        />
                      </Menu.Button>
                    </div>

                    <Transition
                      as={Fragment}
                      enter="transition ease-out duration-100"
                      enterFrom="transform opacity-0 scale-95"
                      enterTo="transform opacity-100 scale-100"
                      leave="transition ease-in duration-75"
                      leaveFrom="transform opacity-100 scale-100"
                      leaveTo="transform opacity-0 scale-95"
                    >
                      <Menu.Items className="absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                        <div className="py-1">
                          {Object.values(EventType).map(type => (
                            <Menu.Item key={type}>
                              {({ active }) => (
                                <button
                                  className={classNames(
                                    active
                                      ? 'bg-gray-100 text-gray-900'
                                      : 'text-gray-700',
                                    'block px-4 py-2 text-sm w-full text-start',
                                  )}
                                  onClick={() => setTypeFilter(type)}
                                >
                                  {t(`eventCenter.${type}`)}
                                </button>
                              )}
                            </Menu.Item>
                          ))}
                          <Menu.Item key="allTypes">
                            {({ active }) => (
                              <button
                                className={classNames(
                                  active
                                    ? 'bg-gray-100 text-gray-900'
                                    : 'text-gray-700',
                                  'block px-4 py-2 text-sm',
                                )}
                                onClick={() => setTypeFilter('allTypes')}
                              >
                                {t(`eventCenter.allTypes`)}
                              </button>
                            )}
                          </Menu.Item>
                        </div>
                      </Menu.Items>
                    </Transition>
                  </Menu>

                  <YSButton
                    onClick={() => setIsModalOpen(true)}
                    classNames="flex flex-row items-center"
                  >
                    <PlusCircleIcon
                      className="-ml-0.5 mr-1.5 h-5 w-5"
                      aria-hidden="true"
                    />
                    {t('eventCenter.newRequest')}
                  </YSButton>
                </div>
                <div className="space-y-4">
                  {requests
                    .filter(
                      request =>
                        (statusFilter !== 'allStatuses'
                          ? request.status === statusFilter
                          : true) &&
                        (typeFilter !== 'allTypes'
                          ? request.type === typeFilter
                          : true),
                    )
                    .sort((a, b) => {
                      // Priority: Pending events first
                      if (
                        a.status === EventStatus.PENDING &&
                        b.status !== EventStatus.PENDING
                      )
                        return -1;
                      if (
                        a.status !== EventStatus.PENDING &&
                        b.status === EventStatus.PENDING
                      )
                        return 1;

                      // Secondary: Sort by start date
                      return (
                        new Date(a.start).getTime() -
                        new Date(b.start).getTime()
                      );
                    })
                    .map((request, index) => (
                      <div
                        key={index}
                        className="bg-white shadow rounded-lg p-4"
                      >
                        <div className="flex justify-between items-start">
                          <div>
                            <h3 className="font-semibold">
                              {t(`eventCenter.${request.type}`)}
                            </h3>
                            <p className="text-sm text-gray-600">
                              {dateToString(request.start, 'long', locale)}
                              {' - '}
                              {dateToString(request.end, 'long', locale)}
                            </p>
                            {/* <p className="text-sm text-gray-600">
                              {t('eventCenter.sentOn', {
                                date: dateToString(request.created, 'long'),
                              })}
                            </p> */}
                            <p className="text-xs text-gray-600 mt-1">
                              {`${t('generic.justification')} ${request.comment}`}
                            </p>
                          </div>
                          <div className="flex flex-row items-center gap-1">
                            <span
                              className={classNames(
                                'px-2 py-1 rounded-full text-xs font-semibold',
                                request.status === EventStatus.PENDING
                                  ? 'bg-yellow-200 text-yellow-800'
                                  : request.status === EventStatus.APPROVED
                                    ? 'bg-green-200 text-green-800'
                                    : 'bg-red-200 text-red-800',
                              )}
                            >
                              {t(`eventCenter.${request.status}`)}
                            </span>
                            <button
                              onClick={() =>
                                deleteEvent.mutate({
                                  id_special_event: request.id_special_event,
                                })
                              }
                            >
                              <TrashIcon className="w-5 h-5 text-gray-400" />
                            </button>
                          </div>
                        </div>
                      </div>
                    ))}
                </div>
              </>
            ) : (
              <EmptyState
                onClick={() => setIsModalOpen(true)}
                title={t('eventCenter.createPrompt')}
                subtitle={t('eventCenter.createSubtitle')}
                buttonText={t('eventCenter.newRequest')}
                Icon={CalendarDaysIcon}
              />
            )}
          </section>
          <section>
            <h2 className="text-xl font-semibold mb-4">
              {t('eventCenter.historic')}
            </h2>
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-gray-50">
                <tr>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    {t('eventCenter.type')}
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    {t('eventCenter.used')}
                  </th>
                  {/* <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Disponible
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Total Permitido
                  </th> */}
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                {historic.map((row, index) => (
                  <tr key={index}>
                    <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                      {t(`eventCenter.${row.type}`)}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                      {row.used}
                    </td>
                    {/* <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                      {row.available}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                      {row.total}
                    </td> */}
                  </tr>
                ))}
              </tbody>
            </table>
          </section>
        </div>
      </main>
    </Wrapper>
  );
}
