import { ExchangeResponseStatus } from '@youshift/shared/types';
import React from 'react';

function ExchangeIcon({ status }) {
  // Determine the size based on the small prop
  const strokeColor =
    status === ExchangeResponseStatus.APPROVED
      ? 'green'
      : status === ExchangeResponseStatus.PENDING_MANAGER ||
        status === ExchangeResponseStatus.PENDING_DOCTOR
        ? '#FFAC30'
        : 'red';

  return (
    <svg
      width="75"
      height="60"
      viewBox="0 0 100 80"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="mx-auto"
    >
      <path
        d="M61.75 54.25L49.25 41.75M49.25 41.75L61.75 29.25M49.25 41.75H74.25C77.5652 41.75 80.7446 43.067 83.0888 45.4112C85.433 47.7554 86.75 50.9348 86.75 54.25C86.75 57.5652 85.433 60.7446 83.0888 63.0888C80.7446 65.433 77.5652 66.75 74.25 66.75H68"
        stroke={strokeColor}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M40.25 51.75L52.75 64.25M52.75 64.25L40.25 76.75M52.75 64.25H27.75C24.4348 64.25 21.2554 62.933 18.9112 60.5888C16.567 58.2446 15.25 55.0652 15.25 51.75C15.25 48.4348 16.567 45.2554 18.9112 42.9112C21.2554 40.567 24.4348 39.25 27.75 39.25H34"
        stroke={strokeColor}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default ExchangeIcon;
