import { ChevronRightIcon, HomeIcon } from '@heroicons/react/20/solid';
import { QueryClient, useQuery } from '@tanstack/react-query';
import { preLoadQuery } from '@youshift/shared/hooks';
import {
  userStatsManagerQuery,
  UserStatsReturn,
  userStatsUserQuery,
} from '@youshift/shared/hooks/queries';
import { useTranslation } from 'react-i18next';
import {
  LoaderFunctionArgs,
  NavLink,
  useLoaderData,
  useLocation,
  useParams,
} from 'react-router-dom';

import NoData from '../../Manager/NoData';
import SingleUserStats from '../UserStats/SingleUserStats';

export const userStatsManagerLoader =
  (queryClient: QueryClient) =>
  async ({ params }: LoaderFunctionArgs): Promise<UserStatsReturn | null> => {
    if (params.idUser === undefined) {
      return null;
    }
    const { idUser } = params;
    const userStats = await preLoadQuery(
      queryClient,
      userStatsManagerQuery(Number(idUser)),
    );
    return userStats;
  };

export const useUserStatsManagerLoader = (idUser: number): UserStatsReturn => {
  const initialData = useLoaderData() as UserStatsReturn;
  const { data: userStats } = useQuery({
    ...userStatsManagerQuery(idUser),
    initialData,
    staleTime: 1000 * 60 * 5,
  });

  return userStats;
};

export default function UserStatsManager() {
  const { idUser } = useParams();

  const { counters, chains } = useUserStatsManagerLoader(Number(idUser));

  const { state } = useLocation();
  const { name, role, previous } = state ?? {};

  const { t } = useTranslation();

  return (
    <div>
      <nav className="flex" aria-label="Breadcrumb">
        <ol className="flex items-center space-x-4">
          <li>
            <div>
              <NavLink
                to="/manager"
                className="text-gray-400 hover:text-blue-600 cursor-pointer"
              >
                <HomeIcon
                  className="h-5 w-5 flex-shrink-0 cursor-pointer"
                  aria-hidden="true"
                />
              </NavLink>
            </div>
          </li>
          <li>
            <div className="flex items-center">
              <ChevronRightIcon
                className="h-5 w-5 flex-shrink-0 text-gray-400"
                aria-hidden="true"
              />
              <NavLink
                to={previous === 'personnel' ? '..' : '/manager/stats'}
                relative="path"
                className="ml-4 text-sm font-medium text-gray-500 hover:text-blue-600"
              >
                {previous === 'personnel'
                  ? t('manager.personnel')
                  : t('generic.stats')}
              </NavLink>
            </div>
          </li>
          <li>
            <div className="flex items-center">
              <ChevronRightIcon
                className="h-5 w-5 flex-shrink-0 text-gray-400"
                aria-hidden="true"
              />
              <div
                className="ml-4 text-sm font-medium text-blue-600 hover:text-blue-700 cursor-pointer"
                aria-current="page"
              >
                {name || t('generic.user')}
              </div>
            </div>
          </li>
        </ol>
      </nav>
      <div className="h-1 w-full mt-6 bg-blue-600" />
      {!Object.keys(chains).length ? (
        <NoData
          text={t('manager.stats.noDataText')}
          description={t('manager.stats.noDataDescription')}
        />
      ) : (
        <SingleUserStats counters={counters} chainStats={chains} />
      )}
    </div>
  );
}
