import { useTranslation } from 'react-i18next';

interface Feature {
  title: string;
  subtitle: string;
  description: string;
  videoUrl: string;
}

function FeatureSection({
  feature,
  index,
}: {
  feature: Feature;
  index: number;
}) {
  const isEven = index % 2 === 0;

  return (
    <div className={isEven ? 'bg-white' : 'bg-gray-50'}>
      <div className="mx-auto max-w-8xl px-4 sm:px-6 py-8 sm:py-12 lg:py-16 lg:flex lg:items-center lg:gap-x-10 lg:px-8">
        {/* Image Section */}
        <div
          className={`mt-8 sm:mt-12 lg:mt-0 lg:flex-shrink-0 ${
            isEven ? 'lg:order-2' : 'lg:order-1'
          } w-full lg:w-1/2`}
        >
          <video
            className="w-full h-auto max-h-[30rem] rounded-xl shadow-xl ring-1 ring-gray-400/10 object-cover mx-auto"
            src={feature.videoUrl}
            autoPlay
            loop
            muted
            playsInline
          />
        </div>

        {/* Content Section */}
        <div
          className={`mx-auto max-w-2xl lg:mx-0 lg:flex-auto ${
            isEven ? 'lg:order-1' : 'lg:order-2'
          } w-full lg:w-1/2 mt-8 lg:mt-0`}
        >
          <p className="text-3xl sm:text-4xl font-bold tracking-tight text-gray-900">
            {feature.title}
          </p>
          <h2 className="text-base sm:text-lg font-semibold mt-4 leading-7 text-blue-600">
            {feature.subtitle}
          </h2>
          <p className="mt-4 sm:mt-6 text-base sm:text-lg leading-7 sm:leading-8 text-gray-600">
            {feature.description}
          </p>
        </div>
      </div>
    </div>
  );
}

export default function Features() {
  const { t } = useTranslation();
  const features: Feature[] = t('landing.features.items', {
    returnObjects: true,
  });

  return (
    <div>
      {features.map((feature, index) => (
        <FeatureSection key={feature.title} feature={feature} index={index} />
      ))}
    </div>
  );
}
