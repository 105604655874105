import { useState, useEffect } from 'react';

export interface AddressComponents {
  country: string;
  state: string;
  province: string;
  city: string;
  name?: string;
  postal_code?: string;
  route?: string;
}

export const usePlacesAutocomplete = () => {
  const [address, setAddress] = useState('');
  const [loading, setLoading] = useState(true);
  const [initialized, setInitialized] = useState(false);

  useEffect(() => {
    async function initPlaces() {
      try {
        await google.maps.importLibrary('places');
        setInitialized(true);
      } catch (error) {
        console.error('Error initializing Places library:', error);
      } finally {
        setLoading(false);
      }
    }

    initPlaces();
  }, []);

  const handleSelect = async (
    value: string,
    onAddressSelect: (components: AddressComponents) => void,
  ) => {
    setAddress(value);
    setLoading(true);

    try {
      const { Place } = (await google.maps.importLibrary(
        'places',
      )) as google.maps.PlacesLibrary;

      const request = {
        textQuery: value,
        fields: ['displayName', 'formattedAddress', 'addressComponents'],
      };

      const { places } = await Place.searchByText(request);

      if (places.length > 0) {
        const place = places[0];
        const components: AddressComponents = {
          country: '',
          state: '',
          province: '',
          city: '',
          route: '',
          postal_code: '',
          name: place.displayName || '',
        };

        place.addressComponents?.forEach(component => {
          const type = component.types[0];
          switch (type) {
            case 'country':
              components.country = component.shortText || '';
              break;
            case 'administrative_area_level_1':
              components.state = component.longText || '';
              break;
            case 'administrative_area_level_2':
              components.province = component.longText || '';
              break;
            case 'route':
              components.route = component.shortText || '';
              break;
            case 'postal_code':
              components.postal_code = component.shortText || '';
              break;
            case 'locality':
            case 'postal_town':
              if (!components.city) {
                components.city = component.longText || '';
              }
              break;
            default:
              break;
          }
        });

        onAddressSelect(components);
      }
    } catch (error) {
      console.error('Error fetching place details:', error);
    } finally {
      setLoading(false);
    }
  };

  return {
    address,
    setAddress,
    loading,
    handleSelect,
    initialized,
  };
};
