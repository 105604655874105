import {
  FaceSmileIcon,
  MinusIcon,
  NoSymbolIcon,
  PlusIcon,
} from '@heroicons/react/24/outline';
import { EventType, UserPreferenceType } from '@youshift/shared/types';
import { eventBgColor } from '@youshift/shared/utils';
import { useTranslation } from 'react-i18next';
import { NumericFormat } from 'react-number-format';

interface EventSquareProps {
  e: UserPreferenceType | EventType;
  small?: boolean;
  date?: string;
  points?: number;
  dispatch?: (action: { type: 'UPDATE_POINTS'; points: number }) => void;
  justPoints?: boolean;
  maxPoints?: number;
  noMargin?: boolean;
  shift_acr?: string;
}

const eventToIcon = (e: UserPreferenceType | EventType) => {
  if (e === UserPreferenceType.PERSONAL_BLOCKING) {
    return <FaceSmileIcon className="h-6 w-6" />;
  }
  if (e === UserPreferenceType.JUSTIFIED_BLOCKING) {
    return <NoSymbolIcon className="h-6 w-6" />;
  }
  return `user.preferences.${e}`;
};

function EventSquare({
  e,
  small = false,
  date,
  points,
  dispatch,
  justPoints = false,
  maxPoints,
  noMargin = false,
  shift_acr,
}: EventSquareProps) {
  const square: React.CSSProperties = {
    width: small ? '35px' : date ? '80px' : '55px',
    height: small ? '35px' : date ? '20px' : '55px',
    backgroundColor: eventBgColor(e, true, points, maxPoints || 50),
    borderRadius: small ? '0.5rem' : '0.5rem',
    border: `1px solid ${eventBgColor(e, false, points, maxPoints || 50)}`,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    fontSize: '0.85rem',
    margin: noMargin ? '' : '0px auto',
    padding: '0px',
  };

  const { t } = useTranslation();

  const changePoints = (increment: boolean) => {
    if (dispatch) {
      let ctPoints = Number(points);
      ctPoints += increment ? 1 : -1;
      dispatch({ type: 'UPDATE_POINTS', points: ctPoints });
    }
  };

  return e === UserPreferenceType.POINTS && !justPoints ? (
    <div className="flex flex-col items-center">
      <button onClick={() => changePoints(true)}>
        <PlusIcon className="h-3 w-3" />
      </button>
      <NumericFormat
        value={points}
        valueIsNumericString
        onValueChange={values =>
          dispatch &&
          dispatch({ type: 'UPDATE_POINTS', points: Number(values.value) })
        }
        style={square}
      />
      <button onClick={() => changePoints(false)}>
        <MinusIcon className="w-3 h-3" />
      </button>
    </div>
  ) : e === UserPreferenceType.POINTS && justPoints ? (
    <div style={square}>{points}</div>
  ) : (
    <div style={square}>
      {shift_acr ||
        (date ? `${t(`user.preferences.${e}`)}: ${date}` : eventToIcon(e))}
    </div>
  );
}

export default EventSquare;
