/* eslint-disable no-else-return */

import { Params } from 'react-router-dom';

export function getIdFromParams(params: Params): string {
  return params.id ?? '';
}

export function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(' ');
}

// react-native-big-calendar will convert any date coming from the backend to
// local timezone if we pass it as is. Because our dates are "absolute" or
// agnostic to timezones, this function "removes" the GMT marker so that the
// library interprets the date as local time without changing it.
// From Sat, 01 Nov 2025 00:00:00 GMT it returns new Date('2025-11-01T00:00:00')

export function backendToLocalDate(
  date: string,
): [number, number, number, number, number] {
  const utcDate = new Date(date);

  // Extract components in zero-based month format
  const year = utcDate.getUTCFullYear();
  const month = utcDate.getUTCMonth(); // Zero-based month (0 = January, 11 = December)
  const day = utcDate.getUTCDate();
  const hour = utcDate.getUTCHours();
  const minute = utcDate.getUTCMinutes();

  return [year, month, day, hour, minute];
}
