import { SlotLabel } from '@youshift/shared/types';
import { Dispatch, SetStateAction, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CustomCounterIncrementSchema } from '@youshift/shared/hooks/mutations';

import SlotDetailsBase from './SlotDetails';

export type AddShiftTypeProps = {
  dayIndexes: number[];
  id_slot_label: number;
  rest_period: number;
  duration_minutes: number;
  start_time: string;
  custom_counter_increments: CustomCounterIncrementSchema[];
};

export interface CreateSlotTypeProps {
  addShiftType: (props: AddShiftTypeProps) => void;
  setOpen: Dispatch<SetStateAction<boolean>>;
  shiftLabels: SlotLabel[];
}

function CreateSlotType({
  setOpen,
  addShiftType,
  shiftLabels,
}: CreateSlotTypeProps) {
  const [selectedLabelId, setSelectedLabelId] = useState<number | null>(null);
  const [startTime, setStartTime] = useState<string>('08:00');
  const [endTime, setEndTime] = useState<string>('15:00');
  const [restHours, setRestHours] = useState<number>(0);
  const [daysOfWeek, setDaysOfWeek] = useState<boolean[]>(Array(7).fill(false));
  const [extra24h, setExtra24h] = useState<number>(0);
  const [counterIncrementSchemas, setCounterIncrementSchemas] = useState<
    CustomCounterIncrementSchema[]
  >([]);

  const { t } = useTranslation();

  // in minutes
  const duration = useMemo(() => {
    if (startTime === '' || endTime === '') {
      return 0;
    }
    const [startHour, startMinute] = startTime.split(':').map(Number);
    const [endHour, endMinute] = endTime.split(':').map(Number);

    let durationHours = endHour - startHour;
    let durationMinutes = endMinute - startMinute;

    // Check if startTime and endTime are the same, implying a 24-hour duration
    if (durationMinutes < 0) {
      durationMinutes += 60;
      durationHours -= 1;
    }

    if (durationHours < 0) {
      durationHours += 24;
    }

    return durationHours * 60 + durationMinutes + extra24h * 24 * 60;
  }, [endTime, startTime, extra24h]);

  const handleSubmit = () => {
    if (selectedLabelId !== null) {
      addShiftType({
        dayIndexes: daysOfWeek
          .map((day, index) => (day ? index : -1))
          .filter(index => index !== -1),
        start_time: startTime,
        duration_minutes: duration,
        rest_period: restHours * 60,
        custom_counter_increments: counterIncrementSchemas,
        id_slot_label: selectedLabelId,
      });
      setOpen(false);
    }
  };

  const buttonDisabled = useMemo(
    () =>
      selectedLabelId === null ||
      startTime === '' ||
      endTime === '' ||
      daysOfWeek.every(day => !day),
    [selectedLabelId, startTime, endTime, daysOfWeek],
  );

  return (
    <SlotDetailsBase
      counterIncrementSchemas={counterIncrementSchemas}
      setCounterIncrementSchemas={setCounterIncrementSchemas}
      selectedLabelId={selectedLabelId}
      setSelectedLabelId={setSelectedLabelId}
      startTime={startTime}
      setStartTime={setStartTime}
      endTime={endTime}
      setEndTime={setEndTime}
      restHours={restHours}
      setRestHours={setRestHours}
      daysOfWeek={daysOfWeek}
      setDaysOfWeek={setDaysOfWeek}
      duration={duration}
      shiftLabels={shiftLabels}
      buttonDisabled={buttonDisabled}
      onSubmit={handleSubmit}
      setExtra24h={setExtra24h}
      submitButtonLabel={t('generic.create')}
    />
  );
}

export default CreateSlotType;
