"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.useAdminCreateGroupMutation = exports.useAddUserAsManagerMutation = exports.useResendVerificationEmailMutation = exports.useDeleteUserFromManagerMutation = exports.useToggleNotionMutation = exports.useRevokeManagerAccessMutation = exports.useGrantManagerAccessMutation = exports.useEditAdminCommentMutation = exports.useEditGroupStatusMutation = exports.useMergeGroupMutation = exports.useDeleteGroupMutation = exports.useApproveGroupMutation = exports.useFetchTokenMutation = exports.useCheckEmailMutation = exports.useExitImpersonationManagerMutation = exports.useImpersonateGroupMutation = exports.useCreateOrganizationMutation = exports.useSaveAdminCommentMutation = void 0;
var react_query_1 = require("@tanstack/react-query");
var api_1 = require("../../api");
var useSaveAdminCommentMutation = function (queryClient, options) {
    return (0, react_query_1.useMutation)(__assign({ mutationFn: function (_a) {
            var id_group = _a.id_group, new_comment = _a.new_comment;
            return (0, api_1.request)({
                url: "/admin/group/".concat(id_group, "/edit_admin_comment"),
                method: "patch",
                data: { new_comment: new_comment },
            });
        } }, options), queryClient);
};
exports.useSaveAdminCommentMutation = useSaveAdminCommentMutation;
var useCreateOrganizationMutation = function (queryClient, options) {
    return (0, react_query_1.useMutation)(__assign({ mutationFn: function (body) { return __awaiter(void 0, void 0, void 0, function () {
            return __generator(this, function (_a) {
                return [2 /*return*/, (0, api_1.request)({
                        url: "/admin/create_org",
                        method: "post",
                        data: body,
                    })];
            });
        }); } }, options), queryClient);
};
exports.useCreateOrganizationMutation = useCreateOrganizationMutation;
var useImpersonateGroupMutation = function (queryClient, options) {
    return (0, react_query_1.useMutation)(__assign({ mutationFn: function (_a) {
            var id_group = _a.id_group;
            return __awaiter(void 0, void 0, void 0, function () {
                return __generator(this, function (_b) {
                    return [2 /*return*/, (0, api_1.request)({
                            url: "/admin/group/".concat(id_group, "/impersonate_manager"),
                            method: "post",
                        })];
                });
            });
        } }, options), queryClient);
};
exports.useImpersonateGroupMutation = useImpersonateGroupMutation;
// ===============
// 3) exit_impersonate_manager (POST /admin/exit_impersonate_manager)
// ===============
var useExitImpersonationManagerMutation = function (queryClient, options) {
    return (0, react_query_1.useMutation)(__assign({ mutationFn: function () { return __awaiter(void 0, void 0, void 0, function () {
            return __generator(this, function (_a) {
                return [2 /*return*/, (0, api_1.request)({
                        url: "/admin/exit_impersonate_manager",
                        method: "post",
                    })];
            });
        }); } }, options), queryClient);
};
exports.useExitImpersonationManagerMutation = useExitImpersonationManagerMutation;
var useCheckEmailMutation = function (queryClient, options) {
    return (0, react_query_1.useMutation)(__assign({ mutationFn: function (_a) {
            var id_user = _a.id_user;
            return __awaiter(void 0, void 0, void 0, function () {
                return __generator(this, function (_b) {
                    return [2 /*return*/, (0, api_1.request)({
                            url: "/admin/user/".concat(id_user, "/check_email"),
                            method: "post",
                        })];
                });
            });
        } }, options), queryClient);
};
exports.useCheckEmailMutation = useCheckEmailMutation;
var useFetchTokenMutation = function (queryClient, options) {
    return (0, react_query_1.useMutation)(__assign({ mutationFn: function (_a) {
            var id_user = _a.id_user;
            return __awaiter(void 0, void 0, void 0, function () {
                return __generator(this, function (_b) {
                    return [2 /*return*/, (0, api_1.request)({
                            url: "/admin/user/".concat(id_user, "/fetch_token"),
                            method: "get",
                        })];
                });
            });
        } }, options), queryClient);
};
exports.useFetchTokenMutation = useFetchTokenMutation;
var useApproveGroupMutation = function (queryClient, options) {
    return (0, react_query_1.useMutation)(__assign({ mutationFn: function (_a) {
            var id_group = _a.id_group;
            return __awaiter(void 0, void 0, void 0, function () {
                return __generator(this, function (_b) {
                    return [2 /*return*/, (0, api_1.request)({
                            url: "/admin/group/".concat(id_group, "/approve"),
                            method: "post",
                        })];
                });
            });
        } }, options), queryClient);
};
exports.useApproveGroupMutation = useApproveGroupMutation;
var useDeleteGroupMutation = function (queryClient, options) {
    return (0, react_query_1.useMutation)(__assign({ mutationFn: function (_a) {
            var id_group = _a.id_group;
            return __awaiter(void 0, void 0, void 0, function () {
                return __generator(this, function (_b) {
                    return [2 /*return*/, (0, api_1.request)({
                            url: "/admin/group/".concat(id_group, "/delete"),
                            method: "delete",
                        })];
                });
            });
        } }, options), queryClient);
};
exports.useDeleteGroupMutation = useDeleteGroupMutation;
var useMergeGroupMutation = function (queryClient, options) {
    return (0, react_query_1.useMutation)(__assign({ mutationFn: function (_a) {
            var id_group = _a.id_group, incoming_group = _a.incoming_group;
            return __awaiter(void 0, void 0, void 0, function () {
                return __generator(this, function (_b) {
                    return [2 /*return*/, (0, api_1.request)({
                            url: "/admin/group/".concat(id_group, "/merge"),
                            method: "post",
                            data: { incoming_group: incoming_group },
                        })];
                });
            });
        } }, options), queryClient);
};
exports.useMergeGroupMutation = useMergeGroupMutation;
var useEditGroupStatusMutation = function (queryClient, options) {
    return (0, react_query_1.useMutation)(__assign({ mutationFn: function (_a) {
            var id_group = _a.id_group, new_status = _a.new_status;
            return __awaiter(void 0, void 0, void 0, function () {
                return __generator(this, function (_b) {
                    return [2 /*return*/, (0, api_1.request)({
                            url: "/admin/group/".concat(id_group, "/edit_status"),
                            method: "patch",
                            data: { new_status: new_status },
                        })];
                });
            });
        } }, options), queryClient);
};
exports.useEditGroupStatusMutation = useEditGroupStatusMutation;
var useEditAdminCommentMutation = function (queryClient, options) {
    return (0, react_query_1.useMutation)(__assign({ mutationFn: function (_a) {
            var id_group = _a.id_group, new_comment = _a.new_comment;
            return __awaiter(void 0, void 0, void 0, function () {
                return __generator(this, function (_b) {
                    return [2 /*return*/, (0, api_1.request)({
                            url: "/admin/group/".concat(id_group, "/edit_admin_comment"),
                            method: "patch",
                            data: { new_comment: new_comment },
                        })];
                });
            });
        } }, options), queryClient);
};
exports.useEditAdminCommentMutation = useEditAdminCommentMutation;
var useGrantManagerAccessMutation = function (queryClient, options) {
    return (0, react_query_1.useMutation)(__assign({ mutationFn: function (_a) {
            var id_group = _a.id_group, id_user = _a.id_user;
            return __awaiter(void 0, void 0, void 0, function () {
                return __generator(this, function (_b) {
                    return [2 /*return*/, (0, api_1.request)({
                            url: "/admin/group/".concat(id_group, "/user/").concat(id_user, "/grant_manager_access"),
                            method: "post",
                        })];
                });
            });
        } }, options), queryClient);
};
exports.useGrantManagerAccessMutation = useGrantManagerAccessMutation;
var useRevokeManagerAccessMutation = function (queryClient, options) {
    return (0, react_query_1.useMutation)(__assign({ mutationFn: function (_a) {
            var id_group = _a.id_group, id_user = _a.id_user;
            return __awaiter(void 0, void 0, void 0, function () {
                return __generator(this, function (_b) {
                    return [2 /*return*/, (0, api_1.request)({
                            url: "/admin/group/".concat(id_group, "/user/").concat(id_user, "/revoke_manager_access"),
                            method: "post",
                        })];
                });
            });
        } }, options), queryClient);
};
exports.useRevokeManagerAccessMutation = useRevokeManagerAccessMutation;
var useToggleNotionMutation = function (queryClient, options) {
    return (0, react_query_1.useMutation)(__assign({ mutationFn: function (_a) {
            var id_group = _a.id_group;
            return __awaiter(void 0, void 0, void 0, function () {
                return __generator(this, function (_b) {
                    return [2 /*return*/, (0, api_1.request)({
                            url: "/admin/group/".concat(id_group, "/toggle_notion"),
                            method: "patch",
                        })];
                });
            });
        } }, options), queryClient);
};
exports.useToggleNotionMutation = useToggleNotionMutation;
var useDeleteUserFromManagerMutation = function (queryClient, options) {
    return (0, react_query_1.useMutation)(__assign({ mutationFn: function (_a) {
            var id_group = _a.id_group, id_user = _a.id_user;
            return __awaiter(void 0, void 0, void 0, function () {
                return __generator(this, function (_b) {
                    return [2 /*return*/, (0, api_1.request)({
                            url: "/admin/group/".concat(id_group, "/user/").concat(id_user, "/revoke_manager_access"),
                            method: "post",
                        })];
                });
            });
        } }, options), queryClient);
};
exports.useDeleteUserFromManagerMutation = useDeleteUserFromManagerMutation;
// ===============
// 15) resend_verification_email (POST /manager/resend_verification_email)
// ===============
var useResendVerificationEmailMutation = function (queryClient, options) {
    return (0, react_query_1.useMutation)(__assign({ mutationFn: function (email) {
            return (0, api_1.request)({
                url: "/manager/resend_verification_email",
                method: "post",
                data: email,
            });
        } }, options), queryClient);
};
exports.useResendVerificationEmailMutation = useResendVerificationEmailMutation;
var useAddUserAsManagerMutation = function (queryClient, options) {
    return (0, react_query_1.useMutation)(__assign({ mutationFn: function (params) {
            return (0, api_1.request)({
                url: "/admin/group/".concat(params.id_group, "/user/").concat(params.id_user, "/grant_manager_access"),
                method: "post",
            });
        } }, options), queryClient);
};
exports.useAddUserAsManagerMutation = useAddUserAsManagerMutation;
var useAdminCreateGroupMutation = function (queryClient, options) {
    return (0, react_query_1.useMutation)(__assign({ mutationFn: function (data) { return __awaiter(void 0, void 0, void 0, function () {
            return __generator(this, function (_a) {
                return [2 /*return*/, (0, api_1.request)({
                        url: "/admin/create_group",
                        method: "post",
                        data: data,
                    })];
            });
        }); } }, options), queryClient);
};
exports.useAdminCreateGroupMutation = useAdminCreateGroupMutation;
