import React, { useState } from 'react';
import { Menu, Popover } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/20/solid';
import { t } from 'i18next';

type FilterItem = {
  id: string;
  name: string;
};

type FilterProps = {
  label: string;
  items: FilterItem[];
  selectedItems: Set<string>;
  onSelectionChange: (selected: Set<string>) => void;
};

function FilterDropdown({
  label,
  items,
  selectedItems,
  onSelectionChange,
}: FilterProps) {
  const toggleSelection = (id: string) => {
    const newSelectedItems = new Set(selectedItems);
    if (newSelectedItems.has(id)) {
      newSelectedItems.delete(id);
    } else {
      newSelectedItems.add(id);
    }
    onSelectionChange(newSelectedItems);
  };

  return (
    <Popover as="div" className="relative inline-block text-left">
      <div>
        <Popover.Button className="group inline-flex items-center justify-center text-sm font-medium text-gray-700 hover:text-gray-900">
          <span>{label}</span>
          {items.length === selectedItems.size ? (
            <span className="ml-1.5 rounded bg-gray-200 px-1.5 py-0.5 text-xs font-semibold tabular-nums text-gray-700">
              {t('generic.all')}
            </span>
          ) : null}
          <ChevronDownIcon
            className="-mr-1 ml-1 h-5 w-5 flex-shrink-0 text-gray-400 group-hover:text-gray-500"
            aria-hidden="true"
          />
        </Popover.Button>
      </div>

      <Popover.Panel className="absolute right-0 z-20 mt-2 max-h-80 overflow-scroll origin-top-right rounded-md bg-white p-4 shadow-2xl ring-1 ring-black ring-opacity-5 transition focus:outline-none">
        <form className="space-y-4">
          {items.map(({ id, name }) => (
            <div key={id} className="flex items-center">
              <input
                id={`filter-${id}`}
                name={`${id}[]`}
                value={id}
                type="checkbox"
                className="h-4 w-4 rounded border-gray-300 text-blue-600 focus:ring-blue-500"
                checked={selectedItems.has(id)}
                onChange={() => toggleSelection(id)}
              />
              <label
                htmlFor={`filter-${id}`}
                className="ml-3 whitespace-nowrap pr-6 text-sm font-medium text-gray-900"
              >
                {name}
              </label>
            </div>
          ))}
        </form>
      </Popover.Panel>
    </Popover>
  );
}

export default FilterDropdown;
