import { useQueryClient } from '@tanstack/react-query';
import {
  CustomCounterIncrementSchema,
  useDeleteSlotMutation,
  useEditSlotMutation,
} from '@youshift/shared/hooks/mutations';
import { Iteration, SectionSlot, SlotLabel } from '@youshift/shared/types';
import { Dispatch, SetStateAction, useMemo, useState } from 'react';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { minutesToHours } from '@youshift/shared/utils';

import SlotDetailsBase from './SlotDetails';

export interface EditSlotProps {
  shiftLabels: SlotLabel[];
  slot: SectionSlot;
  iteration: Iteration;
  idSection: number;
  setOpen: Dispatch<SetStateAction<boolean>>;
}

function formatDateTimeUTC(dateString: string): string {
  const date = new Date(dateString);

  // Use built-in UTC methods to get each part of the date and time in UTC
  const year = date.getUTCFullYear();
  const month = String(date.getUTCMonth() + 1).padStart(2, '0'); // Months are zero-based
  const day = String(date.getUTCDate()).padStart(2, '0');
  const hours = String(date.getUTCHours()).padStart(2, '0');
  const minutes = String(date.getUTCMinutes()).padStart(2, '0');

  // Combine them into the required format
  return `${year}-${month}-${day}T${hours}:${minutes}`;
}

function EditSlot({
  shiftLabels,
  slot,
  iteration,
  idSection,
  setOpen,
}: EditSlotProps) {
  const idItr = iteration.id_itr;
  const [selectedLabelId, setSelectedLabelId] = useState<number | null>(
    slot.id_slot_label,
  );
  const [startTime, setStartTime] = useState<string>(
    formatDateTimeUTC(slot.start),
  );
  const [endTime, setEndTime] = useState<string>(formatDateTimeUTC(slot.end));
  const [restHours, setRestHours] = useState<number>(
    minutesToHours(slot.rest_period),
  );
  const [counterIncrementSchemas, setCounterIncrementSchemas] = useState<
    CustomCounterIncrementSchema[]
  >(
    Object.entries(slot.custom_counter_slot_increments).map(
      ([idCounter, inc]) => ({
        id_custom_counter: Number(idCounter),
        value: inc,
      }),
    ),
  );

  const duration = useMemo(() => {
    const startDate = new Date(startTime);
    const endDate = new Date(endTime);

    // Get the difference in milliseconds
    const differenceInMs = endDate.getTime() - startDate.getTime();

    // Convert milliseconds to minutes
    const differenceInMinutes = Math.floor(differenceInMs / (1000 * 60));

    return differenceInMinutes;
  }, [endTime, startTime]);

  const { t } = useTranslation();
  const queryClient = useQueryClient();

  const editSlotMutation = useEditSlotMutation(queryClient, {
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['section', idSection.toString()],
      });
      toast.success(t('manager.sectionsConfig.editSlotSuccess'));
      setOpen(false);
    },
  });

  const deleteSlotMutation = useDeleteSlotMutation(queryClient, {
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['section', idSection.toString()],
      });
      queryClient.invalidateQueries({
        queryKey: ['sectionSlots', String(idItr)],
      });
      queryClient.invalidateQueries({
        queryKey: ['extendedUserReqRules', String(idItr)],
      });
      queryClient.invalidateQueries({
        queryKey: ['assignmentTool', String(idItr)],
      });
      queryClient.invalidateQueries({
        queryKey: ['shiftAssignments', String(idItr)],
      });
      toast.success(t('manager.sectionsConfig.eliminateSlotSuccess'));
      setOpen(false);
    },
  });

  const handleSubmit = () => {
    if (selectedLabelId !== null) {
      editSlotMutation.mutate({
        duration_minutes: duration,
        id_itr: idItr,
        id_section_slot: slot.id_section_slot,
        id_slot_label: selectedLabelId,
        rest_period: restHours * 60,
        start: startTime,
        custom_counter_increments: counterIncrementSchemas,
      });
    }
  };

  const handleDelete = () => {
    deleteSlotMutation.mutate({
      id_itr: idItr,
      id_section_slot: slot.id_section_slot,
    });
  };

  const buttonDisabled = useMemo(
    () => selectedLabelId === null || startTime === '' || endTime === '',
    [selectedLabelId, startTime, endTime],
  );

  if (!slot) {
    return null;
  }

  return (
    <SlotDetailsBase
      counterIncrementSchemas={counterIncrementSchemas}
      setCounterIncrementSchemas={setCounterIncrementSchemas}
      selectedLabelId={selectedLabelId}
      setSelectedLabelId={setSelectedLabelId}
      startTime={startTime}
      setStartTime={setStartTime}
      endTime={endTime}
      setEndTime={setEndTime}
      restHours={restHours}
      setRestHours={setRestHours}
      duration={duration}
      shiftLabels={shiftLabels}
      buttonDisabled={buttonDisabled}
      onSubmit={handleSubmit}
      onDelete={handleDelete}
      submitButtonLabel={t('generic.edit')}
      itrStart={iteration.start_day}
      itrEnd={iteration.end_day}
    />
  );
}

export default EditSlot;
