import { Dialog } from '@headlessui/react';
import {
  ExclamationTriangleIcon,
  XMarkIcon,
} from '@heroicons/react/24/outline';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import toast from 'react-hot-toast';

import { YSButton } from './Buttons';

interface DeleteConfirmationModalProps {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => void;
  title: string;
  description: string;
  confirmationText?: string;
}

function DeleteConfirmationModal({
  isOpen,
  onClose,
  onConfirm,
  title,
  description,
  confirmationText,
}: DeleteConfirmationModalProps) {
  const { t } = useTranslation();
  const [inputText, setInputText] = useState('');

  const handleDelete = () => {
    if (confirmationText && inputText !== confirmationText) {
      toast.error(t('generic.confirmationTextError'));
      return;
    }
    onConfirm();
  };

  return (
    <Dialog open={isOpen} onClose={onClose} className="relative z-50">
      <Dialog.Backdrop className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />

      <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
        <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
          <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
            <div className="absolute right-0 top-0 hidden pr-4 pt-4 sm:block">
              <button
                type="button"
                className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                onClick={onClose}
              >
                <span className="sr-only">{t('generic.close')}</span>
                <XMarkIcon className="h-6 w-6" aria-hidden="true" />
              </button>
            </div>

            <div className="sm:flex sm:items-start">
              <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                <ExclamationTriangleIcon
                  className="h-6 w-6 text-red-600"
                  aria-hidden="true"
                />
              </div>
              <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                <Dialog.Title
                  as="h3"
                  className="text-base font-semibold leading-6 text-gray-900"
                >
                  {title}
                </Dialog.Title>
                <div className="mt-2">
                  <p className="text-sm text-gray-500">{description}</p>
                </div>

                {confirmationText && (
                  <>
                    <div className="mt-2">
                      <p className="text-sm text-gray-500">
                        {t('generic.typeToConfirm')}
                        <br />
                        <span className="font-bold text-center">
                          {confirmationText}
                        </span>
                      </p>
                    </div>
                    <div className="mt-2">
                      <input
                        placeholder={confirmationText}
                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                        type="text"
                        onChange={e => setInputText(e.currentTarget.value)}
                      />
                    </div>
                  </>
                )}
              </div>
            </div>

            <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
              <YSButton
                variant="delete"
                onClick={handleDelete}
                classNames="sm:ml-3"
              >
                {t('generic.delete')}
              </YSButton>
              <YSButton
                variant="ghost-secondary"
                onClick={() => {
                  onClose();
                }}
              >
                {t('generic.cancel')}
              </YSButton>
            </div>
          </Dialog.Panel>
        </div>
      </div>
    </Dialog>
  );
}

export default DeleteConfirmationModal;
