import { Industry } from '@youshift/shared/types';
import { Outlet, useParams } from 'react-router-dom';

export default function AuthLayout() {
  const { industry: industryStr = '' } = useParams();
  const industry =
    industryStr.toUpperCase() in Industry
      ? (industryStr.toUpperCase() as Industry)
      : Industry.OTHER;

  return (
    <div className="flex min-h-screen align-center">
      <Outlet />
      <div className="relative hidden w-0 flex-1 lg:block">
        <img
          className="absolute inset-0 h-full w-full object-cover min-h-screen"
          src={`https://youshift-docs.s3.eu-west-3.amazonaws.com/signup-pictures/${industry.toLowerCase()}.jpg`}
          alt=""
        />
      </div>
    </div>
  );
}
