"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.useDeleteCounterMutation = exports.useEditCounterMutation = exports.useCreateCounterMutation = void 0;
var api_1 = require("../../api");
var react_query_1 = require("@tanstack/react-query");
var useCreateCounterMutation = function (queryClient, options) {
    return (0, react_query_1.useMutation)(__assign({ mutationFn: function (params) {
            return (0, api_1.request)({
                url: "manager/group/counters/new",
                method: "post",
                data: params,
            });
        } }, options), queryClient);
};
exports.useCreateCounterMutation = useCreateCounterMutation;
var useEditCounterMutation = function (queryClient, options) {
    return (0, react_query_1.useMutation)(__assign({ mutationFn: function (params) {
            var id_custom_counter = params.id_custom_counter, data = __rest(params, ["id_custom_counter"]);
            return (0, api_1.request)({
                url: "manager/group/counters/".concat(id_custom_counter, "/edit"),
                method: "patch",
                data: data,
            });
        } }, options), queryClient);
};
exports.useEditCounterMutation = useEditCounterMutation;
var useDeleteCounterMutation = function (queryClient, options) {
    return (0, react_query_1.useMutation)(__assign({ mutationFn: function (params) {
            var id_custom_counter = params.id_custom_counter;
            return (0, api_1.request)({
                url: "manager/group/counters/".concat(id_custom_counter, "/delete"),
                method: "delete",
            });
        } }, options), queryClient);
};
exports.useDeleteCounterMutation = useDeleteCounterMutation;
