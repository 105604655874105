import { motion } from 'framer-motion';
import { useTranslation } from 'react-i18next';
import { StopIcon } from '@heroicons/react/24/solid';

import { YSButton } from './Buttons';

export default function RunningAlgOverlay({
  onStopClick,
}: {
  onStopClick: () => void;
}) {
  const { t } = useTranslation();

  // Animation variants for bouncing effect
  const bounceVariants = {
    initial: { y: 0 },
    animate: (i: number) => ({
      y: [0, -10, 0],
      transition: {
        delay: i * 0.2,
        repeat: Infinity,
        repeatType: 'loop' as const,
        duration: 0.6,
        ease: 'easeInOut',
      },
    }),
  };

  return (
    <div className="h-full bg-white bg-opacity-80 flex flex-col gap-3 justify-center items-center z-40 mt-12">
      <div className="flex gap-2">
        {[0, 1, 2].map(index => (
          <motion.img
            key={index}
            src="https://youshift-docs.s3.eu-west-3.amazonaws.com/youshift_bubbles.png"
            alt="Loading"
            className="h-8 w-8"
            variants={bounceVariants}
            initial="initial"
            animate={bounceVariants.animate(index)}
          />
        ))}
      </div>
      <h3 className="font-bold text-xl">
        {t('manager.shiftAssignment.runningAlgorithmTitle')}
      </h3>
      <h4 className="text-lg">
        {t('manager.shiftAssignment.runningAlgorithmSubtitle')}
      </h4>
      <YSButton
        variant="delete"
        classNames="items-center"
        onClick={onStopClick}
      >
        <StopIcon className="w-6 h-6 text-red-900 -ml-2 mr-1" />
        {t('manager.runningAlg.interrupt')}
      </YSButton>
    </div>
  );
}
