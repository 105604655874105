import { Disclosure } from '@headlessui/react';
import {
  CheckBadgeIcon,
  ChevronUpIcon,
  ExclamationCircleIcon,
} from '@heroicons/react/24/outline';
import {
  Section,
  SectionSlot,
  ShiftAssignment,
  UserReqRule,
  UserRequirement,
  UserRequirementType,
  VirtualSlot,
} from '@youshift/shared/types';

import UserReqTable from './UserReqTable';
import {
  CrossGroupIncompatibilityErrors,
  SingleGroupIncompatibilityErrors,
  UserAssignmentErrors,
  UserRequirementRuleErrors,
  VirtualSlotErrors,
} from '../../../utils/iteration_checks/assignment_checks/types';
import SingleIncompTable from './SingleIncompTable';
import CrossGroupTable from './CrossGroupTable';
import UserErrors from './UserErrors';
import VirtualSlotsTable from './VirtualSlotsTable';
import { UserRequirementsTable } from './UserRequirementsTable';

type Props = {
  assignmentsForUser: ShiftAssignment[];
  name?: string;
  noErrors: boolean;
  sections?: Record<number, Section>;
  sectionSlots?: Record<number, SectionSlot>;
  allUserReqs: Record<number, UserReqRule>;
  userErrors?: UserAssignmentErrors[number];
  userReqsForUser?: Record<number, UserRequirement | null>;
};

function UserErrorsTableDisclosure({
  assignmentsForUser,
  name,
  noErrors,
  sections,
  sectionSlots,
  userErrors,
  userReqsForUser,
  allUserReqs,
}: Props) {
  return (
    <Disclosure>
      {({ open }) => (
        <>
          {/* Title Bar */}
          <Disclosure.Button
            className={`flex justify-start gap-1 mb-2 items-center w-full px-4 py-2 text-lg font-medium text-left ${noErrors ? 'bg-green-100' : 'bg-gray-100 hover:bg-gray-200'} text-gray-900 rounded-lg focus:outline-none focus-visible:ring focus-visible:ring-blue-500 focus-visible:ring-opacity-75`}
          >
            <ChevronUpIcon
              className={`${
                open ? 'transform rotate-180' : ''
              } w-5 h-5 text-gray-500`}
            />
            {noErrors ? (
              <CheckBadgeIcon className="text-teal-600 w-7 h-7 font-bold" />
            ) : (
              <div className="flex flex-row gap-1 justify-center items-center">
                <ExclamationCircleIcon className="w-6 h-6 justify-center items-center rounded-full text-red-600 mr-3" />
              </div>
            )}

            <span>{`${name}`}</span>
          </Disclosure.Button>

          {/* Collapsible Content */}
          <Disclosure.Panel className="text-sm text-gray-700">
            {userReqsForUser && assignmentsForUser && (
              <UserRequirementsTable
                userReqsForUser={userReqsForUser}
                allUserReqs={allUserReqs}
                assignmentsForUser={assignmentsForUser}
                sectionSlots={sectionSlots}
              />
            )}
            {sections && sectionSlots && userErrors && (
              <UserErrors
                userErrors={userErrors}
                sectionSlots={sectionSlots}
                sections={sections}
              />
            )}
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  );
}

export default UserErrorsTableDisclosure;
