"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.useEditNeedsMutation = exports.useExcludeUsersSectionMutation = exports.useIncludeUsersSectionMutation = exports.useCreateSectionMutation = exports.useDeleteSectionMutation = exports.useEditSectionMutation = void 0;
var react_query_1 = require("@tanstack/react-query");
var api_1 = require("../../api");
var useEditSectionMutation = function (queryClient, options) {
    return (0, react_query_1.useMutation)(__assign({ mutationFn: function (params) {
            var id_itr = params.id_itr, id_section = params.id_section, name = params.name, acronym = params.acronym, color = params.color;
            return (0, api_1.request)({
                url: "manager/itrs/".concat(id_itr, "/sections/").concat(id_section, "/edit"),
                method: "patch",
                data: { name: name, acronym: acronym, color: color },
            });
        } }, options), queryClient);
};
exports.useEditSectionMutation = useEditSectionMutation;
var useDeleteSectionMutation = function (queryClient, options) {
    return (0, react_query_1.useMutation)(__assign({ mutationFn: function (params) {
            var id_itr = params.id_itr, id_section = params.id_section;
            return (0, api_1.request)({
                url: "manager/itrs/".concat(id_itr, "/sections/").concat(id_section, "/delete"),
                method: "delete",
            });
        } }, options), queryClient);
};
exports.useDeleteSectionMutation = useDeleteSectionMutation;
var useCreateSectionMutation = function (queryClient, options) {
    return (0, react_query_1.useMutation)(__assign({ mutationFn: function (params) {
            var id_itr = params.id_itr, rest = __rest(params, ["id_itr"]);
            return (0, api_1.request)({
                url: "manager/itrs/".concat(id_itr, "/sections/create"),
                method: "post",
                data: rest,
            });
        } }, options), queryClient);
};
exports.useCreateSectionMutation = useCreateSectionMutation;
var useIncludeUsersSectionMutation = function (queryClient, options) {
    return (0, react_query_1.useMutation)(__assign({ mutationFn: function (params) {
            var id_users = params.id_users, id_itr = params.id_itr, id_section = params.id_section;
            return (0, api_1.request)({
                url: "manager/itrs/".concat(id_itr, "/sections/").concat(id_section, "/reqs/include"),
                method: "post",
                data: id_users,
            });
        } }, options), queryClient);
};
exports.useIncludeUsersSectionMutation = useIncludeUsersSectionMutation;
var useExcludeUsersSectionMutation = function (queryClient, options) {
    return (0, react_query_1.useMutation)(__assign({ mutationFn: function (params) {
            var id_users = params.id_users, id_itr = params.id_itr, id_section = params.id_section;
            return (0, api_1.request)({
                url: "manager/itrs/".concat(id_itr, "/sections/").concat(id_section, "/reqs/exclude"),
                method: "post",
                data: id_users,
            });
        } }, options), queryClient);
};
exports.useExcludeUsersSectionMutation = useExcludeUsersSectionMutation;
var useEditNeedsMutation = function (queryClient, options) {
    return (0, react_query_1.useMutation)(__assign({ mutationFn: function (params) {
            var needs = params.needs, rest_periods = params.rest_periods, id_itr = params.id_itr, slot_labels = params.slot_labels;
            return (0, api_1.request)({
                url: "manager/itrs/".concat(id_itr, "/edit_needs"),
                method: "patch",
                data: { needs: needs, rest_periods: rest_periods, slot_labels: slot_labels },
            });
        } }, options), queryClient);
};
exports.useEditNeedsMutation = useEditNeedsMutation;
