import {
  SectionSlot,
  ShiftAssignment,
  SpecialEvent,
  SpecialEventWithoutDates,
  UserPreference,
} from '@youshift/shared/types';
import { dateToString } from '@youshift/shared/utils';
import { useMemo } from 'react';

import { AssignmentsMap, Epa, EventsMap, PreferencesMap } from './types';

export function useEpaData({
  shiftAssignments,
  userPreferences,
  preferenceSlots,
  events,
}: {
  shiftAssignments: ShiftAssignment[];
  userPreferences: Record<number, UserPreference[]>;
  preferenceSlots: Record<number, { section_slots: number[] }>;
  events: SpecialEvent[];
}): Epa {
  const assignmentsMap: AssignmentsMap = useMemo(() => {
    const byUser: Record<number, ShiftAssignment[]> = {};
    const bySectionSlot: Record<number, ShiftAssignment[]> = {};

    shiftAssignments?.forEach(assignment => {
      const { id_user, id_section_slot } = assignment;
      if (!bySectionSlot[id_section_slot]) {
        bySectionSlot[id_section_slot] = [];
      }
      if (!byUser[id_user]) {
        byUser[id_user] = [];
      }
      byUser[id_user].push(assignment);
      bySectionSlot[id_section_slot].push(assignment);
    });
    return { byUser, bySectionSlot };
  }, [shiftAssignments]);

  const preferencesMap: PreferencesMap = useMemo(() => {
    const byUser: Record<number, UserPreference[]> = {};
    const byPreferenceSlot: Record<number, UserPreference[]> = {};
    const bySectionSlotbyUser: Record<
      number,
      Record<number, UserPreference>
    > = {};

    Object.values(userPreferences).forEach(userPrefs =>
      userPrefs.forEach(pref => {
        const { id_user, id_pref_slot } = pref;

        if (!byUser[id_user]) {
          byUser[id_user] = [];
        }
        byUser[id_user].push(pref);

        if (!byPreferenceSlot[id_pref_slot]) {
          byPreferenceSlot[id_pref_slot] = [];
        }
        byPreferenceSlot[id_pref_slot].push(pref);

        const associatedSectionSlots =
          preferenceSlots[id_pref_slot]?.section_slots || [];
        associatedSectionSlots.forEach(id_section_slot => {
          if (!bySectionSlotbyUser[id_section_slot]) {
            bySectionSlotbyUser[id_section_slot] = {};
          }
          bySectionSlotbyUser[id_section_slot][id_user] = pref;
        });
      }),
    );

    return { byUser, byPreferenceSlot, bySectionSlotbyUser };
  }, [userPreferences, preferenceSlots]);

  const eventsMap: EventsMap = useMemo(() => {
    const byUser: Record<number, SpecialEvent[]> = {};
    const byDate: Record<string, SpecialEventWithoutDates[]> = {};

    events.forEach(event => {
      const { id_user, start, end, ...rest } = event;
      const endDate = new Date(end);

      if (!byUser[id_user]) {
        byUser[id_user] = [];
      }
      byUser[id_user].push(event);

      for (
        let date = new Date(start);
        date <= endDate;
        date.setDate(date.getDate() + 1)
      ) {
        const dateString = dateToString(date, 'dd/mm/yyyy');

        if (!byDate[dateString]) {
          byDate[dateString] = [];
        }
        byDate[dateString].push({ id_user, ...rest });
      }
    });

    return { byUser, byDate };
  }, [events]);

  return {
    eventsMap,
    preferencesMap,
    assignmentsMap,
  };
}

export const borderOfSpanningSlot = (
  spanning: string | boolean,
): React.CSSProperties => {
  if (spanning === 'start') {
    return { borderRightStyle: 'dashed', borderRightWidth: '3px' };
  } else if (spanning === 'end') {
    return { borderLeftStyle: 'dashed', borderLeftWidth: '3px' };
  } else return {};
};
