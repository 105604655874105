/* eslint-disable max-len */
import { useQueryClient } from '@tanstack/react-query';
import {
  useArchiveItrMutation,
  useAssignmentToConfigMutation,
  useConfigToAssignmentMutation,
  useDeleteItrAlgResultsMutation,
  useDeleteItrMutation,
  useEditMessageMutation,
  useInterruptAlgMutation,
  usePublishResultsMutation,
  useRunAlgMutation,
  useUnarchiveItrMutation,
  useUnpublishResultsMutation,
} from '@youshift/shared/hooks/mutations';
import {
  ItrStatuses,
  Section,
  SectionSlot,
  ShiftAssignmentType,
  VirtualSlot,
} from '@youshift/shared/types';
import { parseIterationDates } from '@youshift/shared/utils';
import { useMemo, useState } from 'react';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { Outlet, useNavigate, useParams } from 'react-router-dom';

import Alert from '../components/FormFeedback/Alert';
// import ConfigurationPreview from '../components/Iteration/ConfigurationPreview';
import { YSButton } from '../components/Buttons';
import DeleteConfirmationModal from '../components/DeleteConfirmationModal';
import DraggableOverlay from '../components/DraggableOverlay';
import Steps from '../components/Iteration/Steps';
import Toggle from '../components/Iteration/Toggle';
import AssignmentChecksDisplay from '../components/ItrChecks/AssignmentChecksDisplay';
import ConfigChecksDisplay from '../components/ItrChecks/ConfigChecksDisplay';
import Wrapper from '../components/Wrapper';
import ArchiveConfirmation from '../pages/Manager/IterationVerification/ArchiveConfirmation';
import Unpublish from '../pages/Manager/IterationVerification/Unpublish';
import { useAppOptions } from '../utils/AppOptionsContext';
import {
  determineErrorsSeverity,
  ErrorsSeverity,
} from '../utils/iteration_checks/types';
import { useItrContext } from './IterationRootLayout/IterationRootLayout';
import ChecksToolbar from '../components/ChecksToolbar';
import PublishModal from '../pages/Manager/IterationVerification/PublishModal';

export default function IterationLayout() {
  const { idItr } = useParams();
  const {
    users,
    sectionsWithSlots,
    iteration,
    incompatibilities,
    allUserReqs,
    status,
    epa,
    configErrors,
    assignmentErrors,
  } = useItrContext();
  const {
    start_day,
    end_day,
    locked,
    message: itrMessage,
    chain,
    itr_type,
  } = iteration;
  const numberOfSections = sectionsWithSlots.length;

  const { t } = useTranslation();
  const { appOptions } = useAppOptions();
  const [deleteItrOpen, setDeleteItrOpen] = useState(false);
  const [unpublishOpen, setUnpublishOpen] = useState(false);
  const [configDrawerOpen, setConfigDrawerOpen] = useState(false);
  const [assignmentDrawerOpen, setAssignmentDrawerOpen] = useState(false);
  const [archiveOpen, setArchiveOpen] = useState(false);
  const [toggleEnabled, setToggleEnabled] = useState(locked ?? false);
  const [publishModalOpen, setPublishModalOpen] = useState(false);
  const [message, setMessage] = useState(itrMessage);
  const [messageSaved, setMessageSaved] = useState(false);
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const publishResults = usePublishResultsMutation(queryClient, {
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['iteration', idItr] });
      queryClient.setQueryData(['status', idItr], ItrStatuses.PUBLISHED);
    },
  });

  const unpublishResults = useUnpublishResultsMutation(queryClient, {
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['iteration', idItr] });
      queryClient.setQueryData(['status', idItr], ItrStatuses.ASSIGNMENT);
    },
  });

  const archiveItr = useArchiveItrMutation(queryClient, {
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['iteration', idItr] });
      queryClient.setQueryData(['status', idItr], ItrStatuses.ARCHIVED);
    },
  });

  const unarchiveItr = useUnarchiveItrMutation(queryClient, {
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['iteration', idItr] });
      queryClient.setQueryData(['status', idItr], ItrStatuses.PUBLISHED);
    },
  });

  const editMessage = useEditMessageMutation(queryClient, {
    onSuccess: () => {
      setMessageSaved(true);
    },
  });

  const assignmentToConfig = useAssignmentToConfigMutation(queryClient, {
    onSuccess: data => {
      queryClient.setQueryData(['status', idItr], ItrStatuses.CONFIGURATION);
      navigate(`/manager/iteration/${idItr}/configuration/roles`);
    },
  });

  const configToAssignment = useConfigToAssignmentMutation(queryClient, {
    onSuccess: () => {
      queryClient.setQueryData(['status', idItr], ItrStatuses.ASSIGNMENT);
      navigate(`/manager/iteration/${idItr}/assignment/manual`);
    },
  });

  const handleConfigToAssignment = () => {
    if (numberOfSections === 0) {
      toast.error(t('manager.iterationLayouts.noSections'));
      return;
    }
    configToAssignment.mutate({ id_itr: idItr });
  };

  const deleteItrMutation = useDeleteItrMutation(queryClient, {
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['iteration', idItr] });
      setDeleteItrOpen(false);
      navigate('/manager/chains');
    },
  });

  const runAlgorithmMutation = useRunAlgMutation(queryClient, {
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['iteration', idItr] });
      queryClient.invalidateQueries({ queryKey: ['status', idItr] });
    },
  });

  const interruptAlgorithm = useInterruptAlgMutation(queryClient, {
    onSuccess: () => {
      toast.success(t('manager.runningAlg.interruptAlgSuccess'));
      queryClient.invalidateQueries({ queryKey: ['iteration', idItr] });
      queryClient.invalidateQueries({ queryKey: ['status', idItr] });
    },
  });

  const deleteItrAlgResults = useDeleteItrAlgResultsMutation(queryClient, {
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['iteration', idItr] });
      queryClient.invalidateQueries({ queryKey: ['shiftAssignments', idItr] });
    },
  });

  const configuration = status === ItrStatuses.CONFIGURATION;
  const assignment = status === ItrStatuses.ASSIGNMENT;
  const published = status === ItrStatuses.PUBLISHED;
  const archived = status === ItrStatuses.ARCHIVED;

  const nameAndDate = `${chain?.chain_name}: ${parseIterationDates(start_day, end_day, itr_type)}`;

  const allVirtualSlots: VirtualSlot[] = sectionsWithSlots.flatMap(
    ({ virtual_slots }) => Object.values(virtual_slots),
  );

  const virtualSlotsDict = useMemo(
    () =>
      allVirtualSlots.reduce<Record<number, VirtualSlot>>((acc, slot) => {
        acc[slot.id_virtual_slot] = slot;
        return acc;
      }, {}),
    [allVirtualSlots],
  );

  const sectionsDict: Record<number, Section> = useMemo(
    () =>
      sectionsWithSlots.reduce<Record<number, Section>>((acc, { section }) => {
        acc[section.id_section] = section;
        return acc;
      }, {}),
    [sectionsWithSlots],
  );

  const sectionSlotsDict: Record<number, SectionSlot> = useMemo(
    () =>
      sectionsWithSlots.reduce<Record<number, SectionSlot>>(
        (acc, { section_slots }) => ({
          ...acc,
          ...section_slots,
        }),
        {},
      ),
    [sectionsWithSlots],
  );

  const hasConfigErrors = Object.values(configErrors).some(
    errors => Object.keys(errors).length > 0,
  );

  const assignmentErrorsSeverity =
    assignmentErrors && determineErrorsSeverity(assignmentErrors);

  const runAlgorithm = () => {
    if (hasConfigErrors) {
      setConfigDrawerOpen(true);
      toast.error(t('manager.iterationLayouts.runAlgorithmConfigErrors'));
      return;
    }
    if (assignmentErrorsSeverity === ErrorsSeverity.ERRORS) {
      setAssignmentDrawerOpen(true);
      toast.error(t('manager.iterationLayouts.runAlgorithmAssignmentErrors'));
      return;
    }
    if (!appOptions?.app_config.allow_alg_executions) {
      toast.error(
        t('manager.iterationLayouts.runAlgorithmDisabledMaintenance'),
      );
      return;
    }
    runAlgorithmMutation.mutate({ id_itr: idItr });
  };

  return (
    <Wrapper>
      {/* {configPreviewOpen ? (
        <ConfigurationPreview
          open={configPreviewOpen}
          setOpen={setConfigPreviewOpen}
        />
      ) : null} */}
      <PublishModal
        open={publishModalOpen}
        setOpen={setPublishModalOpen}
        publishResults={publishResults}
      />
      <Unpublish
        open={unpublishOpen}
        setOpen={setUnpublishOpen}
        unpublishResults={unpublishResults}
      />
      <DeleteConfirmationModal
        isOpen={deleteItrOpen}
        onClose={() => setDeleteItrOpen(false)}
        onConfirm={() => deleteItrMutation.mutate({ id_itr: idItr })}
        title={t('manager.iterationConfig.deleteIterationTitle')}
        description={t('manager.iterationConfig.deleteIterationWarning')}
        confirmationText={t(
          'manager.iterationConfig.deleteIterationPlaceholder',
        )}
      />
      <ArchiveConfirmation
        open={archiveOpen}
        setOpen={setArchiveOpen}
        archiveItr={archiveItr}
      />
      {configuration && (
        <ChecksToolbar
          status={status}
          openConfigErrors={() => setConfigDrawerOpen(true)}
          hasConfigErrors={!!Object.keys(configErrors).length}
          shouldBeShown={!configDrawerOpen}
        />
      )}
      <ChecksToolbar
        status={status}
        openAssignmentErrors={() => setAssignmentDrawerOpen(true)}
        openConfigErrors={() => setConfigDrawerOpen(true)}
        hasConfigErrors={hasConfigErrors}
        assignmentErrorsSeverity={assignmentErrorsSeverity}
        shouldBeShown={!assignmentDrawerOpen && !configDrawerOpen}
        onStopClick={() => interruptAlgorithm.mutate({ id_itr: idItr })}
        isAlgBeingInterrupted={interruptAlgorithm.isPending}
        runAlg={runAlgorithm}
        runAlgIsPending={runAlgorithmMutation.isPending}
        areThereAlgorithmAssignedShifts={Object.values(
          epa.assignmentsMap.bySectionSlot,
        ).some(assignmentsForSectionSlot =>
          assignmentsForSectionSlot.some(
            assignment => assignment.type === ShiftAssignmentType.ALGORITHM,
          ),
        )}
        onDeleteAssignments={() =>
          deleteItrAlgResults.mutate({ id_itr: idItr })
        }
        lastExecutionStatus={iteration.last_execution_status}
      />
      <DraggableOverlay
        open={configDrawerOpen}
        setOpen={setConfigDrawerOpen}
        title={t('manager.configErrors.title')}
        w="w-1/3"
      >
        <ConfigChecksDisplay
          errors={configErrors}
          users={users}
          // incompatibilitiesNames={Object.fromEntries([
          //   ...Object.entries(incompatibilities.single_group_incomp).map(
          //     ([id, rule]) => [id, rule.name],
          //   ),
          //   ...Object.entries(incompatibilities.cross_group_incomp).map(
          //     ([id, rule]) => [id, rule.name],
          //   ),
          // ])}
          allUserReqs={allUserReqs}
          // sectionSlots={sectionSlotsDict}
          virtualSlots={virtualSlotsDict}
          sections={sectionsDict}
          // assignmentsByUser={epa.assignmentsMap.byUser}
        />
      </DraggableOverlay>
      {assignmentErrors && (
        <DraggableOverlay
          open={assignmentDrawerOpen}
          setOpen={setAssignmentDrawerOpen}
          title={t('manager.assignmentErrors.title')}
        >
          <AssignmentChecksDisplay
            errors={assignmentErrors}
            users={users}
            incompatibilitiesNames={Object.fromEntries([
              ...Object.entries(incompatibilities.single_group_incomp).map(
                ([id, rule]) => [id, rule.name],
              ),
              ...Object.entries(incompatibilities.cross_group_incomp).map(
                ([id, rule]) => [id, rule.name],
              ),
            ])}
            allUserReqs={allUserReqs}
            sectionSlots={sectionSlotsDict}
            virtualSlots={virtualSlotsDict}
            sections={sectionsDict}
            assignmentsByUser={epa.assignmentsMap.byUser}
          />
        </DraggableOverlay>
      )}
      <div className="flex flex-row justify-between mb-3 items-center">
        <p className="text-2xl text-blue-600 font-bold">{nameAndDate}</p>
        {configuration ? (
          <div className="flex flex-row gap-2">
            <YSButton onClick={() => setDeleteItrOpen(true)} variant="delete">
              {t('manager.iterationLayouts.delete')}
            </YSButton>
            <YSButton onClick={handleConfigToAssignment}>
              {t('manager.iterationLayouts.manual')}
            </YSButton>
          </div>
        ) : null}
        {assignment ? (
          <div className="flex flex-row gap-2">
            <YSButton
              onClick={() => assignmentToConfig.mutate({ id_itr: idItr })}
              variant="outline-primary"
            >
              {t('manager.iterationLayouts.backToConfig')}
            </YSButton>
            <YSButton
              onClick={() => {
                setPublishModalOpen(true);
              }}
            >
              {t('manager.iterationLayouts.publish')}
            </YSButton>
          </div>
        ) : null}
        {published && !archived ? (
          <div className="flex flex-row gap-2">
            <YSButton
              variant="ghost-secondary"
              onClick={() => setArchiveOpen(true)}
            >
              {t('generic.archive')}
            </YSButton>
            <YSButton
              variant="outline-primary"
              onClick={() => setUnpublishOpen(true)}
            >
              {t('generic.unpublish')}
            </YSButton>
          </div>
        ) : null}
        {archived ? (
          <YSButton onClick={() => unarchiveItr.mutate({ id_itr: idItr })}>
            {t('generic.unarchive')}
          </YSButton>
        ) : null}
      </div>
      {archived ? (
        <div className="bg-gray-400 h-8 rounded-md flex justify-center items-center text-white font-bold">
          {t('generic.archived')}
        </div>
      ) : null}
      <Steps
        currentStep={status}
        setOpen={() => {}}
        // setOpen={setConfigPreviewOpen}
      />
      {configuration || assignment ? (
        <div className="mt-5 my-5 flex justify-between gap-2 items-end">
          <div className="flex items-center flex-grow relative flex-1">
            <label
              htmlFor="message"
              className="absolute -top-1 left-2 inline-block bg-white px-1 text-xs font-medium text-gray-900"
            >
              {t('manager.iterationLayouts.message')}
            </label>
            <div className="mt-2 flex justify-end flex-grow gap-1">
              <textarea
                name="message"
                id="message"
                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                placeholder={t('manager.iterationLayouts.messagePlaceholder')}
                maxLength={250}
                rows={1}
                value={message || undefined}
                onChange={e => {
                  setMessageSaved(false);
                  setMessage(e.target.value);
                }}
              />
              <YSButton
                type="button"
                disabled={messageSaved || message === itrMessage}
                onClick={() => editMessage.mutate({ message, id_itr: idItr })}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  stroke="white"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="1.5"
                  viewBox="0 0 24 24"
                  className="w-4 h-4"
                >
                  <path d="M20 7.828a2 2 0 0 0-.586-1.414l-1.828-1.828A2 2 0 0 0 16.172 4H6a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V7.828z" />
                  <path d="M14 4v4H8V4m0 16v-8h8v8" />
                </svg>
              </YSButton>
            </div>
          </div>
          <Toggle enabled={toggleEnabled} setEnabled={setToggleEnabled} />
        </div>
      ) : null}
      {configuration && !locked ? (
        <p className="text-red-600 -mt-2 text-center mb-2">
          {t('manager.participantsConfig.locked')}
        </p>
      ) : null}
      <Outlet />
    </Wrapper>
  );
}
