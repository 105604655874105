import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Industry } from '@youshift/shared/types';

const industrySvgPaths = {
  [Industry.HEALTHCARE]: [
    'M12 6v4',
    'M14 14h-4',
    'M14 18h-4',
    'M14 8h-4',
    'M18 12h2a2 2 0 0 1 2 2v6a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2v-9a2 2 0 0 1 2-2h2',
    'M18 22V4a2 2 0 0 0-2-2H8a2 2 0 0 0-2 2v18',
  ],
  [Industry.HOSPITALITY]: [
    'M12 3V2',
    'm15.4 17.4 3.2-2.8a2 2 0 1 1 2.8 2.9l-3.6 3.3c-.7.8-1.7 1.2-2.8 1.2h-4c-1.1 0-2.1-.4-2.8-1.2l-1.302-1.464A1 1 0 0 0 6.151 19H5',
    'M2 14h12a2 2 0 0 1 0 4h-2',
    'M4 10h16',
    'M5 10a7 7 0 0 1 14 0',
    'M5 14v6a1 1 0 0 1-1 1H2',
  ],
  [Industry.LOGISTICS]: [
    'M14 18V6a2 2 0 0 0-2-2H4a2 2 0 0 0-2 2v11a1 1 0 0 0 1 1h2',
    'M15 18H9',
    'M19 18h2a1 1 0 0 0 1-1v-3.65a1 1 0 0 0-.22-.624l-3.48-4.35A1 1 0 0 0 17.52 8H14',
    'M17 18a2 2 0 1 0 0-4 2 2 0 0 0 0 4z',
    'M7 18a2 2 0 1 0 0-4 2 2 0 0 0 0 4z',
  ],
  [Industry.RETAIL]: [
    'M4 2v20l2-1 2 1 2-1 2 1 2-1 2 1 2-1 2 1V2l-2 1-2-1-2 1-2-1-2 1-2-1-2 1Z',
    'M14 8H8',
    'M16 12H8',
    'M13 16H8',
  ],
  [Industry.OTHER]: [
    'M8 2v4',
    'M16 2v4',
    'M3 4h18a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2z',
    'M3 10h18',
    'M8 14h.01',
    'M12 14h.01',
    'M16 14h.01',
    'M8 18h.01',
    'M12 18h.01',
    'M16 18h.01',
  ],
};

export default function ChooseIndustry(): JSX.Element {
  const { t } = useTranslation();

  return (
    <div className="isolate overflow-hidden bg-gradient-to-b from-blue-600 to-teal-400">
      <div className="mx-auto max-w-7xl px-6 pb-32 pt-24 text-center sm:pt-32 lg:px-8">
        <div className="mx-auto max-w-4xl">
          <h2 className="text-3xl font-bold tracking-tight text-white sm:text-5xl">
            {t('auth.industrySelector.title')}
          </h2>
        </div>
      </div>

      <div className="flow-root bg-white pb-24 sm:pb-32">
        <div className="-mt-20">
          <div className="mx-auto max-w-7xl px-6 lg:px-8">
            <div className="mx-auto grid max-w-md grid-cols-1 gap-8 sm:grid-cols-2 lg:max-w-4xl">
              {Object.values(Industry).map((industry, index) => (
                <Link
                  key={industry}
                  to={`${industry.toLowerCase()}`}
                  className={`relative flex flex-col ${index === 0 ? 'col-span-2' : ''} overflow-hidden rounded-2xl bg-white p-8 shadow-xl ring-1 ring-gray-900/10 transition-all hover:shadow-2xl hover:-translate-y-1`}
                >
                  <div className="flex flex-col items-center justify-center space-y-4">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      className="w-16 h-16"
                    >
                      {industrySvgPaths[industry].map((path, index) => (
                        <path key={index} d={path} />
                      ))}
                    </svg>
                    <h2 className="text-2xl font-semibold text-gray-900">
                      {t(`auth.industrySelector.${industry.toLowerCase()}`)}
                    </h2>
                    {/* <p className="text-sm text-gray-500">
                      {t(
                        `auth.industrySelector.${industry.toLowerCase()}Description`,
                      )}
                    </p> */}
                  </div>
                </Link>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
