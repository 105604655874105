/* eslint-disable @typescript-eslint/no-unused-vars */
import { Disclosure } from '@headlessui/react';
import { ChevronUpIcon } from '@heroicons/react/20/solid';
import {
  CalendarDaysIcon,
  MapPinIcon,
  UserIcon,
} from '@heroicons/react/24/outline';
import { QueryClient, useQuery, useQueryClient } from '@tanstack/react-query';
import { preLoadQuery } from '@youshift/shared/hooks';
import { useApproveExchangeResponseMutation } from '@youshift/shared/hooks/mutations';
import {
  managerFetchExchangeApprovalsQuery,
  personnelQuery,
} from '@youshift/shared/hooks/queries';
import {
  ExchangeResponseStatus,
  ExchangeResponseType,
} from '@youshift/shared/types';
import {
  generateErrorStringFromError,
  localeNormalizer,
  parseSectionSlotDateTime,
} from '@youshift/shared/utils';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';

import AcceptIcon from '../../assets/AcceptIcon';
import ExchangeIcon from '../../assets/ExchangeIcon';
import i18n from '../../utils/i18n';
import Wrapper from '../../components/Wrapper';

export const managerShiftExchangeLoader =
  (queryClient: QueryClient) => async () => {
    const approvals = await preLoadQuery(
      queryClient,
      managerFetchExchangeApprovalsQuery(),
    );
    const personnel = await preLoadQuery(queryClient, personnelQuery());

    return { approvals, personnel };
  };

function ManagerShiftExchange() {
  const { data } = useQuery(managerFetchExchangeApprovalsQuery());
  const { data: personnel } = useQuery(personnelQuery());

  const locale = localeNormalizer(i18n.language);

  const { t } = useTranslation();

  const queryClient = useQueryClient();

  const approveResponseMutation = useApproveExchangeResponseMutation(
    queryClient,
    {
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: ['managerFetchExchangeApprovals'],
        });
        toast.success(t('manager.shiftExchange.successfulApproval'));
      },
      // onError: error => {
      //   const { errorKey, context } = generateErrorStringFromError(
      //     error,
      //     t('manager.shiftExchange.errorApproval'),
      //   );
      //   const errorString = t(errorKey, context);
      //   toast.error(errorString);
      // },
    },
  );

  return (
    <Wrapper>
      <div className="flex flex-row justify-between">
        <h3 className="text-2xl font-semibold leading-6 text-gray-900 my-4">
          {t('generic.shiftExchange.title')}
        </h3>
      </div>
      {/* ONGOING */}
      <Disclosure defaultOpen>
        {({ open }) => (
          <div>
            <Disclosure.Button className="flex w-full justify-between rounded-lg bg-blue-500 px-4 py-2 text-left text-sm font-medium text-white hover:bg-blue-500 focus:outline-none focus-visible:ring focus-visible:ring-blue-500 focus-visible:ring-opacity-75">
              <span>{t('generic.shiftExchange.ongoing')}</span>
              <ChevronUpIcon
                className={`${open ? 'rotate-180 transform' : ''} h-5 w-5 text-white`}
              />
            </Disclosure.Button>
            <Disclosure.Panel className="px-4 pt-2 pb-2 text-sm text-gray-500">
              <div className="flex flex-row justify-around text-xl mt-5 text-black">
                <h3>{t('manager.shiftExchange.requests')}</h3>
                <h3>{t('manager.shiftExchange.responses')}</h3>
              </div>
              {data &&
                Object.entries(data).map(([id_itr, { requests, config }]) =>
                  Object.entries(requests.ongoing).map(
                    ([id_request, { request, candidate_responses }]) => {
                      const request_shift_assignment =
                        config.shift_assignments[request.id_shift_assignment];

                      const request_section_slot =
                        config.section_slots[
                          request_shift_assignment.id_section_slot
                        ];
                      return (
                        <div className="flex flex-row mt-5 items-center pb-4 border-b border-gray-500">
                          <div className="border border-slate-200 shadow-md rounded-lg py-4 px-6 w-[45%] mr-4">
                            <div className="flex flex-row justify-between items-center">
                              <div>
                                <div className="flex flex-row items-center gap-1">
                                  <CalendarDaysIcon className="h-4" />
                                  <p>
                                    {parseSectionSlotDateTime(
                                      request_section_slot.start,
                                      request_section_slot.end,
                                      locale,
                                    )}
                                  </p>
                                </div>
                                <div className="flex flex-row items-center gap-1">
                                  <MapPinIcon className="h-4" />
                                  <p>
                                    {' '}
                                    {
                                      config.sections[
                                        request_shift_assignment.id_section
                                      ].name
                                    }
                                  </p>
                                </div>
                              </div>
                            </div>
                            <p className="my-3">
                              <span className="font-semibold">
                                {t('generic.justification')}
                              </span>{' '}
                              {request.reason}
                            </p>
                            <div className="flex flex-row items-center gap-1">
                              <UserIcon className="h-4" />
                              <p className="my-3">
                                {
                                  personnel?.users[request.id_requestor]
                                    .firstname
                                }{' '}
                                {
                                  personnel?.users[request.id_requestor]
                                    .lastname
                                }
                              </p>
                            </div>
                          </div>
                          <div className="flex flex-col gap-3">
                            {/* map over responses for each request */}
                            {Object.entries(candidate_responses).map(
                              ([id_response, response]) => (
                                <div className="flex flex-row items-center">
                                  {response.type ===
                                  ExchangeResponseType.ONE_FOR_ONE ? (
                                    <ExchangeIcon status={response.status} />
                                  ) : response.status ===
                                      ExchangeResponseStatus.REJECTED_DOCTOR ||
                                    response.status ===
                                      ExchangeResponseStatus.REJECTED_MANAGER ? (
                                    <AcceptIcon status="rejected_right" />
                                  ) : (
                                    <AcceptIcon status="pending_right" />
                                  )}
                                  <div className="border border-slate-200 shadow-md rounded-lg py-4 px-6 ml-4 my-auto">
                                    <div className="flex flex-row justify-between items-center gap-20">
                                      {(() => {
                                        const respondent =
                                          personnel?.users[
                                            response.id_respondent
                                          ];

                                        if (
                                          response.type ===
                                            ExchangeResponseType.ONE_FOR_ONE &&
                                          response.id_shift_assignment
                                        ) {
                                          const shiftAssignment =
                                            config.shift_assignments[
                                              response.id_shift_assignment
                                            ];
                                          const sectionSlot =
                                            config.section_slots[
                                              shiftAssignment.id_section_slot
                                            ];
                                          const section =
                                            config.sections[
                                              request_shift_assignment
                                                .id_section
                                            ];

                                          return (
                                            <div className="flex flex-col justify-between items-start">
                                              <div className="flex flex-row items-center gap-1">
                                                <CalendarDaysIcon className="h-4" />
                                                <p>
                                                  {new Date(
                                                    sectionSlot.start,
                                                  ).toLocaleString(undefined, {
                                                    weekday: 'long',
                                                    day: '2-digit',
                                                    month: 'long',
                                                    timeZone: 'GMT',
                                                  })}
                                                </p>
                                              </div>
                                              <div className="flex flex-row items-center gap-1">
                                                <MapPinIcon className="h-4" />
                                                <p> {section.name}</p>
                                              </div>
                                              <div className="flex flex-row items-center gap-1">
                                                <UserIcon className="h-4" />
                                                <p className="my-3">
                                                  {respondent?.firstname}{' '}
                                                  {respondent?.lastname}
                                                </p>
                                              </div>
                                            </div>
                                          );
                                        }

                                        if (
                                          response.type ===
                                          ExchangeResponseType.ONE_FOR_ZERO
                                        ) {
                                          return (
                                            <div className="flex flex-col justify-between items-start">
                                              <div className="flex flex-row items-center gap-1">
                                                <UserIcon className="h-4" />
                                                <p className="my-3">
                                                  {respondent?.firstname}{' '}
                                                  {respondent?.lastname}
                                                </p>
                                              </div>
                                            </div>
                                          );
                                        }

                                        return null;
                                      })()}
                                      {response.status ===
                                      ExchangeResponseStatus.PENDING_MANAGER ? (
                                        <div className="flex flex-col gap-2">
                                          <button
                                            type="button"
                                            className="border border-teal-400 inline-flex w-full my-auto justify-center items-center rounded-md px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm hover:bg-teal-400/50 sm:ml-3 sm:w-auto"
                                            onClick={() =>
                                              approveResponseMutation.mutate({
                                                id_response,
                                                approved: true,
                                              })
                                            }
                                          >
                                            {t('generic.approve')}
                                          </button>
                                          <button
                                            type="button"
                                            className="border border-red-400 inline-flex w-full my-auto justify-center items-center rounded-md px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm hover:bg-red-400/50 sm:ml-3 sm:w-auto"
                                            onClick={() =>
                                              approveResponseMutation.mutate({
                                                id_response,
                                                approved: false,
                                              })
                                            }
                                          >
                                            {t('generic.reject')}
                                          </button>
                                        </div>
                                      ) : null}
                                    </div>
                                    {response.status ===
                                    ExchangeResponseStatus.PENDING_DOCTOR ? (
                                      <p className="text-xs text-orange-400">
                                        {t(
                                          'generic.shiftExchange.pendingDoctor',
                                        )}
                                      </p>
                                    ) : response.status ===
                                      ExchangeResponseStatus.REJECTED_MANAGER ? (
                                      <p className="text-xs text-red-400">
                                        {t(
                                          'generic.shiftExchange.rejectedManager',
                                        )}
                                      </p>
                                    ) : response.status ===
                                      ExchangeResponseStatus.REJECTED_DOCTOR ? (
                                      <p className="text-xs text-red-400">
                                        {t(
                                          'generic.shiftExchange.rejectedDoctor',
                                        )}
                                      </p>
                                    ) : response.status ===
                                      ExchangeResponseStatus.CANCELED_SYSTEM ? (
                                      <p className="text-xs text-red-400">
                                        {t(
                                          'generic.shiftExchange.canceledSystem',
                                        )}
                                      </p>
                                    ) : null}
                                  </div>
                                </div>
                              ),
                            )}
                          </div>
                        </div>
                      );
                    },
                  ),
                )}
            </Disclosure.Panel>
          </div>
        )}
      </Disclosure>
      {/* Completed */}
      <Disclosure>
        {({ open }) => (
          <div>
            <Disclosure.Button className="flex w-full justify-between rounded-lg bg-blue-500 px-4 py-2 text-left text-sm font-medium text-white hover:bg-blue-500 focus:outline-none focus-visible:ring focus-visible:ring-blue-500 focus-visible:ring-opacity-75">
              <span>{t('generic.shiftExchange.completed')}</span>
              <ChevronUpIcon
                className={`${open ? 'rotate-180 transform' : ''} h-5 w-5 text-white`}
              />
            </Disclosure.Button>
            <Disclosure.Panel className="px-4 pt-2 pb-2 text-sm text-gray-500">
              <div className="flex flex-row justify-around text-xl mt-5 text-black">
                <h3>{t('manager.shiftExchange.requestorsNewShifts')}</h3>
                <h3>{t('manager.shiftExchange.respondersNewShifts')}</h3>
              </div>
              {/* User's completed requests */}
              {data &&
                Object.entries(data).map(([id_itr, { requests, config }]) =>
                  Object.entries(requests.completed).map(
                    ([id_request, { request, approved_response }]) => {
                      const request_shift_assignment =
                        config.shift_assignments[request.id_shift_assignment];
                      const request_section_slot =
                        config.section_slots[
                          request_shift_assignment.id_section_slot
                        ];

                      if (
                        approved_response.type ===
                          ExchangeResponseType.ONE_FOR_ONE &&
                        // Added for Typing purposes
                        approved_response.id_shift_assignment
                      ) {
                        const one_for_one_approved_response_shift_assignment =
                          config.shift_assignments[
                            approved_response.id_shift_assignment
                          ];

                        const one_for_one_approved_response_section_slot =
                          config.section_slots[
                            one_for_one_approved_response_shift_assignment
                              .id_section_slot
                          ];

                        return (
                          <div className="justify-between mt-5 items-center grid grid-cols-[2fr_1fr_2fr]">
                            <div className="border border-slate-200 shadow-md rounded-lg py-3 px-4 mr-4">
                              <div className="flex flex-col justify-between items-start">
                                <div className="flex flex-row items-center gap-1">
                                  <CalendarDaysIcon className="h-4" />
                                  <p>
                                    {parseSectionSlotDateTime(
                                      one_for_one_approved_response_section_slot.start,
                                      one_for_one_approved_response_section_slot.end,
                                      locale,
                                    )}
                                  </p>
                                </div>
                                <div className="flex flex-row items-center gap-1">
                                  <MapPinIcon className="h-4" />
                                  <p>
                                    {
                                      config.sections[
                                        one_for_one_approved_response_shift_assignment
                                          .id_section
                                      ].name
                                    }
                                  </p>
                                </div>
                                <div className="flex flex-row items-center gap-1">
                                  <UserIcon className="h-4" />
                                  <p className="">
                                    {
                                      personnel?.users[request.id_requestor]
                                        .firstname
                                    }{' '}
                                    {
                                      personnel?.users[request.id_requestor]
                                        .lastname
                                    }
                                  </p>
                                </div>
                              </div>
                            </div>
                            <div className="mx-auto">
                              <ExchangeIcon status="approved" />
                            </div>
                            <div className="border border-slate-200 shadow-md rounded-lg py-3 px-4 mr-4">
                              <div className="flex flex-col justify-between items-start">
                                <div className="flex flex-row items-center gap-1">
                                  <CalendarDaysIcon className="h-4" />
                                  <p>
                                    {parseSectionSlotDateTime(
                                      request_section_slot.start,
                                      request_section_slot.end,
                                      locale,
                                    )}
                                  </p>
                                </div>
                                <div className="flex flex-row items-center gap-1">
                                  <MapPinIcon className="h-4" />
                                  <p>
                                    {
                                      config.sections[
                                        request_shift_assignment.id_section
                                      ].name
                                    }
                                  </p>
                                </div>
                                <div className="flex flex-row items-center gap-1">
                                  <UserIcon className="h-4" />
                                  <p className="">
                                    {
                                      personnel?.users[
                                        approved_response.id_respondent
                                      ].firstname
                                    }{' '}
                                    {
                                      personnel?.users[
                                        approved_response.id_respondent
                                      ].lastname
                                    }
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        );
                      }
                      if (
                        approved_response.type ===
                        ExchangeResponseType.ONE_FOR_ZERO
                      ) {
                        return (
                          <div className="justify-between mt-5 items-center grid grid-cols-[2fr_1fr_2fr]">
                            <div className="border border-slate-400 border-dashed shadow-md rounded-lg py-3 px-4 mr-4 h-full flex items-center">
                              <div className="flex flex-row items-center gap-1">
                                <UserIcon className="h-4" />
                                <p className="">
                                  {
                                    personnel?.users[request.id_requestor]
                                      .firstname
                                  }{' '}
                                  {
                                    personnel?.users[request.id_requestor]
                                      .lastname
                                  }
                                </p>
                              </div>
                            </div>
                            <div className="mx-auto">
                              <AcceptIcon status="completed_right" />
                            </div>
                            <div className="border border-slate-200 shadow-md rounded-lg py-3 px-4 mr-4">
                              <div className="flex flex-col justify-between items-start">
                                <div className="flex flex-row items-center gap-1">
                                  <CalendarDaysIcon className="h-4" />
                                  <p>
                                    {parseSectionSlotDateTime(
                                      request_section_slot.start,
                                      request_section_slot.end,
                                      locale,
                                    )}
                                  </p>
                                </div>
                                <div className="flex flex-row items-center gap-1">
                                  <MapPinIcon className="h-4" />
                                  <p>
                                    {
                                      config.sections[
                                        request_shift_assignment.id_section
                                      ].name
                                    }
                                  </p>
                                </div>
                                <div className="flex flex-row items-center gap-1">
                                  <UserIcon className="h-4" />
                                  <p className="">
                                    {
                                      personnel?.users[
                                        approved_response.id_respondent
                                      ].firstname
                                    }{' '}
                                    {
                                      personnel?.users[
                                        approved_response.id_respondent
                                      ].lastname
                                    }
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        );
                      }
                      // If it's not a 1-for-1 or 1-for-0, it's not a valid exchange
                      return null;
                    },
                  ),
                )}
            </Disclosure.Panel>
          </div>
        )}
      </Disclosure>
    </Wrapper>
  );
}

export default ManagerShiftExchange;
