import { TextField } from '@mui/material';
import { useTranslation } from 'react-i18next';
import PlacesAutocomplete from 'react-places-autocomplete';
import { useState } from 'react';
import { Country } from '@youshift/shared/types';

import { FormActions, OrganizationInfo } from './types';
import {
  AddressComponents,
  usePlacesAutocomplete,
} from './usePlacesAutocomplete';

interface NewOrganizationSignUpFormProps {
  organization: OrganizationInfo;
  dispatch: React.Dispatch<FormActions>;
}

export default function NewOrganizationSignUpForm({
  organization,
  dispatch,
}: NewOrganizationSignUpFormProps) {
  const { address, setAddress, loading, handleSelect, initialized } =
    usePlacesAutocomplete();
  const { t } = useTranslation();

  const onAddressSelect = (components: AddressComponents) => {
    dispatch({
      type: 'UPDATE_ORGANIZATION_FIELDS',
      payload: {
        name: components.name || '',
        country: components.country,
        state: components.state,
        province: components.province,
        city: components.city,
        address: components.route,
        postal_code: components.postal_code,
      },
    });
  };

  const [noResults, setNoResults] = useState(false);

  return (
    <div className="my-12">
      {initialized && process.env.REACT_APP_GOOGLE_MAPS_API_KEY && (
        <PlacesAutocomplete
          value={address}
          onChange={setAddress}
          onSelect={value => handleSelect(value, onAddressSelect)}
          debounce={500}
          onError={(status, clearSuggestions) => {
            clearSuggestions();
            setNoResults(true);
          }}
        >
          {({
            getInputProps,
            suggestions,
            getSuggestionItemProps,
            loading,
          }) => (
            <div className="mb-8 flex flex-row gap-1 items-center relative pb-2">
              <svg
                viewBox="-3 0 262 262"
                xmlns="http://www.w3.org/2000/svg"
                preserveAspectRatio="xMidYMid"
                fill="#000000"
                className="h-8 w-8"
              >
                <g id="SVGRepo_bgCarrier" strokeWidth="0" />
                <g
                  id="SVGRepo_tracerCarrier"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <g id="SVGRepo_iconCarrier">
                  <path
                    d="M255.878 133.451c0-10.734-.871-18.567-2.756-26.69H130.55v48.448h71.947c-1.45 12.04-9.283 30.172-26.69 42.356l-.244 1.622 38.755 30.023 2.685.268c24.659-22.774 38.875-56.282 38.875-96.027"
                    fill="#4285F4"
                  />
                  <path
                    d="M130.55 261.1c35.248 0 64.839-11.605 86.453-31.622l-41.196-31.913c-11.024 7.688-25.82 13.055-45.257 13.055-34.523 0-63.824-22.773-74.269-54.25l-1.531.13-40.298 31.187-.527 1.465C35.393 231.798 79.49 261.1 130.55 261.1"
                    fill="#34A853"
                  />
                  <path
                    d="M56.281 156.37c-2.756-8.123-4.351-16.827-4.351-25.82 0-8.994 1.595-17.697 4.206-25.82l-.073-1.73L15.26 71.312l-1.335.635C5.077 89.644 0 109.517 0 130.55s5.077 40.905 13.925 58.602l42.356-32.782"
                    fill="#FBBC05"
                  />
                  <path
                    d="M130.55 50.479c24.514 0 41.05 10.589 50.479 19.438l36.844-35.974C195.245 12.91 165.798 0 130.55 0 79.49 0 35.393 29.301 13.925 71.947l42.211 32.783c10.59-31.477 39.891-54.251 74.414-54.251"
                    fill="#EB4335"
                  />
                </g>
              </svg>
              <TextField
                {...getInputProps({
                  placeholder: t('auth.signUpManager.lookUpOrgNamePlaceholder'),
                  className: 'w-full',
                })}
                label={t('auth.signUpManager.lookUpOrgName')}
                variant="outlined"
                fullWidth
              />
              <div className="absolute z-10 bg-white border border-gray-300 top-full">
                {loading && (
                  <div className="w-full rounded-md p-1">
                    {t('generic.loading')}...
                  </div>
                )}
                {!loading && noResults && address && (
                  <div className="w-full rounded-md p-1">
                    {t('generic.noResults')}
                  </div>
                )}
                {suggestions.map(suggestion => {
                  const style = {
                    backgroundColor: suggestion.active ? '#f0f0f0' : '#fff',
                    padding: '10px',
                    cursor: 'pointer',
                  };
                  return (
                    <div {...getSuggestionItemProps(suggestion, { style })}>
                      {suggestion.description}
                    </div>
                  );
                })}
              </div>
            </div>
          )}
        </PlacesAutocomplete>
      )}
      <div className="space-y-4">
        <div>
          <label
            htmlFor="organization-name"
            className="block text-sm font-medium leading-6 text-gray-900"
          >
            {t('auth.signUpManager.organizationName')}
          </label>
          <div className="mt-2">
            <input
              type="text"
              id="organization-name"
              value={organization.name}
              onChange={e =>
                dispatch({
                  type: 'UPDATE_ORGANIZATION_FIELDS',
                  payload: { name: e.target.value },
                })
              }
              className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
            />
          </div>
        </div>

        <div>
          <label
            htmlFor="organization-country"
            className="block text-sm font-medium leading-6 text-gray-900"
          >
            {t('generic.country')}
          </label>
          <div className="mt-2">
            <select
              id="organization-country"
              value={organization.country}
              onChange={e =>
                dispatch({
                  type: 'UPDATE_ORGANIZATION_FIELDS',
                  payload: { country: e.target.value },
                })
              }
              className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
            >
              <option value="">{}</option>
              {Object.entries(Country).map(([key, value]) => (
                <option key={key} value={value}>
                  {t(`countries.${value}`, value)}
                </option>
              ))}
            </select>
          </div>
        </div>

        <div>
          <label
            htmlFor="organization-state"
            className="block text-sm font-medium leading-6 text-gray-900"
          >
            {t('generic.state')}
          </label>
          <div className="mt-2">
            <input
              type="text"
              id="organization-state"
              value={organization.state}
              onChange={e =>
                dispatch({
                  type: 'UPDATE_ORGANIZATION_FIELDS',
                  payload: { state: e.target.value },
                })
              }
              className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
            />
          </div>
        </div>

        <div>
          <label
            htmlFor="organization-province"
            className="block text-sm font-medium leading-6 text-gray-900"
          >
            {t('generic.province')}
          </label>
          <div className="mt-2">
            <input
              type="text"
              id="organization-province"
              value={organization.province}
              onChange={e =>
                dispatch({
                  type: 'UPDATE_ORGANIZATION_FIELDS',
                  payload: { province: e.target.value },
                })
              }
              className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
            />
          </div>
        </div>

        <div>
          <label
            htmlFor="organization-city"
            className="block text-sm font-medium leading-6 text-gray-900"
          >
            {t('generic.city')}
          </label>
          <div className="mt-2">
            <input
              type="text"
              id="organization-city"
              value={organization.city}
              onChange={e =>
                dispatch({
                  type: 'UPDATE_ORGANIZATION_FIELDS',
                  payload: { city: e.target.value },
                })
              }
              className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
            />
          </div>
        </div>
      </div>
    </div>
  );
}
